import React, {Component} from 'react';
import $ from 'jquery';
import './ChooseVersion.css'
import Fwdsync from '../../../../assets/images/fwdsync.svg';
import Rvrsync from '../../../../assets/images/rvrsync.svg';
import fwd_andrvrs from '../../../../assets/images/fwd_andrvrs.svg'
import AwsSetup from '../AWSSetup/AwsSetup'
import axios from 'axios';
import egeBCImg from '../../../../assets/images/egeBC.svg'
import Product from '../../../../assets/images/product.svg'
import Customer from '../../../../assets/images/customer-and-address.svg'
import Inventory from '../../../../assets/images/inventory.svg'
import Tierpricelevel from '../../../../assets/images/tierpricelevel.svg'
import Cancelorder from '../../../../assets/images/cancelorder.svg'
import Configurableproduct from '../../../../assets/images/configurableproduct.svg'
import Shipment from '../../../../assets/images/shipment.svg'

import Salesorder from '../../../../assets/images/Salesorder.svg'

import Businesstaxpostinggroup from '../../../../assets/images/business-tax-posting-group.svg'
import Customerdiscountgroup from '../../../../assets/images/customer-discount-group.svg'
import Discountprice from '../../../../assets/images/discount-price.svg'
import Editorder from '../../../../assets/images/edit-order.svg'
import Itemdiscountgroup from '../../../../assets/images/item-discount-group.svg'
import Itemtaxpostinggroup from '../../../../assets/images/item-tax-posting-group.svg'
import Taxposting from '../../../../assets/images/tax-posting.svg'

import EbayMarkerPlace from '../../../../assets/images/EGE/addons/1_ebay_marketplace_integration.png'
import AmazonMarketplace from '../../../../assets/images/EGE/addons/2_amazon_marketplace_integration.png'
import ClaueTheme from '../../../../assets/images/EGE/addons/3_claue_theme_integration.png'
import ElasticSearch from '../../../../assets/images/EGE/addons/4_elastic_search_integration.png'
import ChargeLogic from '../../../../assets/images/EGE/addons/5_charge_logic _integration.png'

//import businesscentrallimg from '../../../../assets/images/businesscentrall.png'
import businesscentrallimg from '../../../../assets/images/bcnew.png'

import egeimg from '../../../../assets/images/EGE.svg'

import API from '../../../api';

export default class ChooseVersion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            versionType: [],
            selectedId: 1,
            isLoaded: false,
            selectedCustomer1: this.props.versionid,
            featuresType: [],
            showPopup: false,
            ErpName: '',
            erptypeName: 1,
            imageversionType: [],
            producttypeName: '',
            connectorVersionId:'',
            //featuresType1:[]

        };
    }

    togglePopup() {
        this.setState({
            showPopup: !this.state.showPopup
        });
    }

    componentDidMount() { 
        this.handleversion();
    }
  
    handleversion = () => {
        let self = this;
        var response =window.localStorage.getItem("accessToken") ;
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        API.get(`/MarketPlace/connectors/${self.state.selectedCustomer1}/endpoint-versions`, { headers })
        .then(response => response)
        .then(data => {
            self.setState({
                versionType: data
            })
        })
    }
    
    handleNameErpName = (event) => {
        let newValue = event.target.value;
        if(newValue === "" || newValue === null){
            event.target.classList.remove('notEmptyfield')
            $("#cardbdy-ftrnew").addClass("d-none")
            $("#cardftr-ftrnew").addClass("d-none")
            $("#featuersWarning").removeClass("d-none")
            $("#selecteCommerceVersion").removeClass("notEmptyfield")
            $("#ddlViewBy").removeClass("notEmptyfield")
        }else{
            event.target.classList.add('notEmptyfield')
        }

        this.setState({ erptypeName: event.target.value });
        this.setState({featuresType1:[]})
        this.setState({featuresType:[]})
        window.localStorage.setItem("Erp",event.target.value)
    }

    handleItems = (event) => {
        let newValue = event.target.value;

        if(newValue === "" || newValue === null){
            event.target.classList.remove('notEmptyfield')
            $("#cardbdy-ftrnew").addClass("d-none")
            $("#cardftr-ftrnew").addClass("d-none")
            $("#featuersWarning").removeClass("d-none")
            $("#ddlViewBy").removeClass("notEmptyfield")
        }else{
            event.target.classList.add('notEmptyfield')
            document.getElementById("loaderContainer").classList.remove("d-none");
        }

        this.setState({ ErpName: event.target.value });
        this.setState({featuresType1:[]})
        this.setState({featuresType:[]})
        window.localStorage.setItem("Ecommerce",event.target.value)
        let self = this;
        var response =window.localStorage.getItem("accessToken") ;
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };

        const body =
        {
            "id": parseInt(self.state.selectedCustomer1),
            "sourceVersion": `${self.state.erptypeName}`,
            "targetVersion": `${event.target.value}`,
        };
        API.post(`/MarketPlace/connectors/${self.state.selectedCustomer1}/features`, body, { headers })
        .then(response => response)
        .then(data => {
            document.getElementById("loaderContainer").classList.add("d-none");
            document.getElementById("ddlViewBy").classList.add("errorStateSelect");
            self.setState({
                featuresType: data
            });
        })
        
    }

    handleNameErp = (event) => {
        
        console.log("5321897",event.target.value)

        

        
        var e = document.getElementById("ddlViewBy");
        var result = e.options[e.selectedIndex].text;
        this.setState({ producttypeName: result});
        this.setState({selectedCustomer:event.target.value})
        window.localStorage.setItem("egeConnectorId",event.target.value)
        
        let newValue = event.target.value;

        if(newValue === "" || newValue === null){
            event.target.classList.remove('notEmptyfield')
            $("#cardbdy-ftrnew").addClass("d-none")
            $("#cardftr-ftrnew").addClass("d-none")
            $("#featuersWarning").removeClass("d-none")
            event.target.classList.add('errorStateSelect')
        }else{
            event.target.classList.add('notEmptyfield')
            $("#cardbdy-ftrnew").removeClass("d-none")
            $("#cardftr-ftrnew").removeClass("d-none")
            $("#featuersWarning").addClass("d-none")
            event.target.classList.remove('errorStateSelect')
        }
        
        let self = this;
        var response =window.localStorage.getItem("accessToken") ;
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };

        const body =
        {
            "id": parseInt(self.state.selectedCustomer1),
            "sourceVersion": `${self.state.erptypeName}`,
            "targetVersion": window.localStorage.getItem("Ecommerce"),
        };
        API.post(`/MarketPlace/connectors/${self.state.selectedCustomer1}/features`, body, { headers })
        .then(response => response)
        .then(data => {
            document.getElementById("loaderContainerfeatuers").classList.add("d-none");
            self.setState({
                isLoaded: true,
                featuresType1: data
            });
        })

        const bodynew =
        {
            "id": `${event.target.value}`,
        };
        
        API.post(`/MarketPlace/connectors/${event.target.value}/questions`, bodynew, { headers })
            .then(response => response)
            .then(data => {
                self.setState({
                    Questions: data
                });
            })

        
    }

    
    

    

    render() {

        let { versionType } = this.state;
        let { featuresType } = this.state;
        let { featuresType1 } = this.state;
        // let lenconfirmandproceed = featuresType && featuresType.data && featuresType.data.map((item,i)=>{
        //    if(item.latestVersion.versionQuestions.flat(1).length >0)
        //     return <button type="button" className="btn btn-primary w-md featuer-btn-confirm customPopupnew" onClick={this.togglePopup.bind(this)}>Confirm & Proceed</button>
        //    else 
        //     return  <button type="button" className="btn btn-primary w-md featuer-btn-confirm customPanopen"  onClick={() => this.setState({ isPaneOpen: true })}>Confirm & Proceed</button>;;
        // });

        let optionItems = Array.from(new Set(versionType && versionType.data && versionType.data.map(item => item.sourceVersion))).map(sourceVersion => {
            return <option value={sourceVersion}>{sourceVersion}</option>
        })

        let optionecommercetype =versionType && versionType.data && versionType.data.map(function(item, i) {
            if(item.sourceVersion ===this.state.erptypeName){
                return <option value={item.targetVersion}>{item.targetVersion} </option>;        
            }
        }.bind(this));

        return (
            <div className="egeChooseversion">
                <h4 className="newconnector-sub-title">
                    <span className="float-left color-blue prev-back" onClick={this.props.closePopup}><i className="uil-angle-left"></i> Back</span>
                    <span className="float-left">Choose a Version</span>
                    <span className="clearfix"></span>
                </h4>
                <div id="loaderContainer" className="loaderContainer d-none">
                    <div class="loader"></div>
                </div>
                <div id="loaderContainerfeatuers" className="loaderContainer d-none">
                    <div class="loader"></div>
                    <p> Please wait..., We are preparing Core Features & Addons related to your Selection </p>
                </div>
                <div className="row mt-3">
                    <div className="col-lg-6 mb-3">
                    <div className="card-columns card-columns-2 integration-type-listing">
                            <div className="card no-border no-boxshadow pb-0 mb-0">
                                <div className="card-body">
                                    <div className="connector-logo-container">
                                        <img src={businesscentrallimg} className="leftimage" />
                                    </div>
                                    <h5 className="add-connector-title"> {this.props.name} </h5>
                                </div>
                            </div>
                            
                            <div className="card no-border no-boxshadow pb-0 mb-0 attatchicon">
                                <div className="card-body">
                                    <div className="connector-logo-container">
                                    <img src={egeimg} className="rightimage" />
                                    </div>
                                    <h5 className="add-connector-title"> {this.props.targetName} </h5>
                                </div>
                            </div>
                        </div>
                        <div className="form-section mt-3">
                            <form className="custom-validation" action="#">
                                <div className="form-group">
                                    <select id="selectERPVersion" className="form-control version-field" required onChange={this.handleNameErpName}>
                                        <option value="">Select ERP Version</option>
                                        {optionItems}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <select id="selecteCommerceVersion" className="form-control version-field" required onChange={this.handleItems}>
                                        <option value="">Select eCommerce Version </option>
                                        {optionecommercetype}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <select id="selectProductType" className="form-control version-field" required onChange={this.handleNameErp} id="ddlViewBy">
                                        <option value=""> Select Product Type </option>
                                        {
                                            featuresType && featuresType.data && featuresType.data.map(
                                                item =><option value={item.latestVersion.id}>{item.displayName}</option>
                                            )
                                        }
                                    </select>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                        <div className="card featuer-container no-border no-boxshadow pb-0 mb-0">
                            <div className="card-header pb-0">
                                <h2 className="featuer-header"> Core Features & Addons </h2>
                            </div>
                            <div id="featuersWarning" className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-12"> 
                                        <div className="alert alert-warning" role="alert">
                                            Select all fields to get Core Features & Addons
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="cardbdy-ftrnew" className="card-body p-0">
                                {featuresType1 && featuresType1.data && featuresType1.data.length > 0 ?  
                                    <div className="row">
                                        <div className="col-md-12 col-lg-12">
                                            <div id="accordion" className="custom-accordion">
                                                <div className="card mb-1 shadow-none">
                                                    <a href="#collapseOne" className="text-dark" data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne">
                                                        <div className="card-header" id="headingOne">
                                                            <h6 className="m-0 subNewTitleway">
                                                                Core Features
                                                                <i className="mdi mdi-chevron-up float-right accor-down-icon"></i>
                                                            </h6>
                                                        </div>
                                                    </a>
                                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                                        <div className="card-body overflownewhight newoverflowy">
                                                            <div className="row">
                                                                {
                                                                    featuresType1 && featuresType1.data && featuresType1.data.map(
                                                                        item =>(
                                                                            item.latestVersion.versionEntities.map(itemv=>
                                                                                <div className="col-lg-4 card no-border no-boxshadow">
                                                                                    <div className="featuer-circle">
                                                                                        <span className="fwdsyncimg">
                                                                                            {
                                                                                            
                                                                                                (itemv.displayName == "Tier Prices") ? <img src={Tierpricelevel} /> :
                                                                                                (itemv.displayName == "Shipment") ? <img src={Shipment} /> :
                                                                                                (itemv.displayName == "ConfigurableProduct") ? <img src={Configurableproduct} /> :
                                                                                                (itemv.displayName == "Cancel Order") ? <img src={Cancelorder} /> :
                                                                                                (itemv.displayName == "Product") ? <img src={Product} /> :
                                                                                                (itemv.displayName == "Customer") ? <img src={Customer} /> :
                                                                                                (itemv.displayName == "Inventory") ? <img src={Inventory} /> :
                                                                                                (itemv.displayName == "Price Level") ? <img src={Tierpricelevel} /> :
                                                                                                (itemv.displayName == "Customer Group") ? <img src={Customer} /> : 
                                                                                                (itemv.displayName == "Invoice") ? <img src={Inventory} />:
                                                                                                (itemv.displayName == "Sales Order") ? <img src={Salesorder} />:
                            
                                                                                                (itemv.displayName == "Customer Discount Group") ?      <img src={Customerdiscountgroup} />:
                                                                                                (itemv.displayName == "Discount Price") ?               <img src={Discountprice} />:
                                                                                                (itemv.displayName == "Item Discount Group") ?          <img src={Itemdiscountgroup} />:
                                                                                                (itemv.displayName == "Edit Order") ?                   <img src={Editorder} />:
                                                                                                (itemv.displayName == "Tax Postings") ?                  <img src={Taxposting} />:
                                                                                                (itemv.displayName == "Tax Business Posting Group") ?   <img src={Businesstaxpostinggroup} />:
                                                                                                (itemv.displayName == "Tax Product Posting Group") ?    <img src={Taxposting} />:
                                                                                                (itemv.displayName == "Cash Receipt") ? <img src={Salesorder} />:null

                                                                                                //<img src={Itemtaxpostinggroup} />
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                    <h6> {itemv.displayName}</h6>
                                                                                </div>
                                                                            )
                                                                        )
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                                <div className="card mb-1 shadow-none">
                                                    <a href="#collapseTwo" className="text-dark collapsed" data-toggle="collapse" aria-expanded="false" aria-controls="collapseTwo">
                                                        <div className="card-header" id="headingTwo">
                                                            <h6 className="m-0 subNewTitleway">
                                                                Addons
                                                                <i className="mdi mdi-chevron-up float-right accor-down-icon"></i>
                                                            </h6>
                                                        </div>
                                                    </a>
                                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                                        <div className="card-body overflownewhight newoverflowy">
                                                            <div className="row">
                                                                <div className="col-lg-4 card no-border no-boxshadow">
                                                                    <div className="featuer-circle">
                                                                        <span className="fwdsyncimg">
                                                                            <img src={EbayMarkerPlace} />
                                                                        </span>
                                                                    </div>
                                                                    <h6> Ebay MarkerPlace </h6>
                                                                </div>
                                                                <div className="col-lg-4 card no-border no-boxshadow">
                                                                    <div className="featuer-circle">
                                                                        <span className="fwdsyncimg">
                                                                            <img src={AmazonMarketplace} />
                                                                        </span>
                                                                    </div>
                                                                    <h6> Amazon Marketplace </h6>
                                                                </div>
                                                                <div className="col-lg-4 card no-border no-boxshadow">
                                                                    <div className="featuer-circle">
                                                                        <span className="fwdsyncimg">
                                                                            <img src={ClaueTheme} />
                                                                        </span>
                                                                    </div>
                                                                    <h6> Claue Theme </h6>
                                                                </div>
                                                                <div className="col-lg-4 card no-border no-boxshadow">
                                                                    <div className="featuer-circle">
                                                                        <span className="fwdsyncimg">
                                                                            <img src={ElasticSearch} />
                                                                        </span>
                                                                    </div>
                                                                    <h6> Elastic Search </h6>
                                                                </div>
                                                                <div className="col-lg-4 card no-border no-boxshadow">
                                                                    <div className="featuer-circle">
                                                                        <span className="fwdsyncimg">
                                                                            <img src={ChargeLogic} />
                                                                        </span>
                                                                    </div>
                                                                    <h6> Charge Logic </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <div className="row d-none"> 
                                        <div className="col-lg-12"> 
                                            <div className="alert alert-warning" role="alert">
                                                Please Select all fields
                                            </div>
                                        </div> 
                                    </div>
                                }
                            </div>

                            

                            <div id="cardftr-ftrnew" className="card-footer pl-2">
                                <div className="">
                                    {/* <button type="submit" className="btn btn-primary w-md featuer-btn-confirm" onClick={this.togglePopup.bind(this)}>Confirm & Proceed</button> */}
                                    {this.state.isLoaded ? <button type="submit" className="btn btn-primary w-md featuer-btn-confirm" onClick={this.togglePopup.bind(this)}>Confirm & Proceed</button> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showPopup ? <AwsSetup closePopup={this.togglePopup.bind(this)}  features={this.state.Questions}/> : null}
            </div>
        )
    }
}