import React from 'react';
import SessionTimeout from '../SessionTimeout'
import './ege.css'
import egesidebanner from '../../assets/images/ege-side-banner.svg'
import closeicon from '../../assets/images/close-icon.svg';

import SlidingPane from "react-sliding-pane";
import EgeSetup from './EgeSetup/EgeSetup';
import Learnmore from './Learnmore/Learnmore';

import {Header} from '../Shared/Header';
import Sidebar from '../Shared/Sidebar';
import API from '../api';

class Ege extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPaneOpenegelearnmore:false,
            isPaneOpen: false,
            isPaneOpenLeft: false
        };
    }
    render() {
        return (
            <div className="container-fluid">
                <SessionTimeout />
                <Header />
                <Sidebar />
                <div className="main-content mn-ege">
                    <div className="page-content custompaddingdash">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card no-border no-boxshadow">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-7">
                                                    <p className="ege-mn-title">E-Commerce Growth Engine</p>
                                                    <p className="ege-mn-desc">
                                                        A comprehensive & intelligent eCommerce - ERP integrated platform that boosts businesses and helps them scale & achieve higher ROI. The seamless ERP integration provides businesses with multiple benefits such as timely updates, real-time inventory control, timely order placement, on-time delivery, efficient finance management, increased sales, better ROI from marketing, and not to mention, excellent customer experience. 
                                                        i95Dev is an eCommerce thought leader with a team of integration specialists that help build eCommerce businesses that are smart, seamless, productive & efficient. 
                                                    </p>
                                                </div>
                                                <div className="col-sm-5">
                                                    <div className="mt-4 mt-sm-0">
                                                        <img src={egesidebanner} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="mt-5 text-center">
                                                        <button type="button" className="create-yours-now" onClick={() => this.setState({ isPaneOpen: true })}> Create Yours Now </button><br />
                                                        <span className="text-primary learnmorenew" onClick={() => this.setState({ isPaneOpenegelearnmore: true })}> Learn More... </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <SlidingPane className='connector-popup' overlayClassName='some-custom-overlay-class' isOpen={ this.state.isPaneOpen } onRequestClose={ () => { this.setState({ isPaneOpen: true }); } }>
                                    <button className="popup-close" onClick={() => this.setState({ isPaneOpen: false })}>
                                        <img src={closeicon} />
                                    </button>
                                    <EgeSetup />
                                </SlidingPane>

                                <SlidingPane className='connector-popup ege-learnmore' overlayClassName='some-custom-overlay-class' isOpen={ this.state.isPaneOpenegelearnmore } onRequestClose={ () => { this.setState({ isPaneOpenegelearnmore: true }); } }>
                                    <button className="popup-close" onClick={() => this.setState({ isPaneOpenegelearnmore: false })}>
                                        <img src={closeicon} />
                                    </button>
                                    <Learnmore />
                                </SlidingPane>

                            </div>
                        </div>
                    </div>
                </div>
            </div>       
        )
    }
}
export default Ege