import React, { useEffect } from 'react';
import './Header.css'
import Headerbg from './Headerbg'
import minavatar from '../../assets/images/avatar4.jpg';
import { Auth } from 'aws-amplify'
import API from '../api'


export const WelcomeHeader = () => {
  const data = JSON.parse(window.localStorage.getItem('userData'));
  const userName=data?data.name:undefined
  const handleLogout = (logoutType) => {
    Auth.signOut();

  }

  const notificationPanel = () =>{
    document.getElementById("offer-custom-notification").classList.remove("d-none")
  }
  const closenotificationPanel = () =>{
    document.getElementById("offer-custom-notification").classList.add("d-none")
  }

  return (
    <div>
      <header id="page-topbar">

        <div className="page-topbarnew">
          <Headerbg />
        </div>
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <a href="" className="logo logo-dark">
                <span className="logo-sm">
                  <img src="" alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src="" alt="" height="20" />
                </span>
              </a>

              <a href="" className="logo logo-light">
                <span className="logo-sm">
                  <img src="" alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src="" alt="" height="20" />
                </span>
              </a>
            </div>
          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ml-2">
              <button type="button" className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="uil-search"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0" aria-labelledby="page-header-search-dropdown">
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input type="text" className="form-control" placeholder="Search..." aria-label="Recipient's username" />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit"><i className="mdi mdi-magnify"></i></button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>



            <div className="dropdown d-inline-block">
              <button type="button" className="btn header-item noti-icon waves-effect" onClick={() => notificationPanel()} id="page-header-notifications-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="uil-bell"></i>
                <span className="badge badge-danger badge-pill d-none">0</span>
              </button>
            </div>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0" aria-labelledby="page-header-notifications-dropdown">
              <div className="p-3">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="m-0 font-size-16"> Notifications </h5>
                  </div>
                  <div className="col-auto">
                    <a href="#!" className="small"> Mark all as read</a>
                  </div>
                </div>
              </div>
              <div data-simplebar>
                <a href="" className="text-reset notification-item">
                  <div className="media">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title bg-primary rounded-circle font-size-16">
                        <i className="uil-cart"></i>
                      </span>
                    </div>
                    <div className="media-body">
                      <h6 className="mt-0 mb-1">Your order is placed</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">If several languages coalesce the grammar</p>
                        <p className="mb-0"><i className="mdi mdi-clock-outline"></i> 3 min ago</p>
                      </div>
                    </div>
                  </div>
                </a>
                <a href="" className="text-reset notification-item">
                  <div className="media">
                    <img src={minavatar} className="mr-3 rounded-circle avatar-xs" alt="user-pic" />
                    <div className="media-body">
                      <h6 className="mt-0 mb-1">James Lemire</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">It will seem like simplified English.</p>
                        <p className="mb-0"><i className="mdi mdi-clock-outline"></i> 1 hours ago</p>
                      </div>
                    </div>
                  </div>
                </a>
                <a href="" className="text-reset notification-item">
                  <div className="media">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title bg-success rounded-circle font-size-16">
                        <i className="uil-truck"></i>
                      </span>
                    </div>
                    <div className="media-body">
                      <h6 className="mt-0 mb-1">Your item is shipped</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">If several languages coalesce the grammar</p>
                        <p className="mb-0"><i className="mdi mdi-clock-outline"></i> 3 min ago</p>
                      </div>
                    </div>
                  </div>
                </a>
                <a href="" className="text-reset notification-item">
                  <div className="media">
                    <img src={minavatar} className="mr-3 rounded-circle avatar-xs" alt="user-pic" />
                    <div className="media-body">
                      <h6 className="mt-0 mb-1">Salena Layfield</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">It will seem like simplified English.</p>
                        <p className="mb-0"><i className="mdi mdi-clock-outline"></i> 1 hours ago</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="p-2 border-top">
                <a className="btn btn-sm btn-link font-size-14 btn-block text-center" href="javascript:void(0)">
                  <i className="uil-arrow-circle-right mr-1"></i> View More..
                </a>
              </div>
            </div>
            <div className="dropdown d-inline-block">
              <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="uil-user"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <a className="dropdown-item" href="#">
                  <i className="uil uil-user-circle font-size-18 align-middle text-muted mr-1"></i>
                  <span className="align-middle">
                    {userName}
                  </span>
                </a>
                {/* <NavLink className="dropdown-item" to="/Profile">
                  <i className="uil uil-cog font-size-18 align-middle mr-1 text-muted"></i>
                  <span className="align-middle">My Profile</span>
                </NavLink> */}
                <a className="dropdown-item" onClick={() => handleLogout("redirect")} key="logoutRedirect">
                  <i className="uil uil-sign-out-alt font-size-18 align-middle mr-1 text-muted"></i>
                  <span className="align-middle" >Sign out</span>
                </a>
              </div>
            </div>
          </div>
        </div>

      </header>

      <div id="offer-custom-notification" className="card offer-custom-notification no-border d-none">
        <div className="card-body p-12">
          <div className="notification-panel">
            <h3 className="offer-title">
              <span className="float-left text-left title-mn">Notifications</span>
              <span id="close" className="float-right text-right popup-close-mn" onClick={() => closenotificationPanel()}> Close </span>
              <span className="clearfix"></span>
            </h3>
            <p>
              There is no notifications to show
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
