import React, {Component} from 'react';

export default class YearlyAVGBilling extends Component {
    render() {
        return (
            <div>
                <h4 className="card-title mb-4 card-title-wth-border">
                    <span className="float-left">Yearly AVG Billing</span>
                    <div className="clearfix"></div>
                </h4>
                <div> Yearly AVG Billing </div>
            </div>
        )
    }
}