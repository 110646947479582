import React from "react";
import { NavLink, Redirect } from 'react-router-dom'

import Selectendpointimg from '../../assets/images/select_endpoint.png'
import Completeinstallation from '../../assets/images/complete_installation.png'
import Launchnew from '../../assets/images/launchnew.png'





const Modal = ({ handleClose, show, children }) => {
const showHideClassName = show ? "modal d-block" : "modal d-none";
  return (
    <div id="myModal" className={showHideClassName + " modal fade"}>
        <div className="modal-dialog">
            <div className="modal-container">
                <div className="modal-content welcomePopup">
                <div className="modal-header modal-header no-border"><h5 className="modal-title">Welcome to i95 Cloud<span className="welcometag"> Get Started in 3 Simple Steps </span></h5></div>
                <div className="modal-body text-center">
                                        <div className="card no-boxshadow">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <div className="createConnector">
                                                            <img src={Selectendpointimg} />
                                                        </div>
                                                        <h3 className="title-modal-new">
                                                            Select End-Points
                                                        </h3>
                                                        <p className="desc-modal-new">
                                                            Select e-commerce & ERP systems which you want to Integrate.
                                                            Navigate to our Marketplace to know the options available
                                                        </p>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="createConnector">
                                                            <img src={Completeinstallation} />
                                                        </div>
                                                        <h3 className="title-modal-new">
                                                            Complete Installation
                                                        </h3>
                                                        <p className="desc-modal-new">
                                                            Follow our self explanatory installation guide to ensure that your connections authenticated
                                                        </p>                                                
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="createConnector">
                                                            <img src={Launchnew} />
                                                        </div>
                                                        <h3 className="title-modal-new">
                                                            Launch
                                                        </h3>
                                                        <p className="desc-modal-new">
                                                            After successful setup, Launch the integration . Validate the data syn using the dashboard  
                                                        </p>                                                
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <span className="skipModal float-left">
                                                    <NavLink to="/updates" className="waves-effect">
                                                        Skip
                                                    </NavLink>
                                                </span>
                                                <span className="getwalkthroughModal float-right">
                                                    <NavLink to="/Getawalkthrough" className="waves-effect">
                                                        Get a Walkthrough
                                                    </NavLink>
                                                </span>
                                            </div>
                                        </div>
                                        
                                    </div>
      
                </div>
      </div>
        </div>
    </div>
  );
};

export default Modal;
