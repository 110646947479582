import React from 'react';
import './learnmore.css'

class Learnmore extends React.Component {
    render() {
        return (
            <div className="row viewdetails">
                <div className="col-lg-12">
                    <div className="card no-boxshadow">
                        <div className="card-header">
                            <h3 className="heading"> EGE - Benefits </h3>
                        </div>
                        <div className="card-body pt-0">
                            <div className="row">
                                <div className="card no-boxshadow no-padding">
                                    <div className="card-body no-boxshadow no-padding">
                                        <div className="row pt-3">
                                            <div className="col-md-3">
                                                <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                    <a className="nav-link mb-2 active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                                        Scale Your Business
                                                    </a>
                                                    <a className="nav-link mb-2" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                                        Seamless ERP Integrations
                                                    </a>
                                                    <a className="nav-link mb-2" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                                        Build Loyalty
                                                    </a>
                                                    <a className="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                                                        AdvancedB2B Modules
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="tab-content text-muted mt-4 mt-md-0" id="v-pills-tabContent">
                                                    <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                                        <p>
                                                            With streamlined business processes and workflows across various functional departments, 
                                                            EGE helps scale and achieve your business objectives at a much faster pace. All the key business information,
                                                            customer data and order details are synchronized and can be accessed at ease making the entire process hands-on. 
                                                        </p>
                                                    </div>
                                                    <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                                        <p>
                                                            The seamless integration with EGE helps scale by streamlining the business operations and minimizing all the time consuming manual work. 
                                                            It not only enables centralization of data, but also enhances workflow management.
                                                        </p>
                                                    </div>
                                                    <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                                        <p>
                                                            Create brand loyalty by exceeding customer expectations with enhanced shopping experience at every touch point.
                                                            With improved inventory management, product visibility and a personalized shopping experience,
                                                            the technological capabilities that come with EGE certainly put you on top of the competition.
                                                        </p>
                                                    </div>
                                                    <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                                        <p>
                                                            With features unique to B2B businesses, i95Dev’s EGE helps create a seamless customer experience.
                                                            The advanced functionalities play a critical role in the success of a B2B eCommerce platform.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        )
    }
}

export default Learnmore