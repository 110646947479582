import React from 'react';
import $ from 'jquery'
import SlidingPane from 'react-sliding-pane';
import DownloadInstaller from '../DownloadInstaller/DownloadInstaller';
import './addPrerequisites.css'

import axios from 'axios';
import closeicon from '../../../assets/images/close-icon.svg';

class AddPrerequisites extends React.Component { 
    constructor(props){
        super(props);
        this.state={
            questionsUpdated : []
        }
    }

    handleChange1 = (event) => {
        let newValue = event.target.value;
        if(newValue != ""){
            event.target.classList.add('notEmptyfield')
            event.target.classList.remove('errorStateSelect')
        }else{
            event.target.classList.remove('notEmptyfield')
            event.target.classList.add('errorStateSelect')
        }

        $("#defaultQuestioneries tr#newSavequestions"+event.target.id+" td").text(event.target.value)
        
        this.setState({[event.target.id]: event.target.value});
    }
    
    validateNew = (event) => {
        $(".quistionversion-field").each(function() {
            if($(this).val() === "" || $(this).val() === null){
                $(this).addClass("errorStateSelect")
            }
        })
        let allAreFilled = true;
        document.getElementsByClassName("quistionversion-field").forEach(function(i){
            if (!allAreFilled) {
                return;
            }
            if (!i.value) {
                allAreFilled = false;
            }
        })
        if (!allAreFilled) {
            return
        }

        let mainResult = [];
        var row = {};
        $("#defaultQuestioneries tr td").each(function(){
            var mnId = $(this).attr("id");
            var mnValue = $(this).text();
            row[mnId] = mnValue
        })
        mainResult.push(row);

        let result = mainResult

        console.log(row)
        
        
        
        this.setState({
            questionsUpdated : row,
            isPaneOpen: true
        })
    }

    handleSubmit = (event) => {
       const {...inputfields} = this.state;

       let checkeddata = this.state;

       event.preventDefault();
    }

    render() { 
        const {fields}=this.state;
        let Id = this.state.id;
        var connectorfulname = window.localStorage.getItem("fullname")
        var sourcename = window.localStorage.getItem("sourcename")
        let questionsLength = this.props.Questions && this.props.Questions.data && this.props.Questions.data.length
        $("input").each(function(i) {
            var mnIdnew = $(this).val()
            if(mnIdnew != null || mnIdnew != " "){
                $(this).addClass("notEmptyfield")
            }
        });

        

        


        return (  
            <div className='popup'>  
                <div className={ (questionsLength > 3) ? "popup_inner moreQuestioneries" : "popup_inner"}>
                    <div className="addPrerequisites">
                        <h3> Add Prerequisites </h3>
                        <div className="detailsContainer">
                            <h3> {connectorfulname} </h3>
                            <table className="table table-nowrap table-centered mb-0">
                                <tr><th scope="row" colspan="4" className="text-left">{sourcename.split('-')[0]}</th><td className="text-right">{this.props.versionYear}</td></tr>
                                <tr><th scope="row" colspan="4" className="border-0 text-left">{sourcename.split('-')[1]}</th><td className="border-0 text-right"> {this.props.entityVersion}</td></tr>
                                <tr><th scope="row" colspan="4" className="border-0 text-left">Product Type</th><td className="border-0 text-right">{this.props.productName}</td></tr>
                            </table>
                        </div>
                        <div className="form-section mt-3">
                            <form onSubmit={this.handleSubmit}>
                                <div className="card">
                                    <div className="card-header">
                                        Please fill below Prerequisites
                                    </div>
                                    <div className={ (questionsLength > 3) ? "card-body mxht350 form-overflow" : "card-body"}>
                                        <div className="row">
                                            {this.props.Questions && this.props.Questions.data && this.props.Questions.data.map(itemv =>
                                                <div className={ (questionsLength > 3) ? "col-md-4" : "col-md-12"}>
                                                    
                                                    {/* <div class="form-group floating">
                                                        <input type="text" class="form-control floating" id="usr" required />
                                                        <label for="usr">Username</label>
                                                    </div> */}
                                                    <div className="form-group floating mb-0 ">
                                                        {
                                                            itemv.type == "Text" ? 
                                                            <>
                                                                <input parsley-type="text" type="text" className="form-control floating version-field quistionversion-field" placeholder={itemv.text}  id={itemv.id} defaultValue={itemv.defaultValue}  onChange={this.handleChange1} ref={this.input}/>
                                                                <label for={itemv.id}>{itemv.text}</label>
                                                            </>: 
                                                            itemv.type == "URL" ? 
                                                            <>
                                                                <input parsley-type="text" type="url" className="form-control floating version-field quistionversion-field" placeholder={itemv.text}  id={itemv.id}  defaultValue={itemv.defaultValue} onChange={this.handleChange1} ref={this.input}/> 
                                                                <label for={itemv.id}>{itemv.text}</label>
                                                            </>
                                                            : 
                                                            itemv.type == "Password" ? 
                                                            <>
                                                                <input parsley-type="text" type="password" className="form-control floating version-field quistionversion-field" placeholder={itemv.text}  id={itemv.id} defaultValue={itemv.defaultValue} onChange={this.handleChange1} ref={this.input}/>
                                                                <label for={itemv.id}>{itemv.text}</label>
                                                            </>
                                                            : 
                                                            itemv.type == "Number" ? 
                                                            <>
                                                                <input parsley-type="text" type="number" className="form-control floating version-field quistionversion-field" placeholder={itemv.text}  id={itemv.id} defaultValue={itemv.defaultValue} onChange={this.handleChange1} ref={this.input}/>
                                                                <label for={itemv.id}>{itemv.text}</label>
                                                            </>
                                                            :
                                                            itemv.type == "Dropdown" ? 
                                                            <>
                                                                 <select parsley-type="Dropdown" className="form-control floating version-field quistionversion-field" id={itemv.id} defaultValue={itemv.defaultValue} onChange={this.handleChange1} >
                                                                     <option> {itemv.text} </option>
                                                                     { 
                                                                         itemv.questionOptions.map((sub, subindex) =>
                                                                            <option value={sub.code}> {sub.displayName} </option>
                                                                         )
                                                                     }
                                                                 </select>
                                                                 <label for={itemv.id}>{itemv.text}</label>
                                                                </>
                                                              : null
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="table d-none">
                                            <table id="defaultQuestioneries">
                                                {this.props.Questions && this.props.Questions.data && this.props.Questions.data.map(itemv =>
                                                    <tr id={"newSavequestions"+itemv.id}>
                                                        <th>
                                                            {itemv.id}
                                                        </th>
                                                        <td id={itemv.id}>
                                                            {itemv.defaultValue}
                                                        </td>
                                                    </tr>
                                                )}
                                            </table>
                                        </div>
                                    </div>

                                    <div class="card-footer text-muted">
                                        <div className="float-right">
                                            <button type="reset" className="btn btn-secondary waves-effect cancancel" onClick={this.props.closePopup}>
                                                Cancel
                                            </button>
                                            <button type="submit" className="btn btn-primary waves-effect waves-light mr-1 subsubmit" onClick={this.validateNew} >
                                                Submit 
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div> 

                <SlidingPane className='connector-popup' overlayClassName='some-custom-overlay-class' isOpen={ this.state.isPaneOpen } onRequestClose={ () => { this.setState({ isPaneOpen: true }); } }>
                    <button className="popup-close" onClick={() => this.setState({ isPaneOpen: false })}>
                        <img src={closeicon} />
                    </button>
                    <DownloadInstaller connectorId={this.props.connectorId} versionYear={this.props.versionYear} entityVersion={this.props.entityVersion} Questions={this.state.questionsUpdated} />
                </SlidingPane>
            </div>  
        );
    }  
}  

export default AddPrerequisites;
