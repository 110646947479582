import React from 'react';
class SelectedConnectorName extends React.Component {
    render(){
        return(
            <>
                <h4 className="card-title mb-2 card-title-wth-border titlecustomNew">
                    <span className="text-truncate float-left cstmnewwdthcfn" title={this.props.Name}> {this.props.Name} </span>
                    <div className="clearfix"></div>
                </h4>
            </>
        )
    }
}

export default SelectedConnectorName