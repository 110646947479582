import React from 'react';
import './Login.css';
import logo from '../../assets/images/main-logo.png';
import layoutimage from '../../assets/images/loginpageicon.png';

class Login extends React.Component {
    render() {
        return (
            <div className="form-body login">
                <div className="website-logo">
                    <a href="">
                        <div className="logo">
                            <img className="logo-size" src={logo} alt="" />
                        </div>
                        <div className="logoText">
                            <span className="logoTagline">
                                Quick Tip
                            </span>
                            <span className="logoTitle">
                                See the Transfered Records on the Go
                            </span>
                        </div>
                    </a>
                    <div className="introText">
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                            Ipsum has been the industry's standard dummy text ever since the 1500s,
                            Lorem simply dummy text of the printing and ....
                        </p>
                    </div>

                    <h3 className="rdui">
                        Records Details UI
                    </h3>


                </div>
                
                <div className="row">
                    <div className="img-holder">
                        <div className="bg"></div>
                        <div className="info-holder">
                            {/*<img className="md-size" src={layoutimage} alt="" /> */}
                        </div>
                    </div>
                    <div className="form-holder">
                        <div className="form-content">
                            <div className="form-items">
                                <h3 className="form-title"> Welcome to i95 Dev Cloud </h3>
                                <h2 className="form-hd-title"> Sign In </h2>
                                <form className="needs-validation">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group input-group position-relative">
                                                <input type="Email" className="form-control custom-input" id="validationTooltip03" placeholder="EMAIL" required="" />
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">
                                                        <i className="uil-envelope"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group input-group position-relative">
                                                <input type="password" className="form-control custom-input" id="validationTooltip03" placeholder="PASSWORD" required="" />
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">
                                                        <i className=""></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="auth-terms-condition-check" />
                                                <label className="custom-control-label" for="auth-terms-condition-check">
                                                <a href="javascript: void(0);" className="text-dark rmbrme">Remember Me</a></label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="custom-control text-right">
                                                <a href="javascript: void(0);" className="text-dark rmbrme">Forgot Password</a>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="btn btn-primary btn-register" type="submit">Register</button>
                                    <div className="mt-3 text-center newsignin">
                                        <p className="mb-0">
                                            Dont have an Account?
                                            <a href="" className="font-weight-medium text-primary"> Try for Free </a>
                                        </p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Login