import React from 'react';
import $ from 'jquery'
import ConnectorServicePanel from "./ConnectorServicePanel/ConnectorServicePanel"
class ConnectorServices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "Details"
        }
    }
    componentWillReceiveProps(props) {
        this.setState({
            activeTab : props.activeTab
        })
    }

    render(){

        
        return(
            <>
                {/* <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" data-toggle="tab" href={"#details" + this.props.id} role="tab">
                            <span className="d-none d-sm-block">Details</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href={"#mappings" + this.props.id} role="tab">
                            <span className="d-none d-sm-block">Mapping</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href={"#manageentities" + this.props.id} role="tab">
                            <span className="d-none d-sm-block">Manage Entities</span>
                        </a>
                    </li>
                </ul> */}
            </>
        )
    }
}

export default ConnectorServices