import React from 'react';
import $ from 'jquery'
import Select from 'react-select';

import DeleteMapping from './deleteMapping';

class DefaultMappings extends React.Component {
    render(){
        return(
            <>
                <div className="row">
                    <div id={"defaultMappingsrowPayment"+this.props.id} className="col-lg-12 pr-0">
                        {
                            this.props.defaultMappings && this.props.defaultMappings.data.map((item, index) => 
                                <div id={"defaultMapping"+index} class="row mt-2 deleteRow">
                                    <div class="col">
                                        <Select 
                                            placeholder={"Select ERP Method..."}
                                            options={this.props.sourceMethodsGet}
                                            defaultValue={{ label: item.erpMethod, value: item.erpMethod }}
                                            isSearchable= {false}
                                        />
                                    </div>
                                    <div class="col">
                                        <Select 
                                            placeholder={"Select Ecommerce Method..."}
                                            options={this.props.targetMethodsGet}
                                            defaultValue={{ label: item.ecommerceMethod, value: item.ecommerceMethod }}
                                            isSearchable= {false} 
                                        />
                                    </div>
                                    <div class="col">
                                        <div className='row'>
                                            <div className='col text-center'>
                                                <div class="form-check mb-2 custom-checkbox">
                                                    {
                                                        item.isEcommerceDefault === true ? 
                                                        <input class="form-check-input custom-control-input" type="checkbox" id={"isecommdefault"+index} defaultChecked /> 
                                                        : <input class="form-check-input custom-control-input" type="checkbox" id={"isecommdefault"+index} /> 
                                                    }
                                                    <label class="custom-control-label" for={"isecommdefault"+index}></label>
                                                </div>
                                            </div>
                                            <div className='col text-center custom-checkbox'>
                                                <div class="form-check mb-2 custom-checkbox">
                                                    {
                                                        item.isErpDefault === true ? 
                                                        <input class="form-check-input custom-control-input" type="checkbox" id={"isErpDefault"+index} defaultChecked /> 
                                                        : <input class="form-check-input custom-control-input" type="checkbox" id={"isErpDefault"+index} /> 
                                                    }
                                                    <label class="custom-control-label" for={"isErpDefault"+index}></label>
                                                </div>
                                            </div>
                                            <div className='col text-center'>
                                                <DeleteMapping
                                                    erpMethodName={item.erpMethod}
                                                    ecommMethodName={item.ecommerceMethod}
                                                    id={this.props.id}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </>
        )
    }
}

export default DefaultMappings