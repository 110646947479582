import React from 'react';
import './profile.css';

class Profile extends React.Component {
    render() {
        return (
            <div>
                <div className="main-content myProfile">
                    <div className="page-content pb-0">
                        <div className="container-fluid">
                            
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between mb-3 pb-0">
                                        <h4 className="mb-0">My Profile</h4>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row">
                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Profile