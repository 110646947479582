import React from 'react';
import $ from 'jquery'
import API from '../../../../../../../api'
import Select from 'react-select';

class SubmitMappings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentWillReceiveProps(props) {
        this.setState({

        })
    }
    submitShippingMappings = (event) =>{
        let clickableID = event.target.name
        const results = [];
        const headersnewway = [];
        const divnew = document.getElementsByTagName("pre")
        const bodynewway = document.getElementById("saveMappingShippingMapping"+clickableID)
        if (bodynewway !== null) {
            const rows = bodynewway.querySelectorAll("tr");
            rows.forEach((rowData, rowIndex) => {
                const dataRowIndex = rowIndex - 1;
                if (rowIndex > 0) results[dataRowIndex] = {};
                for (let cellIndex = 0; cellIndex < rowData.cells.length; cellIndex++) {
                    const cellData = rowData.cells.item(cellIndex);
                    if (rowIndex === 0) {
                        if (cellData !== null)
                            headersnewway[cellIndex] = cellData.textContent || "";
                    } else if (rowIndex > 0) {
                        if (cellData !== null)
                            results[dataRowIndex][headersnewway[cellIndex]] = cellData.textContent === 'false' ? false : (cellData.textContent === 'true' ? true : cellData.textContent) || "";
                    }
                }
            });
        }
        divnew.innerHTML = results;

        let self = this;
        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        const movies = this.props.selectedData;
        const body = results

        API.post(`/Connection/${movies.id}/instance/${movies.instances[0].id}/shipping-mappings`, body, { headers })
        .then(response => response)
        .then(data => {
            self.setState({
                //instanceMappingdataPost: data
            })
        })
    }
    render(){
        return(
            <>
                <button type="button" id={"saveAllShipmentMappings"+this.props.id} name={this.props.id} class="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector new-connector-position newwaybtn submitmappingNewsubmit" onClick={this.submitShippingMappings}>
                    Submit Mappings
                </button>
            </>
        )
    }
}

export default SubmitMappings