import React from 'react';
class EntityCount extends React.Component {
    render(){
        return(
            <>
                <div className="col-md-4">
                    {
                        <div className="rrorlog">
                            <p className="text-muted mb-0 font-size-10">Pipes</p>
                            <h5 className="font-size-10 font-weight-bold">{this.props.entityCount}</h5>
                        </div>                       
                    }
                </div>
            </>
        )
    }
}

export default EntityCount