import React from 'react';
import $ from 'jquery'
import Select from 'react-select';
class DefaultMappings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            updateSourceValue : null,
            updateTargetValue : null
        }
    }
    deleteExistingMethodSourcetotarget =(event) =>{
        let bodyID = $(event.target).closest("tbody").attr("id")
        let firstTdtext = $(event.target).closest("tr").find("td:eq(0)").text()
        let secondTdtext = $(event.target).closest("tr").find("td:eq(1)").text()
        $("#productAttributeMainAppendTTS"+bodyID+" tbody tr").each(function() {
            let sourceText =  $(this).find("td:eq(0)").text()
            let targetText =  $(this).find("td:eq(3)").text()
            if((firstTdtext === sourceText) && (secondTdtext === targetText)){
                $(this).remove()
            }
        })
        $("#disabledButtonTTS"+bodyID).addClass("d-none")
        $("#saveAllattributeMappingsTTS"+bodyID).removeClass("d-none")
        $(event.target).closest("tr").remove()
    }
    editMapping = (event) =>{
        let currentTR = $(event.target).closest("tr")
        $(currentTR).next("tr").removeClass("d-none")
        $(event.target).closest("tr").addClass("d-none")
        $(currentTR).next("tr").addClass("tbltrActive")
        $(".highliteSection").removeClass("d-none")
    }
    updateSourceValue = (event) =>{
        let selectedOptionTarget = event.value.split("*")
        let selectedecommerceName = selectedOptionTarget[0]
        this.setState({
            updateSourceValue : selectedecommerceName
        })
    }
    updateTargetValue = (event) =>{
        let selectedOptionSource = event.value.split("*")
        let selectederpName = selectedOptionSource[0]
        this.setState({
            updateTargetValue : selectederpName
        })
    }
    saveUpdatedMappings = (event) =>{
        let current_id = $(event.target).closest("tr").attr("id")
        let currentTR = $(event.target).closest("tr")
        let getupdateSourceValue = this.state.updateSourceValue
        let getupdateTargetValue = this.state.updateTargetValue
        let compareText = []
        $("#otherTableTTS"+this.props.id+" tbody tr.odd_even_new").each(function(){
            let newText = $(this).find("td:eq(1)").text()
            compareText.push(newText)
        })

        if(compareText.includes(getupdateTargetValue)){
            let errorMessageNew = "Selected eCommerce method(s) <span style='color:red'>"+ getupdateTargetValue +"</span> is/are already mapped with one of the ERP method(s)."
            $("#errorHandlingNewTTS"+this.props.id).removeClass("d-none")
            $("#errorHandlingNewTTS"+this.props.id).empty()
            $("#errorHandlingNewTTS"+this.props.id).append(errorMessageNew)
        }else{
            $("#productAttributeMainAppendTTS"+this.props.id+" tr").each(function(){
                let find_id = $(this).attr("id")
                if(find_id === current_id){
                    if(getupdateSourceValue != null){
                        $("#"+find_id+" td:eq(3)").text(getupdateSourceValue)
                        $(currentTR).prev("tr").find("td:eq(0)").text(getupdateSourceValue)
                    }
                    if(getupdateTargetValue != null){
                        $("#"+find_id+" td:eq(0)").text(getupdateTargetValue)
                        $(currentTR).prev("tr").find("td:eq(1)").text(getupdateTargetValue)
                    }
                }
            })
            $(event.target).closest("tr").addClass("d-none")
            $(event.target).closest("tr").prev("tr").removeClass("d-none")
            $(".highliteSection").addClass("d-none")
            $("#disabledButtonTTS"+this.props.id).addClass("d-none")
            $("#saveAllattributeMappingsTTS"+this.props.id).removeClass("d-none")
        }
        this.setState({
            updateSourceValue : null,
            updateTargetValue : null
        })
    }
    cancelUpdatedMappings = (event) =>{
        this.setState({
            updateSourceValue : null,
            updateTargetValue : null
        })
        $(event.target).closest("tr").addClass("d-none")
        $(event.target).closest("tr").prev("tr").removeClass("d-none")
        $(".highliteSection").addClass("d-none")
        $("#disabledButtonTTS"+this.props.id).removeClass("d-none")
        $("#saveAllattributeMappingsTTS"+this.props.id).addClass("d-none")
    }

    render(){
        return(
            <>
                <div className="row">
                    <div className='highliteSection d-none'>
                    </div>
                    <div id={"defaultMappingsrowProductAttributeTTS"+this.props.id} className="col-lg-12 pl-1 pr-0">
                        <table id={"otherTableTTS"+this.props.id} class="productAttributemain mappingFixedWidth otherTable table table-borderless table-striped mb-0">
                            <thead className="table-light d-none">
                                <tr>
                                    <th>eComm Method</th>
                                    <th>ERP Method</th>
                                    <th className="text-center">canUpdate</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody id={this.props.id}>
                                {
                                    this.props.defaultMappings && this.props.defaultMappings.map((item, index) =>
                                    <>
                                        <tr className='aligningWidth odd_even_new'>
                                            <td>{item.ecommerceAttributeName}</td>
                                            <td>{item.erpAttributeName}</td>
                                            <td className="text-center">
                                                <div className='row'>
                                                    <div className='col'>
                                                        {
                                                            item.canUpdate === true ? 
                                                            <div className="custom-control custom-checkbox mt-2">
                                                                <input type="checkbox" className="custom-control-input" defaultChecked id={"existedcanupdateTTS"+this.props.id} onClick={this.productattributeSTTcanUpdate} disabled />
                                                                <label className="custom-control-label" for={"canupdateTTS"+this.props.id}></label>
                                                            </div>
                                                            : 
                                                            <div className="custom-control custom-checkbox mt-2">
                                                                <input type="checkbox" className="custom-control-input" id={"existedcanupdateTTS"+this.props.id} onClick={this.productattributeSTTcanUpdate} disabled />
                                                                <label className="custom-control-label" for={"canupdateTTS"+this.props.id}></label>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className='col'>
                                                        <button type="button" class="action edit" onClick={this.editMapping}>
                                                            <span ></span>
                                                            <i class="uil-edit-alt"></i>
                                                        </button>
                                                        {
                                                            (item.erpIsRequired === true) || (item.ecommerceIsRequired === true) || (item.canDelete === false) ?
                                                            <button type="button" class="deleteProductAttributeMapping" disabled={"true"}>
                                                                <span ></span>
                                                                <i class="uil-trash-alt"></i>
                                                            </button>:
                                                            <button type="button" class="deleteProductAttributeMapping stfy1" onClick={this.deleteExistingMethodSourcetotarget}>
                                                                <span ></span>
                                                                <i class="uil-trash-alt"></i>
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr id={"productAttributeTTS"+index} className='aligningWidth defaultNotvisible d-none'>
                                            <td>
                                                <Select 
                                                    placeholder={"Select Ecommerce Method..."}
                                                    options={this.props.targetMethodsGet}
                                                    defaultValue={{ label: item.ecommerceAttributeName, value: item.ecommerceAttributeName }}
                                                    isSearchable= {true}
                                                    onChange={this.updateSourceValue}
                                                />                                                
                                            </td>
                                            <td>
                                                <Select 
                                                    placeholder={"Select ERP Method..."}
                                                    options={this.props.sourceMethodsGet}
                                                    defaultValue={{ label: item.erpAttributeName, value: item.erpAttributeName }}
                                                    isSearchable= {true}
                                                    onChange={this.updateTargetValue}
                                                />
                                            </td>
                                            <td className="text-center">
                                                <div className='row'>
                                                    <div className='col'>
                                                    </div>
                                                    <div className='col'>
                                                        <button type="button" class="action edit" onClick={this.saveUpdatedMappings}>
                                                            <span ></span>
                                                            <i class="uil-check"></i>
                                                        </button>
                                                        <button type="button" class="action deleteProductAttributeMapping stfy1" onClick={this.cancelUpdatedMappings}>
                                                            <span ></span>
                                                            <i class="custom-cross"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                    
                                )}
                            </tbody>
                        </table>
                    </div>
                
                </div>
            </>
        )
    }
}

export default DefaultMappings