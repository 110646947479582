import React from 'react';
import { NavLink, Link } from 'react-router-dom'
class ViewSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listofConnectors : []
        }
    }
    render(){
        
        return(
            <>
                {this.props.instanceSourceDataLength || this.props.instanceTargetDataLength > 0 ?
                    <NavLink to={{ pathname:"/ViewSummary", id: this.props.instanceEntryID}} className="float-right text-primary">
                        View Details
                    </NavLink> : <button className="float-right text-primary no-border disabledNew" title="Installation setup is incomplete" disabled> View Details  </button>
                }
            </>
        )
    }
}
export default ViewSummary
