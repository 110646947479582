import React from 'react';
import './servicerequests.css';
import ActiveServiceRequest from '../ActiveServiceRequest/ActiveServiceRequest';

class Myconnectors extends React.Component {
    render() {
        return (
            <div>
                <div className="main-content servicerequests">
                    <div className="page-content pb-0">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between mb-3 pb-0">
                                        <h4 className="mb-0">Service Requests</h4>
                                        <div className="page-title-right">
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                                <div className="col-lg-4">
                                    <div className="card">
                                        <div className="card-body tabsec active-tabsec">
                                            <span className="nrml-text float-left"> Active Requests </span>
                                            <span className="nrml-nmbr float-right"> 08 </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card">
                                        <div className="card-body tabsec">
                                            <span className="nrml-text float-left"> Requests Pending </span>
                                            <span className="nrml-nmbr float-right"> 08 </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card">
                                        <div className="card-body tabsec">
                                            <span className="nrml-text float-left"> Requests Closed </span>
                                            <span className="nrml-nmbr float-right"> 08 </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                                <div className="new-devider"></div>
                            </div> 
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card info-max">
                                        <div className="card-body">
                                            <ActiveServiceRequest />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Myconnectors