import React from 'react';
import {Header} from '../../../../Shared/Header';
import Sidebar from '../../../../Shared/Sidebar';
import axios from 'axios';
import $ from 'jquery';
import SlidingPane from "react-sliding-pane";
import Viewinfo from '../ViewInfo/Viewinfo';
import closeicon from '../../../../../assets/images/close-icon.svg';
import '../egesetupdownload.css';
import API from '../../../../api';

class DownloadSetup extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      sourceMessage: "0",
      targetMessage: "0%",
            keyType:[],
            krpType:[],
            krpTypeTarget:[],
            krpTypeSource:[],
            count:0,
            status:[],
            curTime: null,
            isDisabled: false,
            targetCount:0
    }
  }
  componentDidMount() {
    if(this.props.location.hilightmn === "MyProducts"){
      $(".trmyconnectors").addClass("active")
    }else{
      $(".trdashboard").addClass("active")
    }

    let self = this;
    var response = window.localStorage.getItem("accessToken");  
    let id = window.sessionStorage.getItem("connectorIndex");
    const headers = {
      "authorization": `Bearer ${response}`,
      "content-type": "application/json"
    };
  
        API.get(`/Client/connections/${self.props.location.downloadData.id}/instances/${self.props.location.downloadData.instances[0].id}`,{headers })
        .then(response => response)
       .then(data1 => {
        document.getElementById("loaderContainer").className += ' d-none';
        self.setState({
      // isLoaded:true,
       krpType: data1 })
         })
        
  }

  handleChangeTarget=(e)=>{
    document.getElementById("loaderContainer").classList.remove("d-none")
    this.setState({ 
      targetMessage: "2%",
    });
    this.setState({count:this.state.count+1});
    let self=this;
    var response =window.localStorage.getItem("accessToken") ;
    const headers={
      "authorization": `Bearer ${response}`,
      "content-type" : "application/json",
    };
    API.get('/User/Token/Check',{headers })
    .then(response => response)
    .then(data1 => {
      if(data1.data.result == false) {
        API.get('/User/Authorize',{headers })
        .then(response=>response)
        .then(data=> {
          
          //alert(JSON.stringify(data.data))
          if(self.state.count < 5){
           // alert(1)
            var win =window.open(data.data, "", "width=500,height=500");
            setTimeout(function() { win.close();}, 10000)
          }
          else{  var html = "<html><head></head><body> Authentication failed : The Request could not be completed </body></html>"

          var win = window.open(data.data, "", "width=500,height=500");

          win.document.write(html)

          setTimeout(function () { win.close(); }, 10000);}
        })
        setTimeout(function () {
          if(self.state.count<5){ self.handleChangeTarget();}
          else{
            self.setState({ count: 0 });
            }
        }, 11000);
          
      }
      else{
        
        const Bcdata = self.state.krpType.data;

        API.post(
            "/Client/connections/setup", {
            "subscriptionId": Bcdata.subscriptionId,
            "endpointId": Bcdata.target.id,
            "instanceId": Bcdata.instances[0].id,
            "fileType": Bcdata.target.installer.type,
            "clientId":128
        },
            { headers}

        )
            .then(resp => {
              document.getElementById("loaderContainer").classList.add("d-none")
               alert(resp.data.message)
            })
    }
    })
  }

  handleChangeSourceSetup=()=>{
    document.getElementById("loaderContainer").classList.remove("d-none")
    this.setState({ 
      targetMessage: "2%",
    });
    this.setState({count:this.state.count+1});
    let self=this;
    var response =window.localStorage.getItem("accessToken") ;
    const headers={
      "authorization": `Bearer ${response}`,
      "content-type" : "application/json",
    };
    API.get('/User/Token/Check',{headers })
    .then(response => response)
    .then(data1 => {
      if(data1.data.result == false) {
        API.get('/User/Authorize',{headers })
        .then(response=>response)
        .then(data=> {
          
          //alert(JSON.stringify(data.data))
          if(self.state.count < 5){
           // alert(1)
            var win =window.open(data.data, "", "width=500,height=500");
            setTimeout(function() { win.close();}, 10000)
          }
          else{  var html = "<html><head></head><body> Authentication failed : The Request could not be completed </body></html>"

          var win = window.open(data.data, "", "width=500,height=500");

          win.document.write(html)

          setTimeout(function () { win.close(); }, 10000);}
        })
        setTimeout(function () {
          if(self.state.count<5){ self.handleChangeSourceSetup();}
          else{
            self.setState({ count: 0 });
            }
        }, 11000);
          
      }
      else{
        
        const Bcdata = self.state.krpType.data;

        API.post(
            "/Client/connections/setup", {
            "subscriptionId": Bcdata.subscriptionId,
            "endpointId": Bcdata.source.id,
            "instanceId": Bcdata.instances[0].id,
            "fileType": Bcdata.source.installer.type
        },
            { headers}

        )
            .then(resp => {
              document.getElementById("loaderContainer").classList.add("d-none")
               alert(resp.data.message)

            })
    }
    })
  }

  handleChangeTargetDownload=()=>{
    document.getElementById("loaderContainer").classList.remove("d-none")
    this.setState({targetCount:this.state.targetCount+1});
    let self=this;
    var response =window.localStorage.getItem("accessToken") ;
    const headers={
      "authorization": `Bearer ${response}`,
      "content-type" : "application/json",
    };

    API.get('/User/Token/Check', { headers })
        .then(response => response)
        .then(data1 => {
            if (data1.data.result == false) {
                //alert(JSON.stringify(data1.data.result))
                API.get('/User/Authorize', { headers })
                    .then(response => response)
                    .then(data => {
                        if (self.state.targetCount < 5) {
                            var win = window.open(data.data, "", "width=500,height=500");
                            setTimeout(function () { win.close(); }, 10000);
                        }
                        else {

                            var html = "<html><head></head><body> Authentication failed : The Request could not be completed </body></html>"

                            var win = window.open(data.data, "", "width=500,height=500");

                            win.document.write(html)

                            setTimeout(function () { win.close(); }, 10000);
                        }
                    })
                setTimeout(function () {
                    if (self.state.targetCount < 5) { self.handleChangeTargetDownload(); }
                    else{
                      self.setState({ targetCount: 0 });
                      }
                }, 11000);
                //this.handleSourceDownload();
            }

            else {
              const Bcdata = self.state.krpType.data;

                API.post(
                    "/Client/connections/download", {
                    "subscriptionId": Bcdata.subscriptionId,
                    "endpointId": Bcdata.target.id,
                    "instanceId": Bcdata.instances[0].id,
                    "fileType": Bcdata.target.installer.type,
                    "refreshToken": response,
                },
                    { headers, responseType: 'blob' }

                )
                    .then(resp => {
                        const disposition = resp.headers["content-disposition"];
                        var fileName = "";
                        const array1 = disposition.split(';');
                        if (array1 && array1.length > 1) {
                            const string1 = array1[1];
                            if (string1) {
                                const array2 = string1.split('=');
                                if (array2 && array2.length > 1) {
                                    fileName = array2[1];
                                }
                            }
                        }
                        const url = window.URL.createObjectURL(new Blob([resp.data], { type: 'application/zip' }));

                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', fileName);
                        link.click();
                        document.getElementById("loaderContainer").classList.add("d-none")
                    })
            }
        })
  }
  
  handleChangeSource=()=>{
    document.getElementById("loaderContainer").classList.remove("d-none")
    this.setState({targetCount:this.state.targetCount+1});
    let self=this;
    var response =window.localStorage.getItem("accessToken") ;
    const headers={
      "authorization": `Bearer ${response}`,
      "content-type" : "application/json",
    };

    API.get('/User/Token/Check', { headers })
        .then(response => response)
        .then(data1 => {
            if (data1.data.result == false) {
                //alert(JSON.stringify(data1.data.result))
                API.get('/User/Authorize', { headers })
                    .then(response => response)
                    .then(data => {
                        if (self.state.targetCount < 5) {
                            var win = window.open(data.data, "", "width=500,height=500");
                            setTimeout(function () { win.close(); }, 10000);
                        }
                        else {

                            var html = "<html><head></head><body> Authentication failed : The Request could not be completed </body></html>"

                            var win = window.open(data.data, "", "width=500,height=500");

                            win.document.write(html)

                            setTimeout(function () { win.close(); }, 10000);
                        }
                    })
                setTimeout(function () {
                    if (self.state.targetCount < 5) { self.handleChangeSource(); }
                    else{
                      self.setState({ targetCount: 0 });
                      }
                }, 11000);
                //this.handleSourceDownload();
            }

            else {
              const Bcdata = self.state.krpType.data;

                API.post(
                    "/Client/connections/download", {
                    "subscriptionId": Bcdata.subscriptionId,
                    "endpointId": Bcdata.source.id,
                    "instanceId": Bcdata.instances[0].id,
                    "fileType": Bcdata.source.installer.type,
                    "refreshToken": response,
                },
                    { headers, responseType: 'blob' }

                )
                    .then(resp => {
                        const disposition = resp.headers["content-disposition"];
                        var fileName = "";
                        const array1 = disposition.split(';');
                        if (array1 && array1.length > 1) {
                            const string1 = array1[1];
                            if (string1) {
                                const array2 = string1.split('=');
                                if (array2 && array2.length > 1) {
                                    fileName = array2[1];
                                }
                            }
                        }
                        const url = window.URL.createObjectURL(new Blob([resp.data], { type: 'application/zip' }));

                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', fileName);
                        link.click();
                        document.getElementById("loaderContainer").classList.add("d-none")
                    })
            }


        })




}


  render() {
    let krpType = this.state;
    let id = window.sessionStorage.getItem("connectorIndex");
    let sourcefilesize = krpType.krpType && krpType.krpType.data && krpType.krpType.data.source.type!==1 ?
    <div className="col-lg-6">
      <div className="card setup-sectionnew">
        <h4 className="text-truncate font-size-14 card-header mt-0" title={krpType.krpType && krpType.krpType.data && krpType.krpType.data.source.name}>
          {krpType.krpType && krpType.krpType.data && krpType.krpType.data.source.name}
        </h4>
        <div className="card-body">
          <div className="media border-bottom custom-height-new">
            <div className="media-body align-self-center overflow-hidden mr-4">
              <div>
                <div className="versionNew">
                  <span className="mainTitle">Version </span>
                  <span className="subTitle"> {this.props.location.downloadData.source.version} </span>
                </div>
                <div className="timeElapsedNew">
                  <span className="mainTitle">File Size</span>
                  <span className="subTitle">{krpType.krpType && krpType.krpType.data && krpType.krpType.data.source.installer ? krpType.krpType && krpType.krpType.data && krpType.krpType.data.source.installer.size:"No Installer data to download"}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="startSetupButton">
            <button type="button" className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector" onClick={this.handleChangeSource}>
              Download
            </button>
          </div>
        </div>
      </div>
    </div> : 
    <div className="col-lg-6  mzeroauto">
      <div className="card setup-sectionnew">
        <h4 className="text-truncate font-size-14 card-header mt-0" title={krpType.krpType && krpType.krpType.data && krpType.krpType.data.source.name}>{krpType.krpType && krpType.krpType.data && krpType.krpType.data.source.name} </h4>
        <div className="card-body">
          <div className="media border-bottom custom-height-new">
            <div className="media-body align-self-center overflow-hidden mr-4">
              <div>
                <div className="versionNew">
                  <span className="mainTitle">Version </span>
                  <span className="subTitle"> {this.props.location.downloadData.source.version} </span>
                </div>
                <div className="timeElapsedNew">
                  <span className="mainTitle">File Size</span>
                  <span className="subTitle">{krpType.krpType && krpType.krpType.data && krpType.krpType.data.source.installer.size}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="startSetupButton">
            <button type="button" className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector" onClick={this.handleChangeSourceSetup}>
              Start SetUp
            </button>
          </div>
        </div>
      </div>
    </div>
    return (
      <div>
        <Header />
        <Sidebar />
          <div id="loaderContainer" className="loaderContainer">
            <div className="loader"></div>
          </div>
          <div class="main-content">
            <div class="page-content">
              <div class="container-fluid">
                <div class="row mlr-176 mt-80">
                  {sourcefilesize}
                  {
                    krpType.krpType && krpType.krpType.data && krpType.krpType.data.target.type!==1 ? 
                    <div className="col-lg-6">
                      <div className="card setup-sectionnew">
                        <h4 className="text-truncate font-size-14 card-header mt-0" title={krpType.krpType && krpType.krpType.data && krpType.krpType.data.target.name}>
                          {krpType.krpType && krpType.krpType.data && krpType.krpType.data.target.name}
                        </h4>
                        <div className="card-body">
                          <div className="media border-bottom custom-height-new">
                            <div className="media-body align-self-center overflow-hidden mr-4">
                              <div>
                                <div className="versionNew">
                                  <span className="mainTitle">Version </span>
                                  <span className="subTitle"> {this.props.location.downloadData.target.version} </span>
                                </div>
                                <div className="timeElapsedNew">
                                  <span className="mainTitle">File Size</span>
                                  <span className="subTitle">{krpType.krpType && krpType.krpType.data && krpType.krpType.data.target.installer ? krpType.krpType && krpType.krpType.data && krpType.krpType.data.target.installer.size:"No Installer data to download"}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="startSetupButton">
                            <button type="button" className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector" onClick={this.handleChangeTargetDownload}>
                              Download
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> : 
                    <div class="col-lg-6 mzeroauto">
                      <div class="card setup-sectionnew">
                        <h4 class="text-truncate font-size-14 card-header mt-0" title={krpType.krpType && krpType.krpType.data && krpType.krpType.data.source.name}>{krpType.krpType && krpType.krpType.data && krpType.krpType.data.target.name}</h4>
                        <div class="card-body">
                          <div class="media border-bottom custom-height-new">
                            <div class="media-body align-self-center overflow-hidden mr-4">
                              <div>
                                <div className="timeElapsedNew">
                                  <span className="mainTitle">Version</span>
                                  <span className="subTitle">{this.props.location.downloadData.target.version} </span>
                                </div>
                                <div className="timeElapsedNew">
                                  <span className="mainTitle">File Size 5858</span>
                                  <span className="subTitle">{krpType.krpType && krpType.krpType.data && krpType.krpType.data.target.installer?krpType.krpType && krpType.krpType.data && krpType.krpType.data.target.installer.size:"No"}</span>
                                </div>
                            </div>
                          </div>
                          <div className="setupProgress invisible">
                            <div className="setupProgresspercentage" id="targetPercentage">
                              <span className="numberPercentage">{this.state.targetMessage}</span>
                            </div>
                          </div>
                        </div>
                        <div className="startSetupButton">
                          <button type="button" disabled={this.state.isDisabled} class="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector"  onClick={this.handleChangeTarget}>
                            Start Setup
                          </button>
                        </div>
                      </div>
                    </div>
                    </div>
                  }



                  
                
              </div>
            </div>
          </div>
          <SlidingPane className='connector-popup ege-learnmore' overlayClassName='some-custom-overlay-class' isOpen={ this.state.isViewQustioneries } onRequestClose={ () => { this.setState({ isViewQustioneries: true }); } }>
            <button className="popup-close" onClick={() => this.setState({ isViewQustioneries: false })}>
              <img src={closeicon} />
            </button>
            <Viewinfo />
          </SlidingPane>
        </div>
      </div>
    )
  }
}
export default DownloadSetup