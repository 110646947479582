import React from 'react';
import './authentication.css';

import API from '../../../api';

class Authentication extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            count:0,
            targetcount:0
           // response=window.localStorage.getItem("data")
        };
    }

    

    render(){
        return(
            <div>
                <div className="main-content authentication">
                    <div className="page-content pb-0">
                        <div className="container-fluid">
                           Authentication failed
                            </div>
                        </div>
                    </div>
                </div>
            
        )
    }
}

export default Authentication