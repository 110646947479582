import React from 'react';
import SelectedConnectorName from './SelectedConnectorName/SelectedConnectorName';
import ConnectorServices from './ConnectorServices/ConnectorServices'
import ConnectorServicePanel from './ConnectorServices/ConnectorServicePanel/ConnectorServicePanel';

class SelectedConnectorInfo extends React.Component {
    render(){
        let selectedConnectorInfo = this.props.selectedConnectorInfo
        return(
            <>
                <div className="row">
                    <div className="col-md-12 col-xl-12">
                        <div className="card">
                            <div className="card-body custom-max-ht">
                                <div className="tab-content new-tab-content text-muted">
                                    <div className="tab-pane nav-tabitemnew" id={"v-pills-home" + selectedConnectorInfo.id} role="tabpanel">
                                        <SelectedConnectorName Name={selectedConnectorInfo.name} />
                                        <ConnectorServices id={selectedConnectorInfo.id} />
                                        <ConnectorServicePanel selectedConnectorInfo = {selectedConnectorInfo} activeTab = {this.props.activeTab} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default SelectedConnectorInfo