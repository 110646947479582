import React from 'react';
import { NavLink, Link } from 'react-router-dom'
import SlidingPane from 'react-sliding-pane';
import closeicon from '../../../../../../../assets/images/close-icon.svg';
import DownloadConnector from '../../../../../../NewConnector/DownloadInstaller/DownloadConnector/DownloadConnector';
import CloneConnector from '../CloneConnector/CloneConnector';

class OtherActions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentWillReceiveProps(props) {
        this.setState({
            selectedConnectorInfo : props.downloadData
        })
    }
    render(){
        let selectedConnectorInfoNew = this.state.selectedConnectorInfo && this.state.selectedConnectorInfo
        let source = this.state.selectedConnectorInfo && this.state.selectedConnectorInfo.source
        let sourceType = this.state.selectedConnectorInfo && this.state.selectedConnectorInfo.source.type
        let target = this.state.selectedConnectorInfo && this.state.selectedConnectorInfo.target
        let targetShortName = this.state.selectedConnectorInfo && this.state.selectedConnectorInfo.target.shortName
        let targetType = this.state.selectedConnectorInfo && this.state.selectedConnectorInfo.target.type
        let featureType = selectedConnectorInfoNew && selectedConnectorInfoNew.featureName

        let connectorID = selectedConnectorInfoNew && selectedConnectorInfoNew.connectorId
        let sourceVersion = this.state.selectedConnectorInfo && this.state.selectedConnectorInfo.source.version
        let targetVersion = this.state.selectedConnectorInfo && this.state.selectedConnectorInfo.target.version

        

        return(
            <>
                <div className="otheroptions mt-1">
                    <h6> Other Options </h6>
                    <Link className="customDownloadnew" to={{ pathname: "/configure", downloadData: selectedConnectorInfoNew }} >Configuration  </Link>
                    {
                        (targetType === 1) || (sourceType === 1) ? 
                            (
                                (targetShortName === "EGE") ? 
                                <Link className="customDownloadnew"  to={{pathname:"/EgeSetup", downloadData: this.state.selectedConnectorInfo}} > Setup </Link>: 
                                <Link className="customDownloadnew"  to={{pathname:"/DownloadSetup", downloadData: this.state.selectedConnectorInfo, hilightmn: "MyProducts"}} > Setup </Link>
                            )
                        : 
                        <button className="customDownloadnew" onClick={() => this.setState({ isPaneOpenDownload: true })}>
                            Download Installers
                        </button>
                    }
                    <CloneConnector
                        connectorId = {connectorID}
                        sourceVersion = {sourceVersion}
                        targetVersion = {targetVersion}
                    />
                </div>
                <SlidingPane className='connector-popup' overlayClassName='some-custom-overlay-class' isOpen={this.state.isPaneOpenDownload} onRequestClose={() => { this.setState({ isPaneOpenDownload: true }); }}>
                    <button className="popup-close" onClick={() => this.setState({ isPaneOpenDownload: false })}>
                        <img src={closeicon} />
                    </button>
                    <DownloadConnector downloadData={this.state.selectedConnectorInfo} />
                </SlidingPane>
            </>
        )
    }
}
export default OtherActions