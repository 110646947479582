import React from 'react';
import SessionTimeout from '../../SessionTimeout'
import $ from 'jquery'
import { NavLink,Link} from 'react-router-dom'
//import { Link } from 'react-router-dom';
import inputCalender from '../../../assets/images/input_calender.svg'
import Shopifyimg from '../../../assets/images/shopify.svg'
import toggleSummary from '../../../assets/images/toggleSummary.svg'
import vieSummaryDetails from './SummaryDetails/SummaryDetails'
import {Header} from '../../Shared/Header';
import Sidebar from '../../Shared/Sidebar';

// import navimg from '../../../assets/images/nav.svg'
// import gpimg from '../../../assets/images/nav.svg'
// import magentoimg from '../../../assets/images/magento.svg'
// import bcimg from '../../../assets/images/bcnew.png'
// import egeimg from '../../../assets/images/EGE.svg'

import nav_gp from '../../../assets/images/nav_gp.svg'
import magento from '../../../assets/images/magento.svg'
import bc from '../../../assets/images/bcnew.png'
import EGE from '../../../assets/images/EGE.svg'
import bigcommerce from '../../../assets/images/bigcommerce.png'
import qb from '../../../assets/images/qb_thumb.png'
import shopify from '../../../assets/images/shopify.svg'

import './viewsummary.css'
import API from '../../api';

class ViewSummary extends React.Component {

    constructor(props) {
        var today = new Date()
        var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        var todayISO = today.toISOString()
        super(props);
        this.state = {
            // isLoaded:false,
            title:props.location.title,
            sourcetoTarget: true,
            targettoSource: false,
            // startnewDate: todayISO,
            // endnewDate: todayISO,
            startnewDate: null,
            endnewDate: null,
            tableTitle: '',
            tableText: '',
            datainstance: window.localStorage.getItem("summaryID")
        };
        this.open = this.open.bind(this);
        let start = "Pick the start data";
        let end = "Pick the end data";
       // window.sessionStorage.setItem("startnewDate", start)
        //window.sessionStorage.setItem("endDate", end)
        window.localStorage.setItem("summarystart",this.state.startnewDate)
        window.localStorage.setItem("summaryend",this.state.endnewDate)
    }

    open() {
        const { sourcetoTarget, targettoSource } = this.state;
        this.setState({
            sourcetoTarget: !sourcetoTarget,
            targettoSource: !targettoSource,
        });
    }

    componentDidMount(){
        if(this.state.title === true){
            this.setState({
                sourcetoTarget: false,
                targettoSource: this.state.title,
            })
        }
        this.handleDConnections();
    }
    handleDConnections = () => {
        let self = this;
        var response = window.localStorage.getItem("accessToken");
        let id = window.sessionStorage.getItem("connectorIndex")
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        }
        API.get("/Client/connections",{headers})
        .then(response => response)
        .then(data => {
            if(data.data.length < 1){
                document.getElementById("errorMessageSummarynew").classList.remove("d-none")
                return
            }
            self.setState({
                datapoint: data 
            })
            const movies = self.state.datapoint.data;
            API.post("/Client/connections/report/summary",
                {
                    "subscriptionInstanceId": movies[id].instances[this.state.datainstance].id,
                    "sourceEndpointId": movies[id].source.id,
                    "requestType": "Source"
                },
                {headers}
            )
            .then(response => response)
            .then(data => {
                document.getElementById("loaderContainer").className += ' d-none';
                document.getElementById("danger-warning-alert").className += ' d-none';
                
                if(data.data.length < 1){
                    document.getElementById("errorMessageSummarynew").classList.remove("d-none")
                }else{
                    document.getElementById("danger-warning-alert").classList.add("d-none")
                }
                self.setState({
                    instanceSourceData: data 
                })
            })
            API.post(
                "Client/connections/report/summary", 
                {
                    "subscriptionInstanceId": movies[id].instances[this.state.datainstance].id,
                    "sourceEndpointId": movies[id].target.id,
                    "requestType": "target"
                },
                {headers}
            )
            .then(response => response)
            .then(data => {
                document.getElementById("loaderContainer").className += ' d-none';
                document.getElementById("danger-warning-alert").className += ' d-none';
                if(data.data.length < 1){
                    //document.getElementById("errorMessageSummarynew").classList.remove("d-none")
                }else{
                    document.getElementById("danger-warning-alert").classList.add("d-none")
                }
                self.setState({
                    instanceTargetData: data
                })
            })
            API.post("/Client/connections/sync-time",
            {
                "subscriptionInstanceId": movies[id].instances[this.state.datainstance].id,
                "sourceEndpointId": movies[id].source.id,
                "requestType": "Source"
            },{headers})
            .then(response => response)
            .then(data => {
                self.setState({
                    sourcedate: data
                })
            })
            API.post("/Client/connections/sync-time",
            {
                "subscriptionInstanceId": movies[id].instances[this.state.datainstance].id,
                "sourceEndpointId": movies[id].target.id,
                "requestType": "target"
            },{headers})
            .then(response => response)
            .then(data => {
                self.setState({
                    targetdate: data
                })
            })
        })
    }

    toPassData = (e) => {
        //alert(e.currentTarget.hreflang)
        var today=new Date();
        var todayISO = today.toISOString()

        var startnewDate = new Date(document.getElementById("startDatenew1").value)
        var endnewDate = new Date(document.getElementById("endDatenew2").value)

        window.localStorage.setItem("newType", e.target.hreflang)

        if (startnewDate == "Invalid Date" || startnewDate == " " ) {
            window.sessionStorage.removeItem("startnewDate1")
            window.sessionStorage.removeItem("endDate1")
            // window.sessionStorage.setItem("startnewDate",todayISO)
            // window.sessionStorage.setItem("endDate",todayISO)
            window.sessionStorage.setItem("endpointID", e.currentTarget.title)
            //window.sessionStorage.setItem("Requesttype", e.currentTarget.value)
            window.sessionStorage.setItem("tableText", e.currentTarget.textContent)
            window.sessionStorage.setItem("Requesttype", e.currentTarget.hreflang)
            window.sessionStorage.setItem("EntityID", e.currentTarget.id)
            window.sessionStorage.setItem("Status", e.currentTarget.name)

        } else {
            var startnewDateiso = startnewDate.toISOString()
            var endnewDateiso = endnewDate.toISOString()

            // window.sessionStorage.setItem("startnewDate",startnewDateiso)
            // window.sessionStorage.setItem("endDate",endnewDateiso)
            window.sessionStorage.setItem("EntityID", e.currentTarget.id)
            window.sessionStorage.setItem("Status", e.currentTarget.name)
            window.sessionStorage.setItem("endpointID", e.currentTarget.title)
            window.sessionStorage.setItem("Requesttype", e.currentTarget.hreflang)
            window.sessionStorage.setItem("tableText", e.currentTarget.textContent)
            window.sessionStorage.setItem("startnewDate", startnewDateiso)
            window.sessionStorage.setItem("endDate", endnewDateiso)
        }

        // this.setState({startnewDate:document.getElementById("startDatenew1").value})
        // this.setState({endnewDate: document.getElementById("endDatenew2").value})
        // this.setState({tableTitle: e.currentTarget.title})
        // this.setState({tableText:e.currentTarget.textContent})
    }

    refreshData = () => {


        document.getElementById("loaderContainer").classList.remove("d-none")
        
        this.setState({startnewDate:document.getElementById("startDatenew1").value})
        this.setState({endnewDate:document.getElementById("endDatenew2").value})

        window.sessionStorage.setItem("startnewDate1",document.getElementById("startDatenew1").value)
        window.sessionStorage.setItem("endDate1",document.getElementById("endDatenew2").value)

        let self = this;
        var response = window.localStorage.getItem("accessToken");
        let id = window.sessionStorage.getItem("connectorIndex")

        var today = new Date();
        var todayISO = today.toISOString()

        var startnewDate = new Date(document.getElementById("startDatenew1").value)
        var endnewDate = new Date(document.getElementById("endDatenew2").value)
         
        window.localStorage.setItem("summarystart",document.getElementById("startDatenew1").value)
        window.localStorage.setItem("summaryend",document.getElementById("endDatenew2").value)
        
        if (startnewDate == "Invalid Date") {
            //   window.sessionStorage.setItem("startnewDate",todayISO)
            //   window.sessionStorage.setItem("endDate",todayISO) 
            this.handleDConnections()
        } else {
            var startnewDateiso = startnewDate.toISOString()
            var endnewDateiso = endnewDate.toISOString()
        }


        // var startnewDate = new Date(document.getElementById("startDatenew1").value)
        // var endnewDate = new Date(document.getElementById("endDatenew2").value)
        // var startnewDateiso = startnewDate.toISOString()
        // var endnewDateiso = endnewDate.toISOString()


        // self.setState({startnewDate:startnewDateiso})
        // self.setState({endnewDate:endnewDateiso})
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        const movies = self.state.datapoint.data;
        API.post(
            "/Client/connections/report/summary",
            {
                "subscriptionInstanceId": movies[id].instances[this.state.datainstance].id,
                "sourceEndpointId": movies[id].source.id,
                "requestType": "Source",
                "createdTime": {
                    "fromTime": window.sessionStorage.getItem("startnewDate"),
                    "toTime": window.sessionStorage.getItem("endDate")
                },
                "updatedTime": {
                    "fromTime":  window.localStorage.getItem("summarystart"),
                    "toTime":window.localStorage.getItem("summaryend")
                },
            },
            { headers }
        )
            .then(response => response)
            .then(data => {
                document.getElementById("loaderContainer").classList.add("d-none")
                if(data.data.length < 1){
                    document.getElementById("danger-warning-alert").classList.remove("d-none")
                }else{
                    document.getElementById("danger-warning-alert").classList.add("d-none")
                }
                self.setState({
                    instanceSourceData: data
                })
            })
        API.post(
            "Client/connections/report/summary",
            {
                "subscriptionInstanceId": movies[id].instances[this.state.datainstance].id,
                "sourceEndpointId": movies[id].target.id,
                "requestType": "target",
                "createdTime": {
                    "fromTime": window.sessionStorage.getItem("startnewDate"),
                    "toTime": window.sessionStorage.getItem("endDate")
                },
                "updatedTime": {
                    "fromTime":  window.localStorage.getItem("summarystart"),
                    "toTime":window.localStorage.getItem("summaryend")
                },
            },
            { headers }
        )
            .then(response => response)
            .then(data => {
                document.getElementById("loaderContainer").classList.add("d-none")
                if(data.data.length < 1){
                    document.getElementById("danger-warning-alert").classList.remove("d-none")
                }else{
                    document.getElementById("danger-warning-alert").classList.add("d-none")
                }
                self.setState({
                    instanceTargetData: data
                })
            })
    }

//     handleDatechange=(event)=>{
//    alert(event.target.value)
//     }

    render() {
        let startnewDate = this.state;
        let endnewDate = this.state;
        let tableTitle = this.state;
        let tableText = this.state;
        var SName = window.sessionStorage.getItem("SourceName")
        var TName = window.sessionStorage.getItem("targetname")
        let sourcedate = this.state;
        let targetdate = this.state


        let startdate = window.sessionStorage.getItem("startnewDate")
        let enddate = window.sessionStorage.getItem("endDate")
        let datapoint = this.state;
        let id = window.sessionStorage.getItem("connectorIndex")

        let subscriptionInstanceId = datapoint.datapoint && datapoint.datapoint.data && datapoint.datapoint.data[id].instances[this.state.datainstance].id;
        let sourceEndpointId = datapoint.datapoint && datapoint.datapoint.data && datapoint.datapoint.data[id].source.id;
        let targetEndpointId = datapoint.datapoint && datapoint.datapoint.data && datapoint.datapoint.data[id].target.id;

        //window.sessionStorage.setItem("Sourcedata",sourceEndpointId)
        window.sessionStorage.setItem("subscriptionInstanceId",subscriptionInstanceId)
        //window.sessionStorage.setItem("targetdata",targetEndpointId)

        let instanceSourceData = this.state;
        let instanceTargetData = this.state;

        $(".trdashboard").addClass("active")

        let pulltimesource=new Date(sourcedate.sourcedate && sourcedate.sourcedate.data && sourcedate.sourcedate.data.lastPullTime);
        let pulltime=pulltimesource.getFullYear()+'-' + (pulltimesource.getMonth()+1) + '-'+pulltimesource.getDate()+ ''+pulltimesource.getMinutes();
        let pushtimesource=new Date(sourcedate.sourcedate && sourcedate.sourcedate.data && sourcedate.sourcedate.data.lastPushTime)
        let pushtime=pushtimesource.getFullYear()+'-' + (pushtimesource.getMonth()+1) + '-'+pushtimesource.getDate()+ ''+pushtimesource.getMinutes();


        let pulltimetarget=new Date(targetdate.targetdate && targetdate.targetdate.data && targetdate.targetdate.data.lastPullTime);
        let targetpull=pulltimetarget.getFullYear()+'-' + (pulltimetarget.getMonth()+1) + '-'+pulltimetarget.getDate()+ ''+pulltimetarget.getMinutes();
        let pushtimetarget= new Date(targetdate.targetdate && targetdate.targetdate.data && targetdate.targetdate.data.lastPushTime) ;
        let targetpush=pushtimetarget.getFullYear()+'-' + (pushtimetarget.getMonth()+1) + '-'+pushtimetarget.getDate()+ ''+pushtimetarget.getMinutes();                                               
                                                     
        
        return (
            <div>
                <SessionTimeout />
                <Header />
                <Sidebar />
                <div className="main-content myConnectors">
                    <div id="loaderContainer" className="loaderContainer">
                        <div className="loader"></div>
                    </div>
                    <div className="page-content pb-0">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <div className="float-left page-title-left">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                                <li className="breadcrumb-item active">Entities</li>
                                            </ol>
                                        </div>
                                        <div className="float-right page-title-right positionrelative topnine">
                                            <div className="form-inline form-group">
                                                <div className="input-daterange input-group mr-3" data-provide="datepicker" data-date-format="yyyy-mm-dd" data-date-autoclose="true">
                                                    <label className="summaryDateFrom"> From </label>
                                                    <input type="text" className="form-control" id="startDatenew1" name="start" placeholder={"Pick the Start date"} onChange={this.handleDatechange}  />
                                                    <span className="calenderIcon"> <img src={inputCalender} /> </span>
                                                    <label className="summaryDateTo"> To </label>
                                                    <input type="text" className="form-control" id="endDatenew2" name="end" placeholder={"Pick the End date"} onChange={this.handleDatechange} />
                                                    <span className="calenderIcon"> <img src={inputCalender} /> </span>
                                                </div>
                                                <button className="btn btn-primary mb-2" onClick={this.refreshData}> Apply </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-7 col-xl-6">
                                    <div className="card">
                                        <div className="p-4 border-top">
                                            <div>
                                                <div className="row flip">
                                                    <div className="col-lg-5">
                                                        <div data-toggle="collapse">
                                                            <label className="card-radio-label">
                                                                {this.state.sourcetoTarget &&
                                                                    <span className="card-radio border-primary p-1 summary-card-radio">
                                                                        {
                                                                            // (SName == "Nav") ? <img src={navimg} /> : 
                                                                            // (SName == "GP") ? <img src={gpimg} /> :
                                                                            // (SName == "Magento") ? <img src={magentoimg} /> : 
                                                                            // (SName == "BC") ? <img src={bcimg} /> :
                                                                            // (SName == "EGE") ? <img src={egeimg} /> : null


                                                                            SName === "EGE" ? <img src={EGE} /> : 
                                                                            SName === "Magento" ? <img src={magento} /> : 
                                                                            SName === "Nav" || SName === "AX" ? <img src={nav_gp} /> : 
                                                                            SName === "GP" ? <img src={nav_gp} /> : 
                                                                            (SName === "BC") || (SName === "Business Central") ? <img src={bc} /> :
                                                                            SName === "BigCommerce" ? <img src={bigcommerce} /> : 
                                                                            SName === "QuickBooks" ?  <img src={qb} /> :
                                                                            SName === "Shopify" ?  <img src={shopify} /> : null

                                                                        }
                                                                        <span className="summaryTitle">
                                                                            {SName}
                                                                        </span>
                                                                    </span>
                                                                }
                                                                {this.state.targettoSource &&
                                                                    <span className="card-radio border-primary p-1 summary-card-radio">
                                                                        {
                                                                            // (TName == "Nav") ? <img src={navimg} /> : 
                                                                            // (TName == "GP") ? <img src={gpimg} /> :
                                                                            // (TName == "Magento") ? <img src={magentoimg} /> : 
                                                                            // (TName == "BC") ? <img src={bcimg} /> :
                                                                            // (TName == "EGE") ? <img src={egeimg} /> : null

                                                                            TName === "EGE" ? <img src={EGE} /> : 
                                                                            TName === "Magento" ? <img src={magento} /> : 
                                                                            TName === "Nav" || TName === "AX" ? <img src={nav_gp} /> : 
                                                                            TName === "GP" ? <img src={nav_gp} /> : 
                                                                            (TName === "BC") || (TName === "Business Central") ? <img src={bc} /> :
                                                                            TName === "BigCommerce" ? <img src={bigcommerce} /> : 
                                                                            TName === "QuickBooks" ?  <img src={qb} /> : 
                                                                            TName === "Shopify" ?  <img src={shopify} /> : null
                                                                        }

                                                                                
                                                                        <span className="summaryTitle">
                                                                            {TName}
                                                                        </span>
                                                                    </span>
                                                                }
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <div className="card margintop15">
                                                            <div className="card-body card-body-customnew">
                                                                <div className="customToggleButton" onClick={this.open}>
                                                                    <img src={toggleSummary} />
                                                                    <span className="imgTitlenew"> Toggle </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-5">
                                                        <div>
                                                            <label className="card-radio-label">
                                                                {this.state.sourcetoTarget &&
                                                                    <span className="card-radio border-primary p-1 summary-card-radio">
                                                                        {
                                                                            TName === "EGE" ? <img src={EGE} /> : 
                                                                            TName === "Magento" ? <img src={magento} /> : 
                                                                            TName === "Nav" ? <img src={nav_gp} /> : 
                                                                            TName === "GP" ? <img src={nav_gp} /> : 
                                                                            (TName === "BC") || (TName === "Business Central") ? <img src={bc} /> :
                                                                            TName === "BigCommerce" ? <img src={bigcommerce} /> : null
                                                                        }
                                                                        <span className="summaryTitle">
                                                                            {TName}
                                                                        </span>
                                                                    </span>
                                                                }
                                                                {this.state.targettoSource &&
                                                                    <span className="card-radio border-primary p-1 summary-card-radio">
                                                                        {
                                                                            SName === "EGE" ? <img src={EGE} /> : 
                                                                            SName === "Magento" ? <img src={magento} /> : 
                                                                            SName === "Nav" ? <img src={nav_gp} /> : 
                                                                            SName === "GP" ? <img src={nav_gp} /> : 
                                                                            (SName === "BC") || (SName === "Business Central") ? <img src={bc} /> :
                                                                            SName === "BigCommerce" ? <img src={bigcommerce} /> : null
                                                                        }
                                                                        <span className="summaryTitle">
                                                                            {SName}
                                                                        </span>
                                                                    </span>
                                                                }
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-xl-6">
                                    { this.state.sourcetoTarget &&
                                        <div className="pushandpullstatus">
                                            <h5 className="sourcetotargettitle"> Transferred from <b>{SName} to {TName}</b> </h5>
                                            <span className="last-pull"> 
                                                <span className="fixedWidthnewLeft"> Last Pull </span>
                                                <span className="deviderColon"> : </span>
                                                <b>
                                                    {
                                                        sourcedate.sourcedate && sourcedate.sourcedate.data && sourcedate.sourcedate.data.lastPullTime === null ? 
                                                        <span> Not updated yet </span> : sourcedate.sourcedate && sourcedate.sourcedate.data && sourcedate.sourcedate.data.lastPullTime.split('.')[0]
                                                    }
                                                </b>
                                            </span>
                                            <span className="last-push"> 
                                                <span className="fixedWidthnewLeft"> Last Push </span>
                                                <span className="deviderColon"> : </span>
                                                <b>
                                                    {
                                                        sourcedate.sourcedate && sourcedate.sourcedate.data && sourcedate.sourcedate.data.lastPushTime === null ? 
                                                        <span> Not updated yet </span> : sourcedate.sourcedate && sourcedate.sourcedate.data && sourcedate.sourcedate.data.lastPushTime.split('.')[0]
                                                    }
                                                </b>
                                            </span> 
                                        </div>
                                    }
                                    { this.state.targettoSource &&
                                        <div className="pushandpullstatus">
                                            <h5 className="sourcetotargettitle"> Transferred from <b>{TName} to {SName}</b> </h5>
                                            <span className="last-pull">
                                                <span className="fixedWidthnewLeft"> Last Pull </span>
                                                <span className="deviderColon"> : </span>
                                                <b>
                                                    {
                                                        targetdate.targetdate && targetdate.targetdate.data && targetdate.targetdate.data.lastPullTime === null ?
                                                        <span> Not updated yet </span> : targetdate.targetdate && targetdate.targetdate.data && targetdate.targetdate.data.lastPullTime.split('.')[0]
                                                    }
                                                </b>
                                            </span>
                                            <span className="last-push"> 
                                                <span className="fixedWidthnewLeft"> Last Push </span>
                                                <span className="deviderColon"> : </span>
                                                <b>
                                                    {
                                                        targetdate.targetdate && targetdate.targetdate.data && targetdate.targetdate.data.lastPushTime === null ?
                                                        <span> Not updated yet </span> : targetdate.targetdate && targetdate.targetdate.data && targetdate.targetdate.data.lastPushTime.split('.')[0]
                                                    }
                                                </b>
                                            </span> 
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card no-padding">
                                        <div className="card-body no-padding">
                                            <div className="table-responsive">
                                                { this.state.sourcetoTarget &&
                                                    <table className="viewSummaryNew table mb-0">
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th>Entity</th>
                                                                <th>Request Received</th>
                                                                <th>Request in Process</th>
                                                                <th>Response Received</th>
                                                                <th>Response Transferred</th>
                                                                <th>Error</th>
                                                                <th>Completed</th>
                                                                <th>Total by Entity</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {instanceSourceData.instanceSourceData && instanceSourceData.instanceSourceData.data.length > 0 ? 
                                                                instanceSourceData.instanceSourceData && instanceSourceData.instanceSourceData.data.map(item =>
                                                                    <tr>
                                                                        <th scope="row">{item.entityName}</th>
                                                                        <td>{item.requestReceivedCount > 0 ?
                                                                            <Link to={{ pathname: "/SummaryDetails", changeID:this.state.datainstance, instanceSourceData: { instanceSourceData }, name: item.entityName, id: item.entityId, sourceEndpointId: { sourceEndpointId }, subscriptionInstanceId: { subscriptionInstanceId }, tableTitle: { tableTitle }, tableText: { tableText }, statusIds: 1 }} id={item.entityId} name="1"  title={sourceEndpointId} hreflang="Source" onClick={this.toPassData}>{item.requestReceivedCount}</Link>
                                                                            : item.requestReceivedCount}</td>
                                                                        <td>{item.inProcessCount > 0 ?
                                                                            <Link to={{ pathname: "/SummaryDetails", changeID:this.state.datainstance, instanceSourceData: { instanceSourceData }, name: item.entityName, id: item.entityId, sourceEndpointId: { sourceEndpointId }, subscriptionInstanceId: { subscriptionInstanceId }, tableTitle: { tableTitle }, tableText: { tableText }, statusIds: 2 }} id={item.entityId} name="2"  title={sourceEndpointId} hreflang="Source" onClick={this.toPassData}>{item.inProcessCount}</Link>
                                                                            : item.inProcessCount}</td>
                                                                        <td>{item.responseReceivedCount > 0 ?
                                                                            <Link to={{ pathname: "/SummaryDetails", changeID:this.state.datainstance, instanceSourceData: { instanceSourceData }, name: item.entityName, id: item.entityId, sourceEndpointId: { sourceEndpointId }, subscriptionInstanceId: { subscriptionInstanceId }, tableTitle: { tableTitle }, tableText: { tableText }, statusIds: 4 }} id={item.entityId} name="4"  title={sourceEndpointId} hreflang="Source" onClick={this.toPassData}>{item.responseReceivedCount}</Link>
                                                                            : item.responseReceivedCount}</td>
                                                                        <td>{item.responseTransferredCount > 0 ?
                                                                            <Link to={{ pathname: "/SummaryDetails", changeID:this.state.datainstance, instanceSourceData: { instanceSourceData }, name: item.entityName, id: item.entityId, sourceEndpointId: { sourceEndpointId }, subscriptionInstanceId: { subscriptionInstanceId }, tableTitle: { tableTitle }, tableText: { tableText }, statusIds: 5 }} id={item.entityId} name="5"  title={sourceEndpointId} hreflang="Source" onClick={this.toPassData}>{item.responseTransferredCount}</Link>
                                                                            : item.responseTransferredCount}</td>
                                                                        <td>{item.errorCount > 0 ? <Link to={{ pathname: "/SummaryDetails", changeID:this.state.datainstance, instanceSourceData: { instanceSourceData }, name: item.entityName, id: item.entityId, sourceEndpointId: { sourceEndpointId }, subscriptionInstanceId: { subscriptionInstanceId }, tableTitle: { tableTitle }, tableText: { tableText }, statusIds: 3 }} id={item.entityId}  title={sourceEndpointId} hreflang="Source" name="3" onClick={this.toPassData}>{item.errorCount}</Link> : item.errorCount} </td>
                                                                        <td>{item.completeCount > 0 ? <Link to={{ pathname: "/SummaryDetails", changeID:this.state.datainstance, instanceSourceData: { instanceSourceData }, name: item.entityName, id: item.entityId, sourceEndpointId: { sourceEndpointId }, subscriptionInstanceId: { subscriptionInstanceId }, tableTitle: { tableTitle }, tableText: { tableText }, statusIds: 6 }} id={item.entityId}  title={sourceEndpointId} hreflang="Source"  name="6"  onClick={this.toPassData}>{item.completeCount}</Link> : item.completeCount}</td>
                                                                        <td>
                                                                            {item.totalCount > 0 ? <Link to={{ pathname: "/SummaryDetails", changeID:this.state.datainstance, instanceSourceData: { instanceSourceData }, id: item.entityId, name: item.entityName, sourceEndpointId: { sourceEndpointId }, subscriptionInstanceId: { subscriptionInstanceId }, tableTitle: { tableTitle }, tableText: { tableText } }} id={item.entityId} title={sourceEndpointId} hreflang="Source" onClick={this.toPassData}>{item.totalCount}</Link> : item.totalCount}
                                                                        </td>
                                                                    </tr>
                                                                ) :
                                                                <tr id="errorMessageSummarynew"  className=" d-none">
                                                                    <td colSpan="8">
                                                                        <div className="alert alert-danger" role="alert">
                                                                            There is no data to Show
                                                                        </div>
                                                                    </td>
                                                                </tr> 
                                                            }
                                                            <tr id="danger-warning-alert"  className="border-0 d-none">
                                                               <td colSpan="8" className="border-0">
                                                                    <div className="alert alert-danger" role="alert">
                                                                        There is no data to Show 
                                                                    </div>
                                                                </td> 
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                }
                                                {
                                                    this.state.targettoSource &&
                                                    <table className="viewSummaryNew table mb-0 targetfn">
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th>Entity</th>
                                                                <th>Request Received</th>
                                                                <th>Request in Process</th>
                                                                <th>Response Received</th>
                                                                <th>Response Transferred</th>
                                                                <th>Error</th>
                                                                <th>Completed</th>
                                                                <th>Total by Entity</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {instanceTargetData.instanceTargetData && instanceTargetData.instanceTargetData.data.length > 0 ? instanceTargetData.instanceTargetData && instanceTargetData.instanceTargetData.data.map(item =>
                                                                <tr>
                                                                    <th scope="row">{item.entityName} </th>
                                                                    <td>{item.requestReceivedCount > 0 ?
                                                                     <Link to={{
                                                                        pathname: "/SummaryDetails",
                                                                        instanceTargetData: { instanceTargetData },
                                                                        title:this.state.targettoSource,
                                                                        id: item.entityId,
                                                                        name: item.entityName,
                                                                        sourceEndpointId: { targetEndpointId },
                                                                        subscriptionInstanceId: { subscriptionInstanceId },
                                                                        statusIds: 1,
                                                                        changeID:this.state.datainstance
                                                                    }} id={item.entityId} hreflang="Target"  title={targetEndpointId} name="1" onClick={this.toPassData}>{item.requestReceivedCount}</Link> : item.requestReceivedCount}</td>
                                                                    <td>{item.inProcessCount > 0 ? 
                                                                    <Link to={{
                                                                        pathname: "/SummaryDetails",
                                                                        instanceTargetData: { instanceTargetData },
                                                                        title:this.state.targettoSource,
                                                                        id: item.entityId,
                                                                        name: item.entityName,
                                                                        sourceEndpointId: { targetEndpointId },
                                                                        subscriptionInstanceId: { subscriptionInstanceId },
                                                                        statusIds: 2,
                                                                        changeID:this.state.datainstance
                                                                    }} id={item.entityId} hreflang="Target" title={targetEndpointId} name="2" onClick={this.toPassData}>{item.inProcessCount}</Link> : item.inProcessCount}</td>
                                                                    <td>{item.responseReceivedCount > 0 ? 
                                                                    <Link to={{
                                                                        pathname: "/SummaryDetails",
                                                                        instanceTargetData: { instanceTargetData },
                                                                        title:this.state.targettoSource,
                                                                        id: item.entityId,
                                                                        name: item.entityName,
                                                                        sourceEndpointId: { targetEndpointId },
                                                                        subscriptionInstanceId: { subscriptionInstanceId },
                                                                        statusIds: 4,
                                                                        changeID:this.state.datainstance
                                                                    }} id={item.entityId} hreflang="Target" title={targetEndpointId} name="4" onClick={this.toPassData}>{item.responseReceivedCount}</Link> : item.responseReceivedCount}</td>
                                                                    <td>{item.responseTransferredCount > 0 ? 
                                                                    <Link to={{
                                                                        pathname: "/SummaryDetails",
                                                                        instanceTargetData: { instanceTargetData },
                                                                        title:this.state.targettoSource,
                                                                        id: item.entityId,
                                                                        name: item.entityName,
                                                                        sourceEndpointId: { targetEndpointId },
                                                                        subscriptionInstanceId: { subscriptionInstanceId },
                                                                        statusIds: 5,
                                                                        changeID:this.state.datainstance
                                                                    }} id={item.entityId}  hreflang="Target" title={targetEndpointId} name="5" onClick={this.toPassData}>{item.responseTransferredCount}</Link> : item.responseTransferredCount}</td>
                                                                    <td>{item.errorCount > 0 ? 
                                                                    <Link to={{
                                                                        pathname: "/SummaryDetails",
                                                                        instanceTargetData: { instanceTargetData },
                                                                        title:this.state.targettoSource,
                                                                        id: item.entityId,
                                                                        name: item.entityName,
                                                                        sourceEndpointId: { targetEndpointId },
                                                                        subscriptionInstanceId: { subscriptionInstanceId },
                                                                        statusIds: 3,
                                                                        changeID:this.state.datainstance
                                                                    }} id={item.entityId} hreflang="Target"  title={targetEndpointId} name="3" onClick={this.toPassData}>{item.errorCount}</Link> : item.errorCount}</td>
                                                                    <td>{item.completeCount > 0 ? 
                                                                    <Link to={{
                                                                        pathname: "/SummaryDetails",
                                                                        instanceTargetData: { instanceTargetData },
                                                                        title:this.state.targettoSource,
                                                                        id: item.entityId,
                                                                        name: item.entityName,
                                                                        sourceEndpointId: { targetEndpointId },
                                                                        subscriptionInstanceId: { subscriptionInstanceId },
                                                                        statusIds: 6,
                                                                        changeID:this.state.datainstance
                                                                    }} id={item.entityId} hreflang="Target"  title={targetEndpointId} name="6" onClick={this.toPassData}>{item.completeCount}</Link> : item.completeCount}</td>
                                                                    <td>{item.totalCount > 0 ? 
                                                                    <Link to={{
                                                                        pathname: "/SummaryDetails",
                                                                        title:this.state.targettoSource,
                                                                        id: item.entityId,
                                                                        name: item.entityName,
                                                                        instanceTargetData: { instanceTargetData },
                                                                        sourceEndpointId: { targetEndpointId },
                                                                        subscriptionInstanceId: { subscriptionInstanceId },
                                                                        changeID:this.state.datainstance
                                                                    }} id={item.entityId} hreflang="Target" title={targetEndpointId} onClick={this.toPassData}>{item.totalCount}</Link> : item.totalCount}</td>
                                                                </tr>
                                                            )
                                                                :
                                                                <tr id="errorMessageSummarynew" className="d-none">
                                                                    <td colSpan="8">
                                                                        <div className="alert alert-danger" role="alert">
                                                                            There is no data to Show
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            }
                                                                <tr id="danger-warning-alert" className="border-0 d-none">
                                                                    <td colSpan="8"  className="border-0">
                                                                        <div className="alert alert-danger" role="alert">
                                                                            There is no data to Show 
                                                                        </div>
                                                                    </td> 
                                                                </tr>
                                                        </tbody>
                                                    </table>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ViewSummary