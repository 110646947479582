import React, { Component } from 'react';

import './DownloadInstaller.css'
import { Link , Redirect} from "react-router-dom";
import API from '../../api';

export default class subDownlaodfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sourceMessage: "0",
            targetMessage: "0%",
            keyType: [],
            isLoaded: false,
            krpType: [],
            downloadData: [],
            result: [],
            count: 0,
            targetCount: 0
        }
    }

    componentDidMount() {
        this.handleDownload();
    }
    refreshPage = () => {
        if (window.location.pathname == '/Updates') {
            return window.location.href = window.location.protocol + "//" + window.location.host
            //<Redirect from='/Updates' to="/Dashboard" />
            //<Link to="/Dashboard"/>
        }else if(window.location.pathname == '/Myconnectors'){
            return window.location.href = window.location.protocol + "//" + window.location.host
        }else if(window.location.pathname == '/Marketplace'){
            return window.location.href = window.location.protocol + "//" + window.location.host
        }else if(window.location.pathname == '/Getawalkthrough'){
            return window.location.href = window.location.protocol + "//" + window.location.host
        }
        else {
            return window.location.reload(false);
        }
    }

    handleDownload = () => {
        let self = this;
        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json",
        };
        API.get(`/Client/connections/${self.props.connectorIdNew}/instances/${self.props.instanceID}`, { headers })
            .then(response => response)
            .then(data1 => {
                document.getElementById("loaderContainerDownload").className += ' d-none';
                self.setState({
                    isLoaded: true,
                    krpType: data1
                })
            }
        )
    }




    handleSourceDownload = () => {
        document.getElementById("loaderContainerDownload").classList.remove("d-none")
        setTimeout(function(){
            document.getElementById('loaderContainerDownload').classList.add('d-none');
        },10000)

        this.setState({ count: this.state.count + 1 });
        let self = this;
        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };

        API.get('/User/Token/Check', { headers })
            .then(response => response)
            .then(data1 => {
                if (data1.data.result == false) {
                    //alert(JSON.stringify(data1.data.result))
                    API.get('/User/Authorize', { headers })
                        .then(response => response)
                        .then(data => {
                            if (self.state.count < 5) {
                                var win = window.open(data.data, "", "width=500,height=500");
                                setTimeout(function () { win.close(); }, 10000);
                            }
                            else {

                                var html = "<html><head></head><body> Authentication failed : The Request could not be completed </body></html>"

                                var win = window.open(data.data, "", "width=500,height=500");

                                win.document.write(html)

                                setTimeout(function () { win.close(); }, 10000);
                            }
                        })
                    setTimeout(function () {
                        if (self.state.count < 5) { self.handleSourceDownload(); }
                        else{
                        self.setState({ count: 0 });
                        }
                    }, 11000);
                    //this.handleSourceDownload();
                }

                else {
                    const downloadData = self.state.krpType.data;

                    API.post(
                        "/Client/connections/download", {
                        "subscriptionId": downloadData.subscriptionId,
                        "endpointId": downloadData.source.id,
                        "instanceId": self.props.instanceID,
                        "fileType": downloadData.source.installer.type,
                        "refreshToken": response,
                    },
                        { headers, responseType: 'blob' }

                    )
                        .then(resp => {
                            const disposition = resp.headers["content-disposition"];
                            var fileName = "";
                            const array1 = disposition.split(';');
                            if (array1 && array1.length > 1) {
                                const string1 = array1[1];
                                if (string1) {
                                    const array2 = string1.split('=');
                                    if (array2 && array2.length > 1) {
                                        fileName = array2[1];
                                    }
                                }
                            }
                            const url = window.URL.createObjectURL(new Blob([resp.data], { type: 'application/zip' }));

                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', fileName);
                            link.click();
                        })
                }


            })




    }
    handleTargetDownload = () => {
        document.getElementById("loaderContainerDownload").classList.remove("d-none")
        setTimeout(function(){
            document.getElementById('loaderContainerDownload').classList.add('d-none');
        },10000)

        this.setState({ targetCount: this.state.targetCount + 1 });
        let self = this;

        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        API.get('/User/Token/Check', { headers })
            .then(response => response)
            .then(data1 => {
                if (data1.data.result == false) {
                    //alert(JSON.stringify(data1.data.result))
                    API.get('/User/Authorize', { headers })
                        .then(response => response)
                        .then(data => {
                            if (self.state.targetCount < 5) {
                                var win = window.open(data.data, "", "width=500,height=500");
                                setTimeout(function () { win.close(); }, 10000);
                            }
                            else {

                                var html = "<html><head></head><body> Authentication failed : The Request could not be completed </body></html>"

                                var win = window.open(data.data, "", "width=500,height=500");

                                win.document.write(html)

                                setTimeout(function () { win.close(); }, 10000);
                            }
                        })
                    setTimeout(function () {
                        if (self.state.targetCount < 5) { self.handleTargetDownload(); }
                        else{
                            self.setState({ targetCount: 0 });
                            }
                    }, 11000);
                    // this.handleSourceDownload();
                }
                else {
                    const downloadData = self.state.krpType.data;
                    API.post(
                        "/Client/connections/download", {
                        "subscriptionId": downloadData.subscriptionId,
                        "endpointId": downloadData.target.id,
                        "instanceId": self.props.instanceID,
                        "fileType": downloadData.target.installer.type,
                        "refreshToken": response,
                    },
                        { headers, responseType: 'blob' }

                    )
                        .then(resp => {
                            const disposition = resp.headers["content-disposition"];
                            var fileName = "";
                            const array1 = disposition.split(';');
                            if (array1 && array1.length > 1) {
                                const string1 = array1[1];
                                if (string1) {
                                    const array2 = string1.split('=');
                                    if (array2 && array2.length > 1) {
                                        fileName = array2[1];
                                    }
                                }
                            }

                            const url = window.URL.createObjectURL(new Blob([resp.data], { type: 'application/zip' }));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', fileName);
                            link.click();

                        })
                }
            })

    }
    handleSourceInstallerDownload = () => {
        let self = this;

        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };

        const downloadData = self.state.krpType.data;

        API.post(
            "/Client/connections/download", {
            "subscriptionId": downloadData.subscriptionId,
            "endpointId": downloadData.source.id,
            "instanceId": self.props.instanceID,
            "fileType": downloadData.source.installerGuide.type,
            "refreshToken": response,
        },
            { headers, responseType: 'blob' }

        )
            .then(resp => {
                const disposition = resp.headers["content-disposition"];
                var fileName = "";
                const array1 = disposition.split(';');
                if (array1 && array1.length > 1) {
                    const string1 = array1[1];
                    if (string1) {
                        const array2 = string1.split('=');
                        if (array2 && array2.length > 1) {
                            fileName = array2[1];
                        }
                    }
                }
                const url = window.URL.createObjectURL(new Blob([resp.data], { type: 'application/pdf' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                link.click();
            })

    }
    handleTargetInstallerDownload = () => {
        let self = this;

        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };

        const downloadData = self.state.krpType.data;

        API.post(
            "/Client/connections/download", {
            "subscriptionId": downloadData.subscriptionId,
            "endpointId": downloadData.target.id,
            "instanceId": self.props.instanceID,
            "fileType": downloadData.target.installerGuide.type,
            "refreshToken": response,
        },
            { headers, responseType: 'blob' }

        )
            .then(resp => {
                const disposition = resp.headers["content-disposition"];
                var fileName = "";
                const array1 = disposition.split(';');
                if (array1 && array1.length > 1) {
                    const string1 = array1[1];
                    if (string1) {
                        const array2 = string1.split('=');
                        if (array2 && array2.length > 1) {
                            fileName = array2[1];
                        }
                    }
                }
                const url = window.URL.createObjectURL(new Blob([resp.data], { type: 'application/pdf' }));

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                link.click();
            })
    }

    handleSourceDownloadUserManual = () => {
        let self = this;

        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };

        const downloadData = self.state.krpType.data;

        API.post(
            "/Client/connections/download", {
            "subscriptionId": downloadData.subscriptionId,
            "endpointId": downloadData.source.id,
            "instanceId": self.props.instanceID,
            "fileType": downloadData.source.userManual.type,
            "refreshToken": response,
        },
            { headers, responseType: 'blob' }

        )
            .then(resp => {
                const disposition = resp.headers["content-disposition"];
                var fileName = "";
                const array1 = disposition.split(';');
                if (array1 && array1.length > 1) {
                    const string1 = array1[1];
                    if (string1) {
                        const array2 = string1.split('=');
                        if (array2 && array2.length > 1) {
                            fileName = array2[1];
                        }
                    }
                }
                const url = window.URL.createObjectURL(new Blob([resp.data], { type: 'application/pdf' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                link.click();
            })
    }


    handleTargetDownloadUserManual = () => {
        let self = this;

        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };

        const downloadData = self.state.krpType.data;

        API.post(
            "/Client/connections/download", {
            "subscriptionId": downloadData.subscriptionId,
            "endpointId": downloadData.target.id,
            "instanceId": self.props.instanceID,
            "fileType": downloadData.target.userManual.type,
            "refreshToken": response,
        },
            { headers, responseType: 'blob' }

        )
            .then(resp => {
                const disposition = resp.headers["content-disposition"];
                var fileName = "";
                const array1 = disposition.split(';');
                if (array1 && array1.length > 1) {
                    const string1 = array1[1];
                    if (string1) {
                        const array2 = string1.split('=');
                        if (array2 && array2.length > 1) {
                            fileName = array2[1];
                        }
                    }
                }
                const url = window.URL.createObjectURL(new Blob([resp.data], { type: 'application/pdf' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                link.click();
            })
    }
    
    handleSourceSetup = () =>{
        document.getElementById("loaderContainer1").classList.remove("d-none")
    this.setState({ 
      targetMessage: "2%",
    });
    this.setState({count:this.state.count+1});
    let self=this;
    var response =window.localStorage.getItem("accessToken") ;
    const headers={
      "authorization": `Bearer ${response}`,
      "content-type" : "application/json",
    };
    API.get('/User/Token/Check',{headers })
    .then(response => response)
    .then(data1 => {
      if(data1.data.result == false) {
        API.get('/User/Authorize',{headers })
        .then(response=>response)
        .then(data=> {
          
          //alert(JSON.stringify(data.data))
          if(self.state.count < 5){
           // alert(1)
            var win =window.open(data.data, "", "width=500,height=500");
            setTimeout(function() { win.close();}, 10000)
          }
          else{  var html = "<html><head></head><body> Authentication failed : The Request could not be completed </body></html>"

          var win = window.open(data.data, "", "width=500,height=500");

          win.document.write(html)

          setTimeout(function () { win.close(); }, 10000);}
        })
        setTimeout(function () {
          if(self.state.count<5){ self.handleSourceSetup();}
          else{
            self.setState({ count: 0 });
            }
        }, 11000);
          
      }
      else{
        
        const Bcdata = self.state.krpType.data;

        API.post(
            "/Client/connections/setup", {
            "subscriptionId": Bcdata.subscriptionId,
            "endpointId": Bcdata.source.id,
            "instanceId": Bcdata.instances[0].id,
            "fileType": Bcdata.source.installer.type
        },
            { headers}

        )
            .then(resp => {
              document.getElementById("loaderContainer1").classList.add("d-none")
               alert(resp.data.message)

            })
    }
    })
    
    

    }

    handleTargetSetup =()=>{
        document.getElementById("loaderContainer1").classList.remove("d-none")
        this.setState({ 
          targetMessage: "2%",
        });
        this.setState({count:this.state.count+1});
        let self=this;
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
          "authorization": `Bearer ${response}`,
          "content-type" : "application/json",
        };
        API.get('/User/Token/Check',{headers })
        .then(response => response)
        .then(data1 => {
          if(data1.data.result == false) {
            API.get('/User/Authorize',{headers })
            .then(response=>response)
            .then(data=> {
              
              //alert(JSON.stringify(data.data))
              if(self.state.count < 5){
               // alert(1)
                var win =window.open(data.data, "", "width=500,height=500");
                setTimeout(function() { win.close();}, 10000)
              }
              else{  var html = "<html><head></head><body> Authentication failed : The Request could not be completed </body></html>"
    
              var win = window.open(data.data, "", "width=500,height=500");
    
              win.document.write(html)
    
              setTimeout(function () { win.close(); }, 10000);}
            })
            setTimeout(function () {
              if(self.state.count<5){ self.handleTargetSetup();}
              else{
                self.setState({ count: 0 });
                }
            }, 11000);
              
          }
          else{
            
            const Bcdata = self.state.krpType.data;
    
            API.post(
                "/Client/connections/setup", {
                "subscriptionId": Bcdata.subscriptionId,
                "endpointId": Bcdata.target.id,
                "instanceId": Bcdata.instances[0].id,
                "fileType": Bcdata.target.installer.type,
                "clientId":128
            },
                { headers}
    
            )
                .then(resp => {
                  document.getElementById("loaderContainer1").classList.add("d-none")
                   alert(resp.data.message)
                })
        }
        })    
    }

    render() {
        let downloadData = this.state;
        let result = this.state;
        let file = <a href={downloadData.downloadData}> Download</a>
        // let sorceName = window.localStorage.getItem("SourceName")
        // let targetName = window.localStorage.getItem("targetname")
        window.localStorage.setItem("data",JSON.stringify(this.state.krpType.data))
        let sorceName = window.localStorage.getItem("sourcename")
        let targetName = window.localStorage.getItem("targetname")

        let krpType = this.state
        let sourcefilesize = krpType.krpType && krpType.krpType.data && krpType.krpType.data.source.installer ?
            <tr>
                <th>Installer</th>
                <td>{this.props.versionYear}</td>
                <td>{krpType.krpType && krpType.krpType.data && krpType.krpType.data.source.installer.size}</td>
                <td>
                    {
                        krpType.krpType && krpType.krpType.data && krpType.krpType.data.source.type!==1 ? 
                        <a className="cursor-pointer" onClick={this.handleSourceDownload}> Download </a> : 
                        <a className="cursor-pointer" onClick={this.handleSourceSetup}> Setup </a>
                    }
                </td>
            </tr> : 
            <tr>
                <td colSpan="4"> 
                    <div className="alert alert-warning text-center" role="alert">
                        Installer Not Found
                    </div>
                </td>
            </tr>

        /*User manual */

        let sourceManualSize = krpType.krpType && krpType.krpType.data && krpType.krpType.data.source.userManual ?
            <tr>
                <th>User Manual</th>
                <td>{this.props.versionYear}</td>
                <td>{krpType.krpType && krpType.krpType.data && krpType.krpType.data.source.userManual.size}</td>
                <td ><a className="cursor-pointer" onClick={this.handleSourceDownloadUserManual}> Download</a></td>
            </tr> : null

        let targetManualSize = krpType.krpType && krpType.krpType.data && krpType.krpType.data.target.userManual ?
            <tr>
                <th>User Manual</th>
                <td>{this.props.entityVersion}</td>
                <td>{krpType.krpType && krpType.krpType.data && krpType.krpType.data.target.userManual.size}</td>
                <td><a className="cursor-pointer" onClick={this.handleTargetDownloadUserManual}> Download </a></td>
            </tr> : null
        /* */
            let targetfilesize = krpType.krpType && krpType.krpType.data && krpType.krpType.data.target.installer ?
            <tr>
                <th>Installer</th>
                <td>{this.props.entityVersion}</td>
                <td>{krpType.krpType && krpType.krpType.data && krpType.krpType.data.target.installer.size}</td>
                <td>
                    {
                        krpType.krpType && krpType.krpType.data && krpType.krpType.data.target.type!==1 ? 
                        <a className="cursor-pointer" onClick={this.handleTargetDownload}> Download </a> : 
                        <a className="cursor-pointer" onClick={this.handleTargetSetup}> Setup </a>
                    }
                </td>
            </tr>: 
            <tr>
                <td colSpan="4"> 
                    <div className="alert alert-warning text-center" role="alert">
                        Installer Not Found
                    </div>
                </td>
            </tr>
            let targetInstallersize=krpType.krpType && krpType.krpType.data && krpType.krpType.data.target.installerGuide? 
            <tr>
                <th>Installer Guide</th>
                <td>{this.props.entityVersion}</td>
                <td>{krpType.krpType && krpType.krpType.data && krpType.krpType.data.target.installerGuide.size}</td>
                <td><a className="cursor-pointer" onClick={this.handleTargetInstallerDownload}> Download </a></td>
            </tr>:null

       let sourceInstallerSize=krpType.krpType && krpType.krpType.data && krpType.krpType.data.source.installerGuide?<tr>
       <th>Installer Guide</th>
       <td>{this.props.versionYear}</td>
       <td>{krpType.krpType && krpType.krpType.data && krpType.krpType.data.source.installerGuide.size}</td>
       <td><a className="cursor-pointer" onClick={this.handleSourceInstallerDownload}> Download </a></td>
   </tr>:null

        var connectorfulname = window.localStorage.getItem("fullname")
        return (
            <div className="row">
                <div id="loaderContainer1" className="loaderContainer d-none">
                    <div className="loader"></div>
                </div>
                {(krpType.krpType && krpType.krpType.data && krpType.krpType.data.source.isAuthenticationRequired) || (krpType.krpType && krpType.krpType.data && krpType.krpType.data.target.isAuthenticationRequired) ? <Redirect to="/Authentication"/>:
                <div className="col-lg-12">
                    <div className="card no-border no-boxshadow mb-0">
                        <div id="loaderContainerDownload" className="loaderContainer">
                            <div className="loader"></div>
                        </div>
                        <div className="card-body p-0">
                            <div className="downloadComponent">
                                <div className="detailsContainer">
                                    <h3> {connectorfulname}  </h3>
                                    <table className="table table-nowrap table-centered mb-0">
                                        <tr><th scope="row" colspan="4" className="text-left">{(sorceName).split('-')[0]}</th><td className="text-right">{this.props.versionYear}</td></tr>
                                        <tr><th scope="row" colspan="4" className="border-0 text-left">{(sorceName).split('-')[1]}</th><td className="border-0 text-right">{this.props.entityVersion}</td></tr>
                                        <tr><th scope="row" colspan="4" className="border-0 text-left">Product Type</th><td className="border-0 text-right">{this.props.productName}</td></tr>
                                    </table>
                                </div>

                                <div className="installerComponent">
                                    <h6 className="installer-title"> {(sorceName).split('-')[0]} Installer </h6>
                                    <div className="table-responsive">
                                        <table className="table mb-0">
                                            {sourcefilesize}
                                            {sourceInstallerSize}
                                            {sourceManualSize}
                                        </table>
                                    </div>
                                    <h6 className="installer-title"> {(sorceName).split('-')[1]} Installer </h6>
                                    <div className="table-responsive">
                                        <table className="table mb-0">
                                            {targetfilesize}
                                            {targetInstallersize}
                                            {targetManualSize}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="alert alert-warning" role="alert">
                                <b> Note </b> : Please click on Finish Setup, Once done with Download and Setup process.
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <button className="btn btn-primary btn-rounded waves-effect waves-light float-right finishSetupbtn" onClick={this.refreshPage}>Finish Setup</button>
                        </div>
                    </div>
                </div>
    }
            </div>
        )
    }
}