import React from 'react';
class ConnectorName extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listofConnectors : []
        }
    }
    render(){
        return(
            <>
                <div>
                    <h5 className="text-truncate font-size-14 custom-h5">
                        <a className="text-dark connector-title">{this.props.Name} </a>
                    </h5>
                </div>
            </>
        )
    }
}
export default ConnectorName