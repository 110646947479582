import React from 'react';
import $ from 'jquery'
import Details from './Details/Details';
import Mappings from './Mappings/Mappings'
import ManageEntities from './ManageEntities/ManageEntities';

class ConnectorServicePanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "Details"
        }
    }
    componentWillReceiveProps(props) {
        this.setState({
            activeTab : props.activeTab
        })
    }

    render(){
        let selectedConnectorInfo = this.props.selectedConnectorInfo
        let activeTabNew = this.state.activeTab

        $("body").on("click", ".custom-nav-link-new", function () {
            let attrName = $(this).attr("title")
            if(attrName === "Details"){
                $("#connectorInfo"+selectedConnectorInfo.id+" .tab-pane-details").removeClass("active")
                $("#connectorInfo"+selectedConnectorInfo.id+" #details"+selectedConnectorInfo.id).addClass("active")
            }else if(attrName === "Mapping"){
                $("#connectorInfo"+selectedConnectorInfo.id+" .tab-pane-details").removeClass("active")
                $("#connectorInfo"+selectedConnectorInfo.id+" #mappings"+selectedConnectorInfo.id).addClass("active")                
            }else if(attrName === "ManageEntities"){
                $("#connectorInfo"+selectedConnectorInfo.id+" .tab-pane-details").removeClass("active")
                $("#connectorInfo"+selectedConnectorInfo.id+" #manageentities"+selectedConnectorInfo.id).addClass("active")                
            }
        })

        return(
            <>
                <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link custom-nav-link-new active" title='Details' data-toggle="tab" href={"#details"+this.props.id} role="tab">
                            <span className="d-none d-sm-block">Details</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link custom-nav-link-new" title='Mapping' data-toggle="tab" href={"#mappings"+this.props.id} role="tab">
                            <span className="d-none d-sm-block">Mapping</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link custom-nav-link-new" title='ManageEntities' data-toggle="tab" href={"#manageentities"+this.props.id} role="tab">
                            <span className="d-none d-sm-block">Manage Entities</span>
                        </a>
                    </li>
                </ul>
            
                <div id={"connectorInfo"+selectedConnectorInfo.id} className="tab-content text-muted tabconnectorInfo">
                        <Details Name={selectedConnectorInfo.name} id={selectedConnectorInfo.id} selectedConnectorInfo = {selectedConnectorInfo} />
                        <Mappings id={selectedConnectorInfo.id} selectedConnectorInfo = {selectedConnectorInfo} />
                        <ManageEntities  id={selectedConnectorInfo.id} selectedConnectorInfo = {selectedConnectorInfo} />
                </div>
            </>
        )
    }
}

export default ConnectorServicePanel