import React from 'react';
import ShippingMapping from './ShippingMapping/ShippingMapping';
import PaymentMapping from './PaymentMapping/PaymentMapping';
import ProductAttributeMapping from './ProductAttributeMapping/ProductAttributeMapping';


class Mappings extends React.Component {
    render(){
        let selectedConnectorInfo = this.props.selectedConnectorInfo
        return(
            <>
                <div className="tab-pane tab-pane-details" id={"mappings" + this.props.id} role="tabpanel">
                    <div className="row mappin">
                        <div className="col-md-3 col-xl-3">
                            <div className="card mndesc">
                                <div className="card-body mapping-tab-sec">
                                    <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        <a className="nav-link mb-2 customstylenewway active" id="v-pills-shippingmapping-tab" data-toggle="pill" href={"#v-pills-shippingmapping"+this.props.id} role="tab" aria-controls={"v-pills-shippingmapping"+this.props.id} aria-selected="true">Shipping Method</a>
                                        <a className="nav-link mb-2 customstylenewway" id="v-pills-paymentmapping-tab" data-toggle="pill" href={"#v-pills-paymentmapping"+this.props.id} role="tab" aria-controls={"v-pills-paymentmapping"+this.props.id} aria-selected="true">Payment Method</a>
                                        <a className="nav-link mb-2 customstylenewway" id="v-pills-productAttributemapping-tab" data-toggle="pill" href={"#v-pills-productAttributemapping"+this.props.id} role="tab" aria-controls={"v-pills-productAttributemapping"+this.props.id} aria-selected="true">Product Attribute</a>
                                        <a className="nav-link mb-2 customstylenewway" id="v-pills-customerAttributemapping-tab" data-toggle="pill" href={"#v-pills-customerAttributemapping"+this.props.id} role="tab" aria-controls={"v-pills-customerAttributemapping"+this.props.id} aria-selected="true">Customer Attribute</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9 col-xl-9">
                            <div className="tab-content text-muted mt-4 mt-md-0" id="v-pills-tabContent">
                                <div className="tab-pane fade show active" id={"v-pills-shippingmapping"+this.props.id} role="tabpanel" aria-labelledby="v-pills-shippingmapping-tab">
                                    <ShippingMapping id={this.props.id} selectedConnectorInfo={selectedConnectorInfo} />
                                </div>
                                <div className="tab-pane fade" id={"v-pills-paymentmapping"+this.props.id} role="tabpanel" aria-labelledby="v-pills-paymentmapping-tab">
                                    <PaymentMapping id={this.props.id} selectedConnectorInfo={selectedConnectorInfo} />
                                </div>
                                <div className="tab-pane fade" id={"v-pills-productAttributemapping"+this.props.id} role="tabpanel" aria-labelledby="v-pills-productAttributemapping-tab">
                                    <ProductAttributeMapping id={this.props.id} selectedConnectorInfo={selectedConnectorInfo} />
                                </div>
                                <div className="tab-pane fade" id={"v-pills-customerAttributemapping"+this.props.id} role="tabpanel" aria-labelledby="v-pills-customerAttributemapping-tab">
                                    <p> We will update soon </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Mappings