import React from 'react';
import './knowledgebase.css'

class KnowledgeBase extends React.Component {
    constructor( props ){
        super();
        this.state = { ...props };
      }
      componentDidMount(){
        window.open('https://docs.i95dev.com/', '_blank');
      }
    render() {
        return (
            <div className="container-fluid">
                <section>Redirecting...</section>
                <div className="main-content myConnectors">
                    <div className="page-content pb-0">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <div className="page-title-left">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a href="javascript: void(0);">Dashboard</a></li>
                                                <li className="breadcrumb-item active">Help Desk</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <iframe id="helpdeskurl" src="https://docs.i95dev.com/" width="100%" frameBorder="none"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default KnowledgeBase