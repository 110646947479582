import React from "react";
import { render } from "react-dom";
import ReactDOM from "react-dom";
import Select from "react-select";
//import "react-select/dist/react-select.css";
import API from '../../api';
export default class ReactSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemtitle: "",
      multi: true,
      multiValue: "",
      options: [
        // { value: "Color", label: 2018 },
        // { value: "Fruit", label: 2017 },
      ]
    };
  }

componentDidMount(){
        let self = this;
        var response =window.localStorage.getItem("accessToken") ;
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        API.get(`/MarketPlace/connectors/2/endpoint-versions`, { headers })
        .then(response => response)
        .then(data => {
            const options = data.data.map(d => ({
                "value" : d.sourceVersion,
                "label" : d.sourceVersion
                }))
            self.setState({
                options: options
            })
        })
    
}

  onTitleChange(e, value) {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ multiValue: e.target.value });
  }
  handleOnChange(value) {
    this.setState({ multiValue: value });
  }
  render() {
    
    return (
      <div>
        <Select
          // multi={this.state.multi}
          options={this.state.options}
          onChange={this.handleOnChange.bind(this)}
          value={this.state.multiValue}
          isSearchable={false}
          placeholder="Select ERP Version "
        />
      </div>
    );
  }
}