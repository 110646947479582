import React from 'react';
import SessionTimeout from '../SessionTimeout'
import axios from 'axios';
import $ from 'jquery';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import {Header} from '../Shared/Header';
import SideBar from '../Shared/Sidebar';
import Newconnector from '../NewConnector/Newconnector';
import './myconnectors.css';
import pimg from '../../assets/images/nav-magento.png';
import pimgactive from '../../assets/images/nav-magento-active.png';
import closeicon from '../../assets/images/close-icon.svg';
import API from '../api';
import { NavLink, Link } from 'react-router-dom'
import ConnectorDownloader from '../NewConnector/DownloadInstaller/ConnectorDownloader'
import EgeSetup from '../EGE/EgeSetup/EgeSetupDownload/DashboardEGESetup';
import toggleSummary from '../../assets/images/toggleSummary.svg'

import nav_gp from '../../assets/images/nav_gp.svg'
import magento from '../../assets/images/magento.svg'
import bc from '../../assets/images/bcnew.png'
import EGE from '../../assets/images/EGE.svg'
import bigcommerce from '../../assets/images/bigcommerce.png'
import qb from '../../assets/images/qb_thumb.png'
import shopify from '../../assets/images/shopify.svg'

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import oneWayArrow from '../../assets/images/oneWayArrow.png'

import switchtostage from '../../assets/images/switchtostage.png'
import switchtoproduction from '../../assets/images/switchtoproduction.png'

const animatedComponents = makeAnimated()
class Myconnectors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sourcetoTarget: true,
            targettoSource: false,
            isPaneOpen: false,
            isPaneOpenLeft: false,
            render: '',
            datapoint: [],
            instanceSourceData: [],
            instanceTargetData: [],
            keyType: [],
            isLoaded: false,
            name: "",
            tname: "",
            childClass: "",
            businessEntityId: 0,
            instanceMappingdata: [],
            instanceMappingdataTarget: [],
            instanceMappingdataPost: [],
            instanceMappingdataget: [],
            instancePaymentTarget: [],
            instancePaymentSource: [],
            ecommerceoptionname: '',
            erpoptionname: '',
            instancePaymentdataget: [],
            instancePaymentdataPost: [],
            instancemanageentity:[],
            instanceProductAttribuetdataget: [],
            instanceProductAttribuetdataPost: [],
            entityCountNew:'',
            selectedOptionsTarget: [],
            selectedOptionSource: null,
            selectedOptionSourcereverseValue:null,
            selectedOptionTargetreverseValue:[],
            productAttribuetSourceMethods:[],
            productAttribuetsourceMappedSchema:[],
            productAttribuettargetMappedSchema:[],
            productAttribuettargetMethods:[],
            ownOptions:[],
            canUpdate:false,
            canUpdateTTS:false,
            checkDelete: 0
        };
        this.open = this.open.bind(this);
        window.sessionStorage.setItem("connectorIndex", 0);
        this.productattributeTargetchange = this.productattributeTargetchange.bind(this);
        this.productattributeSourcechange = this.productattributeSourcechange.bind(this);
        this.productattributeSourcechangereverse = this.productattributeSourcechangereverse.bind(this);
        this.productattributeTargetchangereverse = this.productattributeTargetchangereverse.bind(this);
        this.addNewProductAttributeMethod = this.addNewProductAttributeMethod.bind(this)
    }
    redirect() {
        this.props.history.push('/Updates')
    }
    
    open(event) {
        const { sourcetoTarget, targettoSource } = this.state;
        if(this.state.sourcetoTarget){
            $("#targettosourceNV"+event.target.id).addClass("activeNewway")
            $("#sourcetotargetNV"+event.target.id).removeClass("activeNewway")
        }else{
            $("#targettosourceNV"+event.target.id).removeClass("activeNewway")
            $("#sourcetotargetNV"+event.target.id).addClass("activeNewway")            
        }
        this.setState({
            sourcetoTarget: !sourcetoTarget,
            targettoSource: !targettoSource,
        });
    }

    componentDidMount() {
        this.handleDConnections();
    }

    handleDConnections = () => {
        let self = this;
        var response = window.localStorage.getItem("accessToken");
        let id = window.sessionStorage.getItem("connectorIndex")
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        API.get("/Client/connections", { headers })
        .then(response => response)
        .then(data => {
            document.getElementById("loaderContainer").className += ' d-none';
            if (data.data.length < 1) {
                document.getElementById("connectorErrorWarnign").classList.remove("d-none")
                document.getElementById("myproductshide").classList.add("d-none")
                return
            }
            self.setState({
                datapoint: data
            })
            $(".nav-itemnew:first-child a").addClass("active")
            $(".nav-tabitemnew:first-child").addClass("active")
            
            const movies = self.state.datapoint.data;

            //(data.data[0].instances.length === 2) ? data.data[0].instances[1].id : data.data[0].instances[0].id,

            //(movies[id].instances.length === 2) ? movies[id].instances[1].id : movies[id].instances[1].id,

            $(".customTab"+data.data[0].id+" .customstylenewway:first-child").addClass("active")
            $(".customTabExpand"+data.data[0].id+" .fade:first-child").addClass("show")
            $(".customTabExpand"+data.data[0].id+" .fade:first-child").addClass("active")

            

            if(movies[id].instances.length === 2){
                self.setState({
                    instanceEntryID: 1
                })
            }else{
                self.setState({
                    instanceEntryID: 0
                })
            }
            
            API.post(
                "/Client/connections/report/summary",
                {
                    "subscriptionInstanceId": (movies[id].instances.length === 2) ? movies[id].instances[1].id : movies[id].instances[0].id,
                    "sourceEndpointId": movies[id].source.id,
                    "requestType": "Source"
                },
                { headers }
            )
            .then(response => response)
            .then(data => {
                self.setState({
                    instanceSourceData: data
                })
            })
            


            API.post("/Client/connections/report/summary",{
                    "subscriptionInstanceId": (movies[id].instances.length === 2) ? movies[id].instances[1].id : movies[id].instances[0].id,
                    "sourceEndpointId": movies[id].target.id,
                    "requestType": "target"
                },
                {
                    headers
                }
            )
            .then(response => response)
            .then(data => {
                self.setState({
                    instanceTargetData: data
                })
            })

            //(movies[self.state.businessEntityId].instances.length === 2) ? movies[self.state.businessEntityId].instances[1].id : movies[self.state.businessEntityId].instances[0].id

            API.get(`/Connection/${movies[self.state.businessEntityId].id}/instance/${(movies[self.state.businessEntityId].instances.length === 2) ? movies[self.state.businessEntityId].instances[1].id : movies[self.state.businessEntityId].instances[0].id}/shipping-methods/source`, { headers })
            .then(response => response)
            .then(data => {
                self.setState({
                    instanceMappingdata: data
                })
            })
            
            API.get(`/Connection/${movies[self.state.businessEntityId].id}/instance/${(movies[self.state.businessEntityId].instances.length === 2) ? movies[self.state.businessEntityId].instances[1].id : movies[self.state.businessEntityId].instances[0].id}/shipping-methods/target`, { headers })
            .then(response => response)
            .then(data => {
                self.setState({
                    instanceMappingdataTarget: data
                })
            })
            API.get(`/Connection/${movies[self.state.businessEntityId].id}/instance/${(movies[self.state.businessEntityId].instances.length === 2) ? movies[self.state.businessEntityId].instances[1].id : movies[self.state.businessEntityId].instances[0].id}/shipping-mappings`, { headers })
            .then(response => response)
            .then(data => {
                self.setState({
                    instanceMappingdataget: data
                })
            })
            
            //Payment Mapping Start
                API.get(`/Connection/${movies[self.state.businessEntityId].id}/instance/${(movies[self.state.businessEntityId].instances.length === 2) ? movies[self.state.businessEntityId].instances[1].id : movies[self.state.businessEntityId].instances[0].id}/payment-methods/source`, { headers })
                    .then(response => response)
                    .then(data => {
                        self.setState({
                            instancepaymentdatasource: data
                        })
                    })
                API.get(`/Connection/${movies[self.state.businessEntityId].id}/instance/${(movies[self.state.businessEntityId].instances.length === 2) ? movies[self.state.businessEntityId].instances[1].id : movies[self.state.businessEntityId].instances[0].id}/payment-methods/target`, { headers })
                    .then(response => response)
                    .then(data => {
                        self.setState({
                            instancePaymentdataTarget: data
                        })
                    })
                API.get(`/Connection/${movies[self.state.businessEntityId].id}/instance/${(movies[self.state.businessEntityId].instances.length === 2) ? movies[self.state.businessEntityId].instances[1].id : movies[self.state.businessEntityId].instances[0].id}/payment-mappings`, { headers })
                    .then(response => response)
                    .then(data => {
                        self.setState({
                            instancePaymentdataget: data,
                            isLoaded: true
                        })
                })

                //payment Mapping End

                //Product Attribuet Mapping Start
                    API.get(`/Connection/${movies[self.state.businessEntityId].id}/instance/${(movies[self.state.businessEntityId].instances.length === 2) ? movies[self.state.businessEntityId].instances[1].id : movies[self.state.businessEntityId].instances[0].id}/product-attribute`, { headers })
                    .then(response => response)
                    .then(data => {
                        self.setState({
                            instanceProductAttribuetdataget: data,
                            productAttribuetsourceMappedSchema: data.data.sourceMappedSchemaList,
                            productAttribuettargetMappedSchema: data.data.targetMappedSchemaList,
                            productAttribuetsourceMethods: data.data.sourceMethods,
                            productAttribuettargetMethods: data.data.targetMethods,
                            isLoaded: true
                        })

                        if(data.data.sourceMethods != undefined){
                            this.setState({
                                productAttribuetsourceMethods: this.state.productAttribuetsourceMethods.concat('Enter Custom ERP Method'),
                                productAttribuettargetMethods: this.state.productAttribuettargetMethods.concat('Enter Custom eCommerce Method')
                            })
                        }
                    })
                //Product Attribuet Mapping End

                //Manage Entity Start
                API.get(`/Connection/${movies[self.state.businessEntityId].id}/instance/${(movies[self.state.businessEntityId].instances.length === 2) ? movies[self.state.businessEntityId].instances[1].id : movies[self.state.businessEntityId].instances[0].id}/entities`, { headers })
                .then(response => response)
                .then(data => {
                    self.setState({
                        instancemanageentity: data
                    })
                })
                //Manage Entity End
            })
    }

    handleActive = (event) => {
        $("#loaderContainer").removeClass("d-none")
        //alert(event.target.id)
        $(".firstactive").removeClass("active")
        $(".nav-tabs.nav-tabs-custom li.nav-item a").removeClass("active")
        $(".nav-tabs.nav-tabs-custom li.nav-item:first-child a").addClass("active")

        $(event.target).addClass("active")
        $(".newlyAddedMappingrowfix").remove()

        $("#v-pills-tab .customstylenewway").removeClass("active")
        $("#v-pills-tab .customstylenewway:first-child").addClass("active")

        $(".tab-pane.fade").removeClass("active")
        $(".tab-pane.fade").removeClass("show")

        $(".tab-pane.fade:first-child").addClass("active")
        $(".tab-pane.fade:first-child").addClass("show")
        $(".tab-content .tab-pane").removeClass("active")
        $(".tab-content .tab-pane:first-child").addClass("active")

        let self = this;
        window.sessionStorage.setItem("connectorIndex", event.target.id)
        let id = window.sessionStorage.getItem("connectorIndex")

        //alert(id)
        // self.setState({businessEntityId:id})

        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        const movies = self.state.datapoint.data;

        if(movies[id].instances.length === 2){
            self.setState({
                instanceEntryID: 1
            })
        }else{
            self.setState({
                instanceEntryID: 0
            })
        }

        API.post(
            "/Client/connections/report/summary",
            {
                "subscriptionInstanceId": (movies[id].instances.length === 2) ? movies[id].instances[1].id : movies[id].instances[0].id,
                "sourceEndpointId": movies[id].source.id,
                "requestType": "Source"
            },
            { headers }
        )
            .then(response => response)
            .then(data => {
                self.setState({
                    isLoaded: true,
                    instanceSourceData: data
                })
            })
        API.post(
            "Client/connections/report/summary",
            {
                "subscriptionInstanceId": (movies[id].instances.length === 2) ? movies[id].instances[1].id : movies[id].instances[0].id,
                "sourceEndpointId": movies[id].target.id,
                "requestType": "target"
            },
            { headers }
        )
            .then(response => response)
            .then(data => {
                self.setState({
                    isLoaded: true,
                    instanceTargetData: data
                })
            })

            $(".customTab"+movies[event.target.id].id+" .customstylenewway:first-child").addClass("active")
            $(".customTabExpand"+movies[event.target.id].id+" .fade:first-child").addClass("show")
            $(".customTabExpand"+movies[event.target.id].id+" .fade:first-child").addClass("active")
            

        API.get(`/Connection/${movies[event.target.id].id}/instance/${(movies[event.target.id].instances.length === 2) ? movies[event.target.id].instances[1].id : movies[event.target.id].instances[0].id}/shipping-methods/source`, { headers })
            .then(response => response)
            .then(data => {
                self.setState({
                    instanceMappingdata: data
                })
            })
        API.get(`/Connection/${movies[event.target.id].id}/instance/${(movies[event.target.id].instances.length === 2) ? movies[event.target.id].instances[1].id : movies[event.target.id].instances[0].id}/shipping-methods/target`, { headers })
            .then(response => response)
            .then(data => {
                self.setState({
                    instanceMappingdataTarget: data
                })
            })
        API.get(`/Connection/${movies[event.target.id].id}/instance/${(movies[event.target.id].instances.length === 2) ? movies[event.target.id].instances[1].id : movies[event.target.id].instances[0].id}/shipping-mappings`, { headers })
            .then(response => response)
            .then(data => {
                self.setState({
                    instanceMappingdataget: data
                })
                $("#loaderContainer").addClass("d-none")
            })


        //Payment Mapping Start
        API.get(`/Connection/${movies[event.target.id].id}/instance/${(movies[event.target.id].instances.length === 2) ? movies[event.target.id].instances[1].id : movies[event.target.id].instances[0].id}/payment-methods/source`, { headers })
            .then(response => response)
            .then(data => {
                self.setState({
                    instancepaymentdatasource: data
                })
            })
        API.get(`/Connection/${movies[event.target.id].id}/instance/${(movies[event.target.id].instances.length === 2) ? movies[event.target.id].instances[1].id : movies[event.target.id].instances[0].id}/payment-methods/target`, { headers })
            .then(response => response)
            .then(data => {
                self.setState({
                    instancePaymentdataTarget: data
                })
            })


        // /api/Connection/{ConnectionId}/instance/{InstanceId}/payment-mappings
        API.get(`/Connection/${movies[event.target.id].id}/instance/${(movies[event.target.id].instances.length === 2) ? movies[event.target.id].instances[1].id : movies[event.target.id].instances[0].id}/payment-mappings`, { headers })
            .then(response => response)
            .then(data => {
                self.setState({
                    instancePaymentdataget: data
                })
            })
        //Payment Mapping End

    //Product Attribuet Mapping Start
    API.get(`/Connection/${movies[event.target.id].id}/instance/${(movies[event.target.id].instances.length === 2) ? movies[event.target.id].instances[1].id : movies[event.target.id].instances[0].id}/product-attribute`, { headers })
    .then(response => response)
    .then(data => {
        self.setState({
            instanceProductAttribuetdataget: data,
            productAttribuetsourceMappedSchema:data.data.sourceMappedSchemaList,
            productAttribuettargetMappedSchema:data.data.targetMappedSchemaList,
            productAttribuetsourceMethods:data.data.sourceMethods,
            productAttribuettargetMethods:data.data.targetMethods,
            isLoaded: true
        })
    })
//Product Attribuet Mapping End
        
        //Manage Entity Start
        API.get(`/Connection/${movies[event.target.id].id}/instance/${(movies[event.target.id].instances.length === 2) ? movies[event.target.id].instances[1].id : movies[event.target.id].instances[0].id}/entities`, { headers })
        .then(response => response)
        .then(data => {
            self.setState({
                instancemanageentity: data
            })
        })
        //Manage Entity End
    }

    switchtoProduction = (event) =>{

        let self = this;
        var response = window.localStorage.getItem("accessToken");
        let id = window.sessionStorage.getItem("connectorIndex")

        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };

        API.get("/Client/connections", { headers })
        .then(response => response)
        .then(data => {
            if (data.data.length < 1) {
                document.getElementById("connectorErrorWarnign").classList.remove("d-none")
                document.getElementById("myproductshide").classList.add("d-none")
                return
            }
            self.setState({
                datapoint: data,
                instanceEntryID: 1
            })

            const movies = self.state.datapoint.data;
            
            

            API.post("/Client/connections/report/summary",
                {
                    "subscriptionInstanceId": movies[id].instances[1].id,
                    "sourceEndpointId": movies[id].source.id,
                    "requestType": "Source"
                },{ headers }
            ).then(response => response)
            .then(data => {
                self.setState({
                    instanceSourceData: data
                })
            })
            API.post("/Client/connections/report/summary",
                {
                    "subscriptionInstanceId": movies[id].instances[1].id,
                    "sourceEndpointId": movies[id].target.id,
                    "requestType": "target"
                },{headers}
            ).then(response => response)
            .then(data => {
                self.setState({
                    instanceTargetData: data
                })
            })

            API.get(`/Connection/${movies[self.state.businessEntityId].id}/instance/${movies[self.state.businessEntityId].instances[1].id}/shipping-methods/source`, { headers })
                    .then(response => response)
                    .then(data => {
                        self.setState({
                            instanceMappingdata: data
                        })
                    })
                API.get(`/Connection/${movies[self.state.businessEntityId].id}/instance/${movies[self.state.businessEntityId].instances[1].id}/shipping-methods/target`, { headers })
                    .then(response => response)
                    .then(data => {
                        self.setState({
                            instanceMappingdataTarget: data
                        })
                    })
                API.get(`/Connection/${movies[self.state.businessEntityId].id}/instance/${movies[self.state.businessEntityId].instances[1].id}/shipping-mappings`, { headers })
                    .then(response => response)
                    .then(data => {
                        self.setState({
                            instanceMappingdataget: data
                        })
                    })

                //Payment Mapping Start

                API.get(`/Connection/${movies[self.state.businessEntityId].id}/instance/${movies[self.state.businessEntityId].instances[1].id}/payment-methods/source`, { headers })
                    .then(response => response)
                    .then(data => {
                        self.setState({
                            instancepaymentdatasource: data
                        })
                    })
                API.get(`/Connection/${movies[self.state.businessEntityId].id}/instance/${movies[self.state.businessEntityId].instances[1].id}/payment-methods/target`, { headers })
                    .then(response => response)
                    .then(data => {
                        self.setState({
                            instancePaymentdataTarget: data
                        })
                    })
                API.get(`/Connection/${movies[self.state.businessEntityId].id}/instance/${movies[self.state.businessEntityId].instances[1].id}/payment-mappings`, { headers })
                    .then(response => response)
                    .then(data => {
                        self.setState({
                            instancePaymentdataget: data,
                            isLoaded: true
                        })
                    })

                //payment Mapping End

                //Product Attribuet Mapping Start
                    API.get(`/Connection/${movies[self.state.businessEntityId].id}/instance/${movies[self.state.businessEntityId].instances[1].id}/product-attribute`, { headers })
                    .then(response => response)
                    .then(data => {
                        self.setState({
                            instanceProductAttribuetdataget: data,
                            productAttribuetsourceMappedSchema:data.data.sourceMappedSchemaList,
                            productAttribuettargetMappedSchema:data.data.targetMappedSchemaList,
                            productAttribuetsourceMethods: data.data.sourceMethods,
                            productAttribuettargetMethods:data.data.targetMethods,
                            isLoaded: true
                        })
                        

                        if(data.data.sourceMethods != undefined){
                            this.setState({
                                productAttribuetsourceMethods: this.state.productAttribuetsourceMethods.concat('Enter Custom ERP Method'),
                                productAttribuettargetMethods: this.state.productAttribuettargetMethods.concat('Enter Custom eCommerce Method')
                            })
                        }
                    })
                //Product Attribuet Mapping End

                //Manage Entity Start
                API.get(`/Connection/${movies[self.state.businessEntityId].id}/instance/${movies[self.state.businessEntityId].instances[1].id}/entities`, { headers })
                .then(response => response)
                .then(data => {
                    self.setState({
                        instancemanageentity: data
                    })
                })
                //Manage Entity End

        })
        
        document.getElementById("switchtoStaging").classList.remove("d-none")
        document.getElementById("switchtoProduction").classList.add("d-none")
    }

    switchtoStaging = (event) => {
        let self = this;
        var response = window.localStorage.getItem("accessToken");
        let id = window.sessionStorage.getItem("connectorIndex")

        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };

        API.get("/Client/connections", { headers })
        .then(response => response)
        .then(data => {
            if (data.data.length < 1) {
                document.getElementById("connectorErrorWarnign").classList.remove("d-none")
                document.getElementById("myproductshide").classList.add("d-none")
                return
            }
            self.setState({
                datapoint: data,
                instanceEntryID: 0
            })

            const movies = self.state.datapoint.data;
            API.post("/Client/connections/report/summary",
                {
                    "subscriptionInstanceId": movies[id].instances[0].id,
                    "sourceEndpointId": movies[id].source.id,
                    "requestType": "Source"
                },{ headers }
            ).then(response => response)
            .then(data => {
                self.setState({
                    instanceSourceData: data
                })
            })
            API.post("/Client/connections/report/summary",
                {
                    "subscriptionInstanceId": movies[id].instances[0].id,
                    "sourceEndpointId": movies[id].target.id,
                    "requestType": "target"
                },{headers}
            ).then(response => response)
            .then(data => {
                self.setState({
                    instanceTargetData: data
                })
            })

            API.get(`/Connection/${movies[self.state.businessEntityId].id}/instance/${movies[self.state.businessEntityId].instances[0].id}/shipping-methods/source`, { headers })
                    .then(response => response)
                    .then(data => {
                        self.setState({
                            instanceMappingdata: data
                        })
                    })
                API.get(`/Connection/${movies[self.state.businessEntityId].id}/instance/${movies[self.state.businessEntityId].instances[0].id}/shipping-methods/target`, { headers })
                    .then(response => response)
                    .then(data => {
                        self.setState({
                            instanceMappingdataTarget: data
                        })
                    })
                API.get(`/Connection/${movies[self.state.businessEntityId].id}/instance/${movies[self.state.businessEntityId].instances[0].id}/shipping-mappings`, { headers })
                    .then(response => response)
                    .then(data => {
                        self.setState({
                            instanceMappingdataget: data
                        })
                    })

                //Payment Mapping Start

                API.get(`/Connection/${movies[self.state.businessEntityId].id}/instance/${movies[self.state.businessEntityId].instances[0].id}/payment-methods/source`, { headers })
                    .then(response => response)
                    .then(data => {
                        self.setState({
                            instancepaymentdatasource: data
                        })
                    })
                API.get(`/Connection/${movies[self.state.businessEntityId].id}/instance/${movies[self.state.businessEntityId].instances[0].id}/payment-methods/target`, { headers })
                    .then(response => response)
                    .then(data => {
                        self.setState({
                            instancePaymentdataTarget: data
                        })
                    })
                API.get(`/Connection/${movies[self.state.businessEntityId].id}/instance/${movies[self.state.businessEntityId].instances[0].id}/payment-mappings`, { headers })
                    .then(response => response)
                    .then(data => {
                        self.setState({
                            instancePaymentdataget: data,
                            isLoaded: true
                        })
                    })

                //payment Mapping End

                //Product Attribuet Mapping Start
                    API.get(`/Connection/${movies[self.state.businessEntityId].id}/instance/${movies[self.state.businessEntityId].instances[0].id}/product-attribute`, { headers })
                    .then(response => response)
                    .then(data => {
                        self.setState({
                            instanceProductAttribuetdataget: data,
                            productAttribuetsourceMappedSchema:data.data.sourceMappedSchemaList,
                            productAttribuettargetMappedSchema:data.data.targetMappedSchemaList,
                            productAttribuetsourceMethods: data.data.sourceMethods,
                            productAttribuettargetMethods:data.data.targetMethods,
                            isLoaded: true
                        })
                        

                        if(data.data.sourceMethods != undefined){
                            this.setState({
                                productAttribuetsourceMethods: this.state.productAttribuetsourceMethods.concat('Enter Custom ERP Method'),
                                productAttribuettargetMethods: this.state.productAttribuettargetMethods.concat('Enter Custom eCommerce Method')
                            })
                        }
                    })
                //Product Attribuet Mapping End

                //Manage Entity Start
                API.get(`/Connection/${movies[self.state.businessEntityId].id}/instance/${movies[self.state.businessEntityId].instances[0].id}/entities`, { headers })
                .then(response => response)
                .then(data => {
                    self.setState({
                        instancemanageentity: data
                    })
                })
                //Manage Entity End

        })
        
        document.getElementById("switchtoStaging").classList.add("d-none")
        document.getElementById("switchtoProduction").classList.remove("d-none")
    }

    createPaymentMappingRow = (event) => {
        let clickableID = event.target.id

        let checkotherValue = document.getElementById("paymentselectErpMethod"+clickableID)
        let enteredValue = document.getElementById("otherpaymentERPValue"+clickableID)

        let checkothereCommValue = document.getElementById("paymentselecteCommerceMethod"+clickableID)
        let enteredeCommValue = document.getElementById("otherpaymenteCommerceValue"+clickableID)

        if(enteredValue.value != ""){
            var option = document.createElement("OPTION");
            option.innerHTML = document.getElementById("otherpaymentERPValue"+clickableID).value;
            option.value = document.getElementById("otherpaymentERPValue"+clickableID).value;
            checkotherValue.options.add(option);
            option.setAttribute('selected', true);
        }

        if(enteredeCommValue.value != ""){
            var option = document.createElement("OPTION");
            option.innerHTML = document.getElementById("otherpaymenteCommerceValue"+clickableID).value;
            option.value = document.getElementById("otherpaymenteCommerceValue"+clickableID).value;
            checkothereCommValue.options.add(option);
            option.setAttribute('selected', true);
        }

        if((checkotherValue.value === "") && (enteredValue.value  === "")){
            document.getElementById("otherpaymentERPValue"+clickableID).classList.add("errorStateSelect")
            document.getElementById("paymentselectErpMethod"+clickableID).classList.add("errorStateSelect")
        }

        if((checkothereCommValue.value === "") && (enteredeCommValue.value  === "")){
            document.getElementById("otherpaymenteCommerceValue"+clickableID).classList.add("errorStateSelect")
            document.getElementById("paymentselecteCommerceMethod"+clickableID).classList.add("errorStateSelect")
        }

        let myTable = document.getElementById("paymentmappingTablenew"+clickableID);
        let currentIndex = myTable.rows.length;
        let currentRow = myTable.insertRow(1);
        currentRow.setAttribute("id", "paymentMapping" + currentIndex);
        currentRow.setAttribute("class", "newlyAddedMappingrowfix");

        let paymentselectedErpMethod = document.getElementById("paymentselectErpMethod"+clickableID).innerHTML;
        let paymentselectedErpMethodvalue = document.getElementById("paymentselectErpMethod"+clickableID).value;

        let paymentselectedeCommerceMethod = document.getElementById("paymentselecteCommerceMethod"+clickableID).innerHTML;
        let paymentselectedeCommerceMethodvalue = document.getElementById("paymentselecteCommerceMethod"+clickableID).value;

        let paymenterptofnecom = document.getElementById("paymenterp-ecom").innerHTML;
        let paymentecomtofnerp = document.getElementById("paymentecom-erp").innerHTML;

        let newerptoecom = document.getElementById("paymentnewerptoecom"+clickableID);
        let newecomtoerp = document.getElementById("paymentnewecomtoerp"+clickableID);


        let deletenewbutton = "<button type='button' class='deleteMapping'> <span onClick={this.handleDeletemethod}></span> <i class='uil-trash-alt'></i></button><div class='deleteConfirmPopup d-none'> <div class='confirmNewSection'> <div class='alertTitle'> Are you sure you want to delete ? </div> <div class='alertBody'> <a class='cancelDeleteMappingNewsec'> Cancel </a> <button class='deleteMappingNewsec'> Delete </button><div class='clearfix'></div></div></div></div>"

        if (paymentselectedErpMethodvalue === "" && paymentselectedeCommerceMethodvalue === "") {
            //document.getElementById("errorMessage1").classList.remove('d-none')
            document.getElementById("paymentselectErpMethod"+clickableID).classList.add("errorStateSelect")
            document.getElementById("paymentselecteCommerceMethod"+clickableID).classList.add("errorStateSelect")
            return
        } else if (paymentselectedErpMethodvalue !== "" && paymentselectedeCommerceMethodvalue === "") {
            //document.getElementById("errorMessage1").classList.remove('d-none')
            //document.getElementById("selectErpMethod").classList.add("errorStateSelect")
            document.getElementById("paymentselecteCommerceMethod"+clickableID).classList.add("errorStateSelect")
            return
        } else if (paymentselectedErpMethodvalue === "" && paymentselectedeCommerceMethodvalue !== "") {
            document.getElementById("paymentselectErpMethod"+clickableID).classList.add("errorStateSelect")
            return
        } else {
            document.getElementById("paymentselectErpMethod"+clickableID).classList.remove("errorStateSelect")
            document.getElementById("paymentselecteCommerceMethod"+clickableID).classList.remove("errorStateSelect")
        }

        // alert(paymentselectedErpMethodvalue)
        // alert(paymentselectedeCommerceMethodvalue)

        //First td

        var linksBox = document.createElement("select");
        linksBox.setAttribute("name", "links" + currentIndex);
        linksBox.setAttribute("id", "paymentselectednewErp");
        linksBox.setAttribute("class", "form-control");

        var firstOption = document.createElement("option");
        firstOption.setAttribute("value", paymentselectedErpMethodvalue);
        firstOption.setAttribute("class", "form-control");

        firstOption.appendChild(linksBox);
        linksBox.innerHTML = "<option>" + paymentselectedErpMethodvalue + "</option>" + paymentselectedErpMethod;

        //saveValuespan.innerText = selectedErpMethodvalue

        //Second td
        var keywordsBox = document.createElement("select");
        keywordsBox.setAttribute("name", "keywords" + currentIndex);
        keywordsBox.setAttribute("id", "selecteCommerceMethod");
        keywordsBox.setAttribute("class", "form-control");


        var firstOption1 = document.createElement("option");
        firstOption1.setAttribute("value", paymentselectedeCommerceMethodvalue);
        firstOption1.appendChild(keywordsBox);
        keywordsBox.innerHTML = "<option>" + paymentselectedeCommerceMethodvalue + "</option>" + paymentselectedeCommerceMethod;

        var checkBox1 = document.createElement("input");
        checkBox1.setAttribute("type", "checkbox");
        checkBox1.setAttribute("id", "paymentcheckbox1");
        checkBox1.setAttribute("class", "custom-control-input");

        var checkBox1 = document.createElement("input");
        checkBox1.setAttribute("type", "checkbox");
        checkBox1.setAttribute("id", "paymentcheckbox1");
        checkBox1.setAttribute("class", "custom-control-input");




        var currentCell = currentRow.insertCell(-1);
        currentCell.setAttribute("class", "form-group")
        currentCell.appendChild(linksBox);
        currentCell.setAttribute("width", "276px")


        currentCell = currentRow.insertCell(-1);
        currentCell.setAttribute("class", "form-group")
        currentCell.appendChild(keywordsBox);
        currentCell.setAttribute("width", "276px")

        currentCell = currentRow.insertCell(-1);
        currentCell.appendChild(checkBox1);
        //currentCell.innerHTML = newerptoecom.checked
        if (newerptoecom.checked == true) {
            currentCell.innerHTML = "<div class='custom-control custom-checkbox mt-2'><input type='checkbox' name='" + paymentselectedErpMethodvalue + "' class='custom-control-input paymenterptoecomcheckboxnew " + paymentselectedErpMethodvalue + "' id='paymentnewerptoecomnewwr" + currentIndex + "' checked='true'><label class='custom-control-label' for='paymentnewerptoecomnewwr" + currentIndex + "'>ERP-ECom</label></div>"
        } else {
            currentCell.innerHTML = "<div class='custom-control custom-checkbox mt-2'><input type='checkbox' name='" + paymentselectedErpMethodvalue + "' class='custom-control-input paymenterptoecomcheckboxnew " + paymentselectedErpMethodvalue + "' id='paymentnewerptoecomnewwr" + currentIndex + "'><label class='custom-control-label' for='paymentnewerptoecomnewwr" + currentIndex + "'>ERP-ECom</label></div>"
        }

        currentCell = currentRow.insertCell(-1);
        currentCell.appendChild(checkBox1);
        //currentCell.innerHTML = newerptoecom.checked
        if (newecomtoerp.checked == true) {
            currentCell.innerHTML = "<div class='custom-control custom-checkbox mt-2'><input type='checkbox' name='" + paymentselectedeCommerceMethodvalue + "' class='custom-control-input paymentecomtoerpcheckboxnew " + paymentselectedeCommerceMethodvalue + "' id='paymentnewecomtoerpnewwr" + currentIndex + "' checked='true'><label class='custom-control-label' for='paymentnewecomtoerpnewwr" + currentIndex + "'>ECom-ERP</label></div>"
        } else {
            currentCell.innerHTML = "<div class='custom-control custom-checkbox mt-2'><input type='checkbox' name='" + paymentselectedeCommerceMethodvalue + "' class='custom-control-input paymentecomtoerpcheckboxnew " + paymentselectedeCommerceMethodvalue + "' id='paymentnewecomtoerpnewwr" + currentIndex + "'><label class='custom-control-label' for='paymentnewecomtoerpnewwr" + currentIndex + "'>ECom-ERP</label></div>"
        }

        currentCell = currentRow.insertCell(-1);
        currentCell.innerHTML = deletenewbutton

        let resultTable = document.getElementById("paymentmappingTablenewfnnew"+clickableID);
        let resultTableIndex = resultTable.rows.length;
        let resultTableRow = resultTable.insertRow(-1);
        resultTableRow.setAttribute("id", "paymentMapping" + currentIndex);

        var linksBoxValue = paymentselectedErpMethodvalue
        var currentCell1 = resultTableRow.insertCell(-1);
        currentCell1.textContent = linksBoxValue

        var keywordsBoxValue = paymentselectedeCommerceMethodvalue
        var currentCell2 = resultTableRow.insertCell(-1);
        currentCell2.textContent = keywordsBoxValue

        //var checkbox1value = selectedeCommerceMethodvalue
        var currentCell3 = resultTableRow.insertCell(-1);
        currentCell3.textContent = newerptoecom.checked


        var currentCell4 = resultTableRow.insertCell(-1);
        currentCell4.textContent = newecomtoerp.checked
        
        document.getElementById("saveAllPaymentMappings"+clickableID).disabled = false
        document.getElementById("paymentselectErpMethod"+clickableID).selectedIndex = 0;
        document.getElementById("paymentselecteCommerceMethod"+clickableID).selectedIndex = 0;

        document.getElementById("defaultsaveAllPaymentMappings"+clickableID).classList.add("d-none")
        document.getElementById("saveAllPaymentMappings"+clickableID).classList.remove("d-none")

        
        document.getElementById("otherpaymentERPValue"+clickableID).value = ""
        document.getElementById("otherpaymentERPValue"+clickableID).classList.add("d-none")
        document.getElementById("paymentselectErpMethod"+clickableID).classList.remove("d-none")

        document.getElementById("otherpaymenteCommerceValue"+clickableID).value = ""
        document.getElementById("otherpaymenteCommerceValue"+clickableID).classList.add("d-none")
        document.getElementById("paymentselecteCommerceMethod"+clickableID).classList.remove("d-none")
        
        document.getElementById("cancelOtherpaymentErp"+clickableID).classList.add("d-none")
        document.getElementById("cancelOtherpaymentCommerce"+clickableID).classList.add("d-none")
    }

    createShippingMappingRow = (event) => {
        let clickableID = event.target.id

        let checkotherValue = document.getElementById("selectErpMethod"+clickableID)
        let enteredValue = document.getElementById("othershippingERPValue"+clickableID)

        let checkothereCommValue = document.getElementById("selecteCommerceMethod"+clickableID)
        let enteredeCommValue = document.getElementById("othershippingeCommerceValue"+clickableID)

        if(enteredValue.value != ""){
            var option = document.createElement("OPTION");
            option.innerHTML = document.getElementById("othershippingERPValue"+clickableID).value;
            option.value = document.getElementById("othershippingERPValue"+clickableID).value;
            checkotherValue.options.add(option);
            option.setAttribute('selected', true);
        }

        if(enteredeCommValue.value != ""){
            var option = document.createElement("OPTION");
            option.innerHTML = document.getElementById("othershippingeCommerceValue"+clickableID).value;
            option.value = document.getElementById("othershippingeCommerceValue"+clickableID).value;
            checkothereCommValue.options.add(option);
            option.setAttribute('selected', true);
        }

        if((checkotherValue.value === "") && (enteredValue.value  === "")){
            document.getElementById("othershippingERPValue"+clickableID).classList.add("errorStateSelect")
            document.getElementById("selectErpMethod"+clickableID).classList.add("errorStateSelect")
        }

        if((checkothereCommValue.value === "") && (enteredeCommValue.value  === "")){
            document.getElementById("othershippingeCommerceValue"+clickableID).classList.add("errorStateSelect")
            document.getElementById("selecteCommerceMethod"+clickableID).classList.add("errorStateSelect")
        }

        

        let myTable = document.getElementById("mappingTablenew"+clickableID);
        let currentIndex = myTable.rows.length;
        let currentRow = myTable.insertRow(1);
        currentRow.setAttribute("id", "shippingMapping" + currentIndex);
        currentRow.setAttribute("class", "newlyAddedMappingrowfix");

        let selectedErpMethod = document.getElementById("selectErpMethod"+clickableID).innerHTML;
        let selectedErpMethodvalue = document.getElementById("selectErpMethod"+clickableID).value;

        let selectedeCommerceMethod = document.getElementById("selecteCommerceMethod"+clickableID).innerHTML;
        let selectedeCommerceMethodvalue = document.getElementById("selecteCommerceMethod"+clickableID).value;

        let erptofnecom = document.getElementById("erp-ecom").innerHTML;
        let ecomtofnerp = document.getElementById("ecom-erp").innerHTML;

        let newerptoecom = document.getElementById("newerptoecom"+clickableID);
        let newecomtoerp = document.getElementById("newecomtoerp"+clickableID);

        let deletenewbutton = "<button type='button' class='deleteMapping'> <span onClick={this.handleDeletemethod}></span> <i class='uil-trash-alt'></i></button><div class='deleteConfirmPopup d-none'> <div class='confirmNewSection'> <div class='alertTitle'> Are you sure you want to delete ? </div> <div class='alertBody'> <a class='cancelDeleteMappingNewsec'> Cancel </a> <button class='deleteMappingNewsec'> Delete </button><div class='clearfix'></div></div></div></div>"

        //deletenewbutton.onClick = this.handleDeletemethod()

        //onclick="callJavascriptFunction()"
        if (selectedErpMethodvalue === "" && selectedeCommerceMethodvalue === "") {
            //document.getElementById("errorMessage1").classList.remove('d-none')
            document.getElementById("selectErpMethod"+clickableID).classList.add("errorStateSelect")
            document.getElementById("selecteCommerceMethod"+clickableID).classList.add("errorStateSelect")
            return
        } else if (selectedErpMethodvalue !== "" && selectedeCommerceMethodvalue === "") {
            //document.getElementById("errorMessage1").classList.remove('d-none')
            //document.getElementById("selectErpMethod").classList.add("errorStateSelect")
            document.getElementById("selecteCommerceMethod"+clickableID).classList.add("errorStateSelect")
            return
        } else if (selectedErpMethodvalue === "" && selectedeCommerceMethodvalue !== "") {
            document.getElementById("selectErpMethod"+clickableID).classList.add("errorStateSelect")
            return
        } else {
            document.getElementById("selectErpMethod"+clickableID).classList.remove("errorStateSelect")
            document.getElementById("selecteCommerceMethod"+clickableID).classList.remove("errorStateSelect")
        }



        //else if(selectedeCommerceMethodvalue === "" ){
        //     //document.getElementById("errorMessage1").classList.remove('d-none')
        //     document.getElementById("selecteCommerceMethod").classList.add("errorStateSelect")
        //     return
        // }


        //First td

        var linksBox = document.createElement("select");
        linksBox.setAttribute("name", "links" + currentIndex);
        linksBox.setAttribute("id", "selectednewErp");
        linksBox.setAttribute("class", "form-control");

        var firstOption = document.createElement("option");
        firstOption.setAttribute("value", selectedErpMethodvalue);
        firstOption.setAttribute("class", "form-control");

        firstOption.appendChild(linksBox);
        linksBox.innerHTML = "<option>" + selectedErpMethodvalue + "</option>" + selectedErpMethod;

        //saveValuespan.innerText = selectedErpMethodvalue

        //Second td
        var keywordsBox = document.createElement("select");
        keywordsBox.setAttribute("name", "keywords" + currentIndex);
        keywordsBox.setAttribute("id", "selecteCommerceMethod");
        keywordsBox.setAttribute("class", "form-control");


        var firstOption1 = document.createElement("option");
        firstOption1.setAttribute("value", selectedeCommerceMethodvalue);
        firstOption1.appendChild(keywordsBox);
        keywordsBox.innerHTML = "<option>" + selectedeCommerceMethodvalue + "</option>" + selectedeCommerceMethod;

        var checkBox1 = document.createElement("input");
        checkBox1.setAttribute("type", "checkbox");
        checkBox1.setAttribute("id", "checkbox1");
        checkBox1.setAttribute("class", "custom-control-input");

        var checkBox1 = document.createElement("input");
        checkBox1.setAttribute("type", "checkbox");
        checkBox1.setAttribute("id", "checkbox1");
        checkBox1.setAttribute("class", "custom-control-input");




        var currentCell = currentRow.insertCell(-1);
        currentCell.setAttribute("class", "form-group")
        currentCell.appendChild(linksBox);
        currentCell.setAttribute("width", "276px")


        currentCell = currentRow.insertCell(-1);
        currentCell.setAttribute("class", "form-group")
        currentCell.appendChild(keywordsBox);
        currentCell.setAttribute("width", "276px")

        currentCell = currentRow.insertCell(-1);
        currentCell.appendChild(checkBox1);
        //currentCell.innerHTML = newerptoecom.checked
        if (newerptoecom.checked == true) {
            currentCell.innerHTML = "<div class='custom-control custom-checkbox mt-2'><input type='checkbox' name='" + selectedErpMethodvalue + "' class='custom-control-input shippingerptoecomcheckboxnew " + selectedErpMethodvalue + "' id='shippingnewerptoecomnewwr" + currentIndex + "' checked='true'><label class='custom-control-label' for='shippingnewerptoecomnewwr" + currentIndex + "'>ERP-ECom</label></div>"
        } else {
            currentCell.innerHTML = "<div class='custom-control custom-checkbox mt-2'><input type='checkbox' name='" + selectedErpMethodvalue + "' class='custom-control-input shippingerptoecomcheckboxnew " + selectedErpMethodvalue + "' id='shippingnewerptoecomnewwr" + currentIndex + "'><label class='custom-control-label' for='shippingnewerptoecomnewwr" + currentIndex + "'>ERP-ECom</label></div>"
        }

        currentCell = currentRow.insertCell(-1);
        currentCell.appendChild(checkBox1);
        //currentCell.innerHTML = newerptoecom.checked
        if (newecomtoerp.checked == true) {
            currentCell.innerHTML = "<div class='custom-control custom-checkbox mt-2'><input type='checkbox' name='" + selectedeCommerceMethodvalue + "' class='custom-control-input shippingecomtoerpcheckboxnew " + selectedeCommerceMethodvalue + "' id='shippingnewecomtoerpnewwr" + currentIndex + "' checked='true'><label class='custom-control-label' for='shippingnewecomtoerpnewwr" + currentIndex + "'>ECom-ERP</label></div>"
        } else {
            currentCell.innerHTML = "<div class='custom-control custom-checkbox mt-2'><input type='checkbox' name='" + selectedeCommerceMethodvalue + "' class='custom-control-input shippingecomtoerpcheckboxnew " + selectedeCommerceMethodvalue + "' id='shippingnewecomtoerpnewwr" + currentIndex + "'><label class='custom-control-label' for='shippingnewecomtoerpnewwr" + currentIndex + "'>ECom-ERP</label></div>"
        }

        currentCell = currentRow.insertCell(-1);
        currentCell.innerHTML = deletenewbutton



        let resultTable = document.getElementById("mappingTablenewfnnew"+clickableID);
        let resultTableIndex = resultTable.rows.length;
        let resultTableRow = resultTable.insertRow(-1);
        resultTableRow.setAttribute("id", "shippingMapping" + currentIndex);

        var linksBoxValue = selectedErpMethodvalue
        var currentCell1 = resultTableRow.insertCell(-1);
        currentCell1.textContent = linksBoxValue

        var keywordsBoxValue = selectedeCommerceMethodvalue
        var currentCell2 = resultTableRow.insertCell(-1);
        currentCell2.textContent = keywordsBoxValue

        //var checkbox1value = selectedeCommerceMethodvalue
        var currentCell3 = resultTableRow.insertCell(-1);
        currentCell3.textContent = newerptoecom.checked


        var currentCell4 = resultTableRow.insertCell(-1);
        currentCell4.textContent = newecomtoerp.checked

        document.getElementById("saveAllShipmentMappings"+clickableID).disabled = false
        document.getElementById("selectErpMethod"+clickableID).selectedIndex = 0;
        document.getElementById("selecteCommerceMethod"+clickableID).selectedIndex = 0;

        
        
        
        document.getElementById("defaultsaveAllShipmentMappings"+clickableID).classList.add("d-none")
        document.getElementById("saveAllShipmentMappings"+clickableID).classList.remove("d-none")


        document.getElementById("othershippingERPValue"+clickableID).value = ""
        document.getElementById("othershippingERPValue"+clickableID).classList.add("d-none")
        document.getElementById("selectErpMethod"+clickableID).classList.remove("d-none")

        document.getElementById("othershippingeCommerceValue"+clickableID).value = ""
        document.getElementById("othershippingeCommerceValue"+clickableID).classList.add("d-none")
        document.getElementById("selecteCommerceMethod"+clickableID).classList.remove("d-none")
        
        document.getElementById("cancelOthershippingErp"+clickableID).classList.add("d-none")
        document.getElementById("cancelOthershippingeCommerce"+clickableID).classList.add("d-none")

    }

    paymenthandleclickmethodnew = (event) => {
        let clickableID = event.target.name
        document.getElementById("loaderContainer").classList.remove("d-none")
        setTimeout(function () {
            document.getElementById('loaderContainer').classList.add('d-none');
        }, 5000)

        const results = [];
        const headersnewway = [];
        const divnew = document.getElementsByTagName("pre")
        const bodynewway = document.getElementById("paymentmappingTablenewfnnew"+clickableID)
        if (bodynewway !== null) {
            const rows = bodynewway.querySelectorAll("tr");
            rows.forEach((rowData, rowIndex) => {
                const dataRowIndex = rowIndex - 1;
                if (rowIndex > 0) results[dataRowIndex] = {};
                for (let cellIndex = 0; cellIndex < rowData.cells.length; cellIndex++) {
                    const cellData = rowData.cells.item(cellIndex);
                    if (rowIndex === 0) {
                        if (cellData !== null)
                            headersnewway[cellIndex] = cellData.textContent || "";
                    } else if (rowIndex > 0) {
                        if (cellData !== null)
                            results[dataRowIndex][headersnewway[cellIndex]] = cellData.textContent === 'false' ? false : (cellData.textContent === 'true' ? true : cellData.textContent) || "";
                    }
                }
            });
        }

        divnew.innerHTML = results;
        

        let self = this;
        let id = window.sessionStorage.getItem("connectorIndex")
        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        const movies = self.state.datapoint.data;
        const body = results

        API.post(`/Connection/${movies[id].id}/instance/${movies[id].instances[0].id}/payment-mappings`, body, { headers })
            .then(response => response)
            .then(data => {
                self.setState({
                    instancePaymentdataPost: data
                })
                document.getElementById('loaderContainer').classList.add('d-none');
                if(this.state.checkDelete === 1){
                    window.location.reload()
                }
            })
        
            // API.get(`/Connection/${movies[id].id}/instance/${movies[id].instances[0].id}/payment-mappings`, { headers })
        // .then(response => response)
        // .then(data => {
        //     self.setState({
        //         instancePaymentdataget: data
        //     })
        // })

        document.getElementById("defaultsaveAllPaymentMappings"+clickableID).classList.remove("d-none")
        document.getElementById("saveAllPaymentMappings"+clickableID).classList.add("d-none")

        
    }



    handleclickmethodnew = (event) => {
        let clickableID = event.target.name

        document.getElementById("loaderContainer").classList.remove("d-none")
        setTimeout(function () {
            document.getElementById('loaderContainer').classList.add('d-none');
        }, 5000)

        const results = [];
        const headersnewway = [];
        const divnew = document.getElementsByTagName("pre")
        const bodynewway = document.getElementById("mappingTablenewfnnew"+clickableID)
        if (bodynewway !== null) {
            const rows = bodynewway.querySelectorAll("tr");
            rows.forEach((rowData, rowIndex) => {
                const dataRowIndex = rowIndex - 1;
                if (rowIndex > 0) results[dataRowIndex] = {};
                for (let cellIndex = 0; cellIndex < rowData.cells.length; cellIndex++) {
                    const cellData = rowData.cells.item(cellIndex);
                    if (rowIndex === 0) {
                        if (cellData !== null)
                            headersnewway[cellIndex] = cellData.textContent || "";
                    } else if (rowIndex > 0) {
                        if (cellData !== null)
                            results[dataRowIndex][headersnewway[cellIndex]] = cellData.textContent === 'false' ? false : (cellData.textContent === 'true' ? true : cellData.textContent) || "";
                    }
                }
            });
        }
        
        divnew.innerHTML = results;
        
        let id = window.sessionStorage.getItem("connectorIndex")
        let self = this;
        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        
        const movies = self.state.datapoint.data;
        const body = results

        API.post(`/Connection/${movies[id].id}/instance/${movies[id].instances[0].id}/shipping-mappings`, body, { headers })
        .then(response => response)
        .then(data => {
            self.setState({
                instanceMappingdataPost: data
            })
            document.getElementById('loaderContainer').classList.add('d-none');
            if(this.state.checkDelete === 1){
                window.location.reload()
            }
        })

        

        // setTimeout(function () {
        //     //document.getElementById('loaderContainer').classList.add('d-none');
        //     let chckDelete = localStorage.getItem("deleteChecking")
        //     chckDelete === true ? window.location.reload() : null
        // }, 5000)

        // API.get(`/Connection/${movies[id].id}/instance/${movies[id].instances[0].id}/shipping-mappings`, { headers })
        //     .then(response => response)
        //     .then(data => {
        //         self.setState({
        //             instanceMappingdataget: data
        //         })
        //     })
        
        document.getElementById("defaultsaveAllShipmentMappings"+clickableID).classList.remove("d-none")
        document.getElementById("saveAllShipmentMappings"+clickableID).classList.add("d-none")

        
    }

    handleDeletemethod = (event) => {
        let alertVisible = document.getElementById(event.target.id + "delete")
        $("#"+event.target.id + "delete").removeClass("d-none")
    }

    handleDeletemethodshippingMappingNewclose = (event) => {
        document.getElementById(event.target.name).classList.add("d-none");
    }

    handleDeletemethodsep = (event) => {
        this.closest("tr").remove();
    }

    handleDeletemethodPayment = (event) => {
        let alertVisible = document.getElementById(event.target.id + "delete")
        alertVisible.classList.remove("d-none")
    }
    handleDeletemethodPaymentNewclose = (event) => {
        document.getElementById(event.target.name).classList.add("d-none");
    }
    handleItemsErpType = (event) => {
        var sprparentId = $(event.target).parents('tr').parent("tbody").attr("id")
        $("#mappingTablenewfnnew"+sprparentId+" tbody#"+sprparentId+" #relativechnageErp"+event.target.id).text(event.target.value)
        document.getElementById("defaultsaveAllShipmentMappings"+sprparentId).classList.add("d-none")
        document.getElementById("saveAllShipmentMappings"+sprparentId).classList.remove("d-none")
    }

    handleItemsEcommerceType = (event) => {
        var sprparentId = $(event.target).parents('tr').parent("tbody").attr("id")
        $("#mappingTablenewfnnew"+sprparentId+" tbody#"+sprparentId+" #relativechnageEcom"+event.target.id).text(event.target.value)
        document.getElementById("defaultsaveAllShipmentMappings"+sprparentId).classList.add("d-none")
        document.getElementById("saveAllShipmentMappings"+sprparentId).classList.remove("d-none")
    }


    handleItemsErpTypePayment = (event) => {
        var sprparentId = $(event.target).parents('tr').parent("tbody").attr("id")
        $("#paymentmappingTablenewfnnew"+sprparentId+" tbody#"+sprparentId+" #paymentchnageErp"+event.target.id).text(event.target.value)


        document.getElementById("defaultsaveAllPaymentMappings"+sprparentId).classList.add("d-none")
        document.getElementById("saveAllPaymentMappings"+sprparentId).classList.remove("d-none")
    }

    handleItemsEcommerceTypePayment = (event) => {
        var sprparentId = $(event.target).parents('tr').parent("tbody").attr("id")
        $("#paymentmappingTablenewfnnew"+sprparentId+" tbody#"+sprparentId+" #paymentchnageEcom"+event.target.id).text(event.target.value)

        document.getElementById("defaultsaveAllPaymentMappings"+sprparentId).classList.add("d-none")
        document.getElementById("saveAllPaymentMappings"+sprparentId).classList.remove("d-none")
    }

    shippingerptoecomcheck = (elem) => {
        let elems = document.getElementsByClassName(elem.target.name)
        var sprparentId = $(elem.target).parents('tr').parent("tbody").attr("id")
        var currentState = elem.checked;
        var elemsLength = elems.length;
        if (elemsLength > 2) {
            for (var i = 0; i < elemsLength; i++) {
                if (elems[i].type === "checkbox") {
                    elems[i].checked = false;
                }
                var tds = document.getElementsByTagName("td");
                for (var j = 0; j < tds.length; j++) {
                    if (tds[j].className == elem.target.name) {
                        tds[j].textContent = false
                    }
                }
            }
            elem.target.checked = true
            document.getElementById("newone" + elem.target.id).textContent = true
        } else {
            if (elem.target.checked === true) {
                document.getElementById("newone" + elem.target.id).textContent = true
            } else {
                document.getElementById("newone" + elem.target.id).textContent = false
            }
        }

        document.getElementById("defaultsaveAllShipmentMappings"+sprparentId).classList.add("d-none")
        document.getElementById("saveAllShipmentMappings"+sprparentId).classList.remove("d-none")
    }

    shippingecomtoerpcheck = (elem) => {
        let elems = document.getElementsByClassName(elem.target.name)
        var sprparentId = $(elem.target).parents('tr').parent("tbody").attr("id")
        var currentState = elem.checked;
        var elemsLength = elems.length;
        if (elemsLength > 2) {
            for (var i = 0; i < elemsLength; i++) {
                if (elems[i].type === "checkbox") {
                    elems[i].checked = false;
                }
                var tds = document.getElementsByTagName("td");
                for (var j = 0; j < tds.length; j++) {
                    if (tds[j].className == elem.target.name) {
                        tds[j].textContent = false
                    }
                }
            }
            elem.target.checked = true
            document.getElementById("ecomtoerp" + elem.target.id).textContent = true
        } else {
            if (elem.target.checked === true) {
                document.getElementById("ecomtoerp" + elem.target.id).textContent = true
            } else {
                document.getElementById("ecomtoerp" + elem.target.id).textContent = false
            }
        }
        document.getElementById("defaultsaveAllShipmentMappings"+sprparentId).classList.add("d-none")
        document.getElementById("saveAllShipmentMappings"+sprparentId).classList.remove("d-none")
    }

    paymenterptoecomcheck = (elem) => {
        let elems = document.getElementsByClassName(elem.target.name)
        var sprparentId = $(elem.target).parents('tr').parent("tbody").attr("id")
        var currentState = elem.checked;
        var elemsLength = elems.length;
        if (elemsLength > 2) {
            for (var i = 0; i < elemsLength; i++) {
                if (elems[i].type === "checkbox") {
                    elems[i].checked = false;
                }
                var tds = document.getElementsByTagName("td");
                for (var j = 0; j < tds.length; j++) {
                    if (tds[j].className == elem.target.name) {
                        tds[j].textContent = false
                    }
                }
            }
            elem.target.checked = true
            document.getElementById("paymentnewone" + elem.target.id).textContent = true
        } else {
            if (elem.target.checked === true) {
                document.getElementById("paymentnewone" + elem.target.id).textContent = true
            } else {
                document.getElementById("paymentnewone" + elem.target.id).textContent = false
            }
        }

        document.getElementById("defaultsaveAllPaymentMappings"+sprparentId).classList.add("d-none")
        document.getElementById("saveAllPaymentMappings"+sprparentId).classList.remove("d-none")
    }

    paymentecomtoerpcheck = (elem) => {

        let elems = document.getElementsByClassName(elem.target.name)
        var sprparentId = $(elem.target).parents('tr').parent("tbody").attr("id")
        var currentState = elem.checked;
        var elemsLength = elems.length;
        if (elemsLength > 2) {
            for (var i = 0; i < elemsLength; i++) {
                if (elems[i].type === "checkbox") {
                    elems[i].checked = false;
                }

                var tds = document.getElementsByTagName("td");
                for (var j = 0; j < tds.length; j++) {
                    if (tds[j].className == elem.target.name) {
                        tds[j].textContent = false
                    }
                }
            }
            elem.target.checked = true
            document.getElementById("paymentecomtoerp" + elem.target.id).textContent = true
        } else {
            if (elem.target.checked === true) {
                document.getElementById("paymentecomtoerp" + elem.target.id).textContent = true
            } else {
                document.getElementById("paymentecomtoerp" + elem.target.id).textContent = false
            }
        }

        document.getElementById("defaultsaveAllPaymentMappings"+sprparentId).classList.add("d-none")
        document.getElementById("saveAllPaymentMappings"+sprparentId).classList.remove("d-none")
    }
    validAddclassnew = (event) => {
        let changeID = event.target.name

        if (event.target.value !== "") {
            event.target.classList.remove("errorStateSelect")
        }

        if(event.target.value == "othershippingERPValue"){
            event.target.classList.add("d-none")
            document.getElementById("cancelOthershippingErp"+changeID).classList.remove("d-none")
            document.getElementById("othershippingERPValue"+changeID).classList.remove("d-none")
            event.target.selectedIndex = 0;
        }

        if(event.target.value == "othershippingeCommValue"){
            event.target.classList.add("d-none")
            document.getElementById("othershippingeCommerceValue"+changeID).classList.remove("d-none")
            document.getElementById("cancelOthershippingeCommerce"+changeID).classList.remove("d-none")
            event.target.selectedIndex = 0;
        }

        if(event.target.value == "otherpaymentERPValue"){
            event.target.classList.add("d-none")
            document.getElementById("otherpaymentERPValue"+changeID).classList.remove("d-none")
            document.getElementById("cancelOtherpaymentErp"+changeID).classList.remove("d-none")
            event.target.selectedIndex = 0;
        }

        if(event.target.value == "otherpaymenteCommValue"){
            event.target.classList.add("d-none")
            document.getElementById("otherpaymenteCommerceValue"+changeID).classList.remove("d-none")
            document.getElementById("cancelOtherpaymentCommerce"+changeID).classList.remove("d-none")
            event.target.selectedIndex = 0;
        }

    }

    cancelCustomOption = (event) => {
        if(event.target.id === "cancelOthershippingErp"+event.target.name){
            document.getElementById("selectErpMethod"+event.target.name).classList.remove("d-none")
            document.getElementById("selectErpMethod"+event.target.name).selectedIndex = 0;
            document.getElementById("othershippingERPValue"+event.target.name).classList.add("d-none")
            event.target.classList.add("d-none")
        }

        if(event.target.id === "cancelOthershippingeCommerce"+event.target.name){
            document.getElementById("selecteCommerceMethod"+event.target.name).classList.remove("d-none")
            document.getElementById("selecteCommerceMethod"+event.target.name).selectedIndex = 0;
            document.getElementById("othershippingeCommerceValue"+event.target.name).classList.add("d-none")
            event.target.classList.add("d-none")
        }

        if(event.target.id === "cancelOtherpaymentErp"+event.target.name){
            document.getElementById("paymentselectErpMethod"+event.target.name).classList.remove("d-none")
            document.getElementById("paymentselectErpMethod"+event.target.name).selectedIndex = 0;
            document.getElementById("otherpaymentERPValue"+event.target.name).classList.add("d-none")
            event.target.classList.add("d-none")
        }

        if(event.target.id === "cancelOtherpaymentCommerce"+event.target.name){
            document.getElementById("paymentselecteCommerceMethod"+event.target.name).classList.remove("d-none")
            document.getElementById("paymentselecteCommerceMethod"+event.target.name).selectedIndex = 0;
            document.getElementById("otherpaymenteCommerceValue"+event.target.name).classList.add("d-none")
            event.target.classList.add("d-none")
        }
    }

    othervalueChange = (event) => {
        let thisId = event.target.name;
        let thisValue = event.target.value;
    }

    entityecommtoerpchangeUpdate = (event) => {
        document.getElementById("loaderContainer").classList.remove("d-none")
        let currentId = event.target.name;
        let isInboundActiveStatus = event.target.checked
        let isOutboundActiveStatus = JSON.parse(event.target.id)
        $(event.target).parents("tr").find("td:nth-child(3) input").attr("id",JSON.parse(event.target.checked))
        let connectionID = $(event.target).parents("tbody").attr("id")
        let instanceId = $(event.target).parents("tbody").attr("id")
        let entityId = event.target.name
        let id = window.sessionStorage.getItem("connectorIndex");
        var response = window.localStorage.getItem("accessToken");
        let self = this;
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        const movies = self.state.datapoint.data;
        const body = {
            "isInboundActive" : isInboundActiveStatus,
            "isOutboundActive": isOutboundActiveStatus
        }
        API.post(`/Connection/${movies[id].id}/instance/${movies[id].instances[0].id}/entities/${entityId}`, body, { headers })
        .then(response => response)
        .then(data => {
            if(data.data.result === false && data.data.message === "No changes made"){
                document.getElementById("loaderContainer").classList.add("d-none")
                document.getElementById("disableAftersync").textContent = data.data.message
                document.getElementById("disableAftersync").classList.remove("d-none")
                setTimeout(function () {
                    document.getElementById('disableAftersync').classList.add('d-none');
                }, 7000)
            }else if(data.data.result === true && data.data.message === null){
                document.getElementById("loaderContainer").classList.add("d-none")
                document.getElementById("entityMappingerrormessage").classList.remove("d-none")
                setTimeout(function () {
                    document.getElementById('entityMappingerrormessage').classList.add('d-none');
                }, 5000)
            }else if(data.data.result === false && data.data.message === "Please disable after all entities Synced...!"){
                $(event.target).prop("checked", true)
                document.getElementById("loaderContainer").classList.add("d-none")
                document.getElementById("disableAftersync").textContent = data.data.message
                document.getElementById("disableAftersync").classList.remove("d-none")
                setTimeout(function () {
                    document.getElementById('disableAftersync').classList.add('d-none');
                }, 7000)
            }
        })
    }

    entityerptoecommchangeUpdate = (event) => {
        document.getElementById("loaderContainer").classList.remove("d-none")
        let currentId = event.target.name;
        let isOutboundActiveStatus = event.target.checked
        let isInboundActiveStatus = JSON.parse(event.target.id)
        $(event.target).parents("tr").find("td:nth-child(2) input").attr("id",JSON.parse(event.target.checked))
        let connectionID = $(event.target).parents("tbody").attr("id")
        let instanceId = $(event.target).parents("tbody").attr("id")
        let entityId = event.target.name
        let id = window.sessionStorage.getItem("connectorIndex");
        var response = window.localStorage.getItem("accessToken");
        let self = this;
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        const movies = self.state.datapoint.data;
        const body = {
            "isInboundActive" : isInboundActiveStatus,
            "isOutboundActive": isOutboundActiveStatus
        }
        API.post(`/Connection/${movies[id].id}/instance/${movies[id].instances[0].id}/entities/${entityId}`, body, { headers })
        .then(response => response)
        .then(data => {
            if(data.data.result === false && data.data.message === "No changes made"){
                document.getElementById("loaderContainer").classList.add("d-none")
                document.getElementById("disableAftersync").textContent = data.data.message
                document.getElementById("disableAftersync").classList.remove("d-none")
                setTimeout(function () {
                    document.getElementById('disableAftersync').classList.add('d-none');
                }, 7000)
            }else if(data.data.result === true && data.data.message === null){
                document.getElementById("loaderContainer").classList.add("d-none")
                document.getElementById("entityMappingerrormessage").classList.remove("d-none")
                setTimeout(function () {
                    document.getElementById('entityMappingerrormessage').classList.add('d-none');
                }, 5000)
            }else if(data.data.result === false && data.data.message === "Please disable after all entities Synced...!"){
                $(event.target).prop("checked", true)
                document.getElementById("loaderContainer").classList.add("d-none")
                document.getElementById("disableAftersync").textContent = data.data.message
                document.getElementById("disableAftersync").classList.remove("d-none")
                setTimeout(function () {
                    document.getElementById('disableAftersync').classList.add('d-none');
                }, 7000)
            }
        })
    }

    productattributeSourcechange = (selectedOptionSource) => {
        if(selectedOptionSource.value === "Enter Custom ERP Method"){
            document.getElementById('attributeMappingSTTselectERPmethodNew').classList.add('d-none');
            document.getElementById('productattributeSourcechange').classList.remove('d-none');
        }else{
            this.setState({ selectedOptionSource });
        }
    };
    
    productattributeTargetchange(selectedOptionsTarget) {
        if(selectedOptionsTarget != null){
            this.setState({ selectedOptionsTarget })
        }
    }

    productattributeSTTcanUpdate = (event) =>{
        this.setState(
            {
                canUpdate: event.target.checked
            }
        )
    }

//Add new Product Attribuite Mapping ERP to eCommerce Started
    addNewProductAttributeMethod = (event) =>{
        let selectedOptionsTargetValue = this.state.selectedOptionsTarget.map(item =>
            item.label
        )
        let compareText = []
        $("#otherTable"+event.target.id+" tbody tr").each(function(){
            let newText = $(this).find("td:eq(1)").text()
            compareText.push(newText)
        })
        var array_one = selectedOptionsTargetValue
        var array_two = compareText
        var foo = [];
        $.grep(array_two, function (el) {
            if ($.inArray(el, array_one) != -1) {
                foo.push(el);
            }
        });

        const { selectedOptionSource } = this.state;

        if(selectedOptionSource === null && this.state.selectedOptionsTarget.length === 0){
            $("#attributeMappingSTTselectERPmethodNew"+event.target.id).addClass("border-red")
            $("#newfinerect"+event.target.id).addClass("border-red")
        }else if(selectedOptionSource === null){
            $("#attributeMappingSTTselectERPmethodNew"+event.target.id).addClass("border-red")
        }else if(this.state.selectedOptionsTarget.length === 0){
            $("#newfinerect"+event.target.id).addClass("border-red")
        }else if(foo.length > 0){
            //alert("Mapping allreayd happend with one of your selected ecommerc method, Please check below")
            let errorMessageNew = "Selected eCommerce method(s) <span style='color:red'>"+ foo +"</span> is/are already mapped with one of the ERP method(s)."
            $("#errorHandlingNew"+event.target.id).removeClass("d-none")
            $("#errorHandlingNew"+event.target.id).empty()
            $("#errorHandlingNew"+event.target.id).append(errorMessageNew)

            window.setTimeout(function(){
                $("#errorHandlingNew"+event.target.id).addClass("d-none")
            }, 30000); //<-- Delay in milliseconds
        }else{
            let selectedOptionValue = selectedOptionSource.value
            var selectedOptionValueSplit = selectedOptionValue.split("*")

            let selectederpName = selectedOptionValueSplit[0]
            let selectederpdataType = selectedOptionValueSplit[1]
            let selectederpsize = selectedOptionValueSplit[2]
            let selectederptype = selectedOptionValueSplit[3]
            let selectederpisRequired = selectedOptionValueSplit[4]

            $("#disabledButton"+event.target.id).addClass("d-none")
            $("#saveAllattributeMappings"+event.target.id).removeClass("d-none")
            $("#mainTableway"+event.target.id+" tbody tr td:first-child").text(selectederpName)

            let settingValue = this.state.canUpdate 
            $("#mainTablewayMain"+event.target.id+" tbody tr").each(function(){
                let findTdText = $(this).find("td:eq(3)").text()
                let findTdTextSplit  = findTdText.split("*")
                let findTdTextName = findTdTextSplit[0]
                let findTdTextDatatype = findTdTextSplit[1]
                let findTdTextSize = findTdTextSplit[2]
                let findTdTextType = findTdTextSplit[3]
                let findTdTextisRequired = findTdTextSplit[4]

                $(this).find("td:eq(0)").text(selectederpName)
                $(this).find("td:eq(1)").text(selectederpdataType)
                $(this).find("td:eq(2)").text(selectederpsize)
                $(this).find("td:eq(4)").text(findTdTextDatatype)
                $(this).find("td:eq(5)").text(findTdTextSize)
                $(this).find("td:eq(6)").text(findTdTextType)
                $(this).find("td:eq(7)").text(settingValue)
                $(this).find("td:eq(8)").text("true")
                $(this).find("td:eq(9)").text(selectederpisRequired)
                $(this).find("td:eq(10)").text(findTdTextisRequired)
                $(this).find("td:eq(3)").text(findTdTextName)
            })
            var row = $("#mainTableway"+event.target.id+" tbody").html()
            var row1 = $("#mainTablewayMain"+event.target.id+" tbody").html()
            let currentIndex = $("#mainTablewayMain"+event.target.id+" tbody tr").length
            $("#productAttributeMainAppend"+event.target.id).prepend('<tr>'+row1+'</tr>');

            
            $("#otherTable"+event.target.id).prepend('<tr>'+row+'</tr>');

            $("#productAttributeMainAppend"+event.target.name+" tbody tr td.deletefinal").remove()
            $("#productAttributeMainAppend"+event.target.name+" tr").each(function() {
                if (this.innerHTML === '') {
                    //this.remove()
                }
            });
            this.setState({
                selectedOptionSource : null
            })
        }
    }
//Add new Product Attribuite Mapping ERP to eCommerce Ended

//Submit Mapping ERP to eCommerce Started 
    productattributesaveallSource = (event) => {
        $("#mappingLoaderContainer"+event.target.name).removeClass("d-none")
        let connectorId = event.target.name
        
        $("#productAttributeMainAppend"+event.target.name+" tbody tr td.deletefinal").remove()

        $("#productAttributeMainAppend"+event.target.name+" tr").each(function() {
            if (this.innerHTML === '') {
                this.remove()
            }
        });

        var response = window.localStorage.getItem("accessToken");

        const results = [];
        const headersnewway = [];
        const divnew = document.getElementsByTagName("pre")
        const bodynewway = document.getElementById("productAttributeMainAppend"+event.target.name)
        if (bodynewway !== null) {
            const rows = bodynewway.querySelectorAll("tr");
            rows.forEach((rowData, rowIndex) => {
                const dataRowIndex = rowIndex - 1;
                if (rowIndex > 0) results[dataRowIndex] = {};
                for (let cellIndex = 0; cellIndex < rowData.cells.length; cellIndex++) {
                    const cellData = rowData.cells.item(cellIndex);
                    if (rowIndex === 0) {
                        if (cellData !== null)
                            headersnewway[cellIndex] = cellData.textContent || "";
                    } else if (rowIndex > 0) {
                        if (cellData !== null)
                            results[dataRowIndex][headersnewway[cellIndex]] = cellData.textContent === 'false' ? false : (cellData.textContent === 'true' ? true : cellData.textContent) || "";
                    }
                }
            });
        }

        divnew.innerHTML = results;

        let self = this;
        let id = window.sessionStorage.getItem("connectorIndex")
        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        const movies = self.state.datapoint.data;
        const body = {"sourceMappedSchema" : JSON.stringify(results)}

        API.post(`/Connection/${movies[id].id}/instance/${movies[id].instances[0].id}/save-product-mappings/Source`, body, { headers })
        .then(response => response)
        .then(data => {
            self.setState({
                instanceProductAttribuetdataPost: data,
            })
            $("#mappingLoaderContainer"+event.target.name).addClass("d-none")
            $("#productAttributeMappingerrormessage").removeClass("d-none")
            setTimeout(function () {
                document.getElementById('productAttributeMappingerrormessage').classList.add('d-none');
            }, 5000)
        })
    }
//Submit Mapping ERP to eCommerce Ended

    deleteAddedRow = (event) =>{
        let selectIndex = $(event.target).closest("tr").index()
        $("#productAttribute"+selectIndex).remove()
        $(event.target).closest("tr").remove()
    }

/*Target to Source */
    productattributeTTScanUpdate = (event) =>{
        this.setState(
            {
                canUpdateTTS: event.target.checked
            }
        )
    }

    productattributeSourcechangereverse = selectedOptionSourcereverseValue => {
        this.setState({ selectedOptionSourcereverseValue });
    }

    productattributeTargetchangereverse(selectedOptionTargetreverseValue) {
        if(selectedOptionTargetreverseValue != null){
            this.setState({ selectedOptionTargetreverseValue })
        }
    }

    addNewProductAttributeMethodTargettoSource = (event) =>{

        let selectedOptionsTargetValueCheck = this.state.selectedOptionTargetreverseValue.map(item =>
            item.label
        )
        let compareText = []
        $("#otherTableNewtargettoSource"+event.target.id+" tbody tr").each(function(){
            let newText = $(this).find("td:eq(1)").text()
            compareText.push(newText)
        })
        var array_one = selectedOptionsTargetValueCheck
        var array_two = compareText
        var foo = [];
        $.grep(array_two, function (el) {
            if ($.inArray(el, array_one) != -1) {
                foo.push(el);
            }
        });
        

        const { selectedOptionSourcereverseValue } = this.state;
        if(selectedOptionSourcereverseValue === null && this.state.selectedOptionTargetreverseValue.length === 0){
            $("#attributeMappingTTSselectERPmethodNew"+event.target.id).addClass("border-red")
            $("#newfinerectTTS"+event.target.id).addClass("border-red")
        }if(selectedOptionSourcereverseValue === null){
            $("#attributeMappingTTSselectERPmethodNew"+event.target.id).addClass("border-red")
        }if(this.state.selectedOptionTargetreverseValue.length === 0){
            $("#newfinerectTTS"+event.target.id).addClass("border-red")
        }else if(foo.length > 0){
            //alert("Mapping allreayd happend with one of your selected ecommerc method, Please check below")
            let errorMessageNew = "Selected ERP method(s) <span style='color:red'>"+ foo +"</span> is/are already mapped with one of the eCommerce method."
            $("#errorHandlingNewTTS"+event.target.id).removeClass("d-none")
            $("#errorHandlingNewTTS"+event.target.id).empty()
            $("#errorHandlingNewTTS"+event.target.id).append(errorMessageNew)

            window.setTimeout(function(){
                $("#errorHandlingNewTTS"+event.target.id).addClass("d-none")
            }, 30000); //<-- Delay in milliseconds
        }else{
            let selectedOptionValue = selectedOptionSourcereverseValue.value
            var selectedOptionValueSplit = selectedOptionValue.split("*")
            let selectedecommerceName = selectedOptionValueSplit[0]
            let selectedecommercedataType = selectedOptionValueSplit[1]
            let selectedecommercesize = selectedOptionValueSplit[2]
            let selectedecommercetype = selectedOptionValueSplit[3]
            let selectedecommerceisRequired = selectedOptionValueSplit[4]

            $("#mainTablewayNewtargettoSource"+event.target.id+" tbody tr td:first-child").text(selectedecommerceName)
            //$("#mainTablewayNewtargettoSource"+event.target.id+" tbody tr td:eq(2)").text(this.state.canUpdate)

            let settingValue = this.state.canUpdateTTS
            $("#mainTablewayMainNewtargettoSource"+event.target.id+" tbody tr").each(function(){
                let findTdText = $(this).find("td:eq(4)").text()
                let findTdTextSplit  = findTdText.split("*")
                let findTdTextName = findTdTextSplit[0]
                let findTdTextDatatype = findTdTextSplit[1]
                let findTdTextSize = findTdTextSplit[2]
                let findTdTextisRequired = findTdTextSplit[4]

                $(this).find("td:eq(0)").text(selectedecommerceName)
                $(this).find("td:eq(1)").text(selectedecommercedataType)
                $(this).find("td:eq(2)").text(selectedecommercesize)
                $(this).find("td:eq(3)").text(selectedecommercetype)
                $(this).find("td:eq(5)").text(findTdTextDatatype)
                $(this).find("td:eq(6)").text(findTdTextSize)
                $(this).find("td:eq(7)").text(settingValue)
                $(this).find("td:eq(8)").text("true")
                $(this).find("td:eq(9)").text(findTdTextisRequired)
                $(this).find("td:eq(10)").text(selectedecommerceisRequired)
                $(this).find("td:eq(4)").text(findTdTextName)
            })
            var row = $("#mainTablewayNewtargettoSource"+event.target.id+" tbody").html()
            var row1 = $("#mainTablewayMainNewtargettoSource"+event.target.id+" tbody").html()
            let currentIndex = $("#mainTablewayMainNewtargettoSource"+event.target.id+" tbody tr").length
            $("#productAttributeMainAppendNewtargettoSource"+event.target.id).prepend('<tr>'+row1+'</tr>');
            $("#otherTableNewtargettoSource"+event.target.id).prepend('<tr>'+row+'</tr>');
            $("#disabledButtontargettosource"+event.target.id).addClass("d-none")
            $("#saveAllattributeMappingstargettosource"+event.target.id).removeClass("d-none")
        }
    }
    
    productattributesaveallTargettoSource = (event) => {
        $("#mappingLoaderContainer"+event.target.name).removeClass("d-none")

        let connectorId = event.target.name

        $("#productAttributeMainAppendNewtargettoSource"+event.target.name+" tbody tr td.deletefinal").remove()

        $("#productAttributeMainAppendNewtargettoSource"+event.target.name+" tr").each(function() {
            if (this.innerHTML === '') {
                this.remove()
            }
        });

        var response = window.localStorage.getItem("accessToken");

        const results = [];
        const headersnewway = [];
        const divnew = document.getElementsByTagName("pre")
        const bodynewway = document.getElementById("productAttributeMainAppendNewtargettoSource"+event.target.name)
        if (bodynewway !== null) {
            const rows = bodynewway.querySelectorAll("tr");
            rows.forEach((rowData, rowIndex) => {
                const dataRowIndex = rowIndex - 1;
                if (rowIndex > 0) results[dataRowIndex] = {};
                for (let cellIndex = 0; cellIndex < rowData.cells.length; cellIndex++) {
                    const cellData = rowData.cells.item(cellIndex);
                    if (rowIndex === 0) {
                        if (cellData !== null)
                            headersnewway[cellIndex] = cellData.textContent || "";
                    } else if (rowIndex > 0) {
                        if (cellData !== null)
                            results[dataRowIndex][headersnewway[cellIndex]] = cellData.textContent === 'false' ? false : (cellData.textContent === 'true' ? true : cellData.textContent) || "";
                    }
                }
            });
        }

        divnew.innerHTML = results;
        let self = this;
        let id = window.sessionStorage.getItem("connectorIndex")
        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        const movies = self.state.datapoint.data;
        const body = {"targetMappedSchema" : JSON.stringify(results)}

        API.post(`/Connection/${movies[id].id}/instance/${movies[id].instances[0].id}/save-product-mappings/Target`, body, { headers })
        .then(response => response)
        .then(data => {
            self.setState({
                instanceProductAttribuetdataPost: data
            })
            $("#mappingLoaderContainer"+event.target.name).addClass("d-none")
            $("#productAttributeMappingerrormessage").removeClass("d-none")
            setTimeout(function () {
                document.getElementById('productAttributeMappingerrormessage').classList.add('d-none');
            }, 5000)
        })
    }

    deleteExistingMethodSourcetotarget = (event) =>{
        let bodyID = $(event.target).closest("tbody").attr("id")
        let firstTdtext = $(event.target).closest("tr").find("td:eq(0)").text()
        let secondTdtext = $(event.target).closest("tr").find("td:eq(1)").text()

        $("#productAttributeMainAppend"+bodyID+" tbody tr").each(function() {
            let sourceText =  $(this).find("td:eq(0)").text()
            let targetText =  $(this).find("td:eq(3)").text()
            if((firstTdtext === sourceText) && (secondTdtext === targetText)){
                $(this).remove()
            }
        })
        $("#disabledButton"+bodyID).addClass("d-none")
        $("#saveAllattributeMappings"+bodyID).removeClass("d-none")
        $(event.target).closest("tr").remove()
    }

    deleteExistingMethodTargettosource =(event) =>{
        let bodyID = $(event.target).closest("tbody").attr("id")
        let firstTdtext = $(event.target).closest("tr").find("td:eq(0)").text()
        let secondTdtext = $(event.target).closest("tr").find("td:eq(1)").text()
        $("#productAttributeMainAppendNewtargettoSource"+bodyID+" tbody tr").each(function() {
            let sourceText =  $(this).find("td:eq(0)").text()
            let targetText =  $(this).find("td:eq(4)").text()
            if((firstTdtext === sourceText) && (secondTdtext === targetText)){
                $(this).remove()
            }
        })
        $("#disabledButtontargettosource"+bodyID).addClass("d-none")
        $("#saveAllattributeMappingstargettosource"+bodyID).removeClass("d-none")
        $(event.target).closest("tr").remove()
    }
    /*Target to Source */
    deleteMappingFinal = (event)=>{
        var parentId = $(event.target).parents('tr').attr("id")
        var mnparentId = $(event.target).parents('tbody').attr("id")

        $(event.target).closest("tr").remove()
        $("table #" + parentId).remove()
        $("#defaultsaveAllShipmentMappings"+mnparentId).addClass("d-none")
        $("#saveAllShipmentMappings"+mnparentId).removeClass("d-none")
        $("#defaultsaveAllPaymentMappings"+mnparentId).addClass("d-none")
        $("#saveAllPaymentMappings"+mnparentId).removeClass("d-none")
        this.setState({
            checkDelete : 1
        })
    }


    render() {
        window.localStorage.setItem("summaryID",this.state.instanceEntryID)
        const { selectedOptionSource } = this.state;
        const { selectedOptionsTarget } = this.state;
        const {selectedOptionTargetreverseValue} = this.state
        //configuredata
        window.localStorage.setItem("configuredata", JSON.stringify(this.state.datapoint.data))

        $("body").on("click", ".deleteProductAttributeMappingNewlyAdded", function () {
            var clickedId = $(this).parents("tbody").attr("id")
            var firstChild = $(this).closest("tr").find("td:eq(0)").text()
            var secondChild = $(this).closest("tr").find("td:eq(1)").text()

            $("#productAttributeMainAppend"+clickedId+" tbody tr").each(function(){
                var firstChildValue = $(this).find("td:eq(0)").text()
                var secondChildValue = $(this).find("td:eq(3)").text()
                if((firstChild == firstChildValue) && (secondChild == secondChildValue)){
                    $(this).remove()
                }
            })
            $(this).closest("tr").remove()
        })
        $("body").on("click", ".deleteProductAttributeMappingNewlyAddedTTS", function () {
            var clickedId = $(this).parents("tbody").attr("id")
            var firstChild = $(this).closest("tr").find("td:eq(0)").text()
            var secondChild = $(this).closest("tr").find("td:eq(1)").text()

            $("#productAttributeMainAppendNewtargettoSource"+clickedId+" tbody tr").each(function(){
                var firstChildValue = $(this).find("td:eq(0)").text()
                var secondChildValue = $(this).find("td:eq(4)").text()
                if((firstChild == firstChildValue) && (secondChild == secondChildValue)){
                    $(this).remove()
                }
            })
            $(this).closest("tr").remove()
        })


        $("body").on("click", ".deleteMapping", function () {
            $(this).siblings().removeClass('d-none')
            var alertVisible = $(this).attr("id")
            var alertVisible = document.getElementById(alertVisible + "delete")
            $(alertVisible).removeClass("d-none")
        });

        $("body").on("click", ".deleteMappingNewsec", function () {
            var mnparentId = $(this).parents('tbody').attr("id")
            var parentId = $(this).parents('tr').attr("id")
            $(this).closest("tr").remove();
            $("#" + parentId).remove()
            $("#defaultsaveAllShipmentMappings"+mnparentId).addClass("d-none")
            $("#saveAllShipmentMappings"+mnparentId).removeClass("d-none")
            $("#defaultsaveAllPaymentMappings"+mnparentId).addClass("d-none")
            $("#saveAllPaymentMappings"+mnparentId).removeClass("d-none")
            localStorage.setItem('deleteChecking',true)
        });

        $("body").on("click", ".cancelDeleteMappingNewsec", function () {
            $(this).parents(".deleteConfirmPopup").addClass("d-none")
        })

        /* Start Payment Mapping */
        $("body").on("click", ".paymenterptoecomcheckboxnew", function () {
            var parentId = $(this).parents('tr').attr("id")
            var sprparentId = $(this).parents('tr').parent("tbody").attr("id")
            var th = $(this), name = th.prop('name');

            if (th.is(':checked')) {
                $(':checkbox[name="' + name + '"]').not($(this)).prop('checked', false);
                $("table#paymentmappingTablenewfnnew"+sprparentId+" tr td." + name).text("false");
                $("table#paymentmappingTablenewfnnew"+sprparentId+" tr#" + parentId + " td:nth-child(3)").text("true");
            } else {
                $("table#paymentmappingTablenewfnnew"+sprparentId+" tr#" + parentId + " td:nth-child(3)").text("false");
            }
            document.getElementById("defaultsaveAllPaymentMappings"+sprparentId).classList.add("d-none")
            document.getElementById("saveAllPaymentMappings"+sprparentId).classList.remove("d-none")
        })

        $("body").on("click", ".paymentecomtoerpcheckboxnew", function () {
            var parentId = $(this).parents('tr').attr("id")
            var sprparentId = $(this).parents('tr').parent("tbody").attr("id")
            var th = $(this), name = th.prop('name');

            if (th.is(':checked')) {
                $(':checkbox[name="' + name + '"]').not($(this)).prop('checked', false);
                $("table#paymentmappingTablenewfnnew"+sprparentId+" tr td." + name).text("false");
                $("table#paymentmappingTablenewfnnew"+sprparentId+" tr#" + parentId + " td:nth-child(4)").text("true");
            } else {
                $("table#paymentmappingTablenewfnnew"+sprparentId+" tr#" + parentId + " td:nth-child(4)").text("false");
            }
            document.getElementById("defaultsaveAllPaymentMappings"+sprparentId).classList.add("d-none")
            document.getElementById("saveAllPaymentMappings"+sprparentId).classList.remove("d-none")
        })
        $("body").on("click", ".savedpaymentMappingdiffnewerptoecom", function () {
            var parentId = $(this).parents('tr').attr("id")
            var sprparentId = $(this).parents('tr').parent("tbody").attr("id")
            var th = $(this), name = th.prop('name');

            if (th.is(':checked')) {
                $(':checkbox[name="' + name + '"]').not($(this)).prop('checked', false);
                $("table#paymentmappingTablenewfnnew"+sprparentId+" tr td." + name).text("false");
                $("table#paymentmappingTablenewfnnew"+sprparentId+" tr#" + parentId + " td:nth-child(3)").text("true");
            } else {
                $("table#paymentmappingTablenewfnnew"+sprparentId+" tr#" + parentId + " td:nth-child(3)").text("false");
            }
            
            document.getElementById("defaultsaveAllPaymentMappings"+sprparentId).classList.add("d-none")
            document.getElementById("saveAllPaymentMappings"+sprparentId).classList.remove("d-none")

        })
        $("body").on("click", ".savedpaymentMappingdiffnewecomtoerp", function () {
            var parentId = $(this).parents('tr').attr("id")
            var sprparentId = $(this).parents('tr').parent("tbody").attr("id")
            var th = $(this), name = th.prop('name');

            if (th.is(':checked')) {
                $(':checkbox[name="' + name + '"]').not($(this)).prop('checked', false);
                $("table#paymentmappingTablenewfnnew"+sprparentId+" tr td." + name).text("false");
                $("table#paymentmappingTablenewfnnew"+sprparentId+" tr#" + parentId + " td:nth-child(4)").text("true");
            } else {
                $("table#paymentmappingTablenewfnnew"+sprparentId+" tr#" + parentId + " td:nth-child(4)").text("false");
            }
            document.getElementById("defaultsaveAllPaymentMappings"+sprparentId).classList.add("d-none")
            document.getElementById("saveAllPaymentMappings"+sprparentId).classList.remove("d-none")
        })
        /* End Payment Mapping */

        /* Start Shipping Mapping */
        $("body").on("click", ".shippingerptoecomcheckboxnew", function () {
            var parentId = $(this).parents('tr').attr("id")
            var sprparentId = $(this).parents('tr').parent("tbody").attr("id")
            var th = $(this), name = th.prop('name');

            if (th.is(':checked')) {
                $(':checkbox[name="' + name + '"]').not($(this)).prop('checked', false);
                $("table#mappingTablenewfnnew"+sprparentId+" tr td." + name).text("false");
                $("table#mappingTablenewfnnew"+sprparentId+" tr#" + parentId + " td:nth-child(3)").text("true");
            } else {
                $("table#mappingTablenewfnnew"+sprparentId+" tr#" + parentId + " td:nth-child(3)").text("false");
            }
            document.getElementById("defaultsaveAllShipmentMappings"+sprparentId).classList.add("d-none")
            document.getElementById("saveAllShipmentMappings"+sprparentId).classList.remove("d-none")
        })

        $("body").on("click", ".shippingecomtoerpcheckboxnew", function () {
            var parentId = $(this).parents('tr').attr("id")
            var sprparentId = $(this).parents('tr').parent("tbody").attr("id")
            var th = $(this), 
            name = th.prop('name');

            if (th.is(':checked')) {
                $(':checkbox[name="' + name + '"]').not($(this)).prop('checked', false);
                $("table#mappingTablenewfnnew"+sprparentId+" tr td." + name).text("false");
                $("table#mappingTablenewfnnew"+sprparentId+" tr#" + parentId + " td:nth-child(4)").text("true");
            } else {
                $("table#mappingTablenewfnnew"+sprparentId+" tr#" + parentId + " td:nth-child(4)").text("false");
            }
            document.getElementById("defaultsaveAllShipmentMappings"+sprparentId).classList.add("d-none")
            document.getElementById("saveAllShipmentMappings"+sprparentId).classList.remove("d-none")
        })

        $("body").on("click", ".savedshippingMappingdiffnewerptoecom", function () {
            var parentId = $(this).parents('tr').attr("id")
            var sprparentId = $(this).parents('tr').parent("tbody").attr("id")
            
            var th = $(this), name = th.prop('name');
            

            if (th.is(':checked')) {
                $(':checkbox[name="' + name + '"]').not($(this)).prop('checked', false);
                $("table#mappingTablenewfnnew"+sprparentId+" tr td." + name).text("false");
                $("table#mappingTablenewfnnew"+sprparentId+" tr#" + parentId + " td:nth-child(3)").text("true");
            } else {
                $("table#mappingTablenewfnnew"+sprparentId+" tr#" + parentId + " td:nth-child(3)").text("false");
            }
            
            document.getElementById("defaultsaveAllShipmentMappings"+sprparentId).classList.add("d-none")
            document.getElementById("saveAllShipmentMappings"+sprparentId).classList.remove("d-none")
        })

        $("body").on("click", ".savedshippingMappingdiffnewecomtoerp", function () {
            var parentId = $(this).parents('tr').attr("id")
            var sprparentId = $(this).parents('tr').parent("tbody").attr("id")
            var th = $(this), name = th.prop('name');
            

        

            if (th.is(':checked')) {
                $(':checkbox[name="' + name + '"]').not($(this)).prop('checked', false);
                $("table#mappingTablenewfnnew"+sprparentId+" tr td." + name).text("false");
                $("table#mappingTablenewfnnew"+sprparentId+" tr#" + parentId + " td:nth-child(4)").text("true");
            } else {
                $("table#mappingTablenewfnnew"+sprparentId+" tr#" + parentId + " td:nth-child(4)").text("false");
            }
            
            document.getElementById("defaultsaveAllShipmentMappings"+sprparentId).classList.add("d-none")
            document.getElementById("saveAllShipmentMappings"+sprparentId).classList.remove("d-none")
        })

        
        
        /* Start Shipping Mapping */

        // $(':checkbox').on('change', function () {
        //     var parentId = $(this).parents('tr').attr("id")
        //     var th = $(this), name = th.prop('name');
        //     if (th.is(':checked')) {
        //         $(':checkbox[name="' + name + '"]').not($(this)).prop('checked', false);
        //         $("table#mappingTablenewfnnew tr#"+parentId+" td:nth-child(4)").text("true");
        //         $(this).val("true");
        //     } else (
        //         $("table#mappingTablenewfnnew tr#"+parentId+" td:nth-child(4)").text("false");
        //         $(this).val("false")
        //     );
        // });

        // $("body").on("click", ".paymentecomtoerpcheckboxnew", function(){
        //     var parentId = $(this).parents('tr').attr("id")


        //     if ($(this).prop('checked')) {
        //         $("table#paymentmappingTablenewfnnew tr#"+parentId+" td:nth-child(4)").text("true");
        //     }
        //     else {
        //         $("table#paymentmappingTablenewfnnew tr#"+parentId+" td:nth-child(4)").text("false");
        //     }
        // })

        // if (this.state.isLoaded === false) {
        //     $(".nav-itemnew:first-child a").addClass("active")
        //     $(".nav-tabitemnew:first-child").addClass("active")
        // }

        $("table.defaultmappingTable" ).each(function(i) {
            var mnIdnew = $(this).parents(".overflowMapping")
            var mnTr = $(this).find("tr")
            if(mnTr.length > 5 ){
                $(mnIdnew).addClass("showTracknew")
            }
        });


        let datapoint = this.state;
        let instanceSourceData = this.state;
        let instanceTargetData = this.state;
        let instanceMappingdata = this.state;
        let instanceMappingdataTarget = this.state;
        var date = new Date();
        let dateFinal = date.toISOString()
        let connectionLength = datapoint.datapoint && datapoint.datapoint.data && datapoint.datapoint.data.length
        let instanceSourceDataLength = instanceSourceData.instanceSourceData && instanceSourceData.instanceSourceData.data && instanceSourceData.instanceSourceData.data.length
        let instanceTargetDataLength = instanceTargetData.instanceTargetData && instanceTargetData.instanceTargetData.data && instanceTargetData.instanceTargetData.data.length
        let instanceTargetDataLen = instanceTargetData.instanceTargetData && instanceTargetData.instanceTargetData.data && instanceTargetData.instanceTargetData.data.length > 0 ? instanceTargetData.instanceTargetData && instanceTargetData.instanceTargetData.data && instanceTargetData.instanceTargetData.data.map(item => item.totalCount) : 0

        let instancemanageentity = this.state.instancemanageentity

        let productAttribuetsourceMappedSchema = this.state.productAttribuetsourceMappedSchema
        let productAttribuettargetMappedSchema = this.state.productAttribuettargetMappedSchema

        let optionErpType = instanceMappingdata.instanceMappingdata && instanceMappingdata.instanceMappingdata.data && instanceMappingdata.instanceMappingdata.data.map(function (item, i) {
            return <option value={item}>{item} </option>;
        }.bind(this));

        let optionecommercetype = instanceMappingdataTarget.instanceMappingdataTarget && instanceMappingdataTarget.instanceMappingdataTarget.data && instanceMappingdataTarget.instanceMappingdataTarget.data.map(function (item, i) {
            return <option value={item}>{item} </option>;
        }.bind(this));

        let Mappingdata = this.state.instanceMappingdataget && this.state.instanceMappingdataget.data && this.state.instanceMappingdataget.data.map((item, index) =>
            <tr className="mappingAlignsection" id={"shippingMapping" + index}>
                <td className="erpmethodcss form-group" id="prntnodesdr">
                    <select className="form-control" onChange={this.handleItemsErpType} id={index}>
                        <option value={item.erpMethod}>{item.erpMethod}</option>
                        {optionecommercetype}
                    </select>
                </td>
                <td className="ecommercemethodcss form-group">
                    <select className="form-control" onChange={this.handleItemsEcommerceType} id={index}>
                        <option value={item.ecommerceMethod}>{item.ecommerceMethod}</option>
                        {optionErpType}
                    </select>
                </td>
                <td className="erptoecomcss">
                    <div className="custom-control custom-checkbox mt-2" id={index}>
                        {
                            (item.isEcommerceDefault == true) ?
                                <input type="checkbox" className={"custom-control-input savedshippingMappingdiffnewerptoecom " + item.erpMethod} name={item.erpMethod.trim()} id={"horizontal-customCheck52" + index} defaultChecked="checked" /> :
                                <input type="checkbox" className={"custom-control-input savedshippingMappingdiffnewerptoecom " + item.erpMethod} name={item.erpMethod.trim()} id={"horizontal-customCheck52" + index} />
                        }
                        <label className="custom-control-label" for={"horizontal-customCheck52" + index}>ERP-ECom</label>
                    </div>
                </td>
                <td className="ecomtoerpcss">
                    <div className="custom-control custom-checkbox mt-2" id={index}>
                        {
                            (item.isErpDefault == true) ?
                                <input type="checkbox" className={"custom-control-input savedshippingMappingdiffnewecomtoerp " + item.ecommerceMethod} name={item.ecommerceMethod.trim()} id={"horizontal-customCheck52new" + index} defaultChecked="checked" /> :
                                <input type="checkbox" className={"custom-control-input savedshippingMappingdiffnewecomtoerp " + item.ecommerceMethod} name={item.ecommerceMethod.trim()} id={"horizontal-customCheck52new" + index} />
                        }
                        <label className="custom-control-label" for={"horizontal-customCheck52new" + index} >ECom-ERP</label>
                    </div>
                </td>
                <td className="deletemethodcss">
                    <button type="button" className="deleteMapping" id={"shippingMapping" + index}>
                        <span id={"shippingMapping" + index} onClick={this.handleDeletemethod}></span>
                        <i className="uil-trash-alt"></i>
                    </button>
                    <div id={"shippingMapping" + index + "delete"} className="deleteConfirmPopup d-none">
                        <div className="confirmNewSection">
                            <div className="alertTitle">
                                Are you sure you want to delete ?
                            </div>
                            <div className="alertBody">
                                <a name={"shippingMapping" + index + "delete"} className="cancelDeleteMappingNewsec" onClick={this.handleDeletemethodshippingMappingNewclose}> Cancel </a>
                                <button type="button" id={"shippingMapping" + index} className="deleteMappingNewsecMainUpdate" onClick={this.deleteMappingFinal}> Delete </button>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        )

        let Mappingdata1 = this.state.instanceMappingdataget && this.state.instanceMappingdataget.data && this.state.instanceMappingdataget.data.map((item, index) =>
            <tr id={"shippingMapping" + index}>
                <td id={"relativechnageErp" + index} value={item.erpMethod}>{item.erpMethod}</td>
                <td id={"relativechnageEcom" + index} >{item.ecommerceMethod}</td>
                {(item.isEcommerceDefault == true) ?
                    <td id={"newonehorizontal-customCheck52" + index} className={item.erpMethod}>true</td> : (item.isEcommerceDefault == false) ?
                        <td id={"newonehorizontal-customCheck52" + index} className={item.erpMethod}>false</td> : null
                }
                {(item.isErpDefault == true) ?
                    <td id={"ecomtoerphorizontal-customCheck52new" + index} className={item.ecommerceMethod}>true</td> : (item.isErpDefault == false) ?
                    <td id={"ecomtoerphorizontal-customCheck52new" + index} className={item.ecommerceMethod}>false</td> : null
                }
            </tr>
        )

        //Payment Methods Start

        // this.state.instancepaymentdatasource
        // this.state.instancePaymentdataTarget
        // this.state.instancePaymentdataget

        let instancepaymentdatasource = this.state;
        let instancePaymentdataTarget = this.state;
        let instancePaymentdataget = this.state;
        //let instanceProductAttribuetdataget = this.state;
        let paymentoptionErpType = instancepaymentdatasource.instancepaymentdatasource && instancepaymentdatasource.instancepaymentdatasource.data && instancepaymentdatasource.instancepaymentdatasource.data.map(function (item, i) {
            return <option value={item}>{item} </option>;
        }.bind(this));

        let paymentoptionecommercetype = instancePaymentdataTarget.instancePaymentdataTarget && instancePaymentdataTarget.instancePaymentdataTarget.data && instancePaymentdataTarget.instancePaymentdataTarget.data.map(function (item, i) {
            return <option value={item}>{item} </option>;
        }.bind(this));

        



        let paymentMappingdata = instancePaymentdataget.instancePaymentdataget && instancePaymentdataget.instancePaymentdataget.data && instancePaymentdataget.instancePaymentdataget.data.map((item, index) =>
            <tr className="mappingAlignsection" id={"paymentMapping" + index}>
                <td className="erpmethodcss form-group" width="276px">
                    <select className="form-control" id={index} name={item.id} onChange={this.handleItemsErpTypePayment}>
                        <option value={item.erpMethod}>{item.erpMethod}</option>
                        {paymentoptionecommercetype}
                    </select>
                </td>
                <td className="ecommercemethodcss form-group" width="276px">
                    <select className="form-control" id={index} onChange={this.handleItemsEcommerceTypePayment}>
                        <option value={item.ecommerceMethod}>{item.ecommerceMethod}</option>
                        {paymentoptionErpType}
                    </select>
                </td>
                <td className="erptoecomcss">
                    <div className="custom-control custom-checkbox mt-2">
                        {
                            (item.isEcommerceDefault == true) ?
                                <input type="checkbox" name={item.erpMethod.trim()} className={"custom-control-input savedpaymentMappingdiffnewerptoecom " + item.erpMethod} id={"paymenthorizontal-customCheck52" + index} defaultChecked="checked" /> :
                                <input type="checkbox" name={item.erpMethod.trim()} className={"custom-control-input savedpaymentMappingdiffnewerptoecom " + item.erpMethod} id={"paymenthorizontal-customCheck52" + index} />
                        }
                        <label className="custom-control-label" for={"paymenthorizontal-customCheck52" + index}>ERP-ECom</label>
                    </div>
                </td>
                <td className="ecomtoerpcss">
                    <div className="custom-control custom-checkbox mt-2">
                        {
                            (item.isErpDefault == true) ?
                                <input type="checkbox" name={item.ecommerceMethod.trim()} className={"custom-control-input savedpaymentMappingdiffnewecomtoerp " + item.ecommerceMethod} id={"paymenthorizontal-customCheck52new" + index} defaultChecked="checked" /> :
                                <input type="checkbox" name={item.ecommerceMethod.trim()} className={"custom-control-input savedpaymentMappingdiffnewecomtoerp " + item.ecommerceMethod} id={"paymenthorizontal-customCheck52new" + index} />
                        }
                        <label className="custom-control-label" for={"paymenthorizontal-customCheck52new" + index}>ECom-ERP</label>
                    </div>
                </td>
                <td className="deletemethodcss">
                    <button type="button" className="deleteMapping" id={"paymentMapping" + index}>
                        <span id={"paymentMapping" + index} onClick={this.handleDeletemethodPayment}></span>
                        <i className="uil-trash-alt"></i>
                    </button>
                    <div id={"paymentMapping" + index + "delete"} className="deleteConfirmPopup d-none">
                        <div className="confirmNewSection">
                            <div className="alertTitle">
                                Are you sure you want to delete ?
                                </div>
                            <div className="alertBody">
                                <a name={"paymentMapping" + index + "delete"} className="cancelDeleteMappingNewsec" onClick={this.handleDeletemethodPaymentNewclose}> Cancel </a>
                                <button id={"paymentMapping" + index} className="deleteMappingNewsecMainUpdate" onClick={this.deleteMappingFinal}> Delete </button>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </td>
                
            </tr>
        )



        let paymentMappingdata1 = instancePaymentdataget.instancePaymentdataget && instancePaymentdataget.instancePaymentdataget.data && instancePaymentdataget.instancePaymentdataget.data.map((item, index) =>
            <tr id={"paymentMapping" + index}>
                <td id={"paymentchnageErp" + index} >{item.erpMethod}</td>
                <td id={"paymentchnageEcom" + index}>{item.ecommerceMethod}</td>
                {(item.isEcommerceDefault == true) ?
                    <td id={"paymentnewonepaymenthorizontal-customCheck52" + index} className={item.erpMethod}>true</td> : (item.isEcommerceDefault == false) ?
                        <td id={"paymentnewonepaymenthorizontal-customCheck52" + index} className={item.erpMethod}>false</td> : null
                }
                {(item.isErpDefault == true) ?
                    <td id={"paymentecomtoerppaymenthorizontal-customCheck52new" + index} className={item.ecommerceMethod}>true</td> : (item.isErpDefault == false) ?
                        <td id={"paymentecomtoerppaymenthorizontal-customCheck52new" + index} className={item.ecommerceMethod}>false</td> : null
                }
            </tr>
        )

        //Payment Methods End
        
            let productAttribuetsourceMethods = this.state.productAttribuetsourceMethods
            const productAttribuetsourceMethodsoptions = productAttribuetsourceMethods != null ? Object.entries(productAttribuetsourceMethods).map(([key, value]) => ({
                value: value, label: value
            })) : [
                {id:"1", value: "Please Enter Custom ERP Method", label: "Please Enter Custom eCommerce Method"}
            ]

            let productAttribuettargetMethods = this.state.productAttribuettargetMethods
            const productAttribuettargetMethodsoptions = productAttribuettargetMethods != null ? Object.entries(productAttribuettargetMethods).map(([key, value]) => ({
                value: value, label: value
            })):[
                {value: "Please Enter Custom eCommerce Method", label: "Please Enter Custom ERP Method"}
            ]

            let instanceProductAttribuetdataget = this.state;
        
            const productAttribuetsourceMethodsNewlydded = instanceProductAttribuetdataget.productAttribuetsourceMethods != null ? instanceProductAttribuetdataget.productAttribuetsourceMethods.map(item =>(
                {value:item.name+"*"+item.dataType+"*"+item.size+"*"+item.type+"*"+item.isRequired, label:item.name}
            )) : null

            const productAttribuettargetMethodsNewlydded = instanceProductAttribuetdataget.productAttribuettargetMethods != null ? instanceProductAttribuetdataget.productAttribuettargetMethods.map(item =>(
                {value:item.name+"*"+item.dataType+"*"+item.size+"*"+item.type+"*"+item.isRequired, label:item.name}
            )) : null
        return (
            <div>
                <SessionTimeout />
                <Header />
                <SideBar />
                <div className="main-content myConnectors myConnectorsnewAdded">
                    <div id="loaderContainer" className="loaderContainer">
                        <div className="loader"></div>
                    </div>

                    <div className="page-content pb-0">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between mb-3 pb-0">
                                        <h4 id="myproductshide" className="mb-0">My Products
                                        (
                                                {
                                                (connectionLength == null) ? ("0") :
                                                    (connectionLength < 0) ? ("0" + connectionLength) : connectionLength
                                            }
                                            )
                                        </h4>
                                        <div className="page-title-right d-none">
                                            <div className="form-inline float-md-right mb-0">
                                                <div className="search-box ml-2">
                                                    <div className="position-relative">
                                                        <input type="text" className="form-control rounded" placeholder="Search..." />
                                                        <i className="mdi mdi-magnify search-icon"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <ul className="row nav nav-tabs nav-tabs-custom nav-justified newtabs" role="tablist">
                                    {datapoint.datapoint && datapoint.datapoint.data && datapoint.datapoint.data.map((item, index) =>
                                        <li className="nav-item nav-itemnew col-xl-4 col-sm-6">
                                            <a className="nav-link firstactive" title={item.name} data-toggle="tab" id={index} href={"#v-pills-home" + item.id} role="tab" onClick={this.handleActive}>
                                                <div className="selectionProcessnew" title={item.name} id={index}>
                                                </div>
                                                <div className="card" value={index} id="MappingsID">
                                                    <div className="card-body connectorItem item-active" >
                                                        <div className="media pb-3">
                                                            <div className="mr-3">
                                                                <div className="img-cont-full img-cont-1">
                                                                    {
                                                                        item.source.shortName === "EGE" ? <img src={EGE} /> : 
                                                                        item.source.shortName === "Magento" ? <img src={magento} /> : 
                                                                        (item.source.shortName === "Nav") || (item.source.shortName === "GP") || (item.source.shortName === "AX") ? <img src={nav_gp} /> :                                                                          
                                                                        (item.source.shortName === "BC") || (item.source.shortName === "Business Central") ? <img src={bc} /> :
                                                                        item.source.shortName === "BigCommerce" ? <img src={bigcommerce} /> : 
                                                                        item.source.shortName === "QuickBooks" ? <img src={qb} /> : 
                                                                        item.source.shortName === "Shopify" ? <img src={shopify} /> : null
                                                                       
                                                                    }
                                                                </div>
                                                                <div className="img-cont-full img-cont-2">
                                                                    {/* <img src={magento} /> */}
                                                                    {
                                                                        item.target.shortName === "EGE" ? <img src={EGE} /> : 
                                                                        item.target.shortName === "Magento" ? <img src={magento} /> : 
                                                                        (item.target.shortName === "Nav") || (item.target.shortName === "GP") || (item.target.shortName === "AX") ? <img src={nav_gp} /> : 
                                                                        (item.target.shortName === "BC") || (item.target.shortName === "Business Central") ? <img src={bc} /> :
                                                                        item.target.shortName === "BigCommerce" ? <img src={bigcommerce} /> :
                                                                        item.target.shortName === "QuickBooks" ? <img src={qb} /> : 
                                                                        item.target.shortName === "Shopify" ? <img src={shopify} /> : null
                                                                        
                                                                    }
                                                                </div>
                                                                {/* <img src={pimg} alt="" className="avatar-lg connector-inactive" />
                                                                <img src={pimgactive} alt="" className="avatar-lg connector-active" /> */}
                                                            </div>

                                                            <div className="media-body align-self-center overflow-hidden">
                                                                <div>
                                                                    <h5 className="text-truncate font-size-14 custom-h5">
                                                                        <a className="text-dark connector-title">{item.name} </a>
                                                                    </h5>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        {
                                                                            ((Math.round((new Date(item.expiryDate) - new Date(dateFinal)) / (1000 * 3600 * 24))) < "1") ? <div className=""> <p className="text-muted mb-0 font-size-10 d-none"> Expired </p> <h5 className="connectorExpired"> Expired </h5> </div> : <div className=""> <p className="text-muted mb-0 font-size-10"> Expires in </p> <h5 className="font-size-10"> {Math.round((new Date(item.expiryDate) - new Date(dateFinal)) / (1000 * 3600 * 24)) + " days"} </h5> </div>
                                                                        }
                                                                        <div className="d-none">
                                                                            <p className="text-muted mb-0 font-size-10">Expires in</p>
                                                                            <h5 className="font-size-10">
                                                                                {
                                                                                    ((Math.round((new Date(item.expiryDate) - new Date(dateFinal)) / (1000 * 3600 * 24))) < "1") ? "Expired" : Math.round((new Date(item.expiryDate) - new Date(dateFinal)) / (1000 * 3600 * 24)) + " days"
                                                                                }
                                                                            </h5>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-4">
                                                                        <div className="rrorlog">
                                                                            <p className="text-muted mb-0 font-size-10">Error Logs</p>
                                                                            <h5 className="font-size-10 font-weight-bold">{item.instances[0].errorCount}</h5>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-4">
                                                                        <div className="flows">
                                                                            <p className="text-muted mb-0 font-size-10">Pipes</p>
                                                                            <h5 className="font-size-10 font-weight-bold">{item.instances[0].entityCount}</h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-xl-12">
                                    <div id="entityMappingerrormessage" className="alert alert-success d-none" role="alert">
                                        Entity Updated successfully
                                    </div>
                                    <div id="productAttributeMappingerrormessage" className="alert alert-success d-none" role="alert">
                                        Mapping(s) Updated successfully
                                    </div>
                                    <div id="disableAftersync" className="alert alert-warning d-none" role="alert">
                                        
                                    </div>
                                </div>

                                <div className="col-md-12 col-xl-12">
                                    <div className="card">
                                        <div className="card-body custom-max-ht">
                                            <div className="tab-content new-tab-content text-muted">
                                                {datapoint.datapoint && datapoint.datapoint.data && datapoint.datapoint.data.map(item =>
                                                    <div className="tab-pane nav-tabitemnew" id={"v-pills-home" + item.id} role="tabpanel">
                                                        <h4 className="card-title mb-2 card-title-wth-border titlecustomNew">
                                                            <span className="text-truncate float-left cstmnewwdthcfn" title={item.name}> {item.name} </span>
                                                            <div className="newSwitcher float-right d-none">
                                                                <div className="float-left d-none">
                                                                    {
                                                                        item.instances.map(instanceType =>

                                                                            instanceType.instanceType === "Staging" ? 
                                                                            <span className="stagednew">
                                                                                Staging
                                                                            </span> : null
                                                                        )
                                                                    }
                                                                </div>
                                                                {
                                                                    item.instances.map(instanceType =>
                                                                        instanceType.instanceType === "Production" ?
                                                                        <>
                                                                            <div className="float-left">
                                                                                <span className="stagednew">
                                                                                    Staging
                                                                                </span>
                                                                            </div>
                                                                            <div class="switchToggle form-check form-switch form-switch-md float-left">
                                                                                <label id="switchtoStaging" class="switch" onClick={this.switchtoStaging}>
                                                                                    <img src={switchtostage} />
                                                                                </label>

                                                                                <label id="switchtoProduction" class="switch d-none" onClick={this.switchtoProduction}>
                                                                                    <img src={switchtoproduction} />
                                                                                </label>
                                                                            </div>
                                                                            <div className="float-left">
                                                                                <span className="stagednew">
                                                                                    Production
                                                                                </span>
                                                                            </div>
                                                                        </> : null
                                                                    )
                                                                }
                                                            </div>
                                                            <div className="clearfix"></div>
                                                            
                                                        </h4>


                                                        <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                                                            <li className="nav-item">
                                                                <a className="nav-link active" data-toggle="tab" href={"#home" + item.id} role="tab">
                                                                    <span className="d-none d-sm-block">Details</span>
                                                                </a>
                                                            </li>
                                                            {/* <li className="nav-item">
                                                                <a className="nav-link" data-toggle="tab" href={"#profile" + item.id} role="tab">
                                                                    <span className="d-none d-sm-block">Flows</span>
                                                                </a>
                                                            </li> */}
                                                            {
                                                                item.instances[0].isShippingMethodMappingEnable || 
                                                                item.instances[0].isPaymentMethodMappingEnable ||
                                                                item.instances[0].isProductAttributeMappingEnable ?
                                                                    <li className="nav-item">
                                                                        <a className="nav-link" data-toggle="tab" href={"#messages" + item.id} role="tab">
                                                                            <span className="d-none d-sm-block">Mapping</span>
                                                                        </a>
                                                                    </li> 
                                                                : null
                                                            }
                                                            
                                                            <li className="nav-item">
                                                                <a className="nav-link" data-toggle="tab" href={"#profile" + item.id} role="tab">
                                                                    <span className="d-none d-sm-block">Manage Entities</span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        
                                                        <div className="tab-content text-muted">
                                                            <div className="tab-pane active" id={"home" + item.id} role="tabpanel">
                                                                <div className="row">
                                                                    <div className="col-lg-8">
                                                                        <div className="card mndesc">
                                                                            <div className="card-header">
                                                                                Business Entity's (
                                                                                    {
                                                                                        instanceSourceDataLength > 0 && instanceTargetDataLength < 0 ? instanceSourceDataLength :
                                                                                        instanceSourceDataLength < 0 && instanceTargetDataLength > 0 ? instanceTargetDataLength :
                                                                                        instanceSourceDataLength < instanceTargetDataLength ? instanceTargetDataLength :
                                                                                        instanceSourceDataLength > instanceTargetDataLength ? instanceSourceDataLength : 
                                                                                        instanceSourceDataLength === instanceTargetDataLength ? instanceTargetDataLength : 0
                                                                                    }
                                                                                )

                                                                                {instanceSourceDataLength || instanceTargetDataLength > 0 ?
                                                                                    <NavLink to={{ pathname:"/ViewSummary", id: this.state.instanceEntryID}} className="float-right text-primary">
                                                                                        View Details
                                                                                    </NavLink> : <button className="float-right text-primary no-border disabledNew" title="Installation setup is incomplete" disabled> View Details  </button>
                                                                                }
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <div className="scrollingContainer">

                                                                                {(instanceSourceDataLength > 0 && instanceTargetDataLength < 0) ?
                                                                                    <div className="row" id="instanceSourceData">
                                                                                        {instanceSourceData.instanceSourceData && instanceSourceData.instanceSourceData.data && instanceSourceData.instanceSourceData.data.map((item,i)=>
                                                                                        <div className="col-lg-5 lengthnew">
                                                                                            <div className="card myconnector-businessentity-new text-white text-center p-3">
                                                                                                <h5 className="mt-0 text-white businessentity-title mb-1">{item.entityName}</h5>
                                                                                                <div className="syncContainer">
                                                                                                    <div className="erpCircle float-left">
                                                                                                        <span className="endtext">ERP</span>
                                                                                                    </div>
                                                                                                    <div className="erp-ec-dir float-left">
                                                                                                        <span className="erp-ecomm-dir-container">
                                                                                                            <span className="syncnmbr">
                                                                                                                {item.totalCount}
                                                                                                            </span>
                                                                                                        </span>

                                                                                                        <span class="ecomm-erp-dir-container">
                                                                                                            {instanceTargetData.instanceTargetData && instanceTargetData.instanceTargetData.data &&instanceTargetData.instanceTargetData.data.map(itemT=>
                                                                                                                (item.entityId === itemT.entityId) ? <span className="syncnmbr"> {itemT.totalCount} </span>  :
                                                                                                                null
                                                                                                            )}
                                                                                                            <span className="syncnmbr"> 0 </span>
                                                                                                        </span>
                                                                                                    </div>

                                                                                                    <div className="ecommCircle float-left">
                                                                                                        <span className="endtext">EC</span>
                                                                                                    </div>
                                                                                                    <div className="clearfix"></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        )}
                                                                                    </div> :
                                                                                    (instanceSourceDataLength < 0 && instanceTargetDataLength > 0) ?
                                                                                    <div className="row" id="instanceSourceData">
                                                                                        {instanceTargetData.instanceTargetData && instanceTargetData.instanceTargetData.data &&instanceTargetData.instanceTargetData.data.map((item,i)=>
                                                                                            <div className="col-lg-5 lengthnew">
                                                                                                <div className="card myconnector-businessentity-new text-white text-center p-3">
                                                                                                    <h5 className="mt-0 text-white businessentity-title mb-1">{item.entityName}</h5>
                                                                                                    <div className="syncContainer">
                                                                                                        <div className="erpCircle float-left">
                                                                                                            <span className="endtext">ERP</span>
                                                                                                        </div>
                                                                                                        <div className="erp-ec-dir float-left">
                                                                                                            <span className="erp-ecomm-dir-container">
                                                                                                                {instanceSourceData.instanceSourceData && instanceSourceData.instanceSourceData.data && instanceSourceData.instanceSourceData.data.map(itemT=>
                                                                                                                    (item.entityId === itemT.entityId) ? <span className="syncnmbr"> {itemT.totalCount} </span>  :
                                                                                                                    null
                                                                                                                )}
                                                                                                                <span className="syncnmbr"> 0 </span>
                                                                                                            </span>

                                                                                                            <span class="ecomm-erp-dir-container">
                                                                                                                <span className="syncnmbr">
                                                                                                                    {item.totalCount}
                                                                                                                </span>                                                                                                                
                                                                                                            </span>
                                                                                                        </div>

                                                                                                        <div className="ecommCircle float-left">
                                                                                                            <span className="endtext">EC</span>
                                                                                                        </div>
                                                                                                        <div className="clearfix"></div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>:
                                                                                    (instanceSourceDataLength > instanceTargetDataLength) ? 
                                                                                    <div className="row duplicationData" id="instanceSourceData">
                                                                                        {instanceSourceData.instanceSourceData && instanceSourceData.instanceSourceData.data && instanceSourceData.instanceSourceData.data.map((item,i)=>
                                                                                        <div className={"col-lg-5 lengthnew benew"+item.entityId} id={"lengthnew"+item.entityId}>
                                                                                            <div className="card myconnector-businessentity-new text-white text-center p-3">
                                                                                                <h5 className="mt-0 text-white businessentity-title mb-1">{item.entityName}</h5>
                                                                                                <div className="syncContainer">
                                                                                                    <div className="erpCircle float-left">
                                                                                                        <span className="endtext">ERP</span>
                                                                                                    </div>
                                                                                                    <div className="erp-ec-dir float-left">
                                                                                                        <span className="erp-ecomm-dir-container">
                                                                                                            <span className="syncnmbr">
                                                                                                                {item.totalCount}
                                                                                                            </span>
                                                                                                        </span>

                                                                                                        <span class="ecomm-erp-dir-container">
                                                                                                        {instanceTargetData.instanceTargetData && instanceTargetData.instanceTargetData.data &&instanceTargetData.instanceTargetData.data.map(itemT=>
                                                                                                            (item.entityId === itemT.entityId) ? <span className="syncnmbr"> {itemT.totalCount} </span>  :
                                                                                                            null
                                                                                                        )}
                                                                                                            <span className="syncnmbr"> 0 </span>
                                                                                                        </span>
                                                                                                    </div>

                                                                                                    <div className="ecommCircle float-left">
                                                                                                        <span className="endtext">EC</span>
                                                                                                    </div>
                                                                                                    <div className="clearfix"></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        )}
                                                                                    </div> : 
                                                                                    (instanceSourceDataLength < instanceTargetDataLength) ? 
                                                                                    <div className="row duplicationData" id="instanceSourceData">
                                                                                        {instanceTargetData.instanceTargetData && instanceTargetData.instanceTargetData.data && instanceTargetData.instanceTargetData.data.map((item,i)=>
                                                                                        <div className={"col-lg-5 lengthnew benew"+item.entityId} id={"lengthnew"+item.entityId}>
                                                                                            <div className="card myconnector-businessentity-new text-white text-center p-3">
                                                                                                <h5 className="mt-0 text-white businessentity-title mb-1">{item.entityName}</h5>
                                                                                                <div className="syncContainer">
                                                                                                    <div className="erpCircle float-left">
                                                                                                        <span className="endtext">ERP</span>
                                                                                                    </div>
                                                                                                    <div className="erp-ec-dir float-left">
                                                                                                        <span className="erp-ecomm-dir-container">
                                                                                                            {instanceSourceData.instanceSourceData && instanceSourceData.instanceSourceData.data && instanceSourceData.instanceSourceData.data.map(itemT=>
                                                                                                                (item.entityId === itemT.entityId) ? <span className="syncnmbr"> {itemT.totalCount} </span>  :
                                                                                                                null
                                                                                                            )}
                                                                                                            <span className="syncnmbr">
                                                                                                                0
                                                                                                            </span>
                                                                                                        </span>

                                                                                                        <span class="ecomm-erp-dir-container">
                                                                                                            
                                                                                                            <span className="syncnmbr"> {item.totalCount} </span>
                                                                                                        </span>
                                                                                                    </div>

                                                                                                    <div className="ecommCircle float-left">
                                                                                                        <span className="endtext">EC</span>
                                                                                                    </div>
                                                                                                    <div className="clearfix"></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        )}
                                                                                    </div> : 
                                                                                    (instanceSourceDataLength == instanceTargetDataLength) ? 
                                                                                    <div className="row duplicationData" id="instanceSourceData">
                                                                                        {instanceTargetData.instanceTargetData && instanceTargetData.instanceTargetData.data && instanceTargetData.instanceTargetData.data.map((item,i)=>
                                                                                        <div className={"col-lg-5 lengthnew benew"+item.entityId} id={"lengthnew"+item.entityId}>
                                                                                            <div className="card myconnector-businessentity-new text-white text-center p-3">
                                                                                                <h5 className="mt-0 text-white businessentity-title mb-1">{item.entityName}</h5>
                                                                                                <div className="syncContainer">
                                                                                                    <div className="erpCircle float-left">
                                                                                                        <span className="endtext">ERP</span>
                                                                                                    </div>
                                                                                                    <div className="erp-ec-dir float-left">
                                                                                                        <span className="erp-ecomm-dir-container">
                                                                                                            {instanceSourceData.instanceSourceData && instanceSourceData.instanceSourceData.data && instanceSourceData.instanceSourceData.data.map(itemT=>
                                                                                                                (item.entityId === itemT.entityId) ? <span className="syncnmbr"> {itemT.totalCount} </span>  :
                                                                                                                null
                                                                                                            )}
                                                                                                            <span className="syncnmbr">
                                                                                                                0
                                                                                                            </span>
                                                                                                        </span>

                                                                                                        <span class="ecomm-erp-dir-container">
                                                                                                            
                                                                                                            <span className="syncnmbr"> {item.totalCount} </span>
                                                                                                        </span>
                                                                                                    </div>

                                                                                                    <div className="ecommCircle float-left">
                                                                                                        <span className="endtext">EC</span>
                                                                                                    </div>
                                                                                                    <div className="clearfix"></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        )}
                                                                                    </div> :
                                                                                    <div id="errorMessageSummarynew">
                                                                                        <div className="alert alert-danger" role="alert">
                                                                                            There is no entities to show releted to your selected connector
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="card mndesc">
                                                                            <div className="card-header">
                                                                                Meta Data
                                                                                <a className="float-right font-size-10">
                                                                                    {String(item.instances[0].isSetupCompleted) == "false" ? <span className="text-danger"> Setup Incomplete </span> : null}
                                                                                </a>
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <div className="table-responsive connectorshotdesc">
                                                                                    <table className="table table-nowrap table-centered mb-0">
                                                                                        <tr>
                                                                                            <th scope="row" colspan="4" className="text-left">{item.source.name}</th>
                                                                                            <td className="text-right">{item.source.version}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th scope="row" colspan="4" className="border-0 text-left">{item.target.name}</th>
                                                                                            <td className="border-0 text-right">{item.target.version}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th scope="row" colspan="4" className="border-0 text-left">Product Type</th>
                                                                                            <td className="border-0 text-right">{item.featureName}</td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </div>
                                                                                <div className="otheroptions mt-1">
                                                                                    <h6> Other Options </h6>
                                                                                    <Link className="customDownloadnew" to={{ pathname: "/configure", downloadData: this.state.datapoint.data }} >Configuration  </Link>
                                                                                    {
                                                                                        (item.target.type === 1) || (item.source.type === 1) ? 
                                                                                            (
                                                                                                (item.target.shortName=="EGE")? 
                                                                                                    <Link className="customDownloadnew"  to={{pathname:"/DashboardEGESetup", downloadData:this.state.datapoint.data}} > Setup  </Link>: 
                                                                                                    <Link className="customDownloadnew"  to={{pathname:"/Setup", downloadData:this.state.datapoint.data, hilightmn: "MyProducts"}} > Setup</Link>):
                                                                                                    <button className="customDownloadnew" onClick={() => this.setState({ isPaneOpenDownload: true })}>
                                                                                                        Download Installers
                                                                                                    </button>
                                                                                    }
                                                                                    {/* <a className="customDownloadnew">
                                                                                        Clone Connector
                                                                                    </a> */}
                                                                                    {/* <a href=""> Move to Production </a> */}
                                                                                    {/* <Link 
                                                                                        className="customDownloadnew" 
                                                                                        to={
                                                                                            {
                                                                                                pathname: "/Configuration/Notification",
                                                                                                subscriptionId: item.id, 
                                                                                                isEnableNotification: item.isEnableNotification,
                                                                                                notificationEmails: item.notificationEmails
                                                                                            }
                                                                                        }
                                                                                    >
                                                                                        Configure Notifications
                                                                                    </Link> */}
                                                                                </div>
                                                                                <div className="newDownloadButtonofway">

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            
                                                            <div className="tab-pane" id={"messages" + item.id} role="tabpanel">
                                                                <div className="row mappin">
                                                                    <div className="col-md-3 col-xl-3">
                                                                        <div className="card mndesc">
                                                                            {
                                                                                /* <div className="card-header">
                                                                                    Shipping Mapping
                                                                                </div> */
                                                                            }

                                                                            <div className="card-body mapping-tab-sec">
                                                                                <div className={"nav flex-column nav-pills"+" customTab"+item.id} id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                                                    {
                                                                                        item.instances[0].isShippingMethodMappingEnable ? 
                                                                                            <a className="nav-link mb-2 customstylenewway" id="v-pills-shippingmapping-tab" data-toggle="pill" href={"#v-pills-shippingmapping"+item.id} role="tab" aria-controls={"v-pills-shippingmapping"+item.id} aria-selected="true">Shipping Method</a>
                                                                                        : null
                                                                                    }
                                                                                    {
                                                                                        item.instances[0].isPaymentMethodMappingEnable ? 
                                                                                            <a className="nav-link mb-2 customstylenewway" id="v-pills-paymentmapping-tab" data-toggle="pill" href={"#v-pills-paymentmapping"+item.id} role="tab" aria-controls={"v-pills-paymentmapping"+item.id} aria-selected="false">Payment Method</a>
                                                                                        : null
                                                                                    }
                                                                                    {
                                                                                        item.instances[0].isProductAttributeMappingEnable ? 
                                                                                            <a className="nav-link mb-2 customstylenewway d-none" id="v-pills-attributemapping-tab" data-toggle="pill" href={"#v-pills-attributemapping"+item.id} role="tab" aria-controls={"v-pills-attributemapping"+item.id} aria-selected="false">Product Attribute</a>
                                                                                        : null
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-9 col-xl-9">
                                                                        <div className={"tab-content text-muted mt-4 mt-md-0 customTabFixedHeight customTabExpand"+item.id} id="v-pills-tabContent">
                                                                            {
                                                                                item.instances[0].isShippingMethodMappingEnable ?
                                                                                    <div className="tab-pane fade" id={"v-pills-shippingmapping"+item.id} role="tabpanel" aria-labelledby="v-pills-shippingmapping-tab">

                                                                                        <div className="card mndesc">
                                                                                            <div className="card-header">
                                                                                                Shipping Method Mapping
                                                                                            </div>
                                                                                            <div className="card-body mapping-tab-sec">
                                                                                                <div className="mt-0">
                                                                                                    <form className="">
                                                                                                        <div className="row">
                                                                                                            <div className="col-lg-12 pr-0">
                                                                                                                <div className="table-responsive">
                                                                                                                    <table className="table table-sm m-0 shippingMappingtable">
                                                                                                                        <tr>
                                                                                                                            <td className="selecterptd">
                                                                                                                                <select className="form-control" name={item.id} id={"selectErpMethod"+item.id} onChange={this.validAddclassnew}>
                                                                                                                                    <option value=""> Select Erp Method</option>
                                                                                                                                    <option value="othershippingERPValue"> Enter Custom ERP Method </option>
                                                                                                                                    {optionecommercetype}
                                                                                                                                </select>
                                                                                                                                <input type="text" placeholder="Enter Custom ERP Method" className="form-control version-field erpFieldnew inputpr28 d-none" id={"othershippingERPValue"+item.id} name={item.id} onChange={this.othervalueChange} />
                                                                                                                                <a id={"cancelOthershippingErp"+item.id} name={item.id} className="cancelAdding text-danger d-none" onClick={this.cancelCustomOption}> </a>
                                                                                                                            </td>
                                                                                                                            <td className="selectecommercetd">
                                                                                                                                <select className="form-control" name={item.id} id={"selecteCommerceMethod"+item.id} onChange={this.validAddclassnew}>
                                                                                                                                    <option value="">Select eCommerce Method</option>
                                                                                                                                    <option value="othershippingeCommValue"> Enter Custom eCommerce Method </option>
                                                                                                                                    {optionErpType}
                                                                                                                                </select>
                                                                                                                                <input type="text" placeholder="Enter Custom eCommerce Method" className="form-control version-field erpFieldnew inputpr28 d-none" id={"othershippingeCommerceValue"+item.id} name={item.id} onChange={this.othervalueChange} />
                                                                                                                                <a id={"cancelOthershippingeCommerce"+item.id} name={item.id} className="cancelAdding text-danger d-none" onClick={this.cancelCustomOption}> </a>
                                                                                                                            </td>
                                                                                                                            <td className="erptoecomcss">
                                                                                                                                <div className="textcenter coloumncss" id="erp-ecom">
                                                                                                                                    <div className="custom-control custom-checkbox mt-2">
                                                                                                                                        <input type="checkbox" className="custom-control-input" id={"newerptoecom"+item.id} />
                                                                                                                                        <label className="custom-control-label" for={"newerptoecom"+item.id}>ERP-ECom</label>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td className="ecomtoerpcss">
                                                                                                                                <div className="textcenter coloumncss" id="ecom-erp">
                                                                                                                                    <div className="custom-control custom-checkbox mt-2">
                                                                                                                                        <input type="checkbox" className="custom-control-input" id={"newecomtoerp"+item.id} />
                                                                                                                                        <label className="custom-control-label" for={"newecomtoerp"+item.id}>ECom-ERP</label>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td className="deletemethodcss">
                                                                                                                                <div className="text-center coloumncss addmappingicn">
                                                                                                                                    <button type="button" className="addMapping" id={item.id} onClick={this.createShippingMappingRow}>
                                                                                                                                        <i className="uil-plus-circle" id={item.id}></i>
                                                                                                                                    </button>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    </table>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className="row overflowMapping">
                                                                                                            <div className="col-lg-12 pr-0">
                                                                                                                <div className="table-responsive">
                                                                                                                    <table id={"mappingTablenew"+item.id} className="table table-sm m-0 defaultmappingTable">
                                                                                                                        <thead className="d-none">
                                                                                                                            <tr>
                                                                                                                                <th>erpMethod</th>
                                                                                                                                <th>ecommerceMethod</th>
                                                                                                                                <th>isEcommerceDefault</th>
                                                                                                                                <th>isErpDefault</th>
                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody id={item.id}>
                                                                                                                            <tr className="d-none"><td></td><td></td><td></td><td></td></tr>
                                                                                                                            {Mappingdata}
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                    <table id={"mappingTablenewfnnew"+item.id} className="table table-sm m-0 d-none">
                                                                                                                        <thead className="d-none">
                                                                                                                            <tr>
                                                                                                                                <th>erpMethod</th>
                                                                                                                                <th>ecommerceMethod</th>
                                                                                                                                <th>isEcommerceDefault</th>
                                                                                                                                <th>isErpDefault</th>
                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody id={item.id}>
                                                                                                                            {Mappingdata1}
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="submitButtonnew">
                                                                                                            <button type="button" id={"defaultsaveAllShipmentMappings"+item.id} name={item.id} className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector new-connector-position newwaybtn submitmappingNewsubmit" disabled> Submit Mappings </button>
                                                                                                            <button type="button" id={"saveAllShipmentMappings"+item.id} name={item.id} onClick={this.handleclickmethodnew} className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector new-connector-position newwaybtn submitmappingNewsubmit d-none"> Submit Mappings </button>
                                                                                                        </div>
                                                                                                    </form>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                : null
                                                                            }
                                                                            {
                                                                                item.instances[0].isPaymentMethodMappingEnable ? 
                                                                                    <div className="tab-pane fade" id={"v-pills-paymentmapping"+item.id} role="tabpanel" aria-labelledby="v-pills-paymentmapping-tab">
                                                                                        <div className="card mndesc">
                                                                                            <div className="card-header">
                                                                                                Payment Method Mapping
                                                                                        </div>

                                                                                            <div className="card-body mapping-tab-sec">
                                                                                                <div className="mt-0">
                                                                                                    <form className="">
                                                                                                        <div className="row">
                                                                                                            <div className="col-lg-12 pr-0">
                                                                                                                <div className="table-responsive">
                                                                                                                    <table className="table table-sm m-0 paymentMappingtable">
                                                                                                                        <tr>
                                                                                                                            <td className="selecterptd">
                                                                                                                                <select className="form-control" id={"paymentselectErpMethod"+item.id} name={item.id} onChange={this.validAddclassnew}>
                                                                                                                                    <option value=""> Select Erp Method</option>
                                                                                                                                    <option value="otherpaymentERPValue"> Enter Custom ERP Method </option>
                                                                                                                                    {paymentoptionecommercetype}
                                                                                                                                </select>
                                                                                                                                <input type="text" placeholder="Enter Custom ERP Method" className="form-control version-field erpFieldnew inputpr28 d-none" id={"otherpaymentERPValue"+item.id} name={item.id} onChange={this.othervalueChange} />
                                                                                                                                <a id={"cancelOtherpaymentErp"+item.id} name={item.id} className="cancelAdding text-danger d-none" onClick={this.cancelCustomOption}> </a>
                                                                                                                            </td>
                                                                                                                            <td className="selectecommercetd">
                                                                                                                                <select className="form-control" id={"paymentselecteCommerceMethod"+item.id}  name={item.id} onChange={this.validAddclassnew}>
                                                                                                                                    <option value="">Select eCommerce Method</option>
                                                                                                                                    <option value="otherpaymenteCommValue"> Enter Custom eCommerce Method </option>
                                                                                                                                    {paymentoptionErpType}
                                                                                                                                </select>
                                                                                                                                <input type="text" placeholder="Enter Custom eCommerce Method" className="form-control version-field erpFieldnew inputpr28 d-none" id={"otherpaymenteCommerceValue"+item.id} name={item.id} onChange={this.othervalueChange} />
                                                                                                                                <a id={"cancelOtherpaymentCommerce"+item.id} name={item.id} className="cancelAdding text-danger d-none" onClick={this.cancelCustomOption}> </a>
                                                                                                                            </td>
                                                                                                                            <td className="erptoecomcss">
                                                                                                                                <div className="textcenter mxwidth100 coloumncss" id="paymenterp-ecom">
                                                                                                                                    <div className="custom-control custom-checkbox mt-2">
                                                                                                                                        <input type="checkbox" className="custom-control-input" id={"paymentnewerptoecom"+item.id} />
                                                                                                                                        <label className="custom-control-label" for={"paymentnewerptoecom"+item.id}>ERP-ECom</label>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td className="ecomtoerpcss">
                                                                                                                                <div className="textcenter mxwidth100 coloumncss" id="paymentecom-erp">
                                                                                                                                    <div className="custom-control custom-checkbox mt-2">
                                                                                                                                        <input type="checkbox" className="custom-control-input" id={"paymentnewecomtoerp"+item.id} />
                                                                                                                                        <label className="custom-control-label" for={"paymentnewecomtoerp"+item.id}>ECom-ERP</label>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td className="deletemethodcss">
                                                                                                                                <div className="text-center coloumncss addmappingicn">
                                                                                                                                    <button type="button" className="addMapping" id={item.id} onClick={this.createPaymentMappingRow}>
                                                                                                                                        <i className="uil-plus-circle" id={item.id}></i>
                                                                                                                                    </button>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    </table>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="row overflowMapping">
                                                                                                            <div className="col-lg-12 pr-0">
                                                                                                                <div className="table-responsive">
                                                                                                                    <table id={"paymentmappingTablenew"+item.id} className="table table-sm m-0 defaultmappingTable">
                                                                                                                        <thead className="d-none">
                                                                                                                            <tr>
                                                                                                                                <th>erpMethod</th>
                                                                                                                                <th>ecommerceMethod</th>
                                                                                                                                <th>isEcommerceDefault</th>
                                                                                                                                <th>isErpDefault</th>
                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody id={item.id}>
                                                                                                                            <tr className="d-none"><td></td><td></td><td></td><td></td></tr>
                                                                                                                            {paymentMappingdata}
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                    <table id={"paymentmappingTablenewfnnew"+item.id} className="table table-sm m-0 d-none">
                                                                                                                        <thead className="d-none">
                                                                                                                            <tr>
                                                                                                                                <th>erpMethod</th>
                                                                                                                                <th>ecommerceMethod</th>
                                                                                                                                <th>isEcommerceDefault</th>
                                                                                                                                <th>isErpDefault</th>
                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody id={item.id}>
                                                                                                                            {paymentMappingdata1}
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>


                                                                                                        <div className="submitButtonnew">
                                                                                                            <button type="button" id={"defaultsaveAllPaymentMappings"+item.id} name={item.id} className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector new-connector-position newwaybtn submitmappingNewsubmit" disabled> Submit Mappings </button>
                                                                                                            <button type="button" id={"saveAllPaymentMappings"+item.id} name={item.id} onClick={this.paymenthandleclickmethodnew} className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector new-connector-position newwaybtn submitmappingNewsubmit d-none"> Submit Mappings </button>
                                                                                                        </div>
                                                                                                    </form>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                : null
                                                                            }
                                                                            {
                                                                                item.instances[0].isProductAttributeMappingEnable ? 
                                                                                    <div className="tab-pane fade attributeMappingtablecustom d-none" id={"v-pills-attributemapping"+item.id} role="tabpanel" aria-labelledby="v-pills-attributemapping-tab">
                                                                                        <div className="card mndesc">
                                                                                            <div id={"mappingLoaderContainer"+item.id} className="loaderContainer d-none">
                                                                                                <div className="loader"></div>
                                                                                            </div>
                                                                                            <div className="card-header attribuet_mapping">
                                                                                                <div className="titleNewcard d-none">
                                                                                                    Product Attribute Method Mapping
                                                                                                </div>
                                                                                                <div className="row customToggleButtonContainer">
                                                                                                    <div id={"sourcetotargetNV"+item.id} className="float-left sourcetotargetNV activeNewway">
                                                                                                        <div className="sourceTitlenew text-center pr-1">{item.source.shortName}</div>
                                                                                                        <div className="sourceTitlenew oneWayArrowImg"><img src={oneWayArrow} /></div>
                                                                                                        <div className="targetTitlenew text-center pl-1">{item.target.shortName}</div>
                                                                                                    </div>

                                                                                                    <div class="switchToggle form-check form-switch form-switch-md" id={item.id}>
                                                                                                        <label class="switch" id={item.id}>
                                                                                                            <input type="checkbox" id={item.id} onClick={this.open}/>
                                                                                                            <span class="slider round" id={item.id}></span>
                                                                                                        </label>
                                                                                                    </div>

                                                                                                    <div id={"targettosourceNV"+item.id} className="float-left targettosourceNV">
                                                                                                        <div className="sourceTitlenew text-center pr-1">{item.target.shortName}</div>
                                                                                                        <div className="sourceTitlenew oneWayArrowImg"><img src={oneWayArrow} /></div>
                                                                                                        <div className="targetTitlenew text-center pl-1">{item.source.shortName}</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="card-body mapping-tab-sec pt-2 overflowYH">
                                                                                                <div className="mt-0">
                                                                                                    <form id={"mainForm"+item.id} className="">
                                                                                                        {
                                                                                                            this.state.sourcetoTarget &&
                                                                                                            <>
                                                                                                            <div className="row">
                                                                                                                <div className="col-lg-12 pr-0">
                                                                                                                    <div className="card no-boxshadow no-border p-0 mb-0">
                                                                                                                        <div className="card-body no-boxshadow no-border  p-0">
                                                                                                                            <div className="">
                                                                                                                                <table id="productAttributeMainHeader" class="productAttributemain table table-borderless mb-0">
                                                                                                                                    <thead className="table-light">
                                                                                                                                        <tr>
                                                                                                                                            <th>ERP Method</th>
                                                                                                                                            <th>eComm Method</th>
                                                                                                                                            <th className="text-center">canUpdate</th>
                                                                                                                                            <th className="text-center">Action</th>
                                                                                                                                        </tr>
                                                                                                                                    </thead>
                                                                                                                                    <tbody id={item.id}>
                                                                                                                                        <tr>
                                                                                                                                            <td>
                                                                                                                                                <Select 
                                                                                                                                                    id={"attributeMappingSTTselectERPmethodNew"+item.id}
                                                                                                                                                    placeholder= "Select ERP Method"
                                                                                                                                                    closeMenuOnSelect={true}                                                                                                                                            
                                                                                                                                                    options={productAttribuetsourceMethodsNewlydded}
                                                                                                                                                    value={this.state.value}
                                                                                                                                                    onChange={this.productattributeSourcechange}
                                                                                                                                                    maxMenuHeight={215}
                                                                                                                                                    name={item.id}
                                                                                                                                                />
                                                                                                                                                <input type="text" placeholder="Enter Custom ERP Method" className="form-control version-field erpFieldnew inputpr28 d-none" id={"productAttributemappingSTTERP"+item.id} name={item.id} onChange={this.othervalueChange} />
                                                                                                                                                <a id={"cancelOtherpaymentErp"+item.id} name={item.id} className="cancelAdding text-danger d-none" onClick={this.cancelCustomOption}> </a>
                                                                                                                                            </td>
                                                                                                                                            
                                                                                                                                            <td>
                                                                                                                                                <Select
                                                                                                                                                    id={"newfinerect"+item.id}
                                                                                                                                                    placeholder= "Select eCommerce Methods"
                                                                                                                                                    closeMenuOnSelect={false}
                                                                                                                                                    isMulti
                                                                                                                                                    options={productAttribuettargetMethodsNewlydded}
                                                                                                                                                    onChange={this.productattributeTargetchange}
                                                                                                                                                    maxMenuHeight={215}
                                                                                                                                                />
                                                                                                                                            </td>
                                                                                                                                            <td className="text-center">
                                                                                                                                                <div className="custom-control custom-checkbox mt-2">
                                                                                                                                                    <input type="checkbox" className="custom-control-input" id={"canupdateSTT"+item.id} onClick={this.productattributeSTTcanUpdate} />
                                                                                                                                                    <label className="custom-control-label" for={"canupdateSTT"+item.id}></label>
                                                                                                                                                </div>
                                                                                                                                            </td>
                                                                                                                                            <td className="text-center">
                                                                                                                                                <button type="button" className="addMapping" id={item.id}  onClick={this.addNewProductAttributeMethod}>
                                                                                                                                                    <i className="uil-plus-circle" id={item.id}></i>
                                                                                                                                                </button>
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                    </tbody>
                                                                                                                                </table> 
                                                                                                                                <div className="overflowMapping mxheight">
                                                                                                                                    <div id={"errorHandlingNew"+item.id} className="errorHandlingNew alert alert-warning errorHandlingNew d-none">
                                                                                                                                    </div>
                                                                                                                                    <div className="table-responsive">
                                                                                                                                        <table id={"otherTable"+item.id} class="productAttributemain otherTable table table-borderless table-striped mb-0">
                                                                                                                                            <thead className="table-light d-none">
                                                                                                                                                <tr>
                                                                                                                                                    <th>ERP Method</th>
                                                                                                                                                    <th>eComm Method</th>
                                                                                                                                                    <th className="text-center">canUpdate</th>
                                                                                                                                                    <th className="text-center">Action</th>
                                                                                                                                                </tr>
                                                                                                                                            </thead>
                                                                                                                                            <tbody id={item.id}>
                                                                                                                                                {
                                                                                                                                                    productAttribuetsourceMappedSchema && productAttribuetsourceMappedSchema.map(item =>
                                                                                                                                                        <tr>
                                                                                                                                                            <td>{item.erpAttributeName}</td>
                                                                                                                                                            <td>{item.ecommerceAttributeName}</td>
                                                                                                                                                            <td className="text-center">
                                                                                                                                                                {
                                                                                                                                                                    item.canUpdate === true ? 
                                                                                                                                                                    <div className="custom-control custom-checkbox mt-2">
                                                                                                                                                                        <input type="checkbox" className="custom-control-input" defaultChecked id={"existedcanupdateSTT"+item.id} onClick={this.productattributeSTTcanUpdate} disabled />
                                                                                                                                                                        <label className="custom-control-label" for={"canupdateSTT"+item.id}></label>
                                                                                                                                                                    </div>
                                                                                                                                                                    : 
                                                                                                                                                                    <div className="custom-control custom-checkbox mt-2">
                                                                                                                                                                        <input type="checkbox" className="custom-control-input" id={"existedcanupdateSTT"+item.id} onClick={this.productattributeSTTcanUpdate} disabled />
                                                                                                                                                                        <label className="custom-control-label" for={"canupdateSTT"+item.id}></label>
                                                                                                                                                                    </div>
                                                                                                                                                                }
                                                                                                                                                            </td>
                                                                                                                                                            <td className="text-center">
                                                                                                                                                                {
                                                                                                                                                                    (item.erpIsRequired === true) || (item.ecommerceIsRequired === true) || (item.canDelete === false) ?
                                                                                                                                                                    <button type="button" class="deleteProductAttributeMapping" disabled={"true"}>
                                                                                                                                                                        <span ></span>
                                                                                                                                                                        <i class="uil-trash-alt"></i>
                                                                                                                                                                    </button>:
                                                                                                                                                                    <button type="button" class="deleteProductAttributeMapping stfy1" onClick={this.deleteExistingMethodSourcetotarget}>
                                                                                                                                                                        <span ></span>
                                                                                                                                                                        <i class="uil-trash-alt"></i>
                                                                                                                                                                    </button>
                                                                                                                                                                }
                                                                                                                                                            </td>
                                                                                                                                                        </tr>
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                            </tbody>
                                                                                                                                        </table>

                                                                                                                                        <tabel id={"mainTableway"+item.id} className="d-none">
                                                                                                                                            <thead>
                                                                                                                                                <tr>
                                                                                                                                                    <td> ERP Method </td>
                                                                                                                                                    <td> eCommerce Method </td>
                                                                                                                                                    <td> canUpdate </td>
                                                                                                                                                    <td> Action </td>
                                                                                                                                                </tr>
                                                                                                                                            </thead>
                                                                                                                                            <tbody>
                                                                                                                                                {
                                                                                                                                                    selectedOptionsTarget.map((o,index) =>
                                                                                                                                                        <tr>
                                                                                                                                                            <td> </td>
                                                                                                                                                            <td>{o.label}</td>
                                                                                                                                                            <td className="text-center">
                                                                                                                                                                <div className="custom-control custom-checkbox mt-2">
                                                                                                                                                                    {
                                                                                                                                                                        this.state.canUpdate ? 
                                                                                                                                                                        <>
                                                                                                                                                                            <input type="checkbox" className="custom-control-input" defaultChecked id={"existedcanupdateSTT"+item.id} onClick={this.productattributeSTTcanUpdate} disabled />
                                                                                                                                                                            <label className="custom-control-label" for={"canupdateSTT"+item.id}></label>
                                                                                                                                                                        </> : 
                                                                                                                                                                        <>
                                                                                                                                                                            <input type="checkbox" className="custom-control-input" id={"existedcanupdateSTT"+item.id} disabled />
                                                                                                                                                                            <label className="custom-control-label" for={"canupdateSTT"+item.id}></label>
                                                                                                                                                                        </>
                                                                                                                                                                    }
                                                                                                                                                                </div>
                                                                                                                                                            </td>
                                                                                                                                                            <td className="deletefinal text-center">
                                                                                                                                                                <button type="button" class="deleteMapping deleteProductAttributeMappingNewlyAdded"  onClick={this.deleteExistingMethodSourcetotarget}>
                                                                                                                                                                    <span></span>
                                                                                                                                                                    <i class="uil-trash-alt"></i>
                                                                                                                                                                </button>
                                                                                                                                                            </td>
                                                                                                                                                        </tr>
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                            </tbody>
                                                                                                                                        </tabel>
                                                                                                                                        
                                                                                                                                        <tabel id={"mainTablewayMain"+item.id} className="d-none">
                                                                                                                                            <thead>
                                                                                                                                                <tr>
                                                                                                                                                    <th>erpAttributeName</th>
                                                                                                                                                    <th>erpAttributeDataType</th>
                                                                                                                                                    <th>erpAttributeSize</th>
                                                                                                                                                    <th>ecommerceAttributeName</th>
                                                                                                                                                    <th>ecommerceAttributeDataType</th>
                                                                                                                                                    <th>ecommerceAttributeSize</th>
                                                                                                                                                    <th>ecommerceAttributeType</th>
                                                                                                                                                    <th>canUpdate</th>
                                                                                                                                                    <th>canDelete</th>
                                                                                                                                                    <th>erpIsRequired</th>
                                                                                                                                                    <th>ecommerceIsRequired</th>
                                                                                                                                                    <th> Action </th>
                                                                                                                                                </tr>
                                                                                                                                            </thead>
                                                                                                                                            <tbody>
                                                                                                                                                {
                                                                                                                                                    selectedOptionsTarget.map((o,index) =>
                                                                                                                                                        <tr id={"productAttributeNewRowAdded"+index}>
                                                                                                                                                            <td></td>
                                                                                                                                                            <td></td>
                                                                                                                                                            <td></td>
                                                                                                                                                            <td>{o.value}</td>
                                                                                                                                                            <td></td>
                                                                                                                                                            <td></td>
                                                                                                                                                            <td></td>
                                                                                                                                                            <td></td>
                                                                                                                                                            <td class="">5852</td>
                                                                                                                                                            <td></td>
                                                                                                                                                            <td></td>
                                                                                                                                                            <td className="deletefinal text-center">
                                                                                                                                                                <button type="button" class="deleteMapping deleteNewlyAddedrow">
                                                                                                                                                                    <span></span>
                                                                                                                                                                    <i class="uil-trash-alt"></i>
                                                                                                                                                                </button>
                                                                                                                                                            </td>
                                                                                                                                                        </tr>
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                            </tbody>
                                                                                                                                        </tabel>
                                                                                                                                        
                                                                                                                                        <table id={"productAttributeMainAppend"+item.id} className="table table-sm m-0 d-none">
                                                                                                                                            <thead className="d-none">
                                                                                                                                                <tr>
                                                                                                                                                    <th>erpAttributeName</th>
                                                                                                                                                    <th>erpAttributeDataType</th>
                                                                                                                                                    <th>erpAttributeSize</th>
                                                                                                                                                    <th>ecommerceAttributeName</th>
                                                                                                                                                    <th>ecommerceAttributeDataType</th>
                                                                                                                                                    <th>ecommerceAttributeSize</th>
                                                                                                                                                    <th>ecommerceAttributeType</th>
                                                                                                                                                    <th>canUpdate</th>
                                                                                                                                                    <th>canDelete</th>
                                                                                                                                                    <th>erpIsRequired</th>
                                                                                                                                                    <th>ecommerceIsRequired</th>
                                                                                                                                                </tr>
                                                                                                                                            </thead>
                                                                                                                                            <tbody>
                                                                                                                                                {
                                                                                                                                                    productAttribuetsourceMappedSchema && productAttribuetsourceMappedSchema.map((item, index) =>
                                                                                                                                                        <tr id={"productAttribute"+index}>
                                                                                                                                                            <td>{item.erpAttributeName}</td>
                                                                                                                                                            <td>{item.erpAttributeDataType}</td>
                                                                                                                                                            <td>{item.erpAttributeSize}</td>
                                                                                                                                                            <td>{item.ecommerceAttributeName}</td>
                                                                                                                                                            <td>{item.ecommerceAttributeDataType}</td>
                                                                                                                                                            <td>{item.ecommerceAttributeSize}</td>
                                                                                                                                                            <td>{item.ecommerceAttributeType}</td>
                                                                                                                                                            <td>{item.canUpdate === true ? "true" : "false"}</td>
                                                                                                                                                            <td>{item.canDelete === true ? "true" : "false"}</td>
                                                                                                                                                            <td>{item.erpIsRequired === true ? "true" : "false"}</td>
                                                                                                                                                            <td>{item.ecommerceIsRequired === true ? "true" : "false"}</td>
                                                                                                                                                        </tr>
                                                                                                                                                    )
                                                                                                                                                }                                                                                                                                
                                                                                                                                            </tbody>
                                                                                                                                        </table>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                                                                                                                            
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            
                                                                                                            <div className="submitButtonnew">
                                                                                                                <button type="button" id={"disabledButton"+item.id} className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector new-connector-position newwaybtn submitmappingNewsubmit" disabled> Submit Mappings </button>
                                                                                                                <button type="button" id={"saveAllattributeMappings"+item.id} name={item.id} onClick={this.productattributesaveallSource} className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector new-connector-position newwaybtn submitmappingNewsubmit d-none"> Submit Mappings </button>
                                                                                                            </div>
                                                                                                            
                                                                                                        </>
                                                                                                        }
                                                                                                        {
                                                                                                            this.state.targettoSource &&
                                                                                                            <>
                                                                                                            <div className="row">
                                                                                                                <div className="col-lg-12 pr-0">
                                                                                                                    <div className="card no-boxshadow no-border p-0 mb-0">
                                                                                                                        <div className="card-body no-boxshadow no-border  p-0">
                                                                                                                            <div className="">
                                                                                                                                <table id="productAttributeMainHeader" class="productAttributemain table table-borderless mb-0">
                                                                                                                                    <thead className="table-light">
                                                                                                                                        <tr>
                                                                                                                                            <th>eCommerce Method</th>
                                                                                                                                            <th>ERP Methods</th>
                                                                                                                                            <th>canUpdate</th>
                                                                                                                                            <th className="text-center">Action</th>
                                                                                                                                        </tr>
                                                                                                                                    </thead>
                                                                                                                                    <tbody>
                                                                                                                                        <tr>
                                                                                                                                            <td>
                                                                                                                                                <Select 
                                                                                                                                                    id={"attributeMappingTTSselectERPmethodNew"+item.id}
                                                                                                                                                    placeholder= "Select eCommerce Method"
                                                                                                                                                    closeMenuOnSelect={true}                                                                                                                                            
                                                                                                                                                    options={productAttribuettargetMethodsNewlydded}
                                                                                                                                                    value={this.state.value}
                                                                                                                                                    onChange={this.productattributeSourcechangereverse}
                                                                                                                                                    maxMenuHeight={215}
                                                                                                                                                />
                                                                                                                                            </td>
                                                                                                                                            <td>
                                                                                                                                                <Select
                                                                                                                                                    id={"newfinerectTTS"+item.id}
                                                                                                                                                    placeholder= "Select ERP Methods"
                                                                                                                                                    closeMenuOnSelect={false}                                                                                                                                            
                                                                                                                                                    isMulti
                                                                                                                                                    options={productAttribuetsourceMethodsNewlydded}
                                                                                                                                                    onChange={this.productattributeTargetchangereverse}
                                                                                                                                                    maxMenuHeight={215}
                                                                                                                                                />
                                                                                                                                            </td>
                                                                                                                                            <td className="text-center">
                                                                                                                                                <div className="custom-control custom-checkbox mt-2">
                                                                                                                                                    <input type="checkbox" className="custom-control-input" id={"canupdateTTS"+item.id} onClick={this.productattributeTTScanUpdate} />
                                                                                                                                                    <label className="custom-control-label" for={"canupdateTTS"+item.id}></label>
                                                                                                                                                </div>
                                                                                                                                            </td>
                                                                                                                                            <td className="text-center">
                                                                                                                                                <button type="button" className="addMapping" id={item.id}  onClick={this.addNewProductAttributeMethodTargettoSource}>
                                                                                                                                                    <i className="uil-plus-circle" id={item.id}></i>
                                                                                                                                                </button>
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                    </tbody>
                                                                                                                                </table> 
                                                                                                                                <div className="overflowMapping mxheight">
                                                                                                                                    <div id={"errorHandlingNewTTS"+item.id} className="errorHandlingNew alert alert-warning errorHandlingNew d-none">
                                                                                                                                    </div>
                                                                                                                                    <div className="table-responsive">
                                                                                                                                        <table id={"otherTableNewtargettoSource"+item.id} class="otherTableNew productAttributemain table table-borderless table-striped mb-0">
                                                                                                                                            <thead className="table-light d-none">
                                                                                                                                                <tr>
                                                                                                                                                    <th>eComm Method</th>
                                                                                                                                                    <th>ERP Method</th>
                                                                                                                                                    <th>canUpdate</th>
                                                                                                                                                    <th className="text-center">Action</th>
                                                                                                                                                </tr>
                                                                                                                                            </thead>
                                                                                                                                            <tbody id={item.id}>
                                                                                                                                                {
                                                                                                                                                    productAttribuettargetMappedSchema && productAttribuettargetMappedSchema.map(item =>
                                                                                                                                                        <tr>
                                                                                                                                                            <td>{item.ecommerceAttributeName}</td>
                                                                                                                                                            <td>{item.erpAttributeName}</td>
                                                                                                                                                            <td className="text-center">
                                                                                                                                                                {
                                                                                                                                                                    item.canUpdate === true ? 
                                                                                                                                                                    <div className="custom-control custom-checkbox mt-2">
                                                                                                                                                                        <input type="checkbox" className="custom-control-input" defaultChecked id={"existedcanupdateTTS"+item.id} disabled />
                                                                                                                                                                        <label className="custom-control-label" for={"canupdateTTS"+item.id}></label>
                                                                                                                                                                    </div>
                                                                                                                                                                    : 
                                                                                                                                                                    <div className="custom-control custom-checkbox mt-2">
                                                                                                                                                                        <input type="checkbox" className="custom-control-input" id={"existedcanupdateTTS"+item.id} disabled />
                                                                                                                                                                        <label className="custom-control-label" for={"canupdateTTS"+item.id}></label>
                                                                                                                                                                    </div>
                                                                                                                                                                }
                                                                                                                                                            </td>
                                                                                                                                                            <td className="text-center"> 
                                                                                                                                                                {
                                                                                                                                                                    (item.erpIsRequired === true) || (item.ecommerceIsRequired === true) || (item.canDelete === false) ?
                                                                                                                                                                    <button type="button" class="deleteProductAttributeMapping targettosourceDeleterow" disabled={"true"}>
                                                                                                                                                                        <span ></span>
                                                                                                                                                                        <i class="uil-trash-alt"></i>
                                                                                                                                                                    </button>:
                                                                                                                                                                    <button type="button" class="deleteProductAttributeMapping targettosourceDeleterow" onClick={this.deleteExistingMethodTargettosource}>
                                                                                                                                                                        <span ></span>
                                                                                                                                                                        <i class="uil-trash-alt"></i>
                                                                                                                                                                    </button>
                                                                                                                                                                }
                                                                                                                                                            </td>
                                                                                                                                                        </tr>
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                            </tbody>
                                                                                                                                        </table>

                                                                                                                                        <tabel id={"mainTablewayNewtargettoSource"+item.id} className="d-none">
                                                                                                                                            <thead>
                                                                                                                                                <tr>
                                                                                                                                                    <td> ERP Method </td>
                                                                                                                                                    <td> eCommerce Method </td>
                                                                                                                                                    <th>canUpdate</th>
                                                                                                                                                    <td> Action </td>
                                                                                                                                                </tr>
                                                                                                                                            </thead>
                                                                                                                                            <tbody>
                                                                                                                                                {
                                                                                                                                                    selectedOptionTargetreverseValue.map((o,index) =>
                                                                                                                                                        <tr>
                                                                                                                                                            <td></td>
                                                                                                                                                            <td>{o.label}</td>
                                                                                                                                                            <td className="text-center">
                                                                                                                                                                <div className="custom-control custom-checkbox mt-2">
                                                                                                                                                                    {
                                                                                                                                                                        this.state.canUpdateTTS ? 
                                                                                                                                                                        <>
                                                                                                                                                                            <input type="checkbox" className="custom-control-input" defaultChecked id={"existedcanupdateTTS"+item.id} onClick={this.productattributeSTTcanUpdate} disabled />
                                                                                                                                                                            <label className="custom-control-label" for={"existedcanupdateTTS"+item.id}></label>
                                                                                                                                                                        </> : 
                                                                                                                                                                        <>
                                                                                                                                                                            <input type="checkbox" className="custom-control-input" id={"existedcanupdateTTS"+item.id} disabled />
                                                                                                                                                                            <label className="custom-control-label" for={"existedcanupdateTTS"+item.id}></label>
                                                                                                                                                                        </>
                                                                                                                                                                    }
                                                                                                                                                                </div>
                                                                                                                                                            </td>
                                                                                                                                                            <td className="deletefinal text-center">
                                                                                                                                                                <button type="button" class="deleteMapping targettosourceDeleterow deleteProductAttributeMappingNewlyAddedTTS">
                                                                                                                                                                    <span></span>
                                                                                                                                                                    <i class="uil-trash-alt"></i>
                                                                                                                                                                </button>
                                                                                                                                                            </td>
                                                                                                                                                        </tr>
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                            </tbody>
                                                                                                                                        </tabel>
                                                                                                                                        <tabel id={"mainTablewayMainNewtargettoSource"+item.id} className="d-none">
                                                                                                                                            <thead>
                                                                                                                                                <tr>
                                                                                                                                                    <th>ecommerceAttributeName</th>
                                                                                                                                                    <th>ecommerceAttributeDataType</th>
                                                                                                                                                    <th>ecommerceAttributeSize</th>
                                                                                                                                                    <th>ecommerceAttributeType</th>
                                                                                                                                                    <th>erpAttributeName</th>
                                                                                                                                                    <th>erpAttributeDataType</th>
                                                                                                                                                    <th>erpAttributeSize</th>
                                                                                                                                                    <th>canUpdate</th>
                                                                                                                                                    <th>canDelete</th>
                                                                                                                                                    <th>erpIsRequired</th>
                                                                                                                                                    <th>ecommerceIsRequired</th>
                                                                                                                                                    <th> Action </th>
                                                                                                                                                </tr>
                                                                                                                                            </thead>
                                                                                                                                            <tbody>
                                                                                                                                                {
                                                                                                                                                    selectedOptionTargetreverseValue.map((o,index) =>
                                                                                                                                                        <tr id={"productAttributeNewRowAddedNew"+index}>
                                                                                                                                                            <td></td>
                                                                                                                                                            <td></td>
                                                                                                                                                            <td></td>
                                                                                                                                                            <td></td>
                                                                                                                                                            <td>{o.value}</td>
                                                                                                                                                            <td></td>
                                                                                                                                                            <td></td>
                                                                                                                                                            <td></td>
                                                                                                                                                            <td></td>
                                                                                                                                                            <td></td>
                                                                                                                                                            <td></td>
                                                                                                                                                            <td className="deletefinal text-center">
                                                                                                                                                                <button type="button" class="deleteMapping targettosourceDeleterow">
                                                                                                                                                                    <span></span>
                                                                                                                                                                    <i class="uil-trash-alt"></i>
                                                                                                                                                                </button>
                                                                                                                                                            </td>
                                                                                                                                                        </tr>
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                            </tbody>
                                                                                                                                        </tabel>
                                                                                                                                        <table id={"productAttributeMainAppendNewtargettoSource"+item.id} className="table table-sm m-0 d-none">
                                                                                                                                            <thead className="d-none">
                                                                                                                                                <tr>
                                                                                                                                                    <th>ecommerceAttributeName</th>
                                                                                                                                                    <th>ecommerceAttributeDataType</th>
                                                                                                                                                    <th>ecommerceAttributeSize</th>
                                                                                                                                                    <th>ecommerceAttributeType</th>
                                                                                                                                                    <th>erpAttributeName</th>
                                                                                                                                                    <th>erpAttributeDataType</th>
                                                                                                                                                    <th>erpAttributeSize</th>
                                                                                                                                                    <th>canUpdate</th>
                                                                                                                                                    <th>canDelete</th>
                                                                                                                                                    <th>erpIsRequired</th>
                                                                                                                                                    <th>ecommerceIsRequired</th>
                                                                                                                                                </tr>
                                                                                                                                            </thead>
                                                                                                                                            <tbody>
                                                                                                                                                {
                                                                                                                                                    productAttribuettargetMappedSchema && productAttribuettargetMappedSchema.map((item, index) =>
                                                                                                                                                        <tr id={"productAttributeNew"+index}>
                                                                                                                                                            <td>{item.ecommerceAttributeName}</td>
                                                                                                                                                            <td>{item.ecommerceAttributeDataType}</td>
                                                                                                                                                            <td>{item.ecommerceAttributeSize}</td>
                                                                                                                                                            <td>{item.ecommerceAttributeType}</td>
                                                                                                                                                            <td>{item.erpAttributeName}</td>
                                                                                                                                                            <td>{item.erpAttributeDataType}</td>
                                                                                                                                                            <td>{item.erpAttributeSize}</td>
                                                                                                                                                            <td>{item.canUpdate === true ? "true" : "false"}</td>
                                                                                                                                                            <td>{item.canDelete === true ? "true" : "false"}</td>
                                                                                                                                                            <td>{item.erpIsRequired === true ? "true" : "false"}</td>
                                                                                                                                                            <td>{item.ecommerceIsRequired === true ? "true" : "false"}</td>
                                                                                                                                                        </tr>
                                                                                                                                                    )
                                                                                                                                                }                                                                                                                                
                                                                                                                                            </tbody>
                                                                                                                                        </table>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                                                                                                                            
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div className="submitButtonnew">
                                                                                                                <button type="button" id={"disabledButtontargettosource"+item.id} className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector new-connector-position newwaybtn submitmappingNewsubmit" disabled> Submit Mappings </button>
                                                                                                                <button type="button" id={"saveAllattributeMappingstargettosource"+item.id} name={item.id} onClick={this.productattributesaveallTargettoSource} className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector new-connector-position newwaybtn submitmappingNewsubmit d-none"> Submit Mappings </button>
                                                                                                            </div>
                                                                                                            
                                                                                                        </>
                                                                                                        }
                                                                                                    </form>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        
                                                        
                                                            <div className="tab-pane" id={"profile" + item.id} role="tabpanel">
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="card mndesc height-387">
                                                                            <div className="card-header">
                                                                                Manage Entities
                                                                            </div>
                                                                            
                                                                            <div className="card-body mapping-tab-sec">
                                                                                        <div className="mt-0">
                                                                                            <form className="">
                                                                                                <div className="row">
                                                                                                    <div className="col-lg-12">
                                                                                                        <div className="mt-0 table-responsive overflowMapping mx-height300">
                                                                                                            <table className="table table-bordered table-striped mb-0 manageentitiestable">
                                                                                                                <thead>
                                                                                                                    <tr>
                                                                                                                        <th>Entity Name</th>
                                                                                                                        <th className="text-center">eCommerce to ERP</th>
                                                                                                                        <th className="text-center">ERP to eCommerce</th>
                                                                                                                    </tr>
                                                                                                                </thead>
                                                                                                                <tbody id={item.id}>
                                                                                                                    {
                                                                                                                        this.state.instancemanageentity && this.state.instancemanageentity.data && this.state.instancemanageentity.data.map((entityItem) =>
                                                                                                                        <tr>
                                                                                                                            <td> {entityItem.displayName} </td>
                                                                                                                            <td className="text-center"> 
                                                                                                                                <label class="switch">
                                                                                                                                    {
                                                                                                                                        (entityItem.isInboundEditable === true) && (entityItem.isInboundActive === true) ?
                                                                                                                                            <input type="checkbox" id={entityItem.isOutboundActive.toString()} name={entityItem.id} defaultChecked="checked" onClick={this.entityecommtoerpchangeUpdate} /> 
                                                                                                                                        : null
                                                                                                                                    }
                                                                                                                                    {
                                                                                                                                        (entityItem.isInboundEditable === true) && (entityItem.isInboundActive === false) ?
                                                                                                                                            <input type="checkbox" id={entityItem.isOutboundActive.toString()} name={entityItem.id} onClick={this.entityecommtoerpchangeUpdate} />
                                                                                                                                        : null
                                                                                                                                    }
                                                                                                                                    {
                                                                                                                                        (entityItem.isInboundEditable === false) && (entityItem.isInboundActive === true) ?
                                                                                                                                            <input type="checkbox" id={entityItem.isOutboundActive.toString()} name={entityItem.id} defaultChecked="checked" disabled/>
                                                                                                                                        : null
                                                                                                                                    }
                                                                                                                                    {
                                                                                                                                        (entityItem.isInboundEditable === false) && (entityItem.isInboundActive === false) ?
                                                                                                                                            <input type="checkbox" id={entityItem.isOutboundActive.toString()} name={entityItem.id} disabled/>
                                                                                                                                        : null
                                                                                                                                    }
                                                                                                                                    <span class="slider round"></span>
                                                                                                                                </label>
                                                                                                                            </td>
                                                                                                                            <td className="text-center"> 
                                                                                                                                {
                                                                                                                                    ((entityItem.isOutboundEditable === true) && (entityItem.isOutboundActive === true)) ?
                                                                                                                                    <label class="switch">
                                                                                                                                        <input type="checkbox" id={entityItem.isInboundActive.toString()} name={entityItem.id} onClick={this.entityerptoecommchangeUpdate} defaultChecked />
                                                                                                                                        <span class="slider round"></span>
                                                                                                                                    </label> : null
                                                                                                                                }

                                                                                                                                {
                                                                                                                                    ((entityItem.isOutboundEditable === true) && (entityItem.isOutboundActive === false)) ?
                                                                                                                                    <label class="switch">
                                                                                                                                        <input type="checkbox" id={entityItem.isInboundActive.toString()} name={entityItem.id} onClick={this.entityerptoecommchangeUpdate} />
                                                                                                                                        <span class="slider round"></span>
                                                                                                                                    </label> : null
                                                                                                                                }

                                                                                                                                {
                                                                                                                                    ((entityItem.isOutboundEditable === false) && (entityItem.isOutboundActive === true)) ?
                                                                                                                                    <label class="switch">
                                                                                                                                        <input type="checkbox" id={entityItem.isInboundActive.toString()} name={entityItem.id} defaultChecked="checked" disabled/>
                                                                                                                                        <span class="slider round"></span>
                                                                                                                                    </label> : null
                                                                                                                                }

                                                                                                                                {
                                                                                                                                    ((entityItem.isOutboundEditable === false) && (entityItem.isOutboundActive === false)) ?
                                                                                                                                    <label class="switch">
                                                                                                                                        <input type="checkbox" id={entityItem.isInboundActive.toString()} name={entityItem.id} disabled/>
                                                                                                                                        <span class="slider round"></span>
                                                                                                                                    </label> : null
                                                                                                                                }
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                        )
                                                                                                                    }
                                                                                                                    
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="submitButtonnew">
                                                                                                    {/* <button type="button" id={"saveAllEntities"+item.id} name={item.id} onClick={this.paymenthandleclickmethodnew} className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector new-connector-position newwaybtn submitmappingNewsubmit d-none"> Save Entities </button> */}
                                                                                                </div>
                                                                                            </form>
                                                                                        </div>
                                                                                    </div>
                                                                                
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <div id="connectorErrorWarnign" className="row d-none">
                                                    <div className="col-lg-12">
                                                        <h4 className="card-title mb-2 card-title-wth-border">
                                                            <span className="float-left"> My Products (0)</span>
                                                            <div className="clearfix"></div>
                                                        </h4>
                                                        <div className="alert alert-warning" role="alert">
                                                            You don't have any Products to show, Please click on New connector Button and Follow the process to create Connector.
                                                        </div>
                                                    </div>
                                                </div>
                                                <button id="connectorErrorWarnignButton" type="button" className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector new-connector-position" onClick={() => this.setState({ isPaneOpen: true })}>
                                                    + New Connector
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <SlidingPane className='connector-popup' overlayClassName='some-custom-overlay-class' isOpen={this.state.isPaneOpen} onRequestClose={() => { this.setState({ isPaneOpen: true }); }}>
                                <button className="popup-close" onClick={() => this.setState({ isPaneOpen: false })}>
                                    <img src={closeicon} />
                                </button>
                                <Newconnector />
                            </SlidingPane>
                            <SlidingPane className='connector-popup' overlayClassName='some-custom-overlay-class' isOpen={this.state.isPaneOpenDownload} onRequestClose={() => { this.setState({ isPaneOpenDownload: true }); }}>
                                <button className="popup-close" onClick={() => this.setState({ isPaneOpenDownload: false })}>
                                    <img src={closeicon} />
                                </button>
                                <ConnectorDownloader downloadData={this.state.datapoint.data} />
                            </SlidingPane>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Myconnectors


