import { useEffect, useState } from "react";
//import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// Material-UI imports

import Amplify, { Auth, Hub } from 'aws-amplify'

// Sample app imports
import Home1 from "./pages/Home";

import API from './api';

import { Route, BrowserRouter as Router, Switch, BrowserRouter, Redirect } from 'react-router-dom';

import Home from './Components/Home/Index'
import Users from './Components/Users/Users';
import Header from './Components/Shared/Header';
import SideBar from './Components/Shared/Sidebar';
import Contact from './Components/Contact/Contact'

import Login from './Components/Login/Login'

import Dashboard from './Components/Dashboard/Dashboard'
import Myconnectors from './Components/MyConnectors/Myconnectors'
import MyProducts from './Components/MyConnectors/MyProducts/MyProducts'

import Configure from './Components/Configure/Configure'
import Servicerequests from './Components/ServiceRequests/ServiceRequests'

import Marketplace from './Components/MarketPlace/MarketPlace'
import Viewsummary from './Components/MyConnectors/ViewSummary/ViewSummary'
import SummaryDetails from './Components/MyConnectors/ViewSummary/SummaryDetails/SummaryDetails'
import HelpDesk from './Components/HelpDesk/HelpDesk'
import KnowledgeBase from './Components/KnowledgeBase/KnowledgeBase'
import EgesetupDownload from './Components/EGE/EgeSetup/EgeSetupDownload/EgeSetupDownload'
import Profile from './Components/Profile/Profile'
import Welcome from './Components/Welcome/Welcome'
import GetWalkThrough from './Components/Getawalkthrough/Getawalkthrough'
import Updates from './Components/Updates/Updates'
import BigcommerceRegistration from './Components/BigCommerce/BigcommerceRegistration'
import Shopifyconfiguration from './Components/Shopify/ShopifyConfiguration/Shopifyconfiguration'
import Authentication from './Components/NewConnector/Setup/Authentication/Authentication';
import AuthenticationFailed from './Components/NewConnector/Setup/Authentication/AuthenticationFailed'
import DashboardEGESetup from './Components/EGE/EgeSetup/EgeSetupDownload/DashboardEGESetup'

/* Newly Added */
import EgeSetup from './Components/EGE/EgeSetup/EgeSetupDownload/Setup/EgeSetup'
import DownloadSetup from './Components/EGE/EgeSetup/EgeSetupDownload/Setup/DownloadSetup'
/* Newly Added */


import Setup from './Components/EGE/EgeSetup/EgeSetupDownload/Setup';
import Market from './Components/MarketPlace/MarketPlace.js'
import ShopifyAuth from "./Components/Shopify/ShopifyConfiguration/Shopify.js";
import QuickbooksAuth from "./Components/Shopify/ShopifyConfiguration/Quickbooks";
import DynamicTables from './Components/Eedittable/DynamicTable'
import Ege from './Components/EGE/Ege'



function App() {
    return (
        <Router>
            <Pages />
        </Router>
    );
}

function Pages() {

    Amplify.configure({
        Auth: {
            region: `${process.env.REACT_APP_AWS_REGION}`,
			userPoolId: `${process.env.REACT_APP_AWS_USERPOOLID}`,
			userPoolWebClientId: `${process.env.REACT_APP_AWS_CLIENTID}`,
			mandatorySignIn: true,
			redirectSignIn: window.location.protocol + "//" + window.location.host
        }
    });
    Auth.configure({
        oauth: {
            domain: `${process.env.REACT_APP_AWS_DOMAIN}.auth.${process.env.REACT_APP_AWS_REGION}.amazoncognito.com`,
            scope: [
                "email",
                "profile",
                "openid",
                "aws.cognito.signin.user.admin"
            ],
            redirectSignIn: window.location.protocol + "//" + window.location.host,
            redirectSignOut: window.location.protocol + "//" + window.location.host,
            responseType: "code"
        }
    });

    const [user, setUser] = useState(null);
    const [client, setClient] = useState(null);
    
    

    useEffect(() => {
        Hub.listen('auth', ({ payload: { event, data } }) => {
            switch (event) {
                case 'signIn':
                    getUser().then(userData => {
                        // console.log(event);
                        // console.log('signed in');
                        if (isFirstTime(userData)) {
                            // console.log('creating');
                            createCustomer(userData).then(() => {
                                refreshToken().then(() => {
                                    //console.log('token refreshed');
                                    setUser(data);
                                });
                            }).catch(() => {
                                //console.log('Error occurred');
                                Auth.signOut();
                            });
                        }
                    });

                    break;
                case 'signOut':
                    setUser(null);
                    window.localStorage.clear();
                    break;
                case 'signIn_failure':
                case 'cognitoHostedUI_failure':
                    //console.log('Sign in failure', data);
                    break;
            }
        });

        getUser().then(userData => {
            if (!isFirstTime(userData)) {
                setData(userData.signInUserSession);
                setUser(userData);
            }
        });
    }, []);

    function isFirstTime(userData) {
        if (!userData) {
            return true;
        }
        const groups = userData.signInUserSession.idToken.payload["cognito:groups"];
        window.localStorage.setItem('signInUserSessionidToken', userData.signInUserSession.idToken.jwtToken);
        if (groups && groups.length > 0) {
            return false;
        }
        return true;
    }

    function refreshToken() {
        try {
            return Auth.currentAuthenticatedUser().then(cognitoUser => {
                cognitoUser.refreshSession(cognitoUser.signInUserSession.refreshToken, (err, token) => {
                    setData(token);
                });
            });
        } catch (e) {
            //console.log('Unable to refresh Token', e);
        }
    }

    function setData(userData) {
        window.localStorage.setItem('accessToken', userData.accessToken.jwtToken);
        window.localStorage.setItem('userData', JSON.stringify(userData.idToken.payload));
    }

    function getUser() {
        return Auth.currentAuthenticatedUser()
            .then(userData => {
                return userData;
            })
            .catch(() => {
                //console.log('Not signed in');
                Auth.federatedSignIn();
            });
    }

    function createCustomer(userData) {
        const headers = {
            "authorization": `Bearer ${userData.signInUserSession.idToken.jwtToken}`,
            "content-type": "application/json"
        };

        const body = {};
        return API.post(`/Account/Client`, body, { headers })
        .then(response =>{
            setClient(response)
        })
    }
    return (
        // <Switch>
        //     <Route path="/">
        //         {user ? <Home /> : <div>...Loading</div>}
        //     </Route>
        // </Switch>
<div>
{user ? 
<Router >
        <Switch>
          <div>
            <Route exact path="/" 
              render={() => { 
                return ( 
                    client && client.data.isFirstLogin && client.data.result ?  <Redirect to="/Welcome" /> : <Redirect to="/Dashboard" />
                )
              }}
            />
            <Route path="/Helpdesk" component={HelpDesk} />
            <Route path="/Knowledgebase" component={KnowledgeBase} />
            <Route exact path="/" activeClassName="active" component={Dashboard} />
            <Route path="/users" component={Users} />
            <Route path="/contact" component={Contact} />
            <Route path="/Dashboard" component={Dashboard} />
            <Route path="/Myconnectors" component={Myconnectors} />
            <Route path="/MyProducts" component={MyProducts} />
            <Route path="/home" component={Home} />
            <Route path="/configure" component={Configure} />  
            <Route path="/Servicerequests" component={Servicerequests} />
            <Route exact path="/Marketplace" component={Market} />
            <Route path="/Ege" component={Ege} />
            <Route path="/Viewsummary" component={Viewsummary} />
            <Route path="/SummaryDetails" component={SummaryDetails} />
            <Route path="/Egesetupdownload" component={EgesetupDownload} />
            <Route path="/Profile" component={Profile} />
            <Route path="/Welcome" component={Welcome} />
            <Route path="/Getawalkthrough" component={GetWalkThrough} />
            <Route path="/Updates" component={Updates} />
            <Route path="/DynamicTables" component={DynamicTables} />
            <Route path="/BigcommerceRegistration" component={BigcommerceRegistration} />
            <Route path="/Shopifyconfiguration" component={Shopifyconfiguration} />
            <Route path="/Authentication" component={Authentication} />
            <Route path="/AuthenticationFailed" component={AuthenticationFailed} />
            <Route path="/DashboardEGESetup" component={DashboardEGESetup} /> 
            <Route path="/EgeSetup" component={EgeSetup} /> 
            <Route path="/DownloadSetup" component={DownloadSetup} /> 
            <Route path="/Setup" component={Setup} />
            <Route path="/ShopifyAuth/:Subscriptionid/:instanceType" component={ShopifyAuth}/>
            <Route path="/Auth/:Subscriptionid/:instanceType" component={QuickbooksAuth}/>

          </div>
        </Switch>
      </Router>
      : <div>...Loading</div>}
</div>



    )
}

export default App;
