import React from 'react';
import API from '../../../../../../../api';
import './shippingmappings.css'
import Addingform from './Addingform';
import DefaultMappings from './DefaultMappings';
import SubmitMappings from './SubmitMappings';

class ShippingMapping extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            businessEntityId : 0,
            selectedData : props.selectedConnectorInfo
        }
    }

    componentWillReceiveProps(props) {
        this.setState({
            selectedData : props.selectedConnectorInfo
        })

        let self = this;
        var response = window.localStorage.getItem("accessToken");
        let id = window.sessionStorage.getItem("connectorIndex")
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        const movies = props.selectedConnectorInfo;
        API.get(`/Connection/${movies.id}/instance/${movies.instances[0].id}/shipping-methods/source`, { headers })
            .then(response => response)
            .then(data => {
                self.setState({
                shippingMappingMethodsSource: data.data
            })
        })
        API.get(`/Connection/${movies.id}/instance/${movies.instances[0].id}/shipping-methods/target`, { headers })
            .then(response => response)
            .then(data => {
                self.setState({
                shippingMappingMethodsTarget: data.data
            })
        })
        API.get(`/Connection/${movies.id}/instance/${movies.instances[0].id}/shipping-mappings`, { headers })
            .then(response => response)
            .then(data => {
                self.setState({
                allshippingMappings: data
            })
        })
    }
    
    render(){
        let selectedConnectorInfo = this.props.selectedConnectorInfo
        let sourceMethods = this.state.shippingMappingMethodsSource
        let targetMethods = this.state.shippingMappingMethodsTarget
        let defaultMappings = this.state.allshippingMappings
        
        const sourceMethodsGet = (sourceMethods != null) ? Object.entries(sourceMethods).map(([key, value]) => ({
            value: value, label: value
        })) : null

        const targetMethodsGet = (targetMethods != null) ? Object.entries(targetMethods).map(([key, value]) => ({
            value: value, label: value
        })) : null

        return(
            <>
                <div className="card mndesc">
                    <div className="card-header">
                        Shipping Method Mapping
                        <SubmitMappings
                            id={this.props.id}
                            name={this.props.id}
                            selectedData={this.state.selectedData}
                        />
                    </div>
                    <div className="card-body mapping-tab-sec overflowScroll">
                        <div className="mt-0">
                            <Addingform 
                                id={this.props.id}  
                                sourceMethodsGet={sourceMethodsGet} 
                                targetMethodsGet={targetMethodsGet} 
                                defaultMappings={defaultMappings}
                            />
                            <DefaultMappings 
                                id={this.props.id}
                                sourceMethodsGet={sourceMethodsGet}
                                targetMethodsGet={targetMethodsGet}
                                defaultMappings={defaultMappings}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ShippingMapping