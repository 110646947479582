import React, { Component } from 'react';
import PropTypes from 'prop-types';

import API from '../api';

class ConnectorListing extends Component {
    // static propTypes = {
    //   activeTab: PropTypes.string.isRequired,
    //   label: PropTypes.string.isRequired,
    //   onClick: PropTypes.func.isRequired,
    // };



    onClick = () => {
      const { key, label, onClick,  } = this.props;
      onClick(label);
    }
  
    render() {
      const {
        onClick,
        props: {
          activeTab,
          label,
          key,
        },
      } = this;
  
      let className = 'tab-list-item nav-link';
  
      if (activeTab === label) {
        className += ' active';
      }

      console.log("New Active",key)

      let datapoint=this.state;
      return (
        <li className={className} onClick={onClick}>
            <a className="">
                Mine known
            </a>
        </li>
      );
    }
  }
  
  export default ConnectorListing;