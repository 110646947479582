import React from 'react';
import API from '../../../../../../../api';
import $ from 'jquery';
import './productattributemapping.css'

import SubmitMappings from './SourcetoTargetMapping/SubmitMappings';
import SourcetoTargetMapping from './SourcetoTargetMapping/SourcetoTargetMapping';
import TargettoSourceMapping from './TargettoSourceMapping/TargettoSourceMapping';
import oneWayArrow from '../../../../../../../../assets/images/oneWayArrow.png'

class ProductAttributeMapping extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sourcetoTarget: true,
            targettoSource: false,
            selectedData : props.selectedConnectorInfo
        }
        this.open = this.open.bind(this);
    }
    
    open(event) {
        const { sourcetoTarget, targettoSource } = this.state;
        if(event.target.checked){
            $("#targettosourceNV"+event.target.id).addClass("activeNewway")
            $("#sourcetotargetNV"+event.target.id).removeClass("activeNewway")
            $("#SourcetoTargetMappingVisible"+event.target.id).addClass("d-none")
            $("#TargettoSourceMappingVisible"+event.target.id).removeClass("d-none")
        }else{
            $("#targettosourceNV"+event.target.id).removeClass("activeNewway")
            $("#sourcetotargetNV"+event.target.id).addClass("activeNewway")            
            $("#SourcetoTargetMappingVisible"+event.target.id).removeClass("d-none")
            $("#TargettoSourceMappingVisible"+event.target.id).addClass("d-none")
        }
    }

    componentWillReceiveProps(props) {
        this.setState({
            selectedData : props.selectedConnectorInfo
        })
        document.getElementById("SourcetoTargetMappingVisible"+this.props.id).classList.remove("d-none")
    }
    
    render(){
        let selectedDataSourceShortname = this.state.selectedData.source && this.state.selectedData.source.shortName
        let selectedDataTargetShortname = this.state.selectedData.source && this.state.selectedData.target.shortName
        
        return(
            <>
                <div className="card mndesc attributeMappingtablecustom">
                    <div className="card-header attribuet_mapping">
                        <div className="row customToggleButtonContainer">
                            <div id={"sourcetotargetNV"+this.props.id} className="col-md-5 sourcetotargetNV activeNewway">
                                <div className="sourceTitlenew text-center pr-1">{selectedDataSourceShortname}</div>
                                <div className="sourceTitlenew oneWayArrowImg"><img src={oneWayArrow} /></div>
                                <div className="targetTitlenew text-center pl-1">{selectedDataTargetShortname}</div>
                            </div>
                            <div class="col-md-2 switchToggle form-check form-switch form-switch-md" id={this.props.id}>
                                <label class="switch" id={this.props.id}>
                                    <input type="checkbox" id={this.props.id} onClick={this.open}/>
                                    <span class="slider round" id={this.props.id}></span>
                                </label>
                            </div>
                            <div id={"targettosourceNV"+this.props.id} className="col-md-5 targettosourceNV">
                                <div className="sourceTitlenew text-center pr-1">{selectedDataTargetShortname}</div>
                                <div className="sourceTitlenew oneWayArrowImg"><img src={oneWayArrow} /></div>
                                <div className="targetTitlenew text-center pl-1">{selectedDataSourceShortname}</div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body mapping-tab-sec overflowScroll">
                        <div id={"SourcetoTargetMappingVisible"+this.props.id} className="d-none">
                            <SourcetoTargetMapping 
                                id={this.props.id}
                                name={this.props.id}
                                selectedData={this.state.selectedData}
                            /> 
                        </div>
                        <div id={"TargettoSourceMappingVisible"+this.props.id} className="d-none">
                            <TargettoSourceMapping
                                id={this.props.id}
                                name={this.props.id}
                                selectedData={this.state.selectedData}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ProductAttributeMapping