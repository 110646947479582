import React from 'react';
import $ from 'jquery'
import { NavLink, Redirect } from 'react-router-dom'
import Myconnections from '../MyConnections/Myconnections';
import {Header} from '../Shared/Header';
import Sidebar from '../Shared/Sidebar';
import ActiveServiceRequest from '../ActiveServiceRequest/ActiveServiceRequest';
import ArtifactsLoaded from '../ArtifactsLoaded/ArtifactsLoaded';
import YearlyavgBilling from '../Billing/YearlyAVGBilling/YearlyAVGBilling';
import axios from 'axios';
import API from '../api';
import './dashboard.css'

class Dashboard extends React.Component {
    constructor(props){
        super(props);
        this.tabActive = 'tab-active';
        this.state = {
            render:'',
            datapoint:[],
            isLoaded:false,
            redirect: null,
        }
    }

    handleClick(Myconnections, e){
        this.setState({
            render:Myconnections,
        });
    }
    _renderSubComp(){
        switch(this.state.render){
            // case 'myconnections': return <Myconnections />
            // case 'activeservicerequest' : return <ActiveServiceRequest />
            // case 'artifactsloaded' : return <ArtifactsLoaded />
            // case 'yearlyavgbilling' : return <YearlyavgBilling />
            default : return <Myconnections />
        }
    }
    redirect() {
        this.props.history.push('/Updates')
    }

    componentDidMount(){
        let self=this;
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };    
        API.get("/Client/connections",{headers})
        .then(response => response)
        .then(data => {
            //document.getElementById("loaderContainer").className += ' d-none';
            //placeholder-item
            //$(".crd-ctab").addClass("placeholder-item")
            // if(data.length > 1){
            //     $(".crd-ctab").addClass("placeholder-item")
            // }
            self.setState({
                isLoaded:true,
                datapoint: data
            })
        })        
        API.get("/Dashboard",{headers})
        .then(response => response)
        .then(data => {
            self.setState({
                isLoaded:true,
                dashboarddata: data 
            })
        })
    }
    
    



    render() {

        let datapoint=this.state;
        let dashboarddata=this.state;
        let artifactsLoaded=dashboarddata.dashboarddata && dashboarddata.dashboarddata.data && dashboarddata.dashboarddata.data.artifactsCount;
        let yearlyBilling=dashboarddata.dashboarddata && dashboarddata.dashboarddata.data && dashboarddata.dashboarddata.data.yearlyBilling;
        let outboundCount=dashboarddata.dashboarddata && dashboarddata.dashboarddata.data && dashboarddata.dashboarddata.data.outboundCount?dashboarddata.dashboarddata && dashboarddata.dashboarddata.data && dashboarddata.dashboarddata.data.outboundCount:0;
        let connectionLength=datapoint.datapoint && datapoint.datapoint.data && datapoint.datapoint.data.length;
        let inboundCount=dashboarddata.dashboarddata && dashboarddata.dashboarddata.data && dashboarddata.dashboarddata.data.inboundCount?dashboarddata.dashboarddata && dashboarddata.dashboarddata.data && dashboarddata.dashboarddata.data.inboundCount:0;
        return (
            <div className="container-fluid">
                <Header />
                <Sidebar />
                <div className="main-content mn-dashboard">
                    {/* <div id="loaderContainer" className="loaderContainer">
                        <div className="loader"></div>
                    </div> */}
                    
                    <div className="page-content custompaddingdash">
                        <div className="container-fluid">
                            <div className="row">
                                
                                <div className="col ctab tab-active" onClick={this.handleClick.bind(this, 'myconnections')}>
                                    {
                                        (connectionLength == null) ? 
                                        <div className="card crd-ctab placeholder-item">
                                            <div className="card-body">
                                                <div>
                                                    <h4 className="mb-1 mt-1 heading-number">
                                                        
                                                    </h4>
                                                    <p className="text-muted mb-0"> </p>
                                                </div>
                                            </div>
                                        </div> : 
                                        <div className="card crd-ctab">
                                            <div className="card-body">
                                                <div>
                                                    <h4 className="mb-1 mt-1 heading-number">
                                                        {
                                                            (connectionLength == 0) ? ("0") :
                                                            (connectionLength < 0) ? ("0" + connectionLength) : connectionLength
                                                        }
                                                    </h4>
                                                    <p className="text-muted mb-0">Connectors Used</p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className="col ctab" onClick={this.handleClick.bind(this, 'artifactsloaded')}>
                                    {
                                        (artifactsLoaded == null) ? 
                                        <div className="card crd-ctab placeholder-item">
                                            <div className="card-body">
                                                <div>
                                                    <h4 className="mb-1 mt-1 heading-number">
                                                        
                                                    </h4>
                                                    <p className="text-muted mb-0"> </p>
                                                </div>
                                            </div>
                                        </div> :
                                        <div className="card crd-ctab">
                                            <div className="card-body">
                                                <div>
                                                    <h4 className="mb-1 mt-1 heading-number">{artifactsLoaded}</h4>
                                                    <p className="text-muted mb-0">Artifacts Loaded</p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    
                                </div>
                                
                                <div className="col ctab" onClick={this.handleClick.bind(this, 'yearlyavgbilling')}>
                                    {
                                        yearlyBilling == null ?
                                        <div className="card crd-ctab placeholder-item">
                                                <div className="card-body">
                                                    <div>
                                                        <h4 className="mb-1 mt-1 heading-number">
                                                            
                                                        </h4>
                                                        <p className="text-muted mb-0"> </p>
                                                    </div>
                                                </div>
                                            </div>:
                                            <div className="card crd-ctab">
                                            <div className="card-body">
                                                <div>
                                                    <h4 className="mb-1 mt-1 heading-number">{yearlyBilling}</h4>
                                                    <p className="text-muted mb-0">Yearly Avg Billing</p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    
                                </div>
                                <div className="col ctab"  onClick={this.handleClick.bind(this, 'activeservicerequest')}>
                                        {
                                            (outboundCount == null && inboundCount == null) ?
                                            <div className="card crd-ctab placeholder-item">
                                                <div className="card-body">
                                                    <div>
                                                        <h4 className="mb-1 mt-1 heading-number">
                                                            
                                                        </h4>
                                                        <p className="text-muted mb-0"> </p>
                                                    </div>
                                                </div>
                                            </div> :
                                            <div className="card crd-ctab">
                                                <div className="card-body">
                                                    <div>
                                                        <h4 className="mb-1 mt-1 heading-number">
                                                            {outboundCount+inboundCount}
                                                        </h4>
                                                        <p className="text-muted mb-0">Total Synced Records</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    
                                </div>
                                <div className="col ctab d-none">
                                    <div className="card crd-ctab">
                                        <div className="card-body">
                                            <div>
                                                <h4 className="mb-1 mt-1 heading-number">{inboundCount}</h4>
                                                <p className="text-muted mb-0">Inbound Count</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card info-max">
                                        <div className="card-body">
                                            {this._renderSubComp()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Dashboard

