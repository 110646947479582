import React from 'react';
import SessionTimeout from '../../../SessionTimeout'
import $ from 'jquery'
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap'
import filterIcon from '../../../../assets/images/filter.svg'
import './summarydetails.css'
import { Link } from 'react-router-dom';
import inputCalender from '../../../../assets/images/input_calender.svg'
import API from '../../../api';
import Pagination from "react-js-pagination";
import {Header} from '../../../Shared/Header';
import Sidebar from '../../../Shared//Sidebar';
//require("bootstrap/less/bootstrap.less");

class SummaryDetails extends React.Component {
    constructor(props) {
       
        super(props)
        var today = new Date()
        var todayISO = today.toISOString()
        this.state = {
            activePage: 1,
            popoverOpen: false,
            krpType: [],
            entityId:props.location.id,
            title: props.location.title,
            sourceEndpointId: props.location.sourceEndpointId,
            subscriptionInstanceId: props.location.subscriptionInstanceId,
            statusId: props.location.statusIds,
            TargetId: "",
            krpType1: [],
            
            startDate:(window.sessionStorage.getItem("startnewDate")),
            EndDate: (window.sessionStorage.getItem("endDate")),

            labelstartdate:window.localStorage.getItem("summarystart"),
            labelenddate:window.localStorage.getItem("summaryend"),
            updatedStart:todayISO,
            UpdatedEnd:todayISO,
            messageId:"",
            sourceId:"",
            reference:"",
            changeIDnew: props.location.changeID,
            newType:window.localStorage.getItem("newType"),
            reSync:[]
        }
        
    }

    handlePageChange(pageNumber) {
        this.setState({activePage: pageNumber});
    }

    toggle = () => {
        this.setState({
            popoverOpen: !this.state.popoverOpen,
        })
    }

    toggleClose = () => {
        this.setState({
            popoverOpen: false,
        })
    }

    componentDidMount() {
      if ( window.sessionStorage.getItem("Status")) {
            this.handleErrorApi();
        }
        else { this.handleSourceApi(); }
    }
    
    handleEntityApi=(pageNumber)=>{
        this.setState({activePage: pageNumber});
        if (window.sessionStorage.getItem("Status")) {
            this.handleErrorApi(pageNumber);
            this.setState({activePage: pageNumber});
        }
        else { this.handleSourceApi(pageNumber); }
    }

    resetFilter=(pageNumber)=>{
        $("#startDatenew1s").val("")
        $("#endDatenew2s").val("")

        if (window.sessionStorage.getItem("Status")) {
            this.setState({ TargetName:'' })
            this.setState({ messageId:""});
            this.setState({ sourceId: ''});
            this.setState({ reference: ''});
            this.setState({labelstartdate: null});
            this.setState({labelenddate: null})
            window.sessionStorage.setItem("startnewDate1", document.getElementById("startDatenew1s").value === "" ? "" : null)
            window.sessionStorage.setItem("endDate1", document.getElementById("endDatenew2s").value === "" ? "" : null)
            window.sessionStorage.removeItem("startnewDate1")
            window.sessionStorage.removeItem("endDate1")
            this.handleErrorApi();
        }
        else {
            this.setState({ TargetName:'' })
            this.setState({ messageId:""});
            this.setState({ sourceId: ''});
            this.setState({ reference: ''});
            this.setState({labelstartdate: null});
            this.setState({labelenddate: null})
            window.sessionStorage.setItem("startnewDate1", document.getElementById("startDatenew1s").value === "" ? "" : null)
            window.sessionStorage.setItem("endDate1", document.getElementById("endDatenew2s").value === "" ? "" : null)
            window.sessionStorage.removeItem("startnewDate1")
            window.sessionStorage.removeItem("endDate1")
            this.handleSourceApi(); 
        }
    }

    handleErrorApi = (pageNumber) => {
        let self = this;
        var response = window.localStorage.getItem("accessToken");
        var request= window.sessionStorage.getItem("Requesttype")
        var EntityID= window.sessionStorage.getItem("EntityID");
        var Status= window.sessionStorage.getItem("Status");
        var source= window.sessionStorage.getItem("endpointID")
        //subscriptionInstanceId
        var subscriptionInstanceId= window.sessionStorage.getItem("subscriptionInstanceId");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json",
        };
        const body = {
            "page": pageNumber? pageNumber:this.state.activePage,
            "pageSize": 10,
            "subscriptionInstanceId": parseInt(subscriptionInstanceId),
            "sourceEndpointId": parseInt(source),
            "requestType": request,
            "statusIds": [
                parseInt(Status)
            ],
            "entityIds": [
                parseInt(EntityID)
            ],
            // "createdTime": {
            //     "fromTime":  window.sessionStorage.getItem("startnewDate"),
            //     "toTime":window.sessionStorage.getItem("endDate")
            // },
            "updatedTime": {
                "fromTime":  window.sessionStorage.getItem("startnewDate1"),
                "toTime":window.sessionStorage.getItem("endDate1")
            }
        }

        API.post(`/Client/connections/report/details`, body, { headers })
        .then(response => response)
        .then(data1 => {
            document.getElementById("loaderContainer").className += ' d-none';
            self.setState({
                isLoaded: true,
                krpType: data1,
            })
        })
    }

    handleSourceApi = (pageNumber) => {
        let self = this;
        var response = window.localStorage.getItem("accessToken");
        var source= window.sessionStorage.getItem("endpointID")
        var subscriptionInstanceId= window.sessionStorage.getItem("subscriptionInstanceId");
        var EntityID= window.sessionStorage.getItem("EntityID");
        var Status= window.sessionStorage.getItem("Status");
        var request= window.sessionStorage.getItem("Requesttype")
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json",
        };
        const body = {
            "page": pageNumber? pageNumber:this.state.activePage,
            "pageSize": 10,
            "subscriptionInstanceId":parseInt(subscriptionInstanceId),
            "sourceEndpointId":parseInt(source),
            "requestType": request,
            
            // "createdTime": {
            //     "fromTime":  window.sessionStorage.getItem("startnewDate"),
            //     "toTime":window.sessionStorage.getItem("endDate")
            // },

            "updatedTime": {
                "fromTime":  window.sessionStorage.getItem("startnewDate1"),
                "toTime":window.sessionStorage.getItem("endDate1")
            },

            "entityIds": [
              parseInt(EntityID)
            ]
        }
        API.post(`/Client/connections/report/details`, body, { headers })
            .then(response => response)
            .then(data1 => {
                document.getElementById("loaderContainer").className += ' d-none';
                self.setState({
                    isLoaded: true,
                    krpType: data1
                })
            })
        
    }


    handleChange0 = (event) => {
        this.setState({ EntityName: event.target.value });
    }



    handleChange = (event) => {
        this.setState({ TargetName: event.target.value })
    }
    handleChange0 = (event) => {
            this.setState({ messageId:event.target.value});

    }
    handleChange1 = (event) => {
        this.setState({ sourceId: event.target.value });
    }
    handleChange2 = (event) => {
        this.setState({ reference: event.target.value });
    }

    handleSubmit = (event) => {
        const {...inputfields}=this.state;
        let self = this;
        var request= window.sessionStorage.getItem("Requesttype")
        var response = window.localStorage.getItem("accessToken");
        var source= window.sessionStorage.getItem("endpointID")
        var subscriptionInstanceId= window.sessionStorage.getItem("subscriptionInstanceId");
        var EntityID= window.sessionStorage.getItem("EntityID");
        var Status= window.sessionStorage.getItem("Status");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json",
        };

        const body = {
                "page": 1,
                "pageSize": 10,
                "subscriptionInstanceId":parseInt(subscriptionInstanceId),
                "sourceEndpointId":parseInt(source),
                "requestType": request,
                "targetId": self.state.TargetName,
                "sourceId": self.state.sourceId,
                "reference": self.state.reference,
                "messageIds":self.state.messageId? [parseInt(self.state.messageId)]:[],
                   //[parseInt(self.state.messageId)],
               "statusIds": self.state.statusId? [parseInt( self.state.statusId)]:[],
                // "statusIds": [
                //     parseInt(Status)
                // ],
                // "createdTime": {
                //     "fromTime":  window.sessionStorage.getItem("startnewDate"),
                //     "toTime":window.sessionStorage.getItem("endDate")
                //   },
                  "updatedTime": {
                    // "fromTime":  window.sessionStorage.getItem("startnewDate1"),
                    // "toTime":window.sessionStorage.getItem("endDate1")
                    "fromTime": document.getElementById("startDatenew1s").value === "" ? null : document.getElementById("startDatenew1s").value,
                    "toTime": document.getElementById("endDatenew2s").value === "" ? null : document.getElementById("endDatenew2s").value
                  },
                "entityIds": [
                    parseInt(EntityID)
                ]

                // "entityIds": [
                //     self.state.entityId
                //   ]
                //"reference":self.state.EntityName
            }

        API.post(`/Client/connections/report/details`, body, { headers })
            .then(response => response)
            .then(data1 => {
                document.getElementById("loaderContainer").className += ' d-none';
                self.setState({
                    isLoaded: true,
                    krpType: data1,
                    popoverOpen: false,
                })
            })
        event.preventDefault()
    }

    testSubmit=(event)=>{
        const {...inputfields}=this.state;
        event.preventDefault()
    }

    refreshData=()=>{
        if ( window.sessionStorage.getItem("Status")) {
            this.refreshData1();
        }
        else { this.refreshData2(); }
    }

    refreshData1=()=>{
        var request= window.sessionStorage.getItem("Requesttype")
        var source= window.sessionStorage.getItem("endpointID")
        var EntityID= window.sessionStorage.getItem("EntityID");
        var Status= window.sessionStorage.getItem("Status");
        var subscriptionInstanceId= window.sessionStorage.getItem("subscriptionInstanceId");
        this.setState({labelstartdate:document.getElementById("startDatenew1s").value});
        this.setState({labelenddate:document.getElementById("endDatenew2s").value})
        let self=this;
        var response =window.localStorage.getItem("accessToken") ;
        let id = window.sessionStorage.getItem("connectorIndex")
        var today=new Date();
        var todayISO = today.toISOString()

        var startnewDate = new Date(document.getElementById("startDatenew1s").value)
        var endnewDate = new Date(document.getElementById("endDatenew2s").value)

        if(startnewDate == "Invalid Date"){
          window.sessionStorage.setItem("startnewDate1",todayISO)
          window.sessionStorage.setItem("endDate1",todayISO)
        }else{
          var startnewDateiso = startnewDate.toISOString()
          var endnewDateiso = endnewDate.toISOString()

          window.sessionStorage.setItem("startnewDate1",startnewDateiso)
          window.sessionStorage.setItem("endDate1",endnewDateiso)
        }

        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };

        API.post(
            "/Client/connections/report/details",
            {
                "page": 1,
                "pageSize": 10,
                "subscriptionInstanceId":parseInt(subscriptionInstanceId),
            "sourceEndpointId":parseInt(source),
            "requestType": request,
                // "createdTime": {
                //     "fromTime":  window.sessionStorage.getItem("startnewDate"),
                //     "toTime":window.sessionStorage.getItem("endDate")
                //   },
                  "updatedTime": {
                    // "fromTime":  window.sessionStorage.getItem("startnewDate1"),
                    // "toTime":window.sessionStorage.getItem("endDate1")
                    "fromTime": document.getElementById("startDatenew1s").value,
                    "toTime": document.getElementById("endDatenew2s").value
                  },
                  "statusIds": [
                    parseInt(Status)
                ],
                "entityIds": [
                    parseInt(EntityID)
                ]
            },
            {headers}
        )
        .then(response => response)
        .then(data => {
            document.getElementById("loaderContainer").className += ' d-none';
            self.setState({
                krpType: data
            })
        })
        
    }
    refreshData2=()=>{
        var request= window.sessionStorage.getItem("Requesttype")
        var source= window.sessionStorage.getItem("endpointID")
        var subscriptionInstanceId= window.sessionStorage.getItem("subscriptionInstanceId");
        this.setState({labelstartdate:document.getElementById("startDatenew1s").value});
        this.setState({labelenddate:document.getElementById("endDatenew2s").value})
        var EntityID= window.sessionStorage.getItem("EntityID");
        var Status= window.sessionStorage.getItem("Status");
        let self=this;


        var response =window.localStorage.getItem("accessToken") ;
        let id = window.sessionStorage.getItem("connectorIndex")

        var today=new Date();
        var todayISO = today.toISOString()

        var startnewDate = new Date(document.getElementById("startDatenew1s").value)
        var endnewDate = new Date(document.getElementById("endDatenew2s").value)
        //alert(startnewDate)



        if(startnewDate == "Invalid Date"){
          window.sessionStorage.setItem("startnewDate1",todayISO)
          window.sessionStorage.setItem("endDate1",todayISO)
        }else{
          var startnewDateiso = startnewDate.toISOString()
          var endnewDateiso = endnewDate.toISOString()

          window.sessionStorage.setItem("startnewDate1",startnewDateiso)
          window.sessionStorage.setItem("endDate1",endnewDateiso)

        }

        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };

        API.post(
            "/Client/connections/report/details",
            {
                "page": 1,
                "pageSize": 10,
                "subscriptionInstanceId":parseInt(subscriptionInstanceId),
            "sourceEndpointId":parseInt(source),
            "requestType": request,
                // "createdTime": {
                //     "fromTime":  window.sessionStorage.getItem("startnewDate"),
                //     "toTime":window.sessionStorage.getItem("endDate")
                //   },
                  "updatedTime": {
                    // "fromTime":  window.sessionStorage.getItem("startnewDate1"),
                    // "toTime":window.sessionStorage.getItem("endDate1")

                    "fromTime": document.getElementById("startDatenew1s").value,
                    "toTime": document.getElementById("endDatenew2s").value
                    
                  },
                "entityIds": [
                    parseInt(EntityID)
                ]
            },
            {headers}
        )
        .then(response => response)
        .then(data => {
        document.getElementById("loaderContainer").className += ' d-none';
            self.setState({
                krpType: data
            })
        })
        
    }
    refreshPage = () => {
        window.location.reload(false);
    }
    getresync = (event) =>{
        let checkedCheckbox = $('input:checkbox:checked').length
        if(checkedCheckbox < 1){
            $("button#SelectedreSync").addClass("d-none")
            $("button#defaultSelectedreSync").removeClass("d-none")
        }else{
            $("button#SelectedreSync").removeClass("d-none")
            $("button#defaultSelectedreSync").addClass("d-none")
        }
    }

    reSync = (event) =>{
        $("#loaderContainer").removeClass("d-none")
        var reSyncList = []
        $("input:checkbox[name=resync]:checked").each(function(){
            reSyncList.push(JSON.parse($(this).val()));
        });      

        let self = this;
        var response = window.localStorage.getItem("accessToken");
        let id = window.sessionStorage.getItem("connectorIndex")

        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };

        const body = {
            "requestType": this.state.newType,
            "messageId": reSyncList
        }
        

        API.post("/Client/ManualSync", body, {headers})
        .then(response => response)
        .then(data => {
            data.data.result ? $(".successErrorMessage").removeClass("d-none") : $(".failuerErrorMessage").removeClass("d-none")
            $("#loaderContainer").addClass("d-none")
            if(data.data.result === true){
                setTimeout(function(){
                    window.location.reload(false)
                }, 10000);
            }
        })
    }

    render() {
        let krpType = this.state;
       
        let krpType1 = this.state;

        let options = Array.from(new Set(krpType.krpType && krpType.krpType.data && krpType.krpType.data.records.map(item =>item.entityName ))).map((entityName, entityId) => {
            return <option value={entityName}>{entityName}</option>
        })
        let options1 = Array.from(new Set(krpType.krpType && krpType.krpType.data && krpType.krpType.data.records.map(item=>item)))
       
        $(".trdashboard").addClass("active")
        return (
            <div>
                <SessionTimeout />
                <Header/>
                <Sidebar/>
                <div className="main-content myConnectors">
                    <div id="loaderContainer" className="loaderContainer">
                        <div className="loader"></div>
                    </div>

                    <div className="page-content pb-0">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <div className="float-left page-title-left">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                                <li className="breadcrumb-item"><Link to={{ pathname:"/ViewSummary", title:this.state.title, id:this.state.changeIDnew} }>Entities</Link></li>
                                                <li className="breadcrumb-item active">Entity Details</li>
                                            </ol>
                                        </div>
                                        <div className="float-right page-title-right positionrelative topnine">
                                            <div className="form-inline form-group">
                                                <div className="input-daterange input-group mr-3" data-provide="datepicker" data-date-format="yyyy-mm-dd" data-date-autoclose="true">
                                                    <label className="summaryDateFrom"> From </label>
                                                    <input type="text" className="form-control" id="startDatenew1s" name="start" placeholder={"Pick the Start date"} onChange={this.handleDatechange} value={this.state.labelstartdate != "null" ? this.state.labelstartdate : null} />
                                                    <span className="calenderIcon"> <img src={inputCalender} /> </span>
                                                    <label className="summaryDateTo"> To </label>
                                                    <input type="text" className="form-control" id="endDatenew2s" name="end" placeholder={"Pick the End date"} onChange={this.handleDatechange} value={this.state.labelenddate != "null" ? this.state.labelenddate : null} />
                                                    <span className="calenderIcon"> <img src={inputCalender} /> </span>
                                                </div>
                                                 <button className="btn btn-primary mb-2" onClick={this.refreshData}> Apply </button>
                                                 
                                                <label className="summaryDateTo summaryFilter" id="Popover1" onClick={this.toggle}> <img src={filterIcon} className="filterIcon" /> </label>
                                                <Popover placement="bottom" isOpen={this.state.popoverOpen} target="Popover1" toggle={this.toggle}>
                                                        <div className="popoverForm">
                                                            <PopoverHeader>
                                                                <span className="float-left filter-title"> Filter </span>
                                                                <span className="float-right cursorPointer popoverClose" onClick={this.toggleClose}> <i className="uil-times"></i>  </span>
                                                                <span className="clearfix"></span>
                                                            </PopoverHeader>
                                                            <PopoverBody>
                                                                <div className="table-responsive">
                                                                    <form >
                                                                        <table className="table mb-0">
                                                                            <thead className="thead-light">
                                                                                <tr>
                                                                                    <th>Property</th>
                                                                                    <th>Value to be filtered</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {/* <tr>
                                                                                    <th scope="row">Entity Type</th>
                                                                                    <td>
                                                                                        <div className="form-group m-0">
                                                                                            <select className="form-control version-field" required onChange={this.handleChange}>
                                                                                                <option >Select Entity Type </option>
                                                                                                {options}
                                                                                            </select>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr> */}
                                                                                <tr>
                                                                                    <th scope="row">Target ID</th>
                                                                                    <td>
                                                                                        <div className="form-group m-0">
                                                                                            <div>
                                                                                                <input type="text" className="form-control version-field" required="" placeholder="Enter Target ID" id="TargetName" onChange={this.handleChange} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">Message ID</th>
                                                                                    <td>
                                                                                        <div className="form-group m-0">
                                                                                            <div>
                                                                                                <input type="text" className="form-control version-field" required="" placeholder="Enter Message ID" id="TargetName" onChange={this.handleChange0} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">Source ID</th>
                                                                                    <td>
                                                                                        <div className="form-group m-0">
                                                                                            <div>
                                                                                                <input type="text" className="form-control version-field" required="" placeholder="Enter Source ID" id="TargetName" onChange={this.handleChange1} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">Reference ID</th>
                                                                                    <td>
                                                                                        <div className="form-group m-0">
                                                                                            <div>
                                                                                                <input type="text" className="form-control version-field" required="" placeholder="Enter Reference ID" id="TargetName" onChange={this.handleChange2} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <div className="footerButton">
                                                                            <table className="table mb-0">
                                                                                <tr>
                                                                                    <td> <input type="reset" value="Reset" onClick={this.resetFilter}/> </td>
                                                                                    <td> <input type="submit" value="Apply Filters" onClick={this.handleSubmit} /> </td>
                                                                                </tr>
                                                                            </table>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </PopoverBody>
                                                        </div>
                                                    </Popover>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <button id={"SelectedreSync"} class="btn btn-primary mb-2 float-right d-none" name={this.state.newType} onClick={this.reSync}> Re-sync all selected records </button>
                                    <button id={"defaultSelectedreSync"} class="btn btn-primary mb-2 float-right" disabled> Re-sync all selected records </button>
                                </div>
                                <div className="col-lg-12">
                                    <div class="alert alert-success successErrorMessage d-none" role="alert">
                                        Checked list successfully re-synced, Please wait, we are reloading the page in 10 seconds 
                                    </div>
                                    <div class="alert alert-danger failuerErrorMessage d-none" role="alert">
                                        Selected record(s) are processing please wait some time
                                    </div>
                                    <div className="card no-padding">
                                        <div className="card-body no-padding">
                                            <div className="table-responsive">
                                                <table className="viewSummaryNew table mb-0">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th>Entity</th>
                                                            <th>Message Id</th>
                                                            <th>Target Id</th>
                                                            <th>Source Id</th>
                                                            <th>Status</th>
                                                            <th>Time</th>
                                                            <th>Sync Counter</th>
                                                            <th>Reference</th>
                                                            {/* <th>Queue Id</th> */}
                                                            {/* {
                                                                statusNameDeclared  === "Error" ? <th>Action</th> : <th>Action</th>
                                                            } */}
                                                            <th>Action</th>
                                                            
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {krpType.krpType && krpType.krpType.data && krpType.krpType.data.records.map(item =>
                                                            <tr>
                                                                <th scope="row">{item.entityName}</th>
                                                                <td>{item.messageId}</td>
                                                                <td>{item.targetId}</td>
                                                                <td>{item.sourceId}</td>
                                                                <td>
                                                                    {item.statusName}
                                                                    {
                                                                        item.statusName === "Error" ?
                                                                        <span className="infoIconnew">
                                                                            <i className={item.statusName + " uil-info-circle" }></i>
                                                                            <span className={item.statusName + " infoMessagenew" }>{item.errorMessage}</span>
                                                                        </span> : null
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {item.updatedTime}
                                                                    {/* <span className="infoIconnew">
                                                                        <i className="uil-info-circle"></i>
                                                                        <span className="infoMessagenew">{item.updatedTime}</span>
                                                                    </span> */}
                                                                </td>
                                                                <td>{item.syncCounter}</td>
                                                                <td>
                                                                    {item.reference}
                                                                </td>
                                                                
                                                                {/* <td>
                                                                    {
                                                                        item.statusName === "Error" ?
                                                                        <span className="infoIconnew">
                                                                            <i className={item.statusName + " uil-info-circle" }></i>
                                                                            <span className={item.statusName + " infoMessagenew" }>{item.errorMessage}</span>
                                                                        </span> : null
                                                                    }
                                                                    
                                                                </td> */}
                                                                {/* <td>{item.queueId}</td> */}
                                                                {
                                                                    item.statusName === "Error" ?
                                                                    <td className="text-center">
                                                                        <div className="custom-control custom-checkbox mt-2">
                                                                            <input type="checkbox" className="custom-control-input" id={"errorStatus"+item.messageId} onChange={this.getresync} name={"resync"} defaultValue={item.messageId} />
                                                                            <label className="custom-control-label" for={"errorStatus"+item.messageId}> </label>
                                                                        </div>                                                                    
                                                                    </td>:
                                                                    <td className="text-center">
                                                                        <div className="custom-control custom-checkbox mt-2">
                                                                            <input type="checkbox" className="custom-control-input" id={"errorNewway"+item.messageId} disabled={true} title='It`s Allowed for only error status' />
                                                                            <label className="custom-control-label newDisabledMethod" for={"errorNewway"+item.messageId}>  </label>
                                                                        </div>                                                                    
                                                                    </td>
                                                                }
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </table>
                                                    {(krpType.krpType && krpType.krpType.data && krpType.krpType.data.totalRecords < 0) || krpType.krpType && krpType.krpType.data && krpType.krpType.data.totalRecords === 0 ? 
                                                        <div class="alert alert-danger" role="alert">
                                                            There is no records available, related to your filter
                                                        </div> : null
                                                    }
                                                <div className="clearfix"></div>
                                                <div>
                                                    {
                                                        krpType.krpType && krpType.krpType.data && krpType.krpType.data.totalRecords > 10 ?
                                                        <Pagination
                                                            activePage={this.state.activePage}
                                                            itemsCountPerPage={10}
                                                            totalItemsCount={krpType.krpType && krpType.krpType.data && krpType.krpType.data.totalRecords}
                                                            pageRangeDisplayed={5}
                                                            onChange={this.handleEntityApi}
                                                        /> : null
                                                    }
                                                    
                                                    {/* <Pagination
                                                            activePage={this.state.activePage}
                                                            itemsCountPerPage={10}
                                                            totalItemsCount={krpType.krpType && krpType.krpType.data && krpType.krpType.data.totalRecords}
                                                            pageRangeDisplayed={5}
                                                            onChange={this.handleEntityApi}
                                                        />  */}
                                                    <div className="clearfix"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SummaryDetails