import React from 'react';
class expiryDate extends React.Component {
    render(){
        var date = new Date();
        let dateFinal = date.toISOString()
        return(
            <>
                
                <div className="col-md-4">
                    {
                        ((Math.round((new Date(this.props.expiryDate) - new Date(dateFinal)) / (1000 * 3600 * 24))) < "1") ? 
                            <div className=""> <p className="text-muted mb-0 font-size-10 d-none"> Expired </p>
                                <h5 className="connectorExpired"> Expired </h5> 
                            </div> : 
                            <div className=""> 
                                <p className="text-muted mb-0 font-size-10"> Expires in </p> 
                                <h5 className="font-size-10"> {Math.round((new Date(this.props.expiryDate) - new Date(dateFinal)) / (1000 * 3600 * 24)) + " days"} </h5> 
                            </div>
                    }
                </div>
            </>
        )
    }
}

export default expiryDate