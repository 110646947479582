import React from 'react';
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import './myproducts.css'
import $ from 'jquery';
import API from '../../api';
import { NavLink, Link } from 'react-router-dom'

import {Header} from '../../Shared/Header';
import SideBar from '../../Shared/Sidebar';

import ConnectorLength from './ConnectorLength/ConnectorLength';
import ConnectorList from './ConnectorList/ConnectorList';

class MyProducts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            connectorsList : [],
            instanceEntryID : 0
        }
        window.sessionStorage.setItem("connectorIndex", 0);
    }
    
    componentDidMount() {
        $("#newLoader").removeClass("d-none")
        let self = this
        var response = window.localStorage.getItem("accessToken");
        let id = window.sessionStorage.getItem("connectorIndex")
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        API.get("/Client/connections", { headers })
        .then(response => response)
        .then(data => {
            self.setState({
                connectorsList : data.data
            })
            $(".nav-itemnew:first-child a").addClass("active")
            $(".nav-tabitemnew:first-child").addClass("active")
            $("#newLoader").addClass("d-none")
        })
    }

    render(){
        return(
            <>
                <Header />
                <SideBar />
                <div className="main-content myConnectors myConnectorsnewAdded">
                    <div className="page-content pb-0">
                        <div className="container-fluid">
                            <ConnectorLength connectorsCount={this.state.connectorsList.length} />
                            <ConnectorList connectorsList = {this.state.connectorsList} />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default MyProducts