import React from 'react';

// Material-ui imports
import Paper from "@material-ui/core/Paper";

import Routes from '../Routes';

class Home extends React.Component {
    render() {

        return (
            <Paper>
                <Routes />
            </Paper>
        )
    }
}

export default Home