import React from 'react';
import SessionTimeout from '../SessionTimeout'
import './marketplace.css'

import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import {Header} from '../Shared/Header';
import Sidebar from '../Shared/Sidebar';

import Connector from '../NewConnector/IntegrationType/MarketplaceIntegration';
import EgeConnector from '../EGE/EgeSetup/Selectconnector/Selectconnector';
import Viewdetails from './ViewDetails/ViewDetails';

import Offerimg from '../../assets/images/offer-img.svg'
import OfferNavimg from '../../assets/images/nav.svg'
import pimgactive from '../../assets/images/nav-magento-active.png';
import closeicon from '../../assets/images/close-icon.svg';

import CustomerSupportimg from '../../assets/images/EGE/customer-support.svg';
import Paymentsecurity from '../../assets/images/EGE/payment-security.svg';
import Marketing from '../../assets/images/EGE/marketing.svg';
import Accountfinance from '../../assets/images/EGE/account-finance.svg';
import Shippingfullfill from '../../assets/images/EGE/shipping-fullfill.svg';
import Siteoptimization from '../../assets/images/EGE/site-optimization.svg';
import Analytics from '../../assets/images/EGE/analytics.svg';
import Socialcommerce from '../../assets/images/EGE/socialcommerce.svg';
import Voicecommerce from '../../assets/images/EGE/voicecommerce.svg';
import Vrar from '../../assets/images/EGE/vrar.svg';
import API from '../api';

class MarketPlace extends React.Component {
    constructor(){
        super();
        this.state={
            title:[],
            integrationName:'',
            sourceName:'',
            targetName:'',
            isPaneOpen: false,
            isPaneOpenLeft: false,
            isPaneOpen1: false,
            isPaneOpenLeft1: false,
            images:[],
            selectedCustomer: 1,
            isLoaded:true,
            isLoaded1:true,
            selectedCustomer: 1,
        }
    }

    async componentDidMount(){
        let imagesdata=window.localStorage.getItem("imagedata");
        if(imagesdata == [] || imagesdata == undefined){
        this.handleConnectorData();}
    }

    handleConnectorData=()=>{
        let self=this;
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };
        API.get("/MarketPlace/connectors",{headers})
        .then(response => response)
        .then(data => {
            self.setState({
                isLoaded:true,
                MarketPlacedata: data
            })      
        })
        API.get("/MarketPlace/endpoints",{headers})
        .then(response => response)
        .then(data => {
            window.localStorage.setItem("Content",JSON.stringify(data));
            self.setState({
                isLoaded:true,
                datapoint: data,
            }
        )
        const images = self.state.datapoint;
            Promise.all(images.data.map(image => API.get(
                `/MarketPlace/endpoints/${image.id}/image`,{headers}
            )))
            .then(resp =>resp)
            .then(result => {
                window.localStorage.setItem("imagedata",JSON.stringify(result));
               // document.getElementById("loaderContainermarketplace").className += ' d-none';
                self.setState({
                    images:result
                });
            })
        })
    }

    togglePopup() {  
        this.setState({  
            isLoaded: !this.state.isLoaded,
        }); 
    }
    
    handleItems=(event)=>{
        this.setState({name:event.target.title})
        this.setState({selectedCustomer:event.target.id})
        this.setState({isLoaded:false})
        this.setState({ isPaneOpen: true })
    }

    viewDetails=(event)=>{
        this.setState({ isPaneOpen1: true })
        this.setState({ integrationName: event.target.title })
        this.setState({selectedCustomer:event.target.id})
        this.setState({sourceName:event.target.name});
        this.setState({targetName:event.target.type});
        this.setState({isLoaded:false})
    }
    render() {
        let MarketPlacedata=this.state;
        let {datapoint}= this.state;
        let {images} = this.state;
        let ContentData=window.localStorage.getItem("Content");

        ContentData=JSON.parse(ContentData);
        ContentData= ContentData? ContentData: datapoint;
        
        let imagesdata=window.localStorage.getItem("imagedata");

        imagesdata=JSON.parse(imagesdata);
         imagesdata= imagesdata ? imagesdata: images;


        
        let images1 = imagesdata.map((item)=>item.data);
        return (
            <div className="container-fluid">
                <SessionTimeout />
                <Header />
                <Sidebar />
                <div className="main-content marketplace">
                    {/* <div id="loaderContainermarketplace" className="loaderContainer">
                        <div className="loader"></div>
                    </div> */}
                    <div className="page-content pb-0">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between mb-3 pb-0">
                                        <h4 className="mb-0">Market Place</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" data-toggle="tab" href="#connectorsew5" role="tab">
                                            <span className="d-none d-sm-block">Connectors</span> 
                                        </a>
                                    </li>
                                    {/* <li className="nav-item">
                                        <a className="nav-link" data-toggle="tab" href="#marketplace-ege" role="tab">
                                            <span className="d-none d-sm-block">EGE</span> 
                                        </a>
                                    </li> */}
                                </ul>
                                <div className="tab-content text-muted tab-content">
                                    <div className="tab-pane active" id="connectorsew5" role="tabpanel">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="card mndesc no-boxshadow no-border">
                                                    <div className="marketPlacetypesContent">
                                                        <div className="button-items">
                                                            <ul className="row nav nav-pills" role="tablist">
                                                                <li className="nav-item waves-effect">
                                                                    <a className="nav-link active" data-toggle="tab" href="#allproductsnew" role="tab">
                                                                        <span className="d-none d-sm-block">All</span> 
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item waves-effect">
                                                                    <a className="nav-link" data-toggle="tab" href="#erpnew" role="tab">
                                                                        <span className="d-none d-sm-block">ERP</span> 
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item waves-effect">
                                                                    <a className="nav-link" data-toggle="tab" href="#eCommercenew" role="tab">
                                                                        <span className="d-none d-sm-block">ECommerce</span> 
                                                                    </a>
                                                                </li>
                                                                {/* <li className="nav-item waves-effect">
                                                                    <a className="nav-link" data-toggle="tab" href="#integrationnew" role="tab">
                                                                        <span className="d-none d-sm-block">Integration</span>   
                                                                    </a>
                                                                </li> */}
                                                            </ul>
                                                            <div className="tab-content p-3 text-muted">
                                                                <div className="tab-pane" id="erpnew" role="tabpanel">
                                                                    <div className="row padding-top-20">
                                                                        {ContentData && ContentData.data && ContentData.data.map((item,i)=>
                                                                            <div className={item.type + " col-md-3 col-xl-2 mb-3 conhover"} name={item.shortName} onClick={this.handleItems} >
                                                                                <div className="connector-listing" name={item.shortName}> 
                                                                                    <div className="conhoverselect" id={item.id} name={item.shortName}>
                                                                                        Select
                                                                                    </div>
                                                                                    <div className="connector-logo-container">
                                                                                        <img src={images1[i]} />
                                                                                    </div>
                                                                                    <h5 className="add-connector-title"> {item.shortName} </h5>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="tab-pane" id="eCommercenew" role="tabpanel">
                                                                    <div className="row">
                                                                        {ContentData && ContentData.data && ContentData.data.map((item,i)=>
                                                                            <div className={item.type + " col-md-3 col-xl-2 mb-3 conhover"} onClick={this.handleItems} >
                                                                                <div className="connector-listing"  > 
                                                                                    <div className="conhoverselect" id={item.id} title={item.shortName}>
                                                                                        Select
                                                                                    </div>
                                                                                    <div className="connector-logo-container">
                                                                                        <img src={images1[i]} />
                                                                                    </div>
                                                                                    <h5 className="add-connector-title"> {item.shortName} </h5>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="tab-pane active" id="allproductsnew" role="tabpanel">
                                                                    <div className="row padding-top-20">
                                                                        {ContentData && ContentData.data && ContentData.data.map((item,i)=>
                                                                            <div className={item.type + " col-md-3 col-xl-2 mb-3 conhover"} onClick={this.handleItems} >
                                                                                <div className="connector-listing"  > 
                                                                                    <div className="conhoverselect" id={item.id} title={item.shortName}>
                                                                                        Select
                                                                                    </div>
                                                                                    <div className="connector-logo-container">
                                                                                        <img src={images1[i]} />
                                                                                    </div>
                                                                                    <h5 className="add-connector-title"> {item.shortName} </h5>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="tab-pane" id="integrationnew" role="tabpanel">
                                                                <div className="marketplaceSections marketplaceserp">
                                                                <div className="row">


                                                                {MarketPlacedata.MarketPlacedata && MarketPlacedata.MarketPlacedata.data && MarketPlacedata.MarketPlacedata.data.map(item=>
                                                                    <div className="col-lg-4">
                                                                        <div className="card marketplaceserplist">
                                                                            <div className="card-body">
                                                                                <div className="media">
                                                                                    <div className="mr-4">
                                                                                        <img src={pimgactive} alt="" width="100px" />
                                                                                    </div>
                                                                                    <div className="media-body align-self-center overflow-hidden">
                                                                                        <div>
                                                                                            <h5 className="text-truncate font-size-16">
                                                                                                <a className="text-dark marketplaceserplist-title">{item.name}</a>
                                                                                            </h5>
                                                                                            <p className="mb-1">
                                                                                                Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out prin...
                                                                                            </p>
                                                                                            <a className="viewMore" onClick={this.viewDetails} title={item.name} id={item.id} name={item.source.shortName} type={item.target.shortName}> View Details </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                </div>
                                                            </div>
                                                                </div>

                                                                
                                                            </div>


                                                            





                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane new-tab-pane-ege marketplace-ege" id="marketplace-ege" role="tabpanel">
                                        <h3 className="egeapphdng"> EGE Apps (Banner) </h3>
                                        <div className="row row-cols-5">
                                            <div className="col card no-padding no-boxshadow">
                                                <div className="card-body text-center">
                                                    <img src={CustomerSupportimg} />
                                                </div>
                                            </div>
                                            <div className="col card no-padding no-boxshadow">
                                                <div className="card-body text-center">
                                                    <img src={Paymentsecurity} />
                                                </div>
                                            </div>
                                            <div className="col card no-padding no-boxshadow">
                                                <div className="card-body text-center">
                                                    <img src={Marketing} />
                                                </div>
                                            </div>
                                            <div className="col card no-padding no-boxshadow">
                                                <div className="card-body text-center">
                                                    <img src={Accountfinance} />
                                                </div>
                                            </div>
                                            <div className="col card no-padding no-boxshadow">
                                                <div className="card-body text-center">
                                                    <img src={Shippingfullfill} />
                                                </div>
                                            </div>
                                            <div className="col card no-padding no-boxshadow">
                                                <div className="card-body text-center">
                                                    <img src={Siteoptimization} />
                                                </div>
                                            </div>
                                            <div className="col card no-padding no-boxshadow">
                                                <div className="card-body text-center">
                                                    <img src={Analytics} />
                                                </div>
                                            </div>
                                            <div className="col card no-padding no-boxshadow">
                                                <div className="card-body text-center">
                                                    <img src={Socialcommerce} />
                                                </div>
                                            </div>
                                            <div className="col card no-padding no-boxshadow">
                                                <div className="card-body text-center">
                                                    <img src={Voicecommerce} />
                                                </div>
                                            </div>
                                            <div className="col card no-padding no-boxshadow">
                                                <div className="card-body text-center">
                                                    <img src={Vrar} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <SlidingPane className='connector-popup marketplacesec' overlayClassName='some-custom-overlay-class' isOpen={ this.state.isPaneOpen } onRequestClose={ () => { this.setState({ isPaneOpen: true }); } }>
                                <button className="popup-close" onClick={() => this.setState({ isPaneOpen: false })}>
                                    <img src={closeicon} />
                                </button>
                                <h3 class="marketPlaceHdngw">Add Connector</h3>
                                {this.state.isLoaded?<div> </div>: 
                                (this.state.name== "EGE") ? <EgeConnector   closePopup={this.togglePopup.bind(this)} />:
                                <Connector  id={this.state.selectedCustomer} closePopup={this.togglePopup.bind(this)} />}
                            </SlidingPane>
                            <SlidingPane className='connector-popup marketplacesec marketplacesecviedetails' overlayClassName='some-custom-overlay-class' isOpen={ this.state.isPaneOpen1 } onRequestClose={ () => { this.setState({ isPaneOpen1: true }); } }>
                                <button className="popup-close" onClick={() => this.setState({ isPaneOpen1: false })}>
                                    <img src={closeicon} />
                                </button>
                                <Viewdetails title={this.state.integrationName} versionid={this.state.selectedCustomer} srcshortName={this.state.sourceName} trgtshortName={this.state.targetName} />
                            </SlidingPane>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default MarketPlace