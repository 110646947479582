import React from 'react';
import API from '../../../../../../../../api';
import Addingform from './Addingform';
import DefaultMappings from './DefaultMappings';
import SubmitMappings from './SubmitMappings';
class SourcetoTargetMapping extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sourcetoTarget: true,
            targettoSource: false,
        }
    }
    componentWillReceiveProps(props) {
        let self = this;
        var response = window.localStorage.getItem("accessToken");
        let id = window.sessionStorage.getItem("connectorIndex")
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        
        let connectionId = window.localStorage.getItem("connectionId")
        let instanceID = window.localStorage.getItem("instanceID")

        API.get(`/Connection/${connectionId}/instance/${instanceID}/product-attribute`,{headers})
        .then(response => response)
        .then(data=>{
            self.setState({
                sourceMethods : data.data.sourceMethods,
                targetMethods : data.data.targetMethods,
                targetMappedSchemaList : data.data.targetMappedSchemaList
            })
        })
    }

    render(){
        let selectedConnectorInfo = this.props.selectedConnectorInfo
        let sourceMethods = this.state.sourceMethods
        let targetMethods = this.state.targetMethods
        let defaultMappings = this.state.targetMappedSchemaList

        const sourceMethodsGet = sourceMethods && sourceMethods.map(item => ({
            value:item.name+"*"+item.dataType+"*"+item.size+"*"+item.type+"*"+item.isRequired, label:item.name
        }))
        const targetMethodsGet = targetMethods && targetMethods.map(item => ({
            value:item.name+"*"+item.dataType+"*"+item.size+"*"+item.type+"*"+item.isRequired, label:item.name
        }))

        return(
            <>
                <SubmitMappings
                    id={this.props.id}
                    name={this.props.name}
                    selectedData={this.props.selectedData}
                />
                <div className="mt-0">
                    <div className="col-md-12 col-xl-12">
                        <div id="entityMappingerrormessage" className="alert alert-success d-none" role="alert">
                            Entity Updated successfully
                        </div>
                        <div id="productAttributeMappingerrormessageTTS" className="alert alert-success d-none" role="alert">
                            Mapping(s) Updated successfully
                        </div>
                        <div id="disableAftersync" className="alert alert-warning d-none" role="alert">
                        </div>
                    </div>
                    <Addingform 
                        id={this.props.id}  
                        sourceMethodsGet={sourceMethodsGet} 
                        targetMethodsGet={targetMethodsGet} 
                        defaultMappings={defaultMappings}
                    />
                    <div id={"errorHandlingNewTTS"+this.props.id} className="errorHandlingNew alert alert-warning errorHandlingNew d-none">
                    </div>
                    <DefaultMappings 
                        id={this.props.id}
                        sourceMethodsGet={sourceMethodsGet}
                        targetMethodsGet={targetMethodsGet}
                        defaultMappings={defaultMappings}
                    />
                </div>
            </>
        )
    }
}

export default SourcetoTargetMapping