import React, {Component} from 'react';
import './DownloadInstaller.css'
import SubDownload from './subDownlaodfile.js';
import API from '../../api';

export default class ChooseVersion extends Component {
  constructor(props) {
    console.log(props)
    super(props);
    this.state = {
      keyType: [],
      isLoaded: false,
      krpType: [],
    }
  }

  componentDidMount() {
    if (this.props.Questions) {
      this.handlenoQuestion1();
    }
    else {
      this.handlenoQuestion();
    }
  }
  handlenoQuestion1 = () => {
    let self = this;
    let connectorques=Object.entries(self.props.Questions);
    console.log("chck",connectorques)
    connectorques=connectorques.filter(obj=>{
      if(obj[0]!=="isPaneOpen") { return {
        obj
        }}
    })
    let result1 = connectorques.map((obj) => { 
       return {
        "questionId":parseInt(obj[0]), 
        "answer": obj[1]
        }
    });
    var response = window.localStorage.getItem("accessToken");
    var sourceName= window.localStorage.getItem("fullname")
    const headers = {
      "authorization": `Bearer ${response}`,
      "content-type": "application/json"
    };
    const body =
    {
      connectorVersionId: parseInt(self.props.connectorId),
      "connectorName": `${sourceName}`,
      "sourceVersion": self.props.versionYear.toString(),
      "targetVersion": self.props.entityVersion.toString(),
       "subscriptionAnswers":result1
    }
    API.post("/Subscription", body, { headers })
      .then(response => response)
      .then(data => {
        self.setState({
          isLoaded: true,
          keyType: data
        })
      })
  }
  handlenoQuestion = () => {
    let self = this;
    var response = window.localStorage.getItem("accessToken");
    var targetName= window.localStorage.getItem("targetname")
    var sourceName= window.localStorage.getItem("fullname")
    const headers = {
      "authorization": `Bearer ${response}`,
      "content-type": "application/json"
    };
    const body =
    {
      connectorVersionId: parseInt(self.props.connectorId),
      "connectorName": `${sourceName}`,
      "sourceVersion": self.props.versionYear.toString(),
      "targetVersion": self.props.entityVersion.toString()
    }
    API.post("/Subscription", body, { headers })
      .then(response => response)
      .then(data => {
        self.setState({
          isLoaded: true,
          keyType: data
        })
      })
  }

    render() {
    let keyType=this.state;
    this.state.finalKey=this.state.isLoaded?keyType.keyType.data.instanceId:null;
    this.state.finalKey1=this.state.isLoaded?keyType.keyType.data.connectorId:null;
    return (
      <div className="row downloadInstallernewfn">
        <div className="col-lg-12">
          <div className="card no-border no-boxshadow mb-0">
            <div className="card-body p-0">
              <h4 className="newconnector-sub-title">
                  <span className="float-left color-blue prev-back" onClick={this.props.closePopupnew}>Back</span>
                  <span className="float-left" >Download Files</span>
                  <span className="clearfix"></span>
              </h4>
              <div className="downloadInstallernewfnnew">
                {this.state.isLoaded ? <SubDownload instanceID={this.state.finalKey} connectorIdNew={this.state.finalKey1} connectorId={this.props.connectorId} versionYear={this.props.versionYear} entityVersion={this.props.entityVersion} productName={this.props.productName} />:null}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}