import React, {Component} from 'react';

import  Connector from '../IntegrationType/IntegrationType';

import API from '../../api';

export default class SelectConnector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datapoint:[],
            isLoaded:true,
            selectedCustomer: 1,
        };  
    }
    
    togglePopup() {  
        this.setState({  
            isLoaded: !this.state.isLoaded
        });
    }

    handleItems=(event)=>{
        this.setState({selectedCustomer:event.target.id})
        this.setState({isLoaded:false})
    }

    render() {
        let images=this.props.imageData.map((item)=>item.data);
        return (
            <div className="selectNewconnector">
                <h4 className="newconnector-sub-title">
                    <span className="float-left">Select a Connector</span>
                    <span className="float-right color-blue d-none">Create New</span>
                    <span className="clearfix"></span>
                </h4>
                <div className="cstmoverflow">
                    <div className="row mt-3 padding-20-new">
                        {this.props.passedData && this.props.passedData.data && this.props.passedData.data.map((item, i)=>
                            <div id={"selectendpoint"+item.shortName} className="col-lg-3 mb-3 conhover" onClick={this.handleItems} >
                                <div className="connector-listing"  > 
                                    <div className="conhoverselect" id={item.id}>
                                        Select
                                    </div>
                                    <div className="connector-logo-container">
                                    <img src={images[i]} />
                                    </div>
                                    <h5 className="text-truncate add-connector-title"> {item.shortName} </h5>
                                </div>
                            </div>
                        )}
                        {
                           this.props.passedData && this.props.passedData.data && this.props.passedData.data.length < 1 ? 
                            <div className="col-lg-12 mb-3">
                                <div className="alert alert-danger" role="alert">
                                    There is no connectors to Show 
                                </div>                            
                            </div>: null
                        }
                    </div>
                </div>

                {this.state.isLoaded?<div> </div>:  <Connector  id={this.state.selectedCustomer} closePopup={this.togglePopup.bind(this)} />}
            </div>
        )
    }
}
