import React, { Component } from 'react';
import './Style.css';

class Home extends Component {

    render() {
        return (
            <div className="Home">
                <h2>Home page</h2>
            </div>
        );
    }
}

export default Home;