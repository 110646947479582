import React from 'react';
import ExpiryDate from './expiryDate/expiryDate';
import ErrorLogs from './ErrorLogs/ErrorLogs';
import EntityCount from './EntityCount/EntityCount';
class ConnectorShortInfo extends React.Component {
    render(){
        return(
            <>
                <div className="row">
                    <ExpiryDate expiryDate ={this.props.expiryDate} />
                    <ErrorLogs errorCount={this.props.errorCount} />
                    <EntityCount entityCount={this.props.entityCount} />
                </div>
            </>
        )
    }
}

export default ConnectorShortInfo