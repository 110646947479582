import React from 'react';
import BusinessEntitySection1 from './BusinessEntitySection/BusinessEntitySection1/BusinessEntitySection1';
import BusinessEntitySection2 from './BusinessEntitySection/BusinessEntitySection2/BusinessEntitySection2';
import BusinessEntitySection3 from './BusinessEntitySection/BusinessEntitySection3/BusinessEntitySection3';
import BusinessEntitySection4 from './BusinessEntitySection/BusinessEntitySection4/BusinessEntitySection4';
import BusinessEntitySection5 from './BusinessEntitySection/BusinessEntitySection5/BusinessEntitySection5';

class BusinessEntities extends React.Component {
    render(){
        let instanceSourceDataLength = this.props.instanceSourceDataLength
        let instanceTargetDataLength = this.props.instanceTargetDataLength
        let instanceSourceData = this.props.instanceSourceData
        let instanceTargetData = this.props.instanceTargetData
        return(
            <>
            <div className="card-body">
                <div className="scrollingContainer">
                    <div className="row" id="instanceSourceData">
                        {
                            (instanceSourceDataLength > 0 && instanceTargetDataLength < 0) ? <BusinessEntitySection1 instanceSourceData = {instanceSourceData} instanceTargetData = {instanceTargetData} /> : 
                            (instanceSourceDataLength < 0 && instanceTargetDataLength > 0) ? <BusinessEntitySection2 instanceSourceData = {instanceSourceData} instanceTargetData = {instanceTargetData} /> : 
                            (instanceSourceDataLength > instanceTargetDataLength) ? <BusinessEntitySection3 instanceSourceData = {instanceSourceData} instanceTargetData = {instanceTargetData} /> : 
                            (instanceSourceDataLength < instanceTargetDataLength) ? <BusinessEntitySection4 instanceSourceData = {instanceSourceData} instanceTargetData = {instanceTargetData} /> :
                            (instanceSourceDataLength > 0 && instanceTargetDataLength > 0) ? <BusinessEntitySection5 instanceSourceData = {instanceSourceData} instanceTargetData = {instanceTargetData} /> : 
                            <div id="errorMessageSummarynew" className='col-lg-12'>
                                <div class="alert alert-danger" role="alert">There is no entities to show releted to your selected connector</div>
                            </div>
                        }

                        {/* {
                            (instanceSourceDataLength > 0 || instanceTargetDataLength > 0) ? <BusinessEntitySection5 instanceSourceData = {instanceSourceData} instanceTargetData = {instanceTargetData} /> : null
                        } */}


                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default BusinessEntities