import React from 'react';
import './egesetup.css'

import ChooseEGEVersion from './ChooseVersion/ChooseVersion'
import SelectEGEConnector from './Selectconnector/Selectconnector'
import API from '../../api';

class EgeSetup extends React.Component {
    render() {
        return (
            <div>
                <h3 className="newconnector-title">
                    EGE Setup
                </h3>
                <SelectEGEConnector />
            </div>
        )
    }
}

export default EgeSetup