import React from 'react';
import Select from 'react-select';
import $ from 'jquery'

import NewMappings from './NewMappings'
import StoreMappings from './storeMappings';

class Addingform extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSourceMethod : null,
            selectedTargetMethod : null,
            newMapping : [],
            iserptoecomDefault: false,
            isecomtoerpDefault: false
        }
    }
    getSourceValue = (event) =>{
        this.setState({
            selectedSourceMethod : event.value,
        })
    }
    getTargetValue = (event) =>{
        this.setState({
            selectedTargetMethod : event.value,
        })
    }
    erptoecomDefault = (event) =>{
        this.setState({
            iserptoecomDefault : event.target.checked,
        })
    }
    ecomtoerpDefault = (event) =>{
        this.setState({
            isecomtoerpDefault : event.target.checked,
        })
    }
    addMapping = (event) =>{
        let selectedSourceMethod = this.state.selectedSourceMethod
        let selectedTargetMethod = this.state.selectedTargetMethod
        let erptoecomDefault = this.state.iserptoecomDefault
        let ecomtoerpDefault = this.state.isecomtoerpDefault

        if(selectedSourceMethod != null && selectedTargetMethod != null){
            var selectedMethod = [
                {
                    "selectedSourceMethod": selectedSourceMethod,
                    "selectedTargetMethod": selectedTargetMethod,
                    "erptoecomDefault": erptoecomDefault,
                    "ecomtoerpDefault": ecomtoerpDefault
                }
            ];
            var existingMethod = {
                addingtoexisting: this.state.newMapping
            };
            selectedMethod.map(function(item) {        
                existingMethod.addingtoexisting.push({ 
                    "selectedSourceMethod" : item.selectedSourceMethod,
                    "selectedTargetMethod" : item.selectedTargetMethod,
                    "erptoecomDefault" : item.erptoecomDefault,
                    "ecomtoerpDefault" : item.ecomtoerpDefault
                });
            })
            this.setState({
                newMapping : existingMethod.addingtoexisting
            })
        }else if((selectedSourceMethod && selectedTargetMethod) === null ){
            $("#valid"+event.target.id+" .validControlSource .css-yk16xz-control").addClass("invalid")
            $("#valid"+event.target.id+" .validControlTarget .css-yk16xz-control").addClass("invalid")
        }else if(selectedSourceMethod === null){
            $("#valid"+event.target.id+" .validControlSource .css-yk16xz-control").addClass("invalid")
        }else if(selectedTargetMethod === null){
            $("#valid"+event.target.id+" .validControlTarget .css-yk16xz-control").addClass("invalid")
        }
    }
    render(){
        let addedMappings = this.state.newMapping
        return(
            <>
                <div className="row">
                    <div className="col-lg-12 pr-0">
                        <form id={"valid"+this.props.id} class="row">
                            <div class="col">
                                <label for="validationCustom01">Select ERP Method</label>
                                <Select 
                                    className="validControlSource"
                                    options={this.props.sourceMethodsGet}
                                    placeholder={"Select ERP Method..."}
                                    onChange={this.getSourceValue}
                                />
                            </div>
                            <div class="col">
                                <label for="validationCustom01">Select Ecommerce Method</label>
                                <Select 
                                    className="validControlTarget"
                                    options={this.props.targetMethodsGet}
                                    placeholder={"Select Ecommerce Method..."}
                                    onChange={this.getTargetValue}
                                />
                            </div>
                            <div class="col">
                                <div className='row'>
                                    <div className='col text-center'>
                                        <label className=' fntsize13' for="validationCustom01">ERP-Ecom</label>
                                        <div class="form-check mb-2 custom-checkbox">
                                            <input class="form-check-input custom-control-input" type="checkbox" id={"paymenterptoecom"+this.props.id} onChange={this.erptoecomDefault} />
                                            <label class="custom-control-label" for={"paymenterptoecom"+this.props.id}></label>
                                        </div>
                                    </div>
                                    <div className='col text-center custom-checkbox'>
                                        <label className=' fntsize13' for="validationCustom01">Ecom-ERP</label>
                                        <div class="form-check mb-2 custom-checkbox">
                                            <input class="form-check-input custom-control-input" type="checkbox" id={"paymentecomtoerp"+this.props.id} onChange={this.ecomtoerpDefault} />
                                            <label class="custom-control-label" for={"paymentecomtoerp"+this.props.id}></label>
                                        </div>
                                    </div>
                                    <div className='col text-center'>
                                        <label for="validationCustom01">Action</label>
                                        <button type="button" class="btn btn-primary addButtonnew" id={this.props.id} onClick={this.addMapping}>
                                            <i class="uil-plus-circle" id={this.props.id}></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <NewMappings addedMappings={addedMappings} id={this.props.id} />
                        <StoreMappings addedMappings={addedMappings} id={this.props.id} defaultMappings={this.props.defaultMappings} />
                    </div>
                </div>
            </>
        )
    }
}
export default Addingform