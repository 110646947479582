import React from 'react';

class BusinessEntitiesCount extends React.Component {
    render(){
        return(
            <>
            {
                this.props.instanceSourceDataLength > 0 && this.props.instanceTargetDataLength < 0 ? this.props.instanceSourceDataLength :
                this.props.instanceSourceDataLength < 0 && this.props.instanceTargetDataLength > 0 ? this.props.instanceTargetDataLength :
                this.props.instanceSourceDataLength < this.props.instanceTargetDataLength ? this.props.instanceTargetDataLength :
                this.props.instanceSourceDataLength > this.props.instanceTargetDataLength ? this.props.instanceSourceDataLength :
                this.props.instanceSourceDataLength === this.props.instanceTargetDataLength ? this.props.instanceTargetDataLength  : 0
            }
            </>
        )
    }
}

export default BusinessEntitiesCount