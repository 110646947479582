import React from 'react';
import $ from 'jquery'

class BusinessEntitySection5 extends React.Component {
    render(){
        let instanceSourceData = this.props.instanceSourceData
        let instanceTargetData = this.props.instanceTargetData

        const allStations = [...instanceSourceData , ...instanceTargetData ];

        return(
            <>
                {allStations.map((item,i)=>
                    <div className={"col-lg-5 lengthnew lengthnewCopy removeDuplication"+item.entityId}>
                        <div className="card myconnector-businessentity-new text-white text-center p-3">
                            <h5 className="mt-0 text-white businessentity-title mb-1">{item.entityName}</h5>
                            <div className="syncContainer">
                                <div className="erpCircle float-left">
                                    <span className="endtext">ERP</span>
                                </div>
                                <div className="erp-ec-dir float-left">
                                    <span className="erp-ecomm-dir-container">
                                        {instanceSourceData.map(itemT=>
                                            (item.entityId === itemT.entityId) ? <span className="syncnmbr"> {itemT.totalCount} </span>  :
                                            null
                                        )}
                                        <span className="syncnmbr"> 0 </span>
                                    </span>
                                    <span class="ecomm-erp-dir-container">
                                        {instanceTargetData.map(itemT=>
                                            (item.entityId === itemT.entityId) ? <span className="syncnmbr"> {itemT.totalCount} </span>  :
                                            null
                                        )}
                                        <span className="syncnmbr"> 0 </span>
                                    </span>
                                </div>
                                <div className="ecommCircle float-left">
                                    <span className="endtext">EC</span>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }
}

export default BusinessEntitySection5