import React from 'react';
import Select from 'react-select';
import $ from 'jquery'

import NewMappings from './NewMappings'
import StoreMappings from './storeMappings';

class Addingform extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSourceMethod : null,
            selectedTargetMethod : [],
            newMapping : [],
            iserptoecomDefault: false,
            isecomtoerpDefault: false,
            selectedTargetValues:[],
            canUpdate : false
        }
    }
    getSourceValue = (event) =>{
        this.setState({
            selectedSourceMethod : event.value,
        })
    }
    getTargetValue = (event) =>{
        this.setState({
            selectedTargetMethod : event,
        })
    }

    productattributeSTTcanUpdate = (event) =>{
        this.setState(
            {
                canUpdate: event.target.checked
            }
        )
    }

    addMapping = (event) =>{
        let selectedOptionsTargetValue = this.state.selectedTargetMethod.map(item =>
            item.label
        )

        let compareText = []
        $("#otherTable"+event.target.id+" tbody tr").each(function(){
            let newText = $(this).find("td:eq(1)").text()
            compareText.push(newText)
        })
        var array_one = selectedOptionsTargetValue
        var array_two = compareText
        var foo = [];
        $.grep(array_two, function (el) {
            if ($.inArray(el, array_one) != -1) {
                foo.push(el);
            }
        });
        let selectedSourceMethod = this.state.selectedSourceMethod;

        let selectedOptionValue = selectedSourceMethod

        if(selectedSourceMethod === null && selectedOptionsTargetValue < 1){
            $("#attributeMappingSTTselectERPmethodNew"+event.target.id).addClass("border-red")
            $("#newfinerect"+event.target.id).addClass("border-red")
        }else if(selectedSourceMethod === null){
            $("#attributeMappingSTTselectERPmethodNew"+event.target.id).addClass("border-red")
        }else if(selectedOptionsTargetValue.length < 1){
            $("#newfinerect"+event.target.id).addClass("border-red")
        }else if(foo.length > 0){
            let errorMessageNew = "Selected eCommerce method(s) <span style='color:red'>"+ foo +"</span> is/are already mapped with one of the ERP method(s)."
            $("#errorHandlingNew"+event.target.id).removeClass("d-none")
            $("#errorHandlingNew"+event.target.id).empty()
            $("#errorHandlingNew"+event.target.id).append(errorMessageNew)

            window.setTimeout(function(){
                $("#errorHandlingNew"+event.target.id).addClass("d-none")
            }, 30000); //<-- Delay in milliseconds
        }else{
            var selectedOptionValueSplit = selectedOptionValue.split("*")
            let selectederpName = selectedOptionValueSplit[0]
            let selectederpdataType = selectedOptionValueSplit[1]
            let selectederpsize = selectedOptionValueSplit[2]
            let selectederptype = selectedOptionValueSplit[3]
            let selectederpisRequired = selectedOptionValueSplit[4]
            
            $("#disabledButton"+event.target.id).addClass("d-none")
            $("#saveAllattributeMappings"+event.target.id).removeClass("d-none")
            $("#mainTableway"+event.target.id+" tbody tr td:first-child").text(selectederpName)
            let settingValue = this.state.canUpdate 
            $("#mainTablewayMain"+event.target.id+" tbody tr").each(function(){
                let findTdText = $(this).find("td:eq(3)").text()
                let findTdTextSplit  = findTdText.split("*")
                let findTdTextName = findTdTextSplit[0]
                let findTdTextDatatype = findTdTextSplit[1]
                let findTdTextSize = findTdTextSplit[2]
                let findTdTextType = findTdTextSplit[3]
                let findTdTextisRequired = findTdTextSplit[4]
                $(this).find("td:eq(0)").text(selectederpName)
                $(this).find("td:eq(1)").text(selectederpdataType)
                $(this).find("td:eq(2)").text(selectederpsize)
                $(this).find("td:eq(4)").text(findTdTextDatatype)
                $(this).find("td:eq(5)").text(findTdTextSize)
                $(this).find("td:eq(6)").text(findTdTextType)
                $(this).find("td:eq(7)").text(settingValue)
                $(this).find("td:eq(8)").text("true")
                $(this).find("td:eq(9)").text(selectederpisRequired)
                $(this).find("td:eq(10)").text(findTdTextisRequired)
                $(this).find("td:eq(3)").text(findTdTextName)
            })
            var row = $("#mainTableway"+event.target.id+" tbody").html()
            var row1 = $("#mainTablewayMain"+event.target.id+" tbody").html()
            let currentIndex = $("#mainTablewayMain"+event.target.id+" tbody tr").length
            $("#productAttributeMainAppend"+event.target.id).prepend('<tr>'+row1+'</tr>');
            $("#otherTable"+event.target.id).prepend('<tr>'+row+'</tr>');
            $("#productAttributeMainAppend"+event.target.name+" tbody tr td.deletefinal").remove()
            $("#productAttributeMainAppend"+event.target.name+" tr").each(function() {
                if (this.innerHTML === '') {
                    //this.remove()
                }
            });
            this.setState({
                selectedOptionSource : null
            })
        }

        
    }
    render(){
        let addedMappings = this.state.newMapping
        return(
            <>
                <div className="row">
                    <div className="col-lg-12 pr-0">
                        <form id={"valid"+this.props.id} class="row">
                            <table className='productAttributemain mappingFixedWidth table table-borderless mb-0'>
                                <thead>
                                    <tr>
                                        <td>
                                            <label for="validationCustom01">Select ERP Method</label>
                                            <Select
                                                id={"attributeMappingSTTselectERPmethodNew"+this.props.id} 
                                                className="validControlSource"
                                                options={this.props.sourceMethodsGet}
                                                placeholder={"Select ERP Method..."}
                                                onChange={this.getSourceValue}
                                            />
                                        </td>
                                        <td>
                                            <label for="validationCustom01">Select Ecommerce Method</label>
                                            <Select
                                                id={"newfinerect"+this.props.id} 
                                                className="validControlTarget"
                                                options={this.props.targetMethodsGet}
                                                placeholder={"Select Ecommerce Method..."}
                                                onChange={this.getTargetValue}
                                                closeMenuOnSelect={false}
                                                isMulti
                                            />
                                        </td>
                                        <td className='text-center'>
                                            <div className='row'>
                                                <div className='col'>
                                                    <label for="validationCustom01">CanUpdate</label>
                                                    <div class="form-check mb-2 custom-checkbox">
                                                        <input class="form-check-input custom-control-input" type="checkbox" id={"productAttributeCanUpdate"+this.props.id} onChange={this.productattributeSTTcanUpdate} />
                                                        <label class="custom-control-label" for={"productAttributeCanUpdate"+this.props.id}></label>
                                                    </div>
                                                </div>
                                                <div className='col'>
                                                    <label for="validationCustom01">Action</label>
                                                    <button type="button" class="btn btn-primary addButtonnew" id={this.props.id} onClick={this.addMapping}>
                                                        <i class="uil-plus-circle" id={this.props.id}></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </thead>
                            </table>
                        </form>
                        <NewMappings 
                            addedMappings={addedMappings} 
                            id={this.props.id}
                            selectedSourceMethod={this.state.selectedSourceMethod}
                            selectedTargetValues={this.state.selectedTargetMethod}
                            defaultMappings={this.props.defaultMappings}
                            canUpdate={this.state.canUpdate}
                        />
                        <StoreMappings addedMappings={addedMappings} id={this.props.id} defaultMappings={this.props.defaultMappings} />
                    </div>
                </div>
            </>
        )
    }
}
export default Addingform