import React from 'react';
import Select from 'react-select';
import $ from 'jquery'

import DeleteMappingNew from './deleteMappingNew';

class NewMappings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    
    componentWillReceiveProps(props) {
        this.setState({
            selectedSourceMethod : props.selectedSourceMethod,
            selectedTargetValues : props.selectedTargetValues,
            defaultMappings: props.defaultMappings
        })
    }

    render(){
        let selectedSourceMethod = this.state.selectedSourceMethod
        let selectedTargetValues = this.state.selectedTargetValues
        let defaultMappings = this.state.defaultMappings

        $("body").on("click", ".deleteAddedNew", function () {
            let bodyID = $(this).closest("tbody").attr("id")
            let firstTdtext = $(this).closest("tr").find("td:eq(0)").text()
            let secondTdtext = $(this).closest("tr").find("td:eq(1)").text()
            $("#productAttributeMainAppend"+bodyID+" tbody tr").each(function() {
                let sourceText =  $(this).find("td:eq(0)").text()
                let targetText =  $(this).find("td:eq(3)").text()
                if((firstTdtext === sourceText) && (secondTdtext === targetText)){
                    $(this).remove()
                }
            })
            $("#disabledButton"+bodyID).addClass("d-none")
            $("#saveAllattributeMappings"+bodyID).removeClass("d-none")
            $(this).closest("tr").remove()
        })

        return(
            <>
                <tabel id={"mainTableway"+this.props.id} className="table table-sm m-0 d-none">
                    <thead>
                        <tr>                                
                        </tr>
                    </thead>
                    <tbody>
                    {
                        selectedTargetValues && selectedTargetValues.map((o,index) =>
                            <tr>
                                <td></td>
                                <td>{o.label}</td>
                                <td className="text-center">
                                    <div className='row'>
                                        <div className='col'>
                                            <div className="custom-control custom-checkbox mt-2">
                                                {
                                                    this.props.canUpdate ? 
                                                    <>
                                                        <input type="checkbox" className="custom-control-input" defaultChecked={true} id={"existedcanupdateSTT"+this.props.id} onClick={this.productattributeSTTcanUpdate} disabled />
                                                        <label className="custom-control-label" for={"canupdateSTT"+this.props.id}></label>
                                                    </> : null
                                                }
                                                {
                                                    this.props.canUpdate === false ?
                                                    <>
                                                        <input type="checkbox" className="custom-control-input" id={"existedcanupdateSTT"+this.props.id} disabled />
                                                        <label className="custom-control-label" for={"canupdateSTT"+this.props.id}></label>
                                                    </> : null
                                                }
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <button type="button" class="deleteMapping deleteProductAttributeMappingNewlyAdded deleteAddedNew">
                                                <span></span>
                                                <i class="uil-trash-alt"></i>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </tabel>
                <tabel id={"mainTablewayMain"+this.props.id} className="table table-sm m-0 d-none">
                    <thead>
                        <tr>
                            <th>erpAttributeName</th>
                            <th>erpAttributeDataType</th>
                            <th>erpAttributeSize</th>
                            <th>ecommerceAttributeName</th>
                            <th>ecommerceAttributeDataType</th>
                            <th>ecommerceAttributeSize</th>
                            <th>ecommerceAttributeType</th>
                            <th>canUpdate</th>
                            <th>canDelete</th>
                            <th>erpIsRequired</th>
                            <th>ecommerceIsRequired</th>
                            <th> Action </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            selectedTargetValues && selectedTargetValues.map((o,index) =>
                                <tr id={"productAttributeNewRowAdded"+index}>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>{o.value}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td class=""></td>
                                    <td></td>
                                    <td></td>
                                    <td className="deletefinal text-center">
                                        <button type="button" class="deleteMapping deleteNewlyAddedrow">
                                            <span></span>
                                            <i class="uil-trash-alt"></i>
                                        </button>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </tabel>
                <table id={"productAttributeMainAppend"+this.props.id} className="table table-sm m-0 d-none">
                    <thead className="d-none">
                        <tr>
                            <th>erpAttributeName</th>
                            <th>erpAttributeDataType</th>
                            <th>erpAttributeSize</th>
                            <th>ecommerceAttributeName</th>
                            <th>ecommerceAttributeDataType</th>
                            <th>ecommerceAttributeSize</th>
                            <th>ecommerceAttributeType</th>
                            <th>canUpdate</th>
                            <th>canDelete</th>
                            <th>erpIsRequired</th>
                            <th>ecommerceIsRequired</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            defaultMappings && defaultMappings.map((item, index) =>
                                <tr id={"productAttribute"+index}>
                                    <td>{item.erpAttributeName}</td>
                                    <td>{item.erpAttributeDataType}</td>
                                    <td>{item.erpAttributeSize}</td>
                                    <td>{item.ecommerceAttributeName}</td>
                                    <td>{item.ecommerceAttributeDataType}</td>
                                    <td>{item.ecommerceAttributeSize}</td>
                                    <td>{item.ecommerceAttributeType}</td>
                                    <td>{item.canUpdate === true ? "true" : "false"}</td>
                                    <td>{item.canDelete === true ? "true" : "false"}</td>
                                    <td>{item.erpIsRequired === true ? "true" : "false"}</td>
                                    <td>{item.ecommerceIsRequired === true ? "true" : "false"}</td>
                                </tr>
                            )
                        }                                                                                                                                
                    </tbody>
                </table>
            </>
        )
    }
}
export default NewMappings