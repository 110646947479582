import React from 'react';
import $ from 'jquery'
import ConnectorLogo from '../ConnectorLogo/ConnectorLogo';
import ConnectorName from '../ConnectorName/ConnectorName';
import ConnectorShortInfo from '../ConnectorShortInfo/ConnectorShortInfo';
import SelectedConnectorInfo from '../SelectedConnectorInfo/SelectedConnectorInfo';

class ConnectorList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listofConnectors : [],
            selectedConnector : [],
            activeTab: "Details"
        }
        window.sessionStorage.setItem("connectorIndex", 0);
    }
    componentWillReceiveProps(props) {
        this.setState({
            listofConnectors: props.connectorsList,
            selectedConnector: props.connectorsList[0]
        })
        window.localStorage.setItem("connectionId", props.connectorsList[0].id)
        window.localStorage.setItem("instanceID", props.connectorsList[0].instances[0].id)
    }

    getConnectorInfo = (event) => {
        let self = this
        self.setState({
            selectedConnector: self.props.connectorsList[event.target.id],
            activeTab: "Details"
        })
        $(".custom-nav-link-new").removeClass("active")
        $("li.nav-item:first-child .custom-nav-link-new").addClass("active")
        $(".tabconnectorInfo .tab-pane-details").removeClass("active")
        $(".tabconnectorInfo .tab-pane-details:first-child").addClass("active")
        window.sessionStorage.setItem("connectorIndex", event.target.id)
        window.localStorage.setItem("connectionId", self.props.connectorsList[event.target.id].id)
        window.localStorage.setItem("instanceID", self.props.connectorsList[event.target.id].instances[0].id)
    }

    render(){
        let listofConnectors = this.state.listofConnectors
        let selectedConnectorInfo = this.state.selectedConnector
        return(
            <>
                <div className="row">
                    <ul className="row nav nav-tabs nav-tabs-custom nav-justified newtabs" role="tablist">
                        {listofConnectors.map((item, index) => 
                            <li class="nav-item nav-itemnew col-xl-4 col-sm-6">
                                <a className="nav-link firstactive" title={item.name} data-toggle="tab" id={index} href={"#v-pills-home" + item.id} role="tab" onClick={this.getConnectorInfo}>
                                    <div className="selectionProcessnew" title={item.id} id={index}>
                                    </div>
                                    <div className="card" value={index} id="MappingsID">
                                        <div className="card-body connectorItem item-active" >
                                            <div className="media pb-3">
                                                <ConnectorLogo sourceName={item.source.shortName} trgetName = {item.target.shortName} />
                                                <div className="media-body align-self-center overflow-hidden">
                                                    <ConnectorName Name = {item.name} />
                                                    <ConnectorShortInfo expiryDate ={item.expiryDate} errorCount={item.instances[0].errorCount} entityCount={item.instances[0].entityCount} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
                <SelectedConnectorInfo selectedConnectorInfo={selectedConnectorInfo} activeTab={this.state.activeTab} />
            </>
        )
    }
}

export default ConnectorList