import React, {Component} from 'react';
import { NavLink } from 'react-router-dom'
import './Sidebar.css';
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../Modal/IdleModal'

import logo from '../../assets/images/ex-logo.png'
import activemenu from '../../assets/images/activemenu.png'
import { Auth } from 'aws-amplify'

import API from "../api"

export default class Sidebar extends Component {
    constructor(props){
        super(props)
    }

    componentDidMount() {
        let self = this;
        var response = window.localStorage.getItem("accessToken");
        var response1 = window.localStorage.getItem("signInUserSessionidToken");
        
        const headers = {
            "authorization": `Bearer ${response1}`,
            "content-type": "application/json",
        };
        const body = {}
        API.post(`/Account/client`, body, { headers})
            .then(response => response)
            .then(data1 => {
                self.setState({
                    krpType: data1
                })
            }
        )
    }

    render(){
        const { match } = this.props
        return (
            <div className="vertical-menu">
                <div className="navbar-brand-box">
                    <a href="" className="logo logo-dark">
                        <span className="logo-sm">
                            <img src={logo} alt="" />
                        </span>
                        <span className="logo-lg">
                        <img src={logo} alt="" />
                        </span>
                    </a>
                    <a href="" className="logo logo-light">
                        <span className="logo-sm">
                        <img src={logo} alt="" height="22" />
                        </span>
                        <span className="logo-lg">
                        <img src={logo} alt="" height="20" />
                        </span>
                    </a>
                </div>
                <div data-simplebar className="sidebar-menu-scroll">
                    <div id="sidebar-menu">
                        <ul className="metismenu list-unstyled" id="side-menu">
                            <li className="menu-title d-none">Menu</li>
                            <li>
                                <NavLink to="/Updates" className="trupdates waves-effect">
                                    <div className="mn-circle">
                                        <img src={activemenu} className="activemenu" />
                                    </div>
                                    <i className="uil-calender"></i>
                                    <span>What's New </span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/Dashboard" className="trdashboard waves-effect">
                                    <div className="mn-circle">
                                        <img src={activemenu} className="activemenu" />
                                    </div>
                                    <i className="uil-calender"></i>
                                    <span>Dashboard</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/Myconnectors" className="trmyconnectors waves-effect">
                                    <div className="mn-circle">
                                        <img src={activemenu} className="activemenu" />
                                    </div>
                                    <i className="uil-calender"></i>
                                    <span>My Products</span>
                                </NavLink>
                            </li>
                            <li className="d-none">
                                <NavLink to="/Servicerequests" className="waves-effect">
                                    <div className="mn-circle">
                                        <img src={activemenu} className="activemenu" />
                                    </div>
                                    <i className="uil-calender"></i>
                                    <span>Service Requests</span>
                                </NavLink>
                            </li>
                            <li>
                                {/* <NavLink to="/Helpdesk" className="trhelpdesk waves-effect">
                                    <div className="mn-circle">
                                        <img src={activemenu} className="activemenu" />
                                    </div>
                                    <i className="uil-calender"></i>
                                    <span>Help Desk</span>
                                </NavLink> */}
                                <a href="http://support.i95dev.com/"  className="trhelpdesk waves-effect" target="_blank">
                                    <div className="mn-circle">
                                        <img src={activemenu} className="activemenu" />
                                    </div>
                                    <i className="uil-calender"></i>
                                    <span>Help Desk</span>
                                </a>
                            </li>
                            <li>
                                <a href="https://wiki.i95-dev.com"  className="trknowledgebase waves-effect" target="_blank">
                                    <div className="mn-circle">
                                        <img src={activemenu} className="activemenu" />
                                    </div>
                                    <i className="uil-calender"></i>
                                    <span>Knowledge Base</span>
                                </a>
                            </li>
                            <li>
                                <NavLink to="/Marketplace" className="trmarketplace waves-effect">
                                    <div className="mn-circle">
                                        <img src={activemenu} className="activemenu" />
                                    </div>
                                    <i className="uil-calender"></i>
                                    <span>Market Place</span>
                                </NavLink>
                            </li>
                            <li className="d-none">
                                <NavLink to="/configure" className="trconfiguer waves-effect">
                                    <div className="mn-circle">
                                        <img src={activemenu} className="activemenu" />
                                    </div>
                                    <i className="uil-calender"></i>
                                    <span>Configure</span>
                                </NavLink>
                            </li>
                            <li className="d-none">
                                <NavLink to="/new" className="trsettings waves-effect">
                                    <div className="mn-circle">
                                        <img src={activemenu} className="activemenu" />
                                    </div>
                                    <i className="uil-calender"></i>
                                    <span>Settings</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/EGE" className="trege waves-effect">
                                    <div className="mn-circle">
                                        <img src={activemenu} className="activemenu" />
                                    </div>
                                    <i className="uil-calender"></i>
                                    <span>EGE</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}