import React, {Component} from 'react';
import './ActiveServiceRequest.css'

import pimg from '../../assets/images/nav-magento.png';
import pimgactive from '../../assets/images/nav-magento-active.png';

export default class ActiveServiceRequest extends Component {
    render() {
        return (
            <div className="activeServiceRequestcont">
                <h4 className="card-title mb-2 card-title-wth-border">
                    <span className="float-left">Active Service Requests (02)</span>
                    <button type="button" className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector"> + New Request</button>
                    <div className="clearfix"></div>
                </h4>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card no-border no-boxshadow">
                            <div className="card-body no-padding">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                            <a className="nav-link mb-2 connectortab active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                                <div className="media pb-3">
                                                    <div className="media-body align-self-center overflow-hidden">
                                                        <div>
                                                            <h5 className="text-truncate font-size-14 custom-h5">
                                                                <a href="" className="text-dark request-title">Cannot create Source</a>
                                                            </h5>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className="">
                                                                    <p className="text-muted mb-0 font-size-10 ticket-hd">Created on</p>
                                                                    <h5 className="font-size-10 ticket-result date">20 MAR 2020</h5>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="">
                                                                    <p className="text-muted mb-0 font-size-10 ticket-hd">Messages</p>
                                                                    <h5 className="font-size-10 ticket-result messages">02</h5>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="">
                                                                    <p className="text-muted mb-0 font-size-10 ticket-hd">Priority</p>
                                                                    <h5 className="font-size-10 ticket-result priority">Normal</h5>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="">
                                                                    <p className="text-muted mb-0 font-size-10 ticket-hd">Status</p>
                                                                    <h5 className="font-size-10 ticket-result status">Open</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                            <a className="nav-link mb-2 connectortab" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                                <div className="media pb-3">
                                                    <div className="media-body align-self-center overflow-hidden">
                                                        <div>
                                                            <h5 className="text-truncate font-size-14 custom-h5">
                                                                <a href="" className="text-dark request-title">Why is my Billing not updating?</a>
                                                            </h5>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className="">
                                                                    <p className="text-muted mb-0 font-size-10 ticket-hd">Created on</p>
                                                                    <h5 className="font-size-10 ticket-result date">20 MAR 2020</h5>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="">
                                                                    <p className="text-muted mb-0 font-size-10 ticket-hd">Messages</p>
                                                                    <h5 className="font-size-10 ticket-result messages">02</h5>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="">
                                                                    <p className="text-muted mb-0 font-size-10 ticket-hd">Priority</p>
                                                                    <h5 className="font-size-10 ticket-result priority">Normal</h5>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="">
                                                                    <p className="text-muted mb-0 font-size-10 ticket-hd">Status</p>
                                                                    <h5 className="font-size-10 ticket-result status">Open</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-7 connection-info no-padding">
                                        <div className="tab-content text-muted mt-4 mt-md-0" id="v-pills-tabContent">
                                            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                                <div className="card no-border no-boxshadow">
                                                    <div className="card-header card-titlenew card-ticketnew">
                                                        <span className="float-left">
                                                            <span className="ticketid">
                                                                ID: 49406540
                                                            </span>
                                                            <span className="tickettitledesc">
                                                                Cannot create Source
                                                            </span>
                                                        </span>
                                                        <span className="connector-status float-right">
                                                            <span className="stagednew">
                                                                Open 
                                                            </span>
                                                            <span className="productionnew">
                                                                . Close Ticket
                                                            </span>
                                                            <span className="moreiconwithdot">
                                                                <i className="uil-ellipsis-v"></i>
                                                            </span>
                                                        </span>
                                                        <span className="clearfix"></span>
                                                    </div>
                                                    <div className="card-body custom-card">
                                                        <div className="support-form-container">
                                                            <form>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group input-group position-relative">
                                                                            <input type="text" className="form-control chat-input" id="validationTooltip03" placeholder="Type your Message" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>                                                        
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                            <div className="card no-border no-boxshadow">
                                                    
                                            <div className="card-header card-titlenew card-ticketnew">
                                                        <span className="float-left">
                                                            <span className="ticketid">
                                                                ID: 49406540
                                                            </span>
                                                            <span className="tickettitledesc">
                                                                Cannot create Source
                                                            </span>
                                                        </span>
                                                        <span className="connector-status float-right">
                                                            <span className="stagednew">
                                                                Open 
                                                            </span>
                                                            <span className="productionnew">
                                                                . Close Ticket
                                                            </span>
                                                            <span className="moreiconwithdot">
                                                                <i className="uil-ellipsis-v"></i>
                                                            </span>
                                                        </span>
                                                        <span className="clearfix"></span>
                                                    </div>
                                                    <div className="card-body custom-card">
                                                        <div className="support-form-container">
                                                            <form>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group input-group position-relative">
                                                                            <input type="text" className="form-control chat-input" id="validationTooltip03" placeholder="Type your Message" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                            <div className="card no-border no-boxshadow">
                                                    <div className="card-header card-titlenew">
                                                        <span className="float-left">
                                                            NAV Magento Connector
                                                        </span>
                                                        <span className="connector-status float-right">
                                                            <span className="stagednew">
                                                                Staged 
                                                            </span>
                                                            <span className="productionnew">
                                                                . Move to Production
                                                            </span>
                                                            <span className="moreiconwithdot">
                                                                <i className="uil-ellipsis-v"></i>
                                                            </span>
                                                        </span>
                                                        <span className="clearfix"></span>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="table-responsive connectorshotdesc">
                                                            <table className="table table-nowrap table-centered mb-0">
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row" colspan="4" className="text-left">Microsoft Dynamics Sevices NAV</th>
                                                                        <td className="text-right">2016</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row" colspan="4" className="border-0 text-left">Magento</th>
                                                                        <td className="border-0 text-right">CE 2.25</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row" colspan="4" className="border-0 text-left">Product Type</th>
                                                                        <td className="border-0 text-right">Standard</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <h3 className="subtitleNew"> Business Entity (03) </h3>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="card bg-info businessentity text-white-50">
                                                                    <div className="card-body paddingtop5">
                                                                        <h5 className="mt-0 text-white businessentity-title">
                                                                            Products
                                                                        </h5>
                                                                        <div className="syncContainer">
                                                                            <div className="erpCircle float-left">
                                                                                <span className="endtext">
                                                                                    ERP
                                                                                </span>
                                                                                <span className="erptoecommsync">
                                                                                    163
                                                                                </span>
                                                                            </div>
                                                                            <div className="ecommCircle float-right">
                                                                                <span className="endtext">
                                                                                    EC
                                                                                </span>
                                                                                <span className="ecommtoerpsync">
                                                                                    163
                                                                                </span>
                                                                            </div>
                                                                            <div className="clearfix"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="card bg-info businessentity text-white-50">
                                                                    <div className="card-body paddingtop5">
                                                                        <h5 className="mt-0 text-white businessentity-title">
                                                                            Sales Orders
                                                                        </h5>
                                                                        <div className="syncContainer">
                                                                            <div className="erpCircle float-left">
                                                                                <span className="endtext">
                                                                                    ERP
                                                                                </span>
                                                                                <span className="erptoecommsync">
                                                                                    163
                                                                                </span>
                                                                            </div>
                                                                            <div className="ecommCircle float-right">
                                                                                <span className="endtext">
                                                                                    EC
                                                                                </span>
                                                                                <span className="ecommtoerpsync">
                                                                                    163
                                                                                </span>
                                                                            </div>
                                                                            <div className="clearfix"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="card bg-info businessentity text-white-50">
                                                                    <div className="card-body paddingtop5">
                                                                        <h5 className="mt-0 text-white businessentity-title">
                                                                            Customers
                                                                        </h5>
                                                                        <div className="syncContainer">
                                                                            <div className="erpCircle float-left">
                                                                                <span className="endtext">
                                                                                    ERP
                                                                                </span>
                                                                                <span className="erptoecommsync">
                                                                                    163
                                                                                </span>
                                                                            </div>
                                                                            <div className="ecommCircle float-right">
                                                                                <span className="endtext">
                                                                                    EC
                                                                                </span>
                                                                                <span className="ecommtoerpsync">
                                                                                    163
                                                                                </span>
                                                                            </div>
                                                                            <div className="clearfix"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}