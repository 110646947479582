import React from 'react';
import SessionTimeout from '../SessionTimeout'
import $ from 'jquery'
import { NavLink, Redirect } from 'react-router-dom'
import {Header} from '../Shared/Header';
import Sidebar from '../Shared/Sidebar';
import API from '../api';

import SlidingPane from 'react-sliding-pane';
import Newconnector from '../NewConnector/Newconnector';
import  Connector from '../NewConnector/IntegrationType/MarketplaceIntegration';

import marketplace_icon from '../../assets/images/marketplace_icon.svg'
import knowledgebase from '../../assets/images/knowledgebase.svg'
import customersupport from '../../assets/images/customersupport.svg'
import closeicon from '../../assets/images/close-icon.svg';
import partnerprogram from '../../assets/images/partnerprogram.png';
import partnernetwork from '../../assets/images/i95Dev-Partners-Network.png';
import './updates.css'

class Updates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: true,
            isPaneOpen: false,
            isPaneOpen1: false,
            isPaneOpen2: false,
            isLoaded:true,
            images:[],
            selectedCustomer: 1,
        };
    }
    async componentDidMount(){
        let self=this;
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };
        API.get("/MarketPlace/connectors",{headers})
        .then(response => response)
        .then(data => {
            self.setState({
                isLoaded:true,
                MarketPlacedata: data
            })      
        })
        API.get("/MarketPlace/endpoints",{headers})
        .then(response => response)
        .then(data => {
            self.setState({
                isLoaded:true,
                datapoint: data,
            }
        )
        const endpointsData = self.state.datapoint;
            Promise.all(endpointsData.data.map(endpointData => API.get(
                `/MarketPlace/endpoints/${endpointData.id}/image`,{headers}
            )))
            .then(resp =>resp)
            .then(result => {
                document.getElementById("loaderContainerwelcome").className += ' d-none';
                self.setState({
                    images:result
                });
            })
        })
    }
    togglePopup() {  
        this.setState({  
            isLoaded: !this.state.isLoaded,
        }); 
    }
    handleItems=(event)=>{
        this.setState({selectedCustomer:event.target.id})
        this.setState({isLoaded:false})
        this.setState({ isPaneOpen2: true })
    }

    handleClickStart = (e) => {
        e.preventDefault();
        this.setState({
          run: true,
        });
    };

    modalOpen() {
        this.setState({ modal: true });
      }
    
      modalClose() {
        this.setState({
          modal: false
        });
      }

      
    
        render(){
        const { run, steps } = this.state;
        let {datapoint}= this.state;
        let {images} = this.state;
        let images1 = images.map((item)=>item.data)
        return(
            <div className="container-fluid">
                <SessionTimeout />
                <Header />
                <Sidebar />
                <div className="main-content welcome">
                    <div id="loaderContainerwelcome" className="loaderContainer">
                        <div className="loader"></div>
                    </div>
                    <div className="page-content custompaddingdash">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between mb-3 pb-0">
                                        <h4 className="mb-0">  </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-5 cursor-pointer" onClick={() => this.props.history.push('/Marketplace')}>
                                    <div className="card">
                                        <div className="card-body section1">
                                            <div className="media align-items-center">
                                                <div className="mr-3">
                                                    <img src={marketplace_icon} />
                                                </div>
                                                <div className="media-body overflow-hidden">
                                                    <h5 className="font-size-16 mb-1">Market Place</h5>
                                                    <p className="text-muted mb-0">
                                                        i95Dev Cloud App Marketplace with integration products & services that empower eCommerce businesses to outperform their business objectives.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>
                                <div className="col-lg-5 cursor-pointer" onClick={() => window.open('https://wiki.i95-dev.com', '_blank')}>
                                    <div className="card">
                                        <div className="card-body section1">
                                            <div className="media align-items-center">
                                                <div className="mr-3">
                                                    <img src={knowledgebase} />
                                                </div>
                                                <div className="media-body overflow-hidden">
                                                    <h5 className="font-size-16 mb-1">Knowledge Base</h5>
                                                    <p className="text-muted mb-0">
                                                        A knowledge ecosystem that enables businesses with deep industry insights & expert support. Get a walk-through of information on all products and services here. 
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>
                                <div className="col-lg-2 cursor-pointer" onClick={() => window.open('http://support.i95dev.com/', '_blank')}>
                                    <div className="card">
                                        <div className="card-body section1">
                                            <div className="media align-items-center d-block">
                                                <div className="mr-3">
                                                    <img src={customersupport} />
                                                </div>
                                                <div className="media-body overflow-hidden">
                                                    <h5 className="font-size-16 mb-1 customersupport">Helpdesk</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="welcome-head">
                                                <h3 className="float-left"> Popular Products </h3>
                                                <button className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector"  onClick={() => this.setState({ isPaneOpen: true })}>
                                                    + New Connector
                                                </button>
                                                <div className="clearfix"></div>
                                            </div>
                                            
                                            <div className="row mt-3">
                                                {/* {datapoint && datapoint.data && datapoint.data.slice(Math.max(datapoint.data.length - 4, 0)).map((item,i)=> */}
                                                {datapoint && datapoint.data && datapoint.data.slice(0, 4).map((item,i)=>
                                                    <div className="col-lg-3">
                                                        <div className="card custom-connector cursor-pointer" id={item.id} onClick={this.handleItems}>
                                                            <div className="card-body" id={item.id}>
                                                                <div className="imgContainer" id={item.id}>
                                                                    <img src={images1[i]} id={item.id} />
                                                                </div>
                                                                <h5 id={item.id}> {item.shortName} </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="welcome-head mt-3">
                                                <h3 className="float-left"> Why Partner with Us </h3>
                                                {/* <button className="btn btn-default btn-rounded waves-effect waves-light float-right text-primary">
                                                    + Add Artifact
                                                </button> */}
                                                <div className="clearfix"></div>
                                            </div>
                                            
                                            <div className="row mt-3">
                                                <div className="col-lg-6 cursor-pointer" onClick={() => this.setState({ isPartnerprogram: true })}>
                                                    <div className="card">
                                                        <div className="card-body section1">
                                                            <div className="media align-items-center">
                                                                <div className="mr-3">
                                                                    <img src={partnerprogram} className="partnerImg" />
                                                                </div>
                                                                <div className="media-body overflow-hidden">
                                                                    <h5 className="font-size-16 mb-1">Partner Program</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 cursor-pointer" onClick={() => this.setState({ isPartnernetwork: true })}>
                                                    <div className="card">
                                                        <div className="card-body section1">
                                                            <div className="media align-items-center">
                                                                <div className="mr-3">
                                                                    <img src={partnernetwork} className="partnerImg" />
                                                                </div>
                                                                <div className="media-body overflow-hidden">
                                                                    <h5 className="font-size-16 mb-1">Partners Network</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="welcome-head mb-4">
                                                <h3> Latest Blogs </h3>
                                            </div>
                                            <div className="offersectionnew mb-3">
                                                <div className="media align-items-center cmnNewsection">
                                                    <div className="mr-3">
                                                        <img src="https://www.i95dev.com/wp-content/uploads/2021/01/Key-Roles-and-Profiles-for-eCommerce-Success-Thumbnail-Image.png" />
                                                    </div>
                                                    <div className="media-body overflow-hidden">
                                                        <h5 className="font-size-16 mb-1 updateh5">
                                                            <a href="https://www.i95dev.com/top-4-benefits-of-integrated-ecommerce-for-b2b-companies/" target="_blank">Top 4 benefits of Integrated eCommerce for B2B Companies</a>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="offersectionnew mb-3">
                                                <div className="media align-items-center cmnNewsection">
                                                    <div className="mr-3">
                                                        <img src="https://www.i95dev.com/wp-content/uploads/2021/01/How-integrated-eCommerce-benefits-retailers-and-wholesalers-400-x-250.png" />
                                                    </div>
                                                    <div className="media-body overflow-hidden">
                                                        <h5 className="font-size-16 mb-1 updateh5">
                                                            <a href="https://www.i95dev.com/how-integrated-ecommerce-benefits-retailers-and-wholesalers/" target="_blank">How integrated eCommerce benefits retailers and wholesalers?</a>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="offersectionnew">
                                                <div className="media align-items-center cmnNewsection">
                                                    <div className="mr-3">
                                                        <img src="https://www.i95dev.com/wp-content/uploads/2020/12/Driving-Transparency-with-Dynamics-Business-Central-and-Magento-Integration-400-x-250.png" />
                                                    </div>
                                                    <div className="media-body overflow-hidden">
                                                        <h5 className="font-size-16 mb-1 updateh5">
                                                            <a href="https://www.i95dev.com/magento-dynamics-business-central-integration-and-web-store-performance/" target="_blank">Dynamics 365 Business Central – Magento Integration and Web Store Performance</a>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>                                                                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SlidingPane className='connector-popup' overlayClassName='some-custom-overlay-class' isOpen={ this.state.isPaneOpen } onRequestClose={ () => { this.setState({ isPaneOpen: true }); } }>
                    <button className="popup-close" onClick={() => this.setState({ isPaneOpen: false })}>
                        <img src={closeicon} />
                    </button>
                    <Newconnector />
                </SlidingPane>

                <SlidingPane className='connector-popup welcomecustomerSupport' overlayClassName='some-custom-overlay-class' isOpen={ this.state.isPaneOpen1 } onRequestClose={ () => { this.setState({ isPaneOpen1: true }); } }>
                    <button className="popup-close" onClick={() => this.setState({ isPaneOpen1: false })}>
                        <img src={closeicon} />
                    </button>
                    <iframe id="helpdeskurl" src="https://www.i95dev.com/contact/" width="100%" frameBorder="none"></iframe>
                </SlidingPane>

                <SlidingPane className='connector-popup welcomecustomerSupport' overlayClassName='some-custom-overlay-class' isOpen={ this.state.isPartnerprogram } onRequestClose={ () => { this.setState({ isPartnerprogram: true }); } }>
                    <button className="popup-close" onClick={() => this.setState({ isPartnerprogram: false })}>
                        <img src={closeicon} />
                    </button>
                    <iframe id="helpdeskurl" src="https://www.i95dev.com/become-a-partner/" width="100%" frameBorder="none"></iframe>
                </SlidingPane>

                <SlidingPane className='connector-popup welcomecustomerSupport' overlayClassName='some-custom-overlay-class' isOpen={ this.state.isPartnernetwork } onRequestClose={ () => { this.setState({ isPartnernetwork: true }); } }>
                    <button className="popup-close" onClick={() => this.setState({ isPartnernetwork: false })}>
                        <img src={closeicon} />
                    </button>
                    <iframe id="helpdeskurl" src="https://www.i95dev.com/our-partners/" width="100%" frameBorder="none"></iframe>
                </SlidingPane>

                <SlidingPane className='connector-popup marketplacesec' overlayClassName='some-custom-overlay-class' isOpen={ this.state.isPaneOpen2 } onRequestClose={ () => { this.setState({ isPaneOpen2: true }); } }>
                    <button className="popup-close" onClick={() => this.setState({ isPaneOpen2: false })}>
                        <img src={closeicon} />
                    </button>
                    {this.state.isLoaded?<div> </div>:  <Connector  id={this.state.selectedCustomer} closePopup={this.togglePopup.bind(this)} />}
                </SlidingPane>
            </div>
        )
    }
}
export default Updates