import React, {Component} from 'react';
import $ from 'jquery'
import './awssetup.css'
import MagentoInstance from '../MagentoInstance/MagentoInstance';
import API from '../../../api';

export default class ChooseVersion extends Component {
    constructor(props){
        super(props);
       this.state={
        showPopup: false,
       }
    }

    togglePopup() {
        $(".aws_version-field").each(function() {
            if($(this).val() === "" || $(this).val() === null){
                $(this).addClass("errorStateSelect")
            }
        })

        if($("#workspaceName").val() === "testing" || $("#workspaceName").val() === "Testing" || $("#workspaceName").val() === "internal" || $("#workspaceName").val() === "Internal"){
            $("#workspaceName").addClass("errorStateSelect")
            $("#awsworkspaceerrorInfoMessage").removeClass("d-none")
            return
        }else{
            $("#awsworkspaceerrorInfoMessage").addClass("d-none")
        }

        let allAreFilled = true;
        document.getElementsByClassName("aws_version-field").forEach(function(i){
            if (!allAreFilled) {
                return;
            }
            if (!i.value) {
                allAreFilled = false;
            }
        })
        if (!allAreFilled) {
            return
        }
        this.setState({
            showPopup: !this.state.showPopup
        });

        $("#workspaceName").val().toLowerCase()
    }

    handleChange1 = (event,i) => {
        let newValue = event.target.value;
        if(newValue != ""){
            event.target.classList.add('notEmptyfield')
            event.target.classList.remove('errorStateSelect')
        }else{
            event.target.classList.remove('notEmptyfield')
            event.target.classList.add('errorStateSelect')
        }

        this.setState({[event.target.name]: event.target.value});
    }

    handleChange=(event)=>{
        let newValue = event.target.value;
        let awsworkspacename = event.target.id
        if(awsworkspacename === "workspaceName"){
            (event.target.value).toLowerCase()
        }
        if(newValue != ""){
            event.target.classList.add('notEmptyfield')
            event.target.classList.remove('errorStateSelect')
        }else{
            event.target.classList.remove('notEmptyfield')
            event.target.classList.add('errorStateSelect')
        }
        this.setState({[event.target.name]: event.target.value});
      }
      handleSubmit = (event) => {
           const {...inputfields}=this.state;
            event.preventDefault();
        }
    render() {
        $("html").on("click", function(){
            $(".errorInfoMessage").addClass("d-none")
        })
        return (
            <div className="awssetup">
                <h4 className="newconnector-sub-title">
                    <span className="float-left color-blue prev-back" onClick={this.props.closePopup}> <i className="uil-angle-left"></i> Back</span>
                    <span className="float-left">AWS Setup</span>
                    <span className="clearfix"></span>
                </h4>
                <div className="card mt-3 m-0 no-boxshadow">
                    <div className="card-body no-padding">
                        <form id="aws_setup" name="awsSetup" onSubmit={this.handleSubmit}>
                            <div className="row">
                                {this.props.features && this.props.features.data && this.props.features.data.slice().reverse().map(itemv =>
                                    {
                                        if(itemv.groupName === "aws" || itemv.groupName === "AWS" ){
                                            return(
                                                <div className="col-md-6">
                                                {
                                                    (itemv.type == "DropDown" || itemv.type == "Dropdown") ? 
                                                    <div className="form-group">
                                                        <select className="form-control aws_version-field version-field" id={itemv.code} name={itemv.id} onChange={this.handleChange1}>
                                                            <option value="" data-default>{itemv.text}</option>
                                                            {itemv.questionOptions.map(value=>
                                                                <option value={value.code} >{value.displayName}</option>
                                                            )}
                                                        </select>
                                                    </div>:
                                                    (itemv.type == "Text") ?
                                                    <div className="form-group">
                                                        <input type="text" className="form-control aws_version-field version-field" name={itemv.id} placeholder={itemv.text}  id={itemv.code}  onChange={this.handleChange} />
                                                        <span className="errorInfoicon">
                                                            i
                                                        </span>
                                                        <span id="awsworkspaceerrorInfoMessage" className="errorInfoMessage d-none"> 
                                                            <p>
                                                                Please provide Workspace name
                                                                <ul className="validationFormatlist">
                                                                    <li>Must contain Lowercase characters and Numbers</li>
                                                                    <li>Must not contain Uppercase characters</li>
                                                                    <li>Must not contain <b>Testing</b> or <b>Internal</b> </li>
                                                                </ul>
                                                             </p>
                                                        </span>
                                                    </div>: null
                                                }
                                                </div>
                                            )
                                        }
                                    }
                                )}
                            </div>
                            <div className="row">
                                <div className="col-lg-12 float-right">
                                    <button id="awsSubmitnew" className="btn btn-primary btn-rounded waves-effect waves-light float-right awsnext" onClick={this.togglePopup.bind(this)}> Next </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {this.state.showPopup ? <MagentoInstance closePopup={this.togglePopup.bind(this)}  features={this.props.features} aws={this.state}/> : null}
            </div>
        )
    }
}