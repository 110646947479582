import React from 'react';

import nav_gp from '../../../../assets/images/nav_gp.svg'
import magento from '../../../../assets/images/magento.svg'
import bc from '../../../../assets/images/bcnew.png'
import EGE from '../../../../assets/images/EGE.svg'
import bigcommerce from '../../../../assets/images/bigcommerce.png'
import qb from '../../../../assets/images/qb_thumb.png'
import shopify from '../../../../assets/images/shopify.svg'

class ConnectorLogo extends React.Component {
    render(){
        return(
            <>
                <div className="mr-3">
                    <div className="img-cont-full img-cont-1">
                        {
                            this.props.sourceName === "EGE" ? <img src={EGE} /> : 
                            this.props.sourceName === "Magento" ? <img src={magento} /> : 
                            (this.props.sourceName === "Nav") || (this.props.sourceName === "GP") || (this.props.sourceName === "AX") ? <img src={nav_gp} /> :                                                                          
                            (this.props.sourceName === "BC") || (this.props.sourceName === "Business Central") ? <img src={bc} /> :
                            this.props.sourceName === "BigCommerce" ? <img src={bigcommerce} /> : 
                            this.props.sourceName === "QuickBooks" ? <img src={qb} /> : 
                            this.props.sourceName === "Shopify" ? <img src={shopify} /> : null
                        }
                    </div>
                    <div className="img-cont-full img-cont-2">
                        {
                            this.props.trgetName === "EGE" ? <img src={EGE} /> : 
                            this.props.trgetName === "Magento" ? <img src={magento} /> : 
                            (this.props.trgetName === "Nav") || (this.props.trgetName === "GP") || (this.props.trgetName === "AX") ? <img src={nav_gp} /> : 
                            (this.props.trgetName === "BC") || (this.props.trgetName === "Business Central") ? <img src={bc} /> :
                            this.props.trgetName === "BigCommerce" ? <img src={bigcommerce} /> :
                            this.props.trgetName === "QuickBooks" ? <img src={qb} /> : 
                            this.props.trgetName === "Shopify" ? <img src={shopify} /> : null
                        }
                    </div>
                </div>
            </>
        )
    }
}

export default ConnectorLogo