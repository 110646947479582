import React from 'react';
import './viewinfo.css'
import API from '../../../../api';

class Viewinfo extends React.Component {
    constructor(props) {
        super(props);
        this.state={
        }
    }

    componentDidMount(){
        ///api/Client/connectors/{id}/questionanswers
        let self=this;
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };           
        API.post("/Client/connectors/362/questionanswers",{headers})
        .then(response => response)
        .then(data => {
            self.setState({
                isLoaded:true,
                questionsData: data 
            })
        })
    }

    editAWSDetails=(event)=>{
        let providedAwsSetupDetails = document.getElementById("providedAwsSetupDetails")
        let aws_setup = document.getElementById("aws_setup")

        providedAwsSetupDetails.classList.add("d-none")
        aws_setup.classList.remove("d-none")
    }
    updateAWSDetails=(event)=>{
        let providedAwsSetupDetails = document.getElementById("providedAwsSetupDetails")
        let aws_setup = document.getElementById("aws_setup")

        providedAwsSetupDetails.classList.remove("d-none")
        aws_setup.classList.add("d-none")
    }
    editMagentoDetails=(eventt)=>{
        let magentoInstanceUpdate = document.getElementById("magentoInstanceUpdate")
        let providedMagentoSetupDetails = document.getElementById("providedMagentoSetupDetails")

        providedMagentoSetupDetails.classList.add("d-none")
        magentoInstanceUpdate.classList.remove("d-none")
    }

    render() {
        console.log(this.state.questionsData)
        return (
            <div className="row viewdetails egeViewDetails">
                <div className="col-lg-12">
                    <div className="card no-boxshadow">
                        <div className="card-header">
                            <h3 className="heading"> View Aws and Magento Details </h3>
                        </div>
                        <div className="card-body pt-0">
                            <div className="row">
                                <div className="card no-boxshadow no-padding">
                                    <div className="card-body no-boxshadow no-padding">
                                        <div className="row pt-3">
                                            <div className="col-md-3">
                                                <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                    <a className="nav-link mb-2 active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                                        AWS Details
                                                    </a>
                                                    <a className="nav-link mb-2" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                                        Magento Instance Details
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="tab-content text-muted mt-4 mt-md-0" id="v-pills-tabContent">
                                                    <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                                        <div className="form-section mt-3">
                                                            <div id="providedAwsSetupDetails" className="providedAwsSetupDetails">
                                                                <div class="row">
                                                                    <div class="col-md-12">
                                                                        <button id="awsEditnew" class="btn btn-primary btn-rounded waves-effect waves-light float-right awsnext awsUpdate" onClick={this.editAWSDetails}>
                                                                            Edit AWS Details
                                                                        </button>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <label className="form-label">Workspace Name</label>
                                                                        <div class="form-group">
                                                                            <p> Praveen Kumar </p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <label className="form-label">Plan</label>
                                                                        <div class="form-group">
                                                                            <p>
                                                                                8 core CPU, 32 GiB RAM, moderate performance
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <label className="form-label">Region</label>
                                                                        <div class="form-group">
                                                                            <p> Asia Pacific (Mumbai) </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <form id="aws_setup" name="awsSetup" className="d-none">
                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <label className="form-label">Workspace Name</label>
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control aws_version-field version-field" name="12" placeholder="Enter Workspace Name" defaultValue={"shop_profile_data.NAME"} id="workspaceName" />
                                                                            <span class="errorInfoicon">i</span>
                                                                            <span id="awsworkspaceerrorInfoMessage" class="errorInfoMessage awsUpdateerrorinfo d-none">
                                                                                <p>
                                                                                    Please provide Workspace name
                                                                                    <ul class="validationFormatlist">
                                                                                        <li>Must contain Lowercase characters and Numbers</li>
                                                                                        <li>Must not contain Uppercase characters</li>
                                                                                        <li>Must not contain <b>Testing</b> or <b>Internal</b> </li>
                                                                                    </ul>
                                                                                </p>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <label className="form-label">Plan</label>
                                                                        <div class="form-group">
                                                                            <select class="form-control aws_version-field version-field" id="plan" name="10" defaultValue={"shop_profile_data.NAME"}>
                                                                                <option value="" data-default="true">Select Plan</option>
                                                                                <option value="t2.2xlarge">8 core CPU, 32 GiB RAM, moderate performance</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <label className="form-label">Region</label>
                                                                        <div class="form-group">
                                                                            <select class="form-control aws_version-field version-field" id="region" name="11" defaultValue={"shop_profile_data.NAME"}>
                                                                                <option value="" data-default="true">Select Region</option>
                                                                                <option value="ap-south-1">Asia Pacific (Mumbai)</option>
                                                                                <option value="eu-west-3">Europe (Paris)</option>
                                                                                <option value="us-east-2">US East (Ohio)</option>
                                                                                <option value="eu-west-1">Europe (Ireland)</option>
                                                                                <option value="eu-west-2">Europe (London)</option>
                                                                                <option value="us-west-2">US West (Oregon)</option>
                                                                                <option value="us-west-1">US West (N. California)</option>
                                                                                <option value="ap-southeast-2">Asia Pacific (Sydney)</option>
                                                                                <option value="ca-central-1">Canada (Central)</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-lg-12 float-right">
                                                                        <button id="awsSubmitnew" class="btn btn-primary btn-rounded waves-effect waves-light float-right awsnext awsUpdate" onClick={this.updateAWSDetails}> Update AWS Details </button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                                        <div className="form-section mt-3">                                                       
                                                            <div id="providedMagentoSetupDetails" className="providedMagentoSetupDetails">
                                                                <div class="row">
                                                                    <div class="col-md-12">
                                                                        <button id="awsEditnew" class="btn btn-primary btn-rounded waves-effect waves-light float-right awsnext magentoInstanceUpdate" onClick={this.editMagentoDetails}>
                                                                            Edit AWS Details
                                                                        </button>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <label className="form-label">Workspace Name</label>
                                                                        <div class="form-group">
                                                                            <p> Praveen Kumar </p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <label className="form-label">Plan</label>
                                                                        <div class="form-group">
                                                                            <p>
                                                                                8 core CPU, 32 GiB RAM, moderate performance
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <label className="form-label">Region</label>
                                                                        <div class="form-group">
                                                                            <p> Asia Pacific (Mumbai) </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <form name="magentoInstance">
                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <label className="form-label">Magento Admin FirstName</label>
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control magento_version-field  version-field" name="9" placeholder="Enter Magento Admin FirstName" id="adminFirstname" />
                                                                            <span id="adminFirstnameerrorInfoicon" class="errorInfoicon">i</span>
                                                                            <span id="adminFirstnameerrorInfoMessage" class="errorInfoMessage d-none"></span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <label className="form-label">Magento Admin Lastname</label>
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control magento_version-field  version-field" name="8" placeholder="Enter Magento Admin Lastname" id="adminLastName" />
                                                                            <span id="adminLastNameerrorInfoicon" class="errorInfoicon">i</span>
                                                                            <span id="adminLastNameerrorInfoMessage" class="errorInfoMessage d-none"></span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <label className="form-label">Magento Admin Username</label>
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control magento_version-field  version-field" name="7" placeholder="Enter Magento Admin Username" id="adminUsername" />
                                                                            <span id="adminUsernameerrorInfoicon" class="errorInfoicon">i</span>
                                                                            <span id="adminUsernameerrorInfoMessage" class="errorInfoMessage d-none"></span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <label className="form-label">Magento Admin Password</label>
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control magento_version-field  version-field" name="6" placeholder="Enter Magento Admin Password" id="adminPassword" />
                                                                            <span id="adminPassworderrorInfoicon" class="errorInfoicon">i</span>
                                                                            <span id="adminPassworderrorInfoMessage" class="errorInfoMessage d-none">
                                                                                <p>
                                                                                    Please provide Magento admin Password
                                                                                    <ul class="validationFormatlist">
                                                                                        <li>Must be 8 or more characters in length</li>
                                                                                        <li>Must contain 1 or more uppercase characters</li>
                                                                                        <li>Must contain 1 or more numbers</li>
                                                                                        <li>Must contain 1 or more special characters</li>
                                                                                    </ul>
                                                                                </p>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <label className="form-label">Database Username</label>
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control magento_version-field  version-field" name="5" placeholder="Enter Database Username" id="databaseusername" />
                                                                            <span id="databaseusernameerrorInfoicon" class="errorInfoicon">i</span>
                                                                            <span id="databaseusernameerrorInfoMessage" class="errorInfoMessage d-none"></span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <label className="form-label">Database Password</label>
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control magento_version-field  version-field" name="4" placeholder="Enter Database Password" id="databasePassword" />
                                                                            <span id="databasePassworderrorInfoicon" class="errorInfoicon">i</span>
                                                                            <span id="databasePassworderrorInfoMessage" class="errorInfoMessage d-none">
                                                                                <p>
                                                                                    Please provide Database Password
                                                                                    <ul class="validationFormatlist">
                                                                                        <li>Must be 8 or more characters in length</li>
                                                                                        <li>Must contain alphanumeric characters</li>
                                                                                        <li>Must not contain Uppercase characters</li>
                                                                                        <li>Must not contain any special characters</li>
                                                                                    </ul>
                                                                                </p>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <label className="form-label">Magento Admin Email</label>
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control magento_version-field  version-field" name="3" placeholder="Enter Magento Admin Email" id="adminEmail" />
                                                                            <span id="adminEmailerrorInfoicon" class="errorInfoicon">i</span>
                                                                            <span id="adminEmailerrorInfoMessage" class="errorInfoMessage d-none">
                                                                                Please provide Magento admin email
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <label className="form-label">Currency</label>
                                                                        <div class="form-group">
                                                                            <select class="form-control magento_version-field version-field" id="currency" name="2">
                                                                                <option value="">Select Currency</option>
                                                                                <option value="USD">US Dollar (USD)</option>
                                                                                <option value="INR">Indian Rupee (INR)</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <label className="form-label">Timezone</label>
                                                                        <div class="form-group">
                                                                            <select class="form-control magento_version-field version-field" id="timezone" name="1">
                                                                                <option value="">Select Timezone</option>
                                                                                <option value="America/Cuiaba">Amazon Standard Time (America/Cuiaba)</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-lg-12 float-right">
                                                                        <button id="awsMagentonewmove" class="btn btn-primary btn-rounded waves-effect waves-light float-right awsnext awsUpdate"> Update Magento Instance Details </button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        )
    }
}

export default Viewinfo