import React, {Component} from 'react';
import './viewdetails.css'
import  Connectorversion from '../../NewConnector/ChooseVersion/ChooseVersion';
import API from '../../api';

class ViewDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datapointintegration:[],
            isLoaded:true,
            selectedCustomer: 1,
            testingid: this.props.id,
            imageType:[],
            sourceName:'',
            targetName:''
        }; 
    }
    togglePopup() {  
        this.setState({  
            isLoaded: !this.state.isLoaded
        }); 
    }
    handleItems=(event)=>{
        this.setState({selectedCustomer:event.target.id})
        this.setState({sourceName:event.target.name});
        this.setState({targetName:event.target.title});
        this.setState({isLoaded:false})
    }
    render() {
        return (
            <div className="row viewdetails">
                <div className="col-lg-12">
                    <div className="card no-boxshadow">
                        <div className="card-header">
                            <h3 className="heading"> {this.props.title} </h3>
                            <h4 className="subheading"> by <span className="text-primary"> i95dev </span> </h4>
                        </div>
                        <div className="card-body pt-0">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="pt-3 pr-3 brdrrgt">
                                        <h6 className="subTitlenewmn"> Overview </h6>
                                        <p className="subDescriptionmn"> i95Dev's Dynamics 365 Connect helps you integrate your Magento e-commerce store and your Microsoft Dynamics 365 Finance & Operations systems. It combines the scalability and intelligence of Microsoft Dynamics 365 Finance & Operations with the flexibility and features of Magento, driving operational efficiency, and unparalleled customer experience.
                                            i95Dev’s Dynamics 365 Connect is more than just information sync and helps you engage customers, empower employees, optimize operations, and grow business.
                                            Dynamics 365 Connect has two components - Magento component and Dynamics 365 Finance & Operations component; you need both the components for integration to work. You can download the Magento component from the Magento marketplace. The i95Dev team will install the Microsoft Dynamics 365 component.
                                            The Dynamics 365 Connect is listed on Microsoft AppSource.
                                        </p>
                                        <h6 className="subTitlenewmn"> Engagement & Pricing </h6>
                                        <p className="subDescriptionmn">
                                            i95Dev's Dynamics 365 Connect helps you integrate your Magento e-commerce store and your Microsoft Dynamics 365 Finance & Operations systems. It combines the scalability and intelligence of Microsoft Dynamics 365 Finance & Operations with the flexibility and features of Magento, driving operational efficiency, and unparalleled customer experience.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-4 pr-0">
                                    <form className="pt-3">
                                        <div className="row">
                                            <div className="col-lg-12 pr-0">
                                                <div className="form-group">
                                                    <label for="default-input">Edition</label>
                                                    <select className="form-control version-field">
                                                        <option> Open Source (CE) </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 pr-0">
                                                <div className="form-group">
                                                    <label for="default-input">Your store version</label>
                                                    <select className="form-control version-field">
                                                        <option> Choose Magento Store Version </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 pr-0">
                                                <div className="form-group">
                                                    <label for="default-input">Note</label>
                                                    <textarea className="form-control version-field htnew-100" readOnly>
                                                        This is an Integration with a Third Party Service. Other charges and fees may be required to use this extension on your Store
                                                    </textarea>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 pr-0">
                                                <div className="form-group">
                                                    <button type="submit" className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector newbtn-newbtn"> Get Quote </button>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 pr-0">
                                                <div className="form-group">
                                                    <button type="reset" className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector usetrial-newbtn-newbtn" onClick={this.handleItems} id={this.props.versionid} name={this.props.srcshortName} title={this.props.trgtshortName} > Use Trial Version</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                {this.state.isLoaded?<div> </div>:  <Connectorversion versionid={this.state.selectedCustomer} name={this.state.sourceName} targetName={this.state.targetName} closePopup={this.togglePopup.bind(this)} />}
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        )
    }
}
export default ViewDetails