import React from 'react';
class AboutConnector extends React.Component {
    render(){
        let aboutConnector = this.props.about

       

        let sourceName = aboutConnector && aboutConnector.source.name 
        let sourceVersion = aboutConnector && aboutConnector.source.version
        let targetName = aboutConnector && aboutConnector.target.name 
        let targetVersion = aboutConnector && aboutConnector.target.version
        let featureName = aboutConnector && aboutConnector.featureName

        
        return(
            <>
                <div className="table-responsive connectorshotdesc">
                    <table className="table table-nowrap table-centered mb-0">
                        <tr>
                            <th scope="row" colspan="4" className="text-left">{sourceName}</th>
                            <td className="text-right">{sourceVersion}</td>
                        </tr>
                        <tr>
                            <th scope="row" colspan="4" className="border-0 text-left">{targetName}</th>
                            <td className="border-0 text-right">{targetVersion}</td>
                        </tr>
                        <tr>
                            <th scope="row" colspan="4" className="border-0 text-left">Product Type</th>
                            <td className="border-0 text-right">{featureName}</td>
                        </tr>
                    </table>
                </div>
            </>
        )
    }
}

export default AboutConnector