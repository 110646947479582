import React from 'react';
import API from '../../../../../../api'

class CloneConnector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentWillReceiveProps(props) {
        this.setState({
            selectedConnectorInfo : props.selectedConnectorInfo,
            featureType: props.featureType,
            connectorID : props.connectorId,
            sourceVersion : props.sourceVersion,
            targetVersion : props.targetVersion
        })
    }

    cloneConnector = (event) =>{
        let self = this;
        var response =window.localStorage.getItem("accessToken") ;
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        const body =
        {
            "id": this.props.connectorId,
            "sourceVersion": this.state.sourceVersion,
            "targetVersion": this.state.targetVersion,
        };
        API.post(`/MarketPlace/connectors/${this.props.connectorId}/features`, body, { headers })
        .then(response => response)
        .then(data => {
           
            self.setState({
                features: data
            });
        })
    }

    render(){
        return(
            <>
                <button className="customDownloadnew" onClick={this.cloneConnector}>
                    Clone Connector
                </button>
            </>
        )
    }
}

export default CloneConnector