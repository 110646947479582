import React from 'react';
import Select from 'react-select';
import $ from 'jquery'

class storeMappings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultMappings: []
        }
    }
    componentWillReceiveProps(props) {
        this.setState({
            defaultMappings : props.defaultMappings
        })
    }
    render(){
        let defaultMappingList = this.state.defaultMappings && this.state.defaultMappings.data
        return(
            <>
                <table id={"saveMappingPaymentMapping"+this.props.id} className='d-none'>
                    <thead className="d-none">
                        <tr>
                            <th>erpMethod</th>
                            <th>ecommerceMethod</th>
                            <th>isEcommerceDefault</th>
                            <th>isErpDefault</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.addedMappings && this.props.addedMappings.map((item, index) =>
                                <tr id={"newlyaddedrow"+this.props.id+index}>
                                    <td>{item.selectedSourceMethod.trim()}</td>
                                    <td>{item.selectedTargetMethod.trim()}</td>
                                    <td>{item.erptoecomDefault === true ? "true" : "false"}</td>
                                    <td>{item.ecomtoerpDefault === true ? "true" : "false"}</td>
                                </tr>
                            )
                        }   
                        {
                            defaultMappingList && defaultMappingList.map((item,index) =>
                                <tr id={"defaultMapping"+index}>
                                    <td>{item.erpMethod.trim()}</td>
                                    <td>{item.ecommerceMethod.trim()}</td>
                                    <td>{item.isEcommerceDefault === true ? "true" : "false"}</td>
                                    <td>{item.isErpDefault === true ? "true" : "false"}</td>
                                </tr>                        
                            )
                        }
                    </tbody>
                </table>
                
            </>
        )
    }
}
export default storeMappings