import React from 'react';
import {Header} from '../../../../Shared/Header';
import Sidebar from '../../../../Shared/Sidebar';
import axios from 'axios';
import $ from 'jquery';
import SlidingPane from "react-sliding-pane";
import Viewinfo from '../ViewInfo/Viewinfo';
import closeicon from '../../../../../assets/images/close-icon.svg';
import '../egesetupdownload.css';
import API from '../../../../api';
class EgeSetup extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      sourceMessage: "0",
      targetMessage: "0%",
            keyType:[],
            krpType:[],
            krpTypeTarget:[],
            krpTypeSource:[],
            count:0,
            status:[],
            curTime: null,
            isDisabled: false,
            targetCount:0
    }
  }
  componentDidMount() {
    let self = this;
    var response = window.localStorage.getItem("accessToken");  
    let id = window.sessionStorage.getItem("connectorIndex");
    const headers = {
      "authorization": `Bearer ${response}`,
      "content-type": "application/json"
    };
  
        API.get(`/Client/connections/${self.props.location.downloadData.id}/instances/${self.props.location.downloadData.instances[0].id}`,{headers })
        .then(response => response)
       .then(data1 => {
        document.getElementById("loaderContainer").className += ' d-none';
        self.setState({
      // isLoaded:true,
       krpType: data1 })
         })
        
  }

  handleChangeTarget=(e)=>{
    this.setState({ 
      targetMessage: "2%",
      isDisabled:true
    });
    this.setState({count:this.state.count+1});
    let self=this;
    var response =window.localStorage.getItem("accessToken") ;
    const headers={
      "authorization": `Bearer ${response}`,
      "content-type" : "application/json",
    };
    API.get('/User/Token/Check',{headers })
    .then(response => response)
    .then(data1 => {
      if(data1.data.result == false) {
        API.get('/User/Authorize',{headers })
        .then(response=>response)
        .then(data=> {
          
          //alert(JSON.stringify(data.data))
          if(self.state.count < 5){
           // alert(1)
            var win =window.open(data.data, "", "width=500,height=500");
            setTimeout(function() { win.close();}, 10000)
          }
          else{  var html = "<html><head></head><body> Authentication failed : The Request could not be completed </body></html>"

          var win = window.open(data.data, "", "width=500,height=500");

          win.document.write(html)

          setTimeout(function () { win.close(); }, 10000);}
        })
        setTimeout(function () {
          if(self.state.count<5){ self.handleChangeTarget();}
          else{
            self.setState({ count: 0 });
            }
        }, 11000);
          
      }
      else{
        var dt = new Date();
    dt.setMinutes( dt.getMinutes() + 13 );
    var time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
    //document.write( time );
    var timeNewprocess = dt.toLocaleString('en-IN', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })
    document.getElementById("etaNewApproach").innerHTML= timeNewprocess;


    var hoursLabel = document.getElementById("hours");  
      var minutesLabel = document.getElementById("minutes");
        var secondsLabel = document.getElementById("seconds");
        var totalSeconds = 0;
        var totalMinuet = 0
        var timeElapsed =setInterval(setTime, 1000);

        function setTime()
        {
            ++totalSeconds;
            secondsLabel.innerHTML = pad(totalSeconds%60);
            totalMinuet = pad(parseInt(totalSeconds/60));
            minutesLabel.innerHTML = pad(parseInt(totalSeconds/60));
            hoursLabel.innerHTML = pad(parseInt(totalMinuet/60))
        }

        function pad(val)
        {
            var valString = val + "";
            if(valString.length < 2)
            {
                return "0" + valString;
            }
            else
            {
                return valString;
            }
        }
       // e.target.disabled = true
        const setupdata = self.state.krpType.data;
        API.get(`/Client/endpoints/${setupdata.target.id}/instances/${setupdata.instances[0].id}/setup`,{headers })
        .then(response => response)
        .then(data => {
          self.setState({
          isLoaded:true,
          targetMessage: "5%",
          krpTypeTarget: data,})   
      
          if (this.state.krpTypeTarget.data === "OK") {
        const interval = setInterval(() => {
            //this.setState({ targetMessage: this.state.targetMessage +5});
          const statusdata = self.state.krpType.data;
          API.get(`/Client/endpoints/${statusdata.target.id}/instances/${statusdata.instances[0].id}/status`,{headers })
          .then(response => response)
          .then(data1 => {
             var Statusdata=data1.data
           // alert(Statusdata)
            if(Statusdata == "inprocess" || Statusdata == "Inprocess" ) {
              this.setState({ targetMessage: "7%"});
            }
            if(Statusdata == "pending" || Statusdata == "Pending" ) {
              this.setState({ targetMessage: "10%"});
            }if(Statusdata== "planning" || Statusdata== "Planning" ) {
              this.setState({ targetMessage: "18%"});
            }
            if(Statusdata == "planned" || Statusdata == "Planned" ) {
              this.setState({ targetMessage: "20%"});
            }
            if(Statusdata == "confirmed" || Statusdata == "Confirmed") {
              this.setState({ targetMessage: "30%"});
            }
            if(Statusdata == "applying"  || Statusdata == "Applying" ) {
              this.setState({ targetMessage: "70%"});
            }
            if(data1.data == "applied" || data1.data == "Applied" || data1.data == "APPLIED") {
              clearInterval(timeElapsed)
              document.getElementById("targetPercentage").classList.add("bg-success");
              this.setState({ targetMessage: "100%"});
              clearInterval(interval)
              //timeInterval
            }
            if(data1.data == "errored" || data1.data == "Errored" || data1.data == "ERRORED" || data1.data=="Deleted" ||data1.data=="deleted" ||data1.data=="DELETED" ){
              clearInterval(timeElapsed)
              document.getElementById("targetPercentage").classList.add("bg-danger");
              this.setState({ targetMessage: "Failed"});
              clearInterval(interval)
            }
          })
        }, 150000);
      }
      else if (this.state.krpTypeTarget.data !== "OK"){
        clearInterval(timeElapsed)
        document.getElementById("targetPercentage").classList.add("bg-danger");
        this.setState({ targetMessage: "Failed"});
        
      }
      else {
        clearInterval(timeElapsed)
        document.getElementById("targetPercentage").classList.add("bg-danger");
        this.setState({ targetMessage: "Failed"});
        
      }
      })
      }
    })
  }
  
  handleChangeSource=()=>{
    
    this.setState({targetCount:this.state.targetCount+1});
    let self=this;
    var response =window.localStorage.getItem("accessToken") ;
    const headers={
      "authorization": `Bearer ${response}`,
      "content-type" : "application/json",
    };

    API.get('/User/Token/Check', { headers })
        .then(response => response)
        .then(data1 => {
            if (data1.data.result == false) {
                //alert(JSON.stringify(data1.data.result))
                API.get('/User/Authorize', { headers })
                    .then(response => response)
                    .then(data => {
                        if (self.state.targetCount < 5) {
                            var win = window.open(data.data, "", "width=500,height=500");
                            setTimeout(function () { win.close(); }, 10000);
                        }
                        else {

                            var html = "<html><head></head><body> Authentication failed : The Request could not be completed </body></html>"

                            var win = window.open(data.data, "", "width=500,height=500");

                            win.document.write(html)

                            setTimeout(function () { win.close(); }, 10000);
                        }
                    })
                setTimeout(function () {
                    if (self.state.targetCount < 5) { self.handleChangeSource(); }
                    else{
                      self.setState({ targetCount: 0 });
                      }
                }, 11000);
                //this.handleSourceDownload();
            }

            else {
              const Bcdata = self.state.krpType.data;

                API.post(
                    "/Client/connections/download", {
                    "subscriptionId": Bcdata.subscriptionId,
                    "endpointId": Bcdata.source.id,
                    "instanceId": Bcdata.instances[0].id,
                    "fileType": Bcdata.source.installer.type,
                    "refreshToken": response,
                },
                    { headers, responseType: 'blob' }

                )
                    .then(resp => {
                        const disposition = resp.headers["content-disposition"];
                        var fileName = "";
                        const array1 = disposition.split(';');
                        if (array1 && array1.length > 1) {
                            const string1 = array1[1];
                            if (string1) {
                                const array2 = string1.split('=');
                                if (array2 && array2.length > 1) {
                                    fileName = array2[1];
                                }
                            }
                        }
                        const url = window.URL.createObjectURL(new Blob([resp.data], { type: 'application/zip' }));

                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', fileName);
                        link.click();
                    })
            }


        })




}


  render() {
    let krpType = this.state;
    $(".trege").addClass("active");

    let id = window.sessionStorage.getItem("connectorIndex");
    let sourcefilesize = krpType.krpType && krpType.krpType.data && krpType.krpType.data.source.installer ?
    

<div className="col-lg-6">
                  <div className="card setup-sectionnew">
                    <h4 className="card-header mt-0">BUSINESS CENTRAL </h4>
                    <div className="card-body">
                      <div className="media border-bottom">
                        <div className="media-body align-self-center overflow-hidden mr-4">
                          <div>
                            <div className="versionNew">
                              <span className="mainTitle">Version </span>
                              <span className="subTitle"> {this.props.location.downloadData.source.version} </span>
                            </div>
                            
                            <div className="timeElapsedNew">
                              <span className="mainTitle">File Size</span>
                              <span className="subTitle">{krpType.krpType && krpType.krpType.data && krpType.krpType.data.source.installer.size}</span>
                            </div>
                            <div className="timeElapsedNew invisible" >
                              <span className="mainTitle">Ecommerce Type</span>
                              <span className="subTitle"> Version</span>
                            </div>
                          </div>
                        </div>
                       
                      </div>
                    <div className="startSetupButton">
                      <button type="button" className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector" onClick={this.handleChangeSource}>
                        Download
                      </button>
                    </div>
                  </div>
                </div>
              </div>

        
        
          : <div></div>
       

    return (
      <div>
 <Header />
        <Sidebar />
        <div id="loaderContainer" className="loaderContainer">
                        <div className="loader"></div>
                    </div>
        <div class="main-content">
          <div class="page-content">
            <div class="container-fluid">
            <div class="row mr-4 mt-4">
                <div class="col-lg-12 d-none">
                  <button type="button" class="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector" onClick={() => this.setState({ isViewQustioneries: true })}>
                    View / Edit Provided Info
                  </button>
                </div>
              </div>
              <div class="row mlr-176 mt-80">
              {sourcefilesize}
              <div class="col-lg-6 mzeroauto">
                <div class="card setup-sectionnew">
                  <h4 class="card-header mt-0">EGE SETUP</h4>
                  <div class="card-body">
                    <div class="media border-bottom">
                      <div class="media-body align-self-center overflow-hidden mr-4">
                        <div>
                        <div className="timeElapsedNew">
                              <span className="mainTitle">Version</span>
                              <span className="subTitle">{this.props.location.downloadData.target.version} </span>
                            </div>
                          <div className="etaNew">
                            <span className="mainTitle"> ETA </span>
                            <span className="subTitle" id="etaNewApproach"> 00:00:00 </span>
                          </div>
                          <div className="timeElapsedNew" >
                              <span className="mainTitle">Time Elapsed</span>
                              <span className="subTitle"><label id="hours">00</label>
                  <label id="colon">:</label>
                  <label id="minutes">00</label>
                  <label id="colon">:</label>
                  <label id="seconds">00</label></span>
                            </div>
                        </div>
                      </div>
                      <div className="setupProgress">
                        <div className="setupProgresspercentage" id="targetPercentage">
                          <span className="numberPercentage">{this.state.targetMessage}</span>
                        </div>
                      </div>
                    </div>
                    <div className="startSetupButton">
                      <button type="button" disabled={this.state.isDisabled} class="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector"  onClick={this.handleChangeTarget}>
                        Start Setup
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SlidingPane className='connector-popup ege-learnmore' overlayClassName='some-custom-overlay-class' isOpen={ this.state.isViewQustioneries } onRequestClose={ () => { this.setState({ isViewQustioneries: true }); } }>
          <button className="popup-close" onClick={() => this.setState({ isViewQustioneries: false })}>
            <img src={closeicon} />
          </button>
          <Viewinfo />
        </SlidingPane>
      </div>
    </div>
    )
  }
}
export default EgeSetup