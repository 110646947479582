import React, {Component} from 'react';
import API from '../../../api';
import axios from 'axios';
import  Connectorversion from '../ChooseVersion/ChooseVersion';
//import businesscentrallimg from '../../../../assets/images/businesscentrall.png'
import businesscentrallimg from '../../../../assets/images/bcnew.png'
import egeimg from '../../../../assets/images/EGE.svg'

import  './selectconnector.css'

export default class Selectconnector extends Component {
    constructor(props) {
        super(props);
        this.state={
            isLoaded:true,
            datapointintegration:[]
        }
    }

    togglePopup() {  
        this.setState({  
            isLoaded: !this.state.isLoaded
        }); 
    }

    componentDidMount(){
        this.getEgeConnectors();
    }
    getEgeConnectors=()=>{
        let self=this;
        var response =window.localStorage.getItem("accessToken");
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };
        API.get("/MarketPlace/endpoints/ege/connectors",{headers})
        .then(response => response)
        .then(data => {
            self.setState({
                isLoaded:true,
                datapointintegration: data,
            })
        })
    }

    handleItems=(event)=>{
        this.setState({selectedCustomer:event.target.id})
        this.setState({sourceName:event.target.title});
        this.setState({targetName:event.target.value});
        window.localStorage.setItem("fullname",event.target.name);  
        this.setState({isLoaded:false})
    }

    render() {
        let {datapointintegration}=this.state;
       // console.log("errorcheck",datapointintegration)
       let {imageType}=this.state;
        //window.localStorage.setItem("image",imageType.data)
        return (
            <div className="Selectconnectorewfine">
                <h4 className="newconnector-sub-title">
                    <span className="float-left">Select an Integration Type</span>
                    <span className="clearfix"></span>
                </h4>
                <div className="row mt-3">
                    {datapointintegration && datapointintegration.data && datapointintegration.data.map(item=>
                        <div className="col-lg-6 mb-3" onClick={this.handleItems} id={item.id}>
                            <div className="card-columns card-columns-2 integration-type-listing">
                                <div className="conhoverselect" >
                                    <button type="button" className="btn btn-primary btn-rounded waves-effect waves-light new-connector" onClick={this.handleItems} id={item.id} title={item.source.shortName} value={item.target.shortName} name={item.name}>
                                        Select
                                    </button>
                                </div>
                                <div className="card no-border no-boxshadow pb-0 mb-0">
                                    <div className="card-body">
                                        <div className="connector-logo-container">
                                            <img src={businesscentrallimg} className="leftimage" />
                                        </div>
                                        <h5 className="add-connector-title"> {item.source.shortName} </h5>
                                    </div>
                                </div>
                                <div className="card no-border no-boxshadow pb-0 mb-0 attatchicon">
                                    <div className="card-body">
                                        <div className="connector-logo-container">
                                            <img src={egeimg} className="rightimage" />
                                        </div>
                                        <h5 className="add-connector-title"> {item.target.shortName} </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {
                        datapointintegration && datapointintegration.data && datapointintegration.data.length < 1 ? 
                        <div className="col-lg-12 mb-3">
                            <div className="alert alert-danger" role="alert">
                                There is no connectors to Show 
                            </div>                            
                        </div>
                         : null
                    }
                    
                </div>
                {this.state.isLoaded ? <div> </div> :  <Connectorversion versionid={this.state.selectedCustomer} closePopup={this.togglePopup.bind(this)}  name={this.state.sourceName} targetName={this.state.targetName} />}
            </div>
        )
    }
}
    