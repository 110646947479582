import React from 'react';
//import './authentication.css';
import {Header} from '../../Shared/Header';
import Sidebar from '../../Shared/Sidebar';
import API from '../../api';

class ShopifyAuthentication extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            count:0,
            targetcount:0
           // response=window.localStorage.getItem("data")
        };
    }

    handleSourceAuthenticate=()=>{
        let self = this;
        this.setState({ count: this.state.count + 1 });
        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        API.get('/User/Token/Check', { headers })
            .then(response => response)
            .then(data1 => {
                if (data1.data.result == false) {
                    //alert(JSON.stringify(data1.data.result))
                    API.get('/User/Authorize', { headers })
                        .then(response => response)
                        .then(data => {
                            if (self.state.count < 5) {
                                var win = window.open(data.data, "", "width=500,height=500");
                                setTimeout(function () { win.close(); }, 10000);
                            }
                            else {

                                var html = "<html><head></head><body> Authentication failed : The Request could not be completed </body></html>"

                                var win = window.open(data.data, "", "width=500,height=500");

                                win.document.write(html)

                                setTimeout(function () { win.close(); }, 10000);
                            }
                        })
                    setTimeout(function () {
                        if (self.state.count < 5) { self.handleSourceAuthenticate(); }
                    }, 11000);
                    //this.handleSourceDownload();
                }
                else {
                    API.get(`/Authentication/QuickBooksAuthenticate/?subscriptionConnectorId= ${this.props.match.params.Subscriptionid} &instanceType=${this.props.match.params.instanceType}`, { headers })
                         .then(response => response)
                         .then(data => {
                            window.open(data.data,"_self");
                         })
                     }

            })

    }

    handleTargetAuthenticate=()=>{
        let self = this;
        this.setState({ targetcount: this.state.targetcount + 1 });
        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        API.get('/User/Token/Check', { headers })
            .then(response => response)
            .then(data1 => {
                if (data1.data.result == false) {
                    //alert(JSON.stringify(data1.data.result))
                    API.get('/User/Authorize', { headers })
                        .then(response => response)
                        .then(data => {
                            if (self.state.targetcount < 5) {
                                var win = window.open(data.data, "", "width=500,height=500");
                                setTimeout(function () { win.close(); }, 10000);
                            }
                            else {

                                var html = "<html><head></head><body> Authentication failed : The Request could not be completed </body></html>"

                                var win = window.open(data.data, "", "width=500,height=500");

                                win.document.write(html)

                                setTimeout(function () { win.close(); }, 10000);
                            }
                        })
                    setTimeout(function () {
                        if (self.state.targetcount < 5) { self.handleTargetAuthenticate(); }
                    }, 11000);
                    //this.handleSourceDownload();
                }
                else {
                    API.get(`/Shopify/${this.props.match.params.Subscriptionid}/Authenticate/${this.props.match.params.instanceType}`, { headers })
                         .then(response => response)
                         .then(data => {
                            alert(data.data)
                            window.open(data.data,"_self");
                         })
                     }

            })

    }

    render(){
        let data=window.localStorage.getItem("data");
         data=JSON.parse(data);
        let sourceAuthentication= data.source.isAuthenticationRequired?

        <div class="col-xl-6">
        <div class="card">
            <h4 class="card-header font-size-16">{data.source.shortName} Authentication</h4>
            <div class="card-body">
                <p class="card-title-desc">
                    Dear Subsciber, <br />
                    Click Authenticate button, In order to get Authenticated with {data.source.shortName}
                </p>
                <button class="btn btn-primary float-right i95-button" type="submit" onClick={this.handleSourceAuthenticate}>Authenticate</button>
            </div>
        </div>
    </div>:null
      let targetAuthentication= data.target.isAuthenticationRequired?

      <div class="col-xl-6">
      <div class="card">
      <h4 class="card-header font-size-16">{data.target.shortName} Authentication</h4>
          <div class="card-body">
              <p class="card-title-desc">
                  Dear Subsciber, <br />
                  Click Authenticate button, In order to get Authenticated with {data.target.shortName}
              </p>
              <button class="btn btn-primary float-right i95-button" type="submit" onClick={this.handleTargetAuthenticate} disabled>Authenticated</button>
          </div>
      </div>
  </div>:null


        return(
            <div>
                <Header />
                <Sidebar />
                <div className="main-content authentication">
                    <div className="page-content pb-0">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between mb-3 pb-0">
                                        <h4 className="mb-0">Authentication</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                 {sourceAuthentication}
                                {targetAuthentication} 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ShopifyAuthentication