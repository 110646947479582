import React, {Component} from 'react';
import './newconnector.css';
import SelectConnector from './SelectConnector/SelectConnector';

import API from '../api';

export default class Newconnector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            datapoint:[],
            SelectConnectorId:1,
            isLoaded:false,
            Images:[]
        };
        this.handleAdd=this.handleAdd.bind(this);
    }
    async componentDidMount(){
        let imagesdata=window.localStorage.getItem("imagedata");
        if(imagesdata == [] || imagesdata == undefined){this.handleConnectordata();} 
    }

    handleConnectordata=()=>{

        let self=this;
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };
        API.get("/MarketPlace/endpoints",{headers})
        .then(response => response)
        .then(data => {
            window.localStorage.setItem("Content",JSON.stringify(data));
            self.setState({
                isLoaded:true,
                datapoint: data,
            })
            
            const images = self.state.datapoint;
            Promise.all(images.data.map(image => API.get(
                `/MarketPlace/endpoints/${image.id}/image`,{headers}
            )))
            .then(resp =>resp)
            .then(result => {
                window.localStorage.setItem("imagedata",JSON.stringify(result));
                //document.getElementById("loaderContainer1").className += ' d-none';
                self.setState({
                    Images:result
                });
            })
        })
 window.localStorage.setItem("Content",JSON.stringify( this.state.datapoint));
    }
    handeleEvent=()=>{
    }
    handleAdd = (idSelected) => {
        this.setState({SelectConnectorId:idSelected})
    };
    render() {
        let ContentData=window.localStorage.getItem("Content");
        ContentData=JSON.parse(ContentData);
        ContentData= ContentData? ContentData: this.state.datapoint;
        let imagesdata=window.localStorage.getItem("imagedata");
        imagesdata=JSON.parse(imagesdata);
         imagesdata= imagesdata ? imagesdata: this.state.Images;
        return (
            <div className="row">
                {/* <div id="loaderContainer1" className="loaderContainer">
                    <div className="loader"></div>
                </div>  */}
                <div className="col-lg-12">
                    <div className="card no-border no-boxshadow mb-0">
                        <div className="card-body p-0">
                            <h3 className="newconnector-title">
                                Add Connector
                            </h3>
                            <SelectConnector passedData={ContentData} imageData={imagesdata}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}