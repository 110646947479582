import React, {Component} from 'react';
import { BrowserRouter } from 'react-router';
import $ from 'jquery'
import './magentoinstance.css'
import {Link} from 'react-router-dom';
import API from '../../../api';

export default class Magentoinstance extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    toggleClose(){
        document.getElementById("notificationPopupConfirm").classList.add("d-none")
    }

    togglePopupnew() {
        $(".magento_version-field").each(function() {
            if($(this).val() === "" || $(this).val() === null){
                $(this).addClass("errorStateSelect")
            }
        })
        let allAreFilled = true;
        document.getElementsByClassName("magento_version-field").forEach(function(i){
            if (!allAreFilled) {
                return;
            }
            if (!i.value) {
                allAreFilled = false;
            }
        })
        if (!allAreFilled) {
            return
        }
        
        let adminEmail = document.getElementById("adminEmail")
        let adminPassword = document.getElementById("adminPassword") 
        let databasePassword = document.getElementById("databasePassword") 
        

        
        var dbpasswordFormat = /^(?=.*\d)(?=.*[a-z])(?=.*)[a-z0-9]{8,}$/
        var emailFormat = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        var adminPasswordFormat = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;

        if(!(adminEmail.value.match(emailFormat))){
            adminEmail.classList.add("errorStateSelect")
            document.getElementById("adminEmailerrorInfoMessage").classList.remove("d-none")
            document.getElementById("adminEmailerrorInfoMessage").textContent = "Please provide valid email"
            return false;
        }else{
            adminEmail.classList.remove("errorStateSelect")
            document.getElementById("adminEmailerrorInfoMessage").classList.add("d-none")
            document.getElementById("adminEmailerrorInfoMessage").textContent = "Magento admin email"
        }

        if(!(adminPassword.value.match(adminPasswordFormat))){
            adminPassword.classList.add("errorStateSelect")
            document.getElementById("adminPassworderrorInfoMessage").classList.remove("d-none")
            return false;
        }else{
            adminPassword.classList.remove("errorStateSelect")
            document.getElementById("adminPassworderrorInfoMessage").classList.add("d-none")
        }
        if(!(databasePassword.value.match(dbpasswordFormat))){
            databasePassword.classList.add("errorStateSelect")
            document.getElementById("databasePassworderrorInfoMessage").classList.remove("d-none")
            return false;
        }else{
            databasePassword.classList.remove("errorStateSelect")
            document.getElementById("databasePassworderrorInfoMessage").classList.add("d-none")
        }




        document.getElementById("notificationPopupConfirm").classList.remove("d-none")
    }

    handleChange=(event)=> {
        let newValue = event.target.value;
        var dbpasswordFormat = /^(?=.*\d)(?=.*[a-z])(?=.*)[a-z0-9]{8,}$/
        var emailFormat = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        var adminPasswordFormat = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;

        if((event.target.id == "adminEmail") && !(event.target.value.match(emailFormat))){
            event.target.classList.add("errorStateSelect")
            document.getElementById(event.target.id+"errorInfoMessage").classList.remove("d-none")
            document.getElementById(event.target.id+"errorInfoMessage").textContent = "Please provide valid email"
            return false;
        }else{
            event.target.classList.remove("errorStateSelect")
            document.getElementById(event.target.id+"errorInfoMessage").classList.add("d-none")
            document.getElementById("adminEmailerrorInfoMessage").textContent = "Magento admin email"
        }

        if((event.target.id == "databasePassword") && !(event.target.value.match(dbpasswordFormat))){
            event.target.classList.add("errorStateSelect")
            document.getElementById(event.target.id+"errorInfoMessage").classList.remove("d-none")
            return false;
        }else{
            event.target.classList.remove("errorStateSelect")
            document.getElementById(event.target.id+"errorInfoMessage").classList.add("d-none")
        }

        if((event.target.id == "adminPassword") && !(event.target.value.match(adminPasswordFormat))){
            event.target.classList.add("errorStateSelect")
            document.getElementById(event.target.id+"errorInfoMessage").classList.remove("d-none")
            return false;
        }else{
            event.target.classList.remove("errorStateSelect")
            document.getElementById(event.target.id+"errorInfoMessage").classList.add("d-none")
        }

        if(newValue != ""){
            event.target.classList.add('notEmptyfield')
            event.target.classList.remove('errorStateSelect')
            $(".errorInfoMessage").addClass("d-none");
        }else{
            event.target.classList.remove('notEmptyfield')
            event.target.classList.add('errorStateSelect')
        }
        
        this.setState({[event.target.name]: event.target.value});
    }

    handleChange1 = (event) => {
        let newValue = event.target.value;
        if(newValue != ""){
            event.target.classList.add('notEmptyfield')
            event.target.classList.remove('errorStateSelect')
        }else{
            event.target.classList.remove('notEmptyfield')
            event.target.classList.add('errorStateSelect')
        }
        this.setState({[event.target.name]: event.target.value});
    }

    
    handleSubmit = (event) => {
        const {...inputfields}=this.state;
        event.preventDefault();
    }

    render() {
        $("html").on("click", function(){
            $(".errorInfoMessage").addClass("d-none")
        })
        let final=this.state;
        
        return (
            <div className="awssetup MagentoInstance">
                <h4 className="newconnector-sub-title">
                    <span className="float-left color-blue prev-back" onClick={this.props.closePopup}><i className="uil-angle-left"></i> Back</span>
                    <span className="float-left">Magento Instance</span>
                    <span className="clearfix"></span>
                </h4>
                <div className="card mt-3 m-0 no-boxshadow">
                    <div className="card-body no-padding">
                        <form name="magentoInstance" onSubmit={this.handleSubmit}>
                            <div className="row">
                                {this.props.features && this.props.features.data && this.props.features.data.slice().reverse().map(itemv =>
                                    {
                                        if(itemv.groupName == "magento" || itemv.groupName == "Magento"){
                                            return(
                                                <div className="col-md-6">
                                                {
                                                    (itemv.type == "Text") ?
                                                    <div className="form-group">
                                                        <input  type="text" className="form-control magento_version-field  version-field" name={itemv.id} placeholder={itemv.text}  id={itemv.code}  onChange={this.handleChange}/>
                                                        <span id={itemv.code+"errorInfoicon"} className="errorInfoicon">
                                                            i
                                                        </span>
                                                        <span id={itemv.code+"errorInfoMessage"} className="errorInfoMessage d-none"> 
                                                            {
                                                                itemv.code === "adminEmail" ? "Please provide Magento admin email" :
                                                                itemv.code === "adminPassword" ? 
                                                                <p>
                                                                    Please provide Magento admin Password
                                                                    <ul className="validationFormatlist">
                                                                        
                                                                        <li>Must be 8 or more characters in length</li>
                                                                        <li>Must contain 1 or more uppercase characters</li>
                                                                        <li>Must contain 1 or more numbers</li>
                                                                        <li>Must contain 1 or more special characters</li>
                                                                    </ul>
                                                                </p>

                                                                :
                                                                itemv.code === "databasePassword" ? 
                                                                <p>
                                                                    Please provide Database Password
                                                                    <ul className="validationFormatlist">
                                                                        <li>Must be 8 or more characters in length</li>
                                                                        <li>Must contain alphanumeric characters</li>
                                                                        <li>Must not contain Uppercase characters</li>
                                                                        <li>Must not contain any special characters</li>
                                                                    </ul>
                                                                </p>
                                                                : null
                                                            }
                                                        </span>
                                                    </div>: 
                                                    (itemv.type == "DropDown" || itemv.type == "Dropdown") ? 
                                                    <div className="form-group">
                                                        <select className="form-control magento_version-field version-field" id={itemv.code} name={itemv.id} onChange={this.handleChange1} >
                                                        <option value="">{itemv.text}</option>
                                                            {itemv.questionOptions.map(value=>
                                                                <option value={value.code} >{value.displayName}</option>
                                                            )}
                                                        </select>
                                                    </div>: null
                                                }
                                                </div>
                                            )
                                        }
                                    }
                                )}
                            </div>
                            <div className="row">
                                <div className="col-lg-12 float-right">
                                    <button id="awsMagentonewmove" className="btn btn-primary btn-rounded waves-effect waves-light float-right awsnext" onClick={this.togglePopupnew.bind(this)}>
                                        Next
                                    </button>
                                    {/* <button id="awsMagentonewmove" className="btn btn-primary btn-rounded waves-effect waves-light float-right awsnext" onClick={this.togglePopup.bind(this)}>
                                        <Link to={{pathname:"/Egesetupdownload", aws:this.props.aws,magento:this.state}}>Next</Link>
                                    </button> */}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div id="notificationPopupConfirm" className="d-none">
                    <div className="popupnewoverlay"></div>
                    <div className="card popupContainer">
                        <h4 className="card-header text-center mt-0">Want to Proceed...?</h4>
                        <div className="card-body">
                            <p className="card-text text-center">
                                Please Click on Confirm & Proceed button for Setup
                            </p>
                        </div>
                        <div className="text-muted">
                            <button className="btn btn-light waves-effect btn-rounded waves-effect waves-light cancel float-left" onClick={this.toggleClose.bind(this)}>
                                Cancel
                            </button>
                            <button className="btn btn-primary btn-rounded waves-effect waves-light float-right awsnext">
                                <Link to={{pathname:"/Egesetupdownload", aws:this.props.aws,magento:this.state}}>Confirm & Proceed</Link>
                            </button>
                            <div className="clearfix"> </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}