import React from 'react';

class ConnectorLength extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render(){
        return(
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-flex align-items-center justify-content-between mb-3 pb-0">
                            <h4 id="myproductshide" className="mb-0">My Products ({this.props.connectorsCount})</h4>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ConnectorLength