import React from 'react';

import API from '../../../../../../api';

import ConnectorShortInfo from '../../ConnectorShortInfo/ConnectorShortInfo'
import BusinessEntities from '../BusinessEntities/BusinessEntities';
import BusinessEntitiesCount from '../BusinessEntities/BusinessEntitiesCount/BusinessEntitiesCount';
import ViewSummary from '../BusinessEntities/ViewSummary/ViewSummary';

class Details extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedConnectorInfo : [],
            instanceEntryID : 0
        }
        window.sessionStorage.setItem("connectorIndex", 0);
    }

    componentWillReceiveProps(props) {
        this.setState({
            selectedConnectorInfo : props.selectedConnectorInfo
        })
        let self = this
        var response = window.localStorage.getItem("accessToken");
        let id = window.sessionStorage.getItem("connectorIndex")
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        const businessEntityget = props.selectedConnectorInfo
        let instancesList = businessEntityget.instances
        let instancesLength = instancesList.length

        if(instancesLength === 2){
            self.setState({
                instanceEntryID: 1
            })
        }else{
            self.setState({
                instanceEntryID: 0
            })
        }

        let subscriptionInstanceId = instancesLength === 2 ? instancesList[1].id : instancesList[0].id
        let sourceEndpointId = businessEntityget.source.id
        let targetEndpointId = businessEntityget.target.id
        API.post("/Client/connections/report/summary",
        {
            "subscriptionInstanceId": subscriptionInstanceId,
            "sourceEndpointId": sourceEndpointId,
            "requestType": "Source"
        },{headers})
        .then(response => response)
        .then(data => {
            self.setState({
                instanceSourceData: data.data
            })
        })
        API.post("/Client/connections/report/summary",
        {
            "subscriptionInstanceId": subscriptionInstanceId,
            "sourceEndpointId": targetEndpointId,
            "requestType": "Target"
        },{headers})
        .then(response => response)
        .then(data => {
            self.setState({
                instanceTargetData: data.data
            })
        })
    }

    render(){
        let selectedConnectorInfo = this.state.selectedConnectorInfo
        let instanceSourceDataLength = this.state.instanceSourceData && this.state.instanceSourceData.length
        let instanceTargetDataLength = this.state.instanceTargetData && this.state.instanceTargetData.length
        let instanceEntryID = this.state.instanceEntryID
        let instanceSourceData = this.state.instanceSourceData
        let instanceTargetData = this.state.instanceTargetData
        
        return(
            <>
                <div className="tab-pane tab-pane-details active" id={"details" + this.props.id} role="tabpanel">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="card mndesc">
                                <div className="card-header">
                                    Business Entities (<BusinessEntitiesCount instanceSourceDataLength = {instanceSourceDataLength} instanceTargetDataLength = {instanceTargetDataLength} />)
                                    <ViewSummary instanceSourceDataLength = {instanceSourceDataLength} instanceTargetDataLength = {instanceTargetDataLength} instanceEntryID = {instanceEntryID} />
                                    



                                </div>
                                <BusinessEntities instanceSourceDataLength = {instanceSourceDataLength} instanceTargetDataLength = {instanceTargetDataLength} instanceSourceData = {instanceSourceData} instanceTargetData = {instanceTargetData} />
                            </div>
                        </div>
                        <ConnectorShortInfo
                            selectedConnectorInfo = {selectedConnectorInfo}
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default Details