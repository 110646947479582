import React from 'react';
import AboutConnector from './AboutConnector/AboutConnector';
import OtherActions from './OtherActions/OtherActions';

class ConnectorShortInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentWillReceiveProps(props) {
        this.setState({
            selectedConnectorInfo : props.selectedConnectorInfo
        })
    }

    render(){
        let selectedConnectorInfoNew = this.state.selectedConnectorInfo
        let stagingisSetupCompleted = selectedConnectorInfoNew && selectedConnectorInfoNew.instances[0].isSetupCompleted
        return(
            <>
                <div className="col-lg-4">
                    <div className="card mndesc">
                        <div className="card-header">
                            Meta Data 
                            <a className="float-right font-size-10">
                                {stagingisSetupCompleted === false ? <span className="text-danger"> Setup Incomplete </span> : null}
                            </a>
                        </div>
                        <div className="card-body">
                            <AboutConnector about={selectedConnectorInfoNew} />
                            <OtherActions downloadData={selectedConnectorInfoNew} />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ConnectorShortInfo