import React, { Component } from 'react';
import $ from 'jquery';

//import CreatableSelect from 'react-select/creatable';
import Select from "@material-ui/core/Select";
import AddPrerequisites from './AddPrerequisites';
import './ChooseVersion.css'
import Fwdsync from '../../../assets/images/fwdsync.svg';
import Rvrsync from '../../../assets/images/rvrsync.svg';
import fwd_andrvrs from '../../../assets/images/fwd_andrvrs.svg'

import Navimg from '../../../assets/images/nav.svg';
import Magentoimg from '../../../assets/images/magento.svg';

import SlidingPane from 'react-sliding-pane';
import DownloadInstaller from '../DownloadInstaller/DownloadInstaller';
import axios from 'axios';
import closeicon from '../../../assets/images/close-icon.svg';

import Product from '../../../assets/images/product.svg'
import Customer from '../../../assets/images/customer-and-address.svg'
import Inventory from '../../../assets/images/inventory.svg'
import Tierpricelevel from '../../../assets/images/tierpricelevel.svg'
import Cancelorder from '../../../assets/images/cancelorder.svg'
import Configurableproduct from '../../../assets/images/configurableproduct.svg'
import Shipment from '../../../assets/images/shipment.svg'
import Salesorder from '../../../assets/images/Salesorder.svg'

import Businesstaxpostinggroup from '../../../assets/images/business-tax-posting-group.svg'
import Customerdiscountgroup from '../../../assets/images/customer-discount-group.svg'
import Discountprice from '../../../assets/images/discount-price.svg'
import Editorder from '../../../assets/images/edit-order.svg'
import Itemdiscountgroup from '../../../assets/images/item-discount-group.svg'
import Itemtaxpostinggroup from '../../../assets/images/item-tax-posting-group.svg'
import Taxposting from '../../../assets/images/tax-posting.svg'




import API from '../../api';
import SampleSelect from './reactselect'

export default class ChooseVersion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            versionType: [],
            selectedId: 1,
            isLoaded: false,
            selectedCustomer1: this.props.versionid,
            featuresType: [],
            showPopup: false,
            showPopup1: false,
            ErpName: '',
            erptypeName: 1,
            imageversionType: [],
            producttypeName: '',
            //featuresType1:[]
         
        };
    }

    togglePopup() {
        this.setState({
            showPopup: !this.state.showPopup,
        });
    }

    newtogglePopupfn() {
        this.setState({
            showPopup1: !this.state.showPopup1,
        });
    }

    handleChange(event) {
        let newValue = event.target.value;
        if(newValue != ""){
            event.target.classList.add('notEmptyfield')
        }else{
            event.target.classList.remove('notEmptyfield')
        }
    }

   async componentDidMount() { 
        this.handleversion();
    }
  
    handleversion = () => {
        let self = this;
        var response =window.localStorage.getItem("accessToken") ;
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        API.get(`/MarketPlace/connectors/${self.state.selectedCustomer1}/endpoint-versions`, { headers })
        .then(response => response)
        .then(data => {
            self.setState({
                versionType: data
            })
        })
    }

    handleNameErpName = (event) => {
        this.setState({ erptypeName: event.target.value });

        this.setState({featuresType1:[]})

        this.setState({featuresType:[]})
        
        let newValue = event.target.value;
        
        if(newValue === "" || newValue === null){
            event.target.classList.remove('notEmptyfield')
            $("#cardbdy-ftrnew").addClass("d-none")
            $("#cardftr-ftrnew").addClass("d-none")
            $("#featuersWarning").removeClass("d-none")
            document.getElementById("selecteCommerceVersion").classList.remove("notEmptyfield")
            document.getElementById("ddlViewBy").classList.remove("notEmptyfield")
            //$("#selecteCommerceVersion").removeClass("notEmptyfield")
            //$("#ddlViewBy").removeClass("notEmptyfield")
        }else{
            event.target.classList.add('notEmptyfield')
        }
    }

    handleItems = (event) => {

        let newValue = event.target.value;

        this.setState({featuresType1:[]})
        this.setState({featuresType:[]})

        if(newValue === "" || newValue === null){
            event.target.classList.remove('notEmptyfield')
            $("#cardbdy-ftrnew").addClass("d-none")
            $("#cardftr-ftrnew").addClass("d-none")
            $("#featuersWarning").removeClass("d-none")
            document.getElementById("ddlViewBy").classList.remove("notEmptyfield")
        }else{
            event.target.classList.add('notEmptyfield')
            $("#cardbdy-ftrnew").addClass("d-none")
            $("#cardftr-ftrnew").addClass("d-none")
            $("#featuersWarning").removeClass("d-none")
            $("#ddlViewBy").removeClass("notEmptyfield")
        }

        let self = this;
        self.setState({ ErpName: event.target.value });

                
        var response =window.localStorage.getItem("accessToken") ;
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };

        const body =
        {
            "id": parseInt(self.state.selectedCustomer1),
            "sourceVersion": `${self.state.erptypeName}`,
            "targetVersion": `${event.target.value}`,
        };

        API.post(`/MarketPlace/connectors/${self.state.selectedCustomer1}/features`, body, { headers })
        .then(response => response)
        .then(data => {
            self.setState({
                featuresType: data
            });
        })
    }

    handleNameErp = (event) => {
        var e = document.getElementById("ddlViewBy");
        var result = e.options[e.selectedIndex].text;
        this.setState({ producttypeName: result});
        this.setState({selectedCustomer:event.target.value})
     
        
        let newValue = event.target.value;
      
        if(newValue === "" || newValue === null){
            event.target.classList.remove('notEmptyfield')
            $("#cardbdy-ftrnew").addClass("d-none")
            $("#cardftr-ftrnew").addClass("d-none")
            $("#featuersWarning").removeClass("d-none")
        }else{
            event.target.classList.add('notEmptyfield')
            $("#cardbdy-ftrnew").removeClass("d-none")
            $("#cardftr-ftrnew").removeClass("d-none")
            $("#featuersWarning").addClass("d-none")
        }

        let self = this;
        //self.setState({ ErpName: event.target.value });

                
        var response =window.localStorage.getItem("accessToken") ;
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };

        const body =
        {
            "id": parseInt(self.state.selectedCustomer1),
            "sourceVersion": `${self.state.erptypeName}`,
            "targetVersion": `${self.state.ErpName}`,
        };

        API.post(`/MarketPlace/connectors/${self.state.selectedCustomer1}/features`, body, { headers })
        .then(response => response)
        .then(data => {
            self.setState({
                isLoaded: true,
                featuresType1: data
            });
        })

        const bodynew =
        {
            "id": `${event.target.value}`,
        };
        
        API.post(`/MarketPlace/connectors/${event.target.value}/questions`, bodynew, { headers })
            .then(response => response)
            .then(data => {
                self.setState({
                    Questions: data
                });
            })


    }

    

    
    render() {
        let { versionType } = this.state;
        let { featuresType } = this.state;
        let { featuresType1 } = this.state;

        let lenconfirmandproceed = this.state.Questions && this.state.Questions.data && this.state.Questions.data.map((item,i)=>{
           if(item.length >0)
            return <button type="button" className="btn btn-primary w-md featuer-btn-confirm customPopupnew" onClick={this.togglePopup.bind(this)}>Confirm & Proceed</button>
           else 
            return  <button type="button" className="btn btn-primary w-md featuer-btn-confirm customPanopen" onClick={this.newtogglePopupfn.bind(this)}>Confirm & Proceed</button>;;
        });

        let erpOptionItems = Array.from(new Set(versionType && versionType.data && versionType.data.map(item => item.sourceVersion))).map(sourceVersion => {
            return <option value={sourceVersion}>{sourceVersion}</option>
        })

        let ecommerceOptionItems = versionType && versionType.data && versionType.data.map(function(item, i) {
            if(item.sourceVersion == this.state.erptypeName){
                return <option value={item.targetVersion}>{item.targetVersion} </option>;        
            }
        }.bind(this));

        let productTypeOptionItems = featuresType && featuresType.data && featuresType.data.map(item =>{ 
            if(this.state.erptypeName && this.state.ErpName){ return <option value={item.latestVersion.id}>{item.displayName} </option>}})


        let featurestypesvalues=featuresType1 && featuresType1.data && featuresType1.data.map(
            item =>{
                (
                    item.latestVersion.versionEntities.map(itemv=>
                        <div className="col-lg-4 card no-border no-boxshadow">
                            <div className="featuer-circle">
                                <span className="fwdsyncimg">
                                    {
                                        (itemv.displayName == "Tier Prices") ?          <img src={Tierpricelevel} /> :
                                        (itemv.displayName == "Shipment") ?             <img src={Shipment} /> :
                                        (itemv.displayName == "ConfigurableProduct") ?  <img src={Configurableproduct} /> :
                                        (itemv.displayName == "Cancel Order") ?         <img src={Cancelorder} /> :
                                        (itemv.displayName == "Product") ?              <img src={Product} /> :
                                        (itemv.displayName == "Customer") ? <img src={Customer} /> :
                                        (itemv.displayName == "Inventory") ? <img src={Inventory} /> :
                                        (itemv.displayName == "Price Level") ? <img src={Tierpricelevel} /> :
                                        (itemv.displayName == "Customer Group") ? <img src={Customer} /> : 
                                        (itemv.displayName == "Invoice") ? <img src={Inventory} />:null
                                    }
                                </span>
                            </div>
                            <h6> {itemv.displayName}</h6>
                        </div>
                    )
                )
            
           
           
             
            } 
        )

        return (
            <div className="mainChooseVersion">
                <h4 className="newconnector-sub-title">
                    <span className="float-left color-blue prev-back" onClick={this.props.closePopup}><i className="uil-angle-left"></i> Back</span>
                    <span className="float-left" >Choose a Version</span>
                    <span className="clearfix"></span>
                </h4>
                <div className="row mt-3 plr-20">
                    <div className="col-lg-6 mb-3" >
                        <div className="card-columns card-columns-2 integration-type-listing">
                            <div className="card no-border no-boxshadow pb-0 mb-0">
                                <div className="card-body">
                                    <div className="connector-logo-container">
                                        <img src={this.props.image} className="leftimage" />
                                    </div>
                                    <h5 className="add-connector-title"> {(this.props.name).split('-')[0]} </h5>
                                </div>
                            </div>
                            
                            <div className="card no-border no-boxshadow pb-0 mb-0 attatchicon">
                                <div className="card-body">
                                    <div className="connector-logo-container">
                                        <img src={this.props.image} className="rightimage" />
                                    </div>
                                    <h5 className="add-connector-title"> {(this.props.name).split('-')[1]} </h5>
                                </div>
                            </div>
                        </div>
                        {/* <SampleSelect versionType={this.state.versionType}/> */}
                        <div className="form-section mt-3">
                            <form className="custom-validation" action="#">
                                <div className="form-group">
                                    <select id="selectERPVersion"  className="form-control version-field" required onChange={this.handleNameErpName}>
                                        <option value="">Select ERP Version </option>
                                        {erpOptionItems}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <select id="selecteCommerceVersion" className="form-control version-field"  required onChange={this.handleItems}>
                                        <option value="">Select eCommerce Version </option>
                                        {ecommerceOptionItems}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <select id={"ddlViewBy"} className="form-control version-field"  required onChange={this.handleNameErp}>
                                        <option value=""> Product Type </option>
                                        {productTypeOptionItems}
                                    </select>
                                </div>
                            </form>
                        </div>
                    </div>
                
                    <div className="col-lg-6 mb-3">
                        <div className="card featuer-container no-border no-boxshadow pb-0 mb-0">
                            <div className="card-header pb-0">
                                <h2 className="featuer-header"> Features </h2>
                            </div>
                            <div className="card-body">
                                <div id="cardbdy-ftrnew" className="row d-none">
                                    {
                                        featuresType1 && featuresType1.data && featuresType1.data.map(
                                            item =>
                                            (
                                                item.latestVersion.versionEntities.length > 0 ? 
                                                item.latestVersion.versionEntities.map(itemv=>
                                                    <div className="col-lg-4 card no-border no-boxshadow">
                                                        <div className="featuer-circle">
                                                            <span className="fwdsyncimg">
                                                                {
                                                                    (itemv.displayName == "Tier Prices") ? <img src={Tierpricelevel} /> :
                                                                    (itemv.displayName == "Shipment") ? <img src={Shipment} /> :
                                                                    (itemv.displayName == "ConfigurableProduct") ? <img src={Configurableproduct} /> :
                                                                    (itemv.displayName == "Cancel Order") ? <img src={Cancelorder} /> :
                                                                    (itemv.displayName == "Product") ? <img src={Product} /> :
                                                                    (itemv.displayName == "Customer") ? <img src={Customer} /> :
                                                                    (itemv.displayName == "Inventory") ? <img src={Inventory} /> :
                                                                    (itemv.displayName == "Price Level") ? <img src={Tierpricelevel} /> :
                                                                    (itemv.displayName == "Customer Group") ? <img src={Customer} /> : 
                                                                    (itemv.displayName == "Invoice") ? <img src={Inventory} />:
                                                                    (itemv.displayName == "Sales Order") ? <img src={Salesorder} />:

                                                                    (itemv.displayName == "Customer Discount Group") ?      <img src={Businesstaxpostinggroup} />:
                                                                    (itemv.displayName == "Discount Price") ?               <img src={Customerdiscountgroup} />:
                                                                    (itemv.displayName == "Item Discount Group") ?          <img src={Discountprice} />:
                                                                    (itemv.displayName == "Edit Order") ?                   <img src={Editorder} />:
                                                                    (itemv.displayName == "Tax Posting") ?                  <img src={Itemdiscountgroup} />:
                                                                    (itemv.displayName == "Tax Business Posting Group") ?   <img src={Itemtaxpostinggroup} />:
                                                                    (itemv.displayName == "Tax Product Posting Group") ?    <img src={Taxposting} />:null
                                                                }
                                                            </span>
                                                        </div>
                                                        <h6> {itemv.displayName}</h6>
                                                    </div>
                                                ) :
                                                <div id="featuersWarning5321" className="col-lg-12">
                                                    <div className="alert alert-warning" role="alert">
                                                        There is no Features to show
                                                    </div>
                                                </div>
                                            )
                                        )
                                    }
                                </div>
                                <div id="featuersWarning" className="row">
                                    <div className="col-lg-12"> 
                                        <div className="alert alert-warning" role="alert">
                                            Select all fields to get Features
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="cardftr-ftrnew" className="card-footer pl-2">
                                <div className="">
                                {this.state.isLoaded? (this.state.Questions && this.state.Questions.data.length >0 ?<button type="button" className="btn btn-primary w-md featuer-btn-confirm customPopupnew" onClick={this.togglePopup.bind(this)}>Confirm & Proceed</button>                                
                                :<button type="button" className="btn btn-primary w-md featuer-btn-confirm customPanopen" onClick={this.newtogglePopupfn.bind(this)}>Confirm & Proceed</button>):null}
                               
                                {/* this.state.Questions && this.state.Questions.data && this.state.Questions.data.map((item,i)=>{
                                    if(item.length >0)
                                    return <button type="button" className="btn btn-primary w-md featuer-btn-confirm customPopupnew" onClick={this.togglePopup.bind(this)}>Confirm & Proceed</button>
                                    else 
                                    return  <button type="button" className="btn btn-primary w-md featuer-btn-confirm customPanopen" onClick={this.newtogglePopupfn.bind(this)}>Confirm & Proceed</button>;; */}
                                    {/* {lenconfirmandproceed} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        
                {/* <SlidingPane className='connector-popup' overlayClassName='some-custom-overlay-class' isOpen={ this.state.isPaneOpen } onRequestClose={ () => { this.setState({ isPaneOpen: true }); } }>
                    <DownloadInstaller versionYear={this.state.erptypeName} entityVersion={this.state.ErpName} connectorId={this.state.selectedCustomer} productName={this.state.producttypeName}/>
                </SlidingPane> */}
        
                {this.state.showPopup ?
                    <AddPrerequisites versionYear={this.state.erptypeName} entityVersion={this.state.ErpName} connectorId={this.state.selectedCustomer} connectorEndPointVersionId={this.state.selectedId} closePopup={this.togglePopup.bind(this)} productName={this.state.producttypeName} Questions={this.state.Questions}/> : null 
                } 
                {this.state.showPopup1 ?
                    <DownloadInstaller versionYear={this.state.erptypeName} entityVersion={this.state.ErpName} connectorId={this.state.selectedCustomer} productName={this.state.producttypeName} closePopupnew={this.newtogglePopupfn.bind(this)} /> : null 
                }
            </div>
            
        )
    }
}