import React from 'react';
import $ from 'jquery'
import API from '../api';
import './configure.css';
import {Header} from '../Shared/Header';
import Sidebar from '../Shared/Sidebar';
import { NavLink, Link } from 'react-router-dom'

class Configure extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        let self = this;
        var response = window.localStorage.getItem("accessToken");
        let data = window.localStorage.getItem("configuredata");
        data = JSON.parse(data);
        let id = window.sessionStorage.getItem("connectorIndex");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        API.get(`/Client/connection/${data[id].id}/instance/${data[id].instances[0].id}/configurations`, { headers })
            //https://clouddev2api.i95-dev.com/api/Client/connection/343/instance/343/configurations
            .then(response => response)
            .then(data1 => {
                self.setState({
                    ConfigurationsData: data1
                })
            })
    }
    handleChange = (event) => {
        // this.setState({ sourceinputkey: event.target.id })
        this.setState({ sourceinputvalues: event.target.value })
        this.setState({ sourcetitle: event.target.title })
        // this.setState({ sourcename: event.target.name })
        this.setState({ isVisiblevalue: event.target.name.split('-')[0] })
        this.setState({ typevalue: event.target.name.split('-')[1] })
        this.setState({ endpointNamevalue: event.target.id.split('-')[1] })
        this.setState({ idValue: event.target.id.split('-')[0] })
       //// console.log(event.target.id)
        //console.log(event.target.name)
    }

    handleUpdate =(event) => {
        //alert(this.state.sourcename)
        

        if(this.state.idValue === undefined){
            document.getElementById("notChanged").classList.remove("d-none")
            setTimeout(function () {
                document.getElementById('notChanged').classList.add('d-none');
            }, 5000)
        }else{
            document.getElementById("loaderContainer").classList.remove("d-none")
            setTimeout(function () {
                document.getElementById('loaderContainer').classList.add('d-none');
            }, 5000)
        }

        let self = this;
        var response = window.localStorage.getItem("accessToken");
        let data = window.localStorage.getItem("configuredata");
        data = JSON.parse(data);
        let id = window.sessionStorage.getItem("connectorIndex");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        const body =
            [
                {
                    "id": parseInt(this.state.idValue),
                    "key": `${this.state.sourcetitle}`,
                    "values": `${this.state.sourceinputvalues}`,
                    "isVisible":Boolean(this.state.isVisiblevalue),
                    "endpointName": `${this.state.endpointNamevalue}`,
                    "type": `${this.state.typevalue}`
                }
            ]

        API.put(`/Client/${data[id].source.id}/endpointId/${data[id].target.id}/subInstanceId/${data[id].instances[0].id}`, body, { headers })
            .then(response => response)
            .then(data1 => {
                //document.getElementById("loaderContainer").className += ' d-none';
                //document.getElementById("changeAlertSuccess").className += ' d-none'
                if(data1.data === true){
                    document.getElementById("changeAlertSuccess").classList.remove("d-none")
                    document.getElementById("editeditConfig"+event.target.id).classList.remove("d-none")                    
                    document.getElementById("crosseditConfig"+event.target.id).classList.add("d-none")
                    document.getElementById("saveeditConfig"+event.target.id).classList.add("d-none")
                    setTimeout(function () {
                        document.getElementById('changeAlertSuccess').classList.add('d-none');
                    }, 5000)
                }
                console.log(data1)
                self.setState({
                    //isLoaded:true,
                    updateConfigureData1: data1
                })
            })
    }


    handleDisconnect=()=>{
      ///api/Authentication/QuickBooksDisconnect
      let self = this;
      let data = window.localStorage.getItem("configuredata");
      data = JSON.parse(data);
      var response = window.localStorage.getItem("accessToken");
      let id = window.sessionStorage.getItem("connectorIndex");
      const headers = {
          "authorization": `Bearer ${response}`,
          "content-type": "application/json"
      };
                  API.get(`/Authentication/QuickBooksDisconnect/?subscriptionConnectorId= ${data[id].instances[0].id} &instanceType=${data[id].instances[0].instanceType}`, { headers })
                       .then(response => response)
                       .then(data => {
                         // window.open(data.data,"_self");
                       })
    }
    handleInventory=(event)=>{
    this.setState({inventory:event.target.value})
    }
  handleIncome=(event)=>{
    this.setState({Income:event.target.value})
  }
  handleexpense=(event)=>{
      this.setState({Expense:event.target.value})
  }
  handleProductDetails=()=>{
      ///api​/Authentication​/SaveQuickBooksProductDetails
      let self = this;
      let data = window.localStorage.getItem("configuredata");
        data = JSON.parse(data);
      var response = window.localStorage.getItem("accessToken");
      let id = window.sessionStorage.getItem("connectorIndex");
      const headers = {
          "authorization": `Bearer ${response}`,
          "content-type": "application/json"
      };
      const body=[
        {
          "id": 0,
          "key": "Income",
          "values": `${this.state.Income}`,
          "isVisible": true,
          "endpointName":  "Quickbooks",
          "type": "Source"
        },
        {
            "id": 0,
            "key": "Expense",
            "values": `${this.state.Expense}`,
            "isVisible": true,
            "endpointName":  "Quickbooks",
            "type": "Source"
          },
          {
            "id": 0,
            "key": "Inventory",
            "values": `${this.state.inventory}`,
            "isVisible": true,
            "endpointName": "Quickbooks",
            "type":  "Source"
          }
      ]
    //   {
    //     "Expense": `${this.state.Expense}`,
    //     "Income": `${this.state.Income}`,
    //     "Inventory":`${this.state.inventory}`,
    //     "subscriptionconnectorInstanceId":parseInt(data[id].id)

    //   }
    API.put(`/Client/${data[id].source.id}/endpointId/${data[id].target.id}/subInstanceId/${data[id].instances[0].id}`, body, { headers })
    .then(response => response)
    .then(data1 => {
        //document.getElementById("loaderContainer").className += ' d-none';
        self.setState({
            //isLoaded:true,
            updateConfigureData1: data1
        })
    })
    API.get(`/Client/connection/${data[id].id}/instance/${data[id].instances[0].id}/configurations`, { headers })
    //https://clouddev2api.i95-dev.com/api/Client/connection/343/instance/343/configurations
    .then(response => response)
    .then(data1 => {
        self.setState({
            ConfigurationsData: data1
        })
    })
  }

  handleDiscountDetails=()=>{
    ///api​/Authentication​/SaveQuickBooksProductDetails
    let self = this;
    let data = window.localStorage.getItem("configuredata");
      data = JSON.parse(data);
    var response = window.localStorage.getItem("accessToken");
    let id = window.sessionStorage.getItem("connectorIndex");
    const headers = {
        "authorization": `Bearer ${response}`,
        "content-type": "application/json"
    };
    const body=[
      {
        "id": 0,
        "key": "Discount",
        "values": `${this.state.Income}`,
        "isVisible": true,
        "endpointName":  "Quickbooks",
        "type": "Source"
      }
    ]
  
  API.put(`/Client/${data[id].source.id}/endpointId/${data[id].target.id}/subInstanceId/${data[id].instances[0].id}`, body, { headers })
  .then(response => response)
  .then(data1 => {
      //document.getElementById("loaderContainer").className += ' d-none';
      self.setState({
          //isLoaded:true,
          updateConfigureData1: data1
      })
  })
  API.get(`/Client/connection/${data[id].id}/instance/${data[id].instances[0].id}/configurations`, { headers })
  //https://clouddev2api.i95-dev.com/api/Client/connection/343/instance/343/configurations
  .then(response => response)
  .then(data1 => {
      self.setState({
          ConfigurationsData: data1
      })
  })
}

    passwordShow = (event)=>{
        $(event.target).parents("tr").find("td:nth-child(2) input").attr("type","text")
        $(event.target).parents("tr").find("td:nth-child(2) .passwordHide").removeClass("d-none")
        $(event.target).addClass("d-none")
    }

    passwordHide = (event)=>{
        $(event.target).parents("tr").find("td:nth-child(2) input").attr("type","password")
        $(event.target).parents("tr").find("td:nth-child(2) .showPassword").removeClass("d-none")
        $(event.target).addClass("d-none")
    }

    render() {
        $(".trmyconnectors ").addClass("active")
        console.log(this.state.ProductData)
//     //  let optioninventory= this.state.ConfigurationsData && this.state.ConfigurationsData.data && this.state.ConfigurationsData.data.target.length > 0 ? this.state.ConfigurationsData && this.state.ConfigurationsData.data &&this.state.ConfigurationsData.data.inventory.map(item => {return<option value={item.name}>{item.name}</option>}):<option>Please select</option>
      
//     //let expense=this.state.ConfigurationsData && this.state.ConfigurationsData.data && this.state.ConfigurationsData.data.target
//      let optionexpense=this.state.ConfigurationsData && this.state.ConfigurationsData.data && this.state.ConfigurationsData.data.target.length > 0 ?this.state.ConfigurationsData && this.state.ConfigurationsData.data &&this.state.ConfigurationsData.data.expense.map(item => {return <option value={item.name}>{item.name}</option>}):<select id="" className="form-control version-field" required onChange={this.handleexpense}>
//      <option value="">Please Select </option>
//      {this.state.ConfigurationsData && this.state.ConfigurationsData.data &&this.state.ConfigurationsData.data.expense.map(item => {return <option value={item.id}>{item.name}</option>})}
//  </select>
  

//     // let optionIncome=this.state.ConfigurationsData && this.state.ConfigurationsData.data &&this.state.ConfigurationsData.data.income.map(item => { return <option value={item.name}>{item.name}</option>})

//     // let optionDiscount=this.state.ConfigurationsData && this.state.ConfigurationsData.data &&this.state.ConfigurationsData.data.discount.map(item => {return <option value={item.name}>{item.name}</option>})
       
        $("body").on("click", ".edit", function () {
            $(this).closest('tr').find('input').addClass("notEmptyfield cursorBorder")
            $(this).closest('tr').find('input').removeAttr("disabled");
            $(this).closest('tr').find('input').focus();
            $(this).closest('tr').find('.cross').removeClass("d-none")
            $(this).closest('tr').find('.save').removeClass("d-none")
            $(this).addClass("d-none");
        })

        $("body").on("click", ".cross", function () {
            $(this).addClass("d-none");
            $(this).closest('tr').find('.save').addClass("d-none")
            $(this).closest('tr').find('input').removeClass("notEmptyfield cursorBorder")
            $(this).closest('tr').find('input').attr("disabled", "true")
            $(this).parents("tr").find(".edit").removeClass("d-none")
        })

        return (
            //endpointName
            <div>
                <Header/>
                <Sidebar/>
                <div className="main-content myConnectors">
                    <div id="loaderContainer" className="loaderContainer d-none">
                        <div className="loader"></div>
                    </div>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between mb-3 pb-0">
                                        <h4 className="mb-0"> <Link className="configback customDownloadnew" to={{pathname:"/Myconnectors"}} > <span> <i className="uil-angle-left"></i> </span> Back / </Link> Configuration</h4>
                                    </div>
                                </div>
                                <div id="changeAlertSuccess" className="col-lg-12 d-none">
                                    <div className="alert alert-success" role="alert">
                                        Changes saved successfully
                                    </div>
                                </div>
                                <div id="notChanged" className="col-lg-12 d-none">
                                    <div className="alert alert-danger" role="alert">
                                        You did not changed anything
                                    </div>
                                </div>
                            </div>
                            {this.state.ConfigurationsData && this.state.ConfigurationsData.data && this.state.ConfigurationsData.data.source.length > 0 && this.state.ConfigurationsData.data.source[0].endpointName == "Shopify" ? <div> 
                                <div class="row pt-3">
                                    <div class="col-md-3">
                                        <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                            <a class="nav-link mb-2 active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">Quickbooks Authentication</a>
                                            <a class="nav-link mb-2" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">Staging Confguratiosn</a>
                                            <a class="nav-link mb-2" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">Product Configurations</a>
                                            <a class="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">Discount Configurations</a>
                                        </div>
                                    </div>
                                    <div class="col-md-9">
                                        <div class="tab-content text-muted mt-4 mt-md-0" id="v-pills-tabContent">
                                            <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                                <p>
                                                    Welcome to Quickbooks Authentication, currently you are authenticated with Quickbooks, To disconnect the connection with Quickbooks please click on Disconnect button
                                            </p>
                                                <button onClick={this.handleDisconnect}> Disconnect</button>
                                            </div>
                                            <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                                <div>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                        <table className="table table-centered table-nowrap mb-0">
                                                                            <thead className="table-light">
                                                                                <tr>

                                                                                    <th>Key</th>
                                                                                    <th className="text-center">Value</th>
                                                                                    <th className="action text-center">Action</th>
                                                                                </tr>
                                                                            </thead>
                                                                            {this.state.ConfigurationsData && this.state.ConfigurationsData.data && this.state.ConfigurationsData.data.source.length > 0 ?
                                                                                <tbody>
                                                                                    {this.state.ConfigurationsData.data.source.map(item => {
                                                                                        return (<tr>

                                                                                            <td>
                                                                                                {item.key}
                                                                                            </td>
                                                                                            <td className="text-center">
                                                                                                <input className="form-control" type="text" id="example-text-input" placeholder="Please enter Value" value={item.values} id={item.id +"-"+ item.endpointName} name={item.isVisible +"-"+ item.type} title={item.key} onChange={this.handleChange} disabled />
                                                                                            </td>
                                                                                            <td className="text-center">
                                                                                                <button id="editeditConfig" className="edit editConfig text-primary border-color-primary">
                                                                                                    <i className="uil-edit-alt font-size-18"></i>
                                                                                                </button>
                                                                                                <button id="saveeditConfig" className="save editConfig text-success border-color-success d-none" onClick={this.handleUpdate}>
                                                                                                    <i class="uil-check font-size-18"></i>
                                                                                                </button>
                                                                                                <button id="crosseditConfig" className="cross editConfig text-danger border-color-danger d-none" >
                                                                                                    <i class="uil-times font-size-18"></i>
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                        )
                                                                                    })}

                                                                                </tbody> : <div>There is no data</div>}
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                     {/*<div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="table-responsive">
                                                                        <table className="table table-centered table-nowrap mb-0">
                                                                            <thead className="table-light">
                                                                                <tr>
                                                                                    <th>Key</th>
                                                                                    <th className="text-center">Value</th>
                                                                                    <th className="action text-center">Action</th>
                                                                                </tr>
                                                                            </thead>
                                                                            {this.state.ConfigurationsData && this.state.ConfigurationsData.data && this.state.ConfigurationsData.data.target.length > 0 ? <tbody>
                                                                                {this.state.ConfigurationsData.data.target.map(item => {
                                                                                    return (<tr>
                                                                                        <td>
                                                                                            {item.key}
                                                                                        </td>
                                                                                        <td className="text-center">
                                                                                            <input className="form-control" type="text" id="example-text-input" placeholder="Please enter Value" value={item.values} id={item.id +"-"+ item.endpointName} name={item.isVisible +"-"+ item.type} title={item.key} onChange={this.handleChange} disabled />
                                                                                        </td>
                                                                                        <td className="text-center">
                                                                                            <button className="edit editConfig text-primary border-color-primary">
                                                                                                <i className="uil-edit-alt font-size-18"></i>
                                                                                            </button>
                                                                                            <button className="save editConfig text-success border-color-success d-none">
                                                                                                <i class="uil-check font-size-18"></i>
                                                                                            </button>
                                                                                            <button className="cross editConfig text-danger border-color-danger d-none">
                                                                                                <i class="uil-times font-size-18"></i>
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                    )
                                                                                })}
                                                                            </tbody> : <div>there is no data</div>}
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                                <div className="form-group">
                                                    <select id="" className="form-control version-field" required onChange={this.handleexpense}>
                                                        <option value="">Please Select Expense </option>
                                                        {this.state.ConfigurationsData && this.state.ConfigurationsData.data &&this.state.ConfigurationsData.data.expense.map(item => {return <option value={item.id}>{item.name}</option>})}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <select className="form-control version-field" required onChange={this.handleIncome}>
                                                        <option value="">Shipping Income</option>
                                                       {this.state.ConfigurationsData && this.state.ConfigurationsData.data &&this.state.ConfigurationsData.data.income.map(item => { return <option value={item.id}>{item.name}</option>})}
                                                    </select>
                                                </div><div className="form-group">
                                                    <select className="form-control version-field" required onChange={this.handleInventory}>
                                                        <option value="">Inventory Asset</option>
                                                        {this.state.ConfigurationsData && this.state.ConfigurationsData.data &&this.state.ConfigurationsData.data.inventory.map(item => {return<option value={item.id}>{item.name}</option>})}
                                                    </select>
                                                </div>
                                                <button onClick={this.handleProductDetails}>Submit</button>
                                            </div>
                                            <div class="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                                <div className="form-group">
                                                    <select id="" className="form-control version-field" required onChange={this.handleNameErpName}>
                                                        <option value="">Please Select Discount Type</option>
                                                        {this.state.ConfigurationsData && this.state.ConfigurationsData.data &&this.state.ConfigurationsData.data.discount.map(item => {return <option value={item.id}>{item.name}</option>})}
                                                    </select>
                                                </div>
                                                <button onClick={this.handleDiscountDetails}>Submit</button>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div> :  <div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="table-responsive">
                                                    <table className="table table-centered table-nowrap mb-0">
                                                        <thead className="table-light">
                                                            <tr>

                                                                <th>Key</th>
                                                                <th className="text-center">Value</th>
                                                                <th className="action text-center">Action</th>
                                                            </tr>
                                                        </thead>
                                                        {this.state.ConfigurationsData && this.state.ConfigurationsData.data && this.state.ConfigurationsData.data.source.length > 0 ?
                                                            <tbody>
                                                                {this.state.ConfigurationsData.data.source.map(item => {
                                                                    return (<tr>

                                                                        <td className="text-capitalize">
                                                                            {item.key}
                                                                        </td>
                                                                        <td className="text-center">
                                                                        {
                                                                            item.key === "adminPassword" ? 
                                                                            <>
                                                                                <input className="form-control" type="password" id="example-text-input" placeholder="Please enter Value" defaultValue={item.values} id={item.id +"-"+ item.endpointName} name={item.isVisible +"-"+ item.type} title={item.key} onChange={this.handleChange} disabled /> 
                                                                                <i class="uil-eye font-size-18 showPassword" id={item.id} onClick={this.passwordShow}></i>
                                                                                <i class="uil-eye-slash font-size-18 showPassword passwordHide d-none" id={item.id} onClick={this.passwordHide}></i>
                                                                            </> : 
                                                                            <>
                                                                                <input className="form-control" type="text" id="example-text-input" placeholder="Please enter Value" defaultValue={item.values} id={item.id +"-"+ item.endpointName} name={item.isVisible +"-"+ item.type} title={item.key} onChange={this.handleChange} disabled />
                                                                            </>
                                                                        }
                                                                            
                                                                        </td>
                                                                        <td className="text-center">
                                                                            <button id={"editeditConfig"+item.id} className="edit editConfig text-primary border-color-primary">
                                                                                <i className="uil-edit-alt font-size-18"></i>
                                                                            </button>
                                                                            <button id={"saveeditConfig"+item.id} className="save editConfig text-success border-color-success d-none">
                                                                                <i class="uil-check font-size-18" id={item.id}  onClick={this.handleUpdate}></i>
                                                                            </button>
                                                                            <button id={"crosseditConfig"+item.id} className="cross editConfig text-danger border-color-danger d-none" >
                                                                                <i class="uil-times font-size-18"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                    )
                                                                })}

                                                            </tbody> : <div>There is no data</div>}
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div><div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="table-responsive">
                                                    <table className="table table-centered table-nowrap mb-0">
                                                        <thead className="table-light">
                                                            <tr>

                                                                <th>Key</th>
                                                                <th className="text-center">Value</th>
                                                                <th className="action text-center">Action</th>
                                                            </tr>
                                                        </thead>
                                                        {this.state.ConfigurationsData && this.state.ConfigurationsData.data && this.state.ConfigurationsData.data.target.length > 0 ? <tbody>
                                                            {this.state.ConfigurationsData.data.target.map(item => {
                                                                return (<tr>
                                                                    <td className="text-capitalize">
                                                                        {item.key}
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {
                                                                            item.key === "databasePassword" ? 
                                                                            <>
                                                                                <input className="form-control" type={"password"} id="example-text-input" placeholder="Please enter Value" defaultValue={item.values} id={item.id +"-"+ item.endpointName} name={item.isVisible +"-"+ item.type} title={item.key} onChange={this.handleChange} disabled />
                                                                                <i class="uil-eye font-size-18 showPassword" id={item.id} onClick={this.passwordShow}></i>
                                                                                <i class="uil-eye-slash font-size-18 showPassword passwordHide d-none" id={item.id} onClick={this.passwordHide}></i>
                                                                            </>
                                                                             : 
                                                                            <input className="form-control" type="text" id="example-text-input" placeholder="Please enter Value" defaultValue={item.values} id={item.id +"-"+ item.endpointName} name={item.isVisible +"-"+ item.type} title={item.key} onChange={this.handleChange} disabled />
                                                                        }
                                                                        
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <button id={"editeditConfig"+item.id} className="edit editConfig text-primary border-color-primary">
                                                                            <i className="uil-edit-alt font-size-18"></i>
                                                                        </button>
                                                                        <button id={"saveeditConfig"+item.id} className="save editConfig text-success border-color-success d-none" onClick={this.handleUpdate}>
                                                                            <i class="uil-check font-size-18"></i>
                                                                        </button>
                                                                        <button id={"crosseditConfig"+item.id} className="cross editConfig text-danger border-color-danger d-none" >
                                                                            <i class="uil-times font-size-18"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                )
                                                            })}
                                                        </tbody> : <div>There is no data</div>}
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            
                               

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Configure