import React from 'react';
import {Header} from '../Shared/Header';
import Sidebar from '../Shared/Sidebar';

import './bigcommerce.css'

class BigcommerceRegistration extends React.Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div className="container-fluid">
                <Header />
                <Sidebar />
                <div className="main-content myProfile">
                    <div className="page-content pb-0">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between mb-3 pb-0">
                                        <h4 className="mb-0">Bigcommerce Registration</h4>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-12">
                                    <div className="bigcommerce-mrauto">
                                        <div className="card">
                                            <div className="card-body">
                                                <form className="needs-validation" novalidate="">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label for="validationCustom01">First Name</label>
                                                                <input type="text" className="form-control" id="validationCustom01" placeholder="First name" required="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label for="validationCustom02">Last Name</label>
                                                                <input type="text" className="form-control" id="validationCustom02" placeholder="Last name" required="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label for="validationCustom02">Select ERP Type</label>
                                                                <select className="form-control">
                                                                    <option>Select</option>
                                                                    <option>Large select</option>
                                                                    <option>Small select</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button className="btn btn-primary" type="submit">Submit</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default BigcommerceRegistration