import React from 'react';
import $ from 'jquery'
import Select from 'react-select';

class DeleteMappingNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            erpMethodName : this.props.erpMethodName,
            ecommMethodName:this.props.ecommMethodName
        }
    }
    componentWillReceiveProps(props) {
        this.setState({
            erpMethodName : props.erpMethodName,
            ecommMethodName : props.ecommMethodName
        })
    }

    deleteexistingmapping = (event) =>{
        // let erpMethodName = this.state.erpMethodName.trim()
        // let ecommMethodName = this.state.ecommMethodName.trim()
        let tablerowID = $(event.target).closest(".deleteRow").attr("id")
        $("#saveMappingShippingMapping"+event.target.id+" tr").each(function(){
            let compareID = $(this).attr("id")
            if(tablerowID === compareID){
                $(this).remove()
            }
        })
        $(event.target).closest(".deleteRow").remove()
    }
    render(){
        return(
            <>
                <button type="button" class="btn btn-primary deleteMapping deleteMappingrow" id={this.props.id} onClick={this.deleteexistingmapping}>
                    <i class="uil-trash-alt" id={this.props.id}></i>
                </button>
            </>
        )
    }
}

export default DeleteMappingNew