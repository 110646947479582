import React from 'react';
import "./manageentity.css"
import API from '../../../../../../api';
import $ from 'jquery'

class ManageEntities extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedConnectorInfo : [],
            instanceEntryID : 0
        }
        window.sessionStorage.setItem("connectorIndex", 0);
    }

    componentWillReceiveProps(props) {
        this.setState({
            selectedConnectorInfo : props.selectedConnectorInfo,
            id:props.id,
            connectorID : props.selectedConnectorInfo.id,
            instanceID: props.selectedConnectorInfo.instances[0].id
        })
        let self = this
        var response = window.localStorage.getItem("accessToken");
        let id = window.sessionStorage.getItem("connectorIndex")
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };

        

        //Manage Entity Start
        API.get(`/Connection/${props.selectedConnectorInfo.id}/instance/${props.selectedConnectorInfo.instances[0].id}/entities`, { headers })
        .then(response => response)
        .then(data => {
            self.setState({
                ManageEntities: data.data
            })
        })
        //Manage Entity End
    }

    entityecommtoerpchangeUpdate = (event) => {
        let currentId = event.target.name;
        let isInboundActiveStatus = event.target.checked
        let isOutboundActiveStatus = JSON.parse(event.target.id)
        $(event.target).parents("tr").find("td:nth-child(3) input").attr("id",JSON.parse(event.target.checked))
        let connectionID = $(event.target).parents("tbody").attr("id")
        let instanceId = $(event.target).parents("tbody").attr("id")
        let entityId = event.target.name
        let id = window.sessionStorage.getItem("connectorIndex");
        var response = window.localStorage.getItem("accessToken");
        let self = this;
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        //const movies = self.state.datapoint.data;
        const body = {
            "isInboundActive" : isInboundActiveStatus,
            "isOutboundActive": isOutboundActiveStatus
        }
        API.post(`/Connection/${this.props.selectedConnectorInfo.id}/instance/${this.props.selectedConnectorInfo.instances[0].id}/entities/${entityId}`, body, { headers })
        .then(response => response)
        .then(data => {
            if(data.data.result === false && data.data.message === "No changes made"){
                document.getElementById("loaderContainer").classList.add("d-none")
                document.getElementById("disableAftersync").textContent = data.data.message
                document.getElementById("disableAftersync").classList.remove("d-none")
                setTimeout(function () {
                    document.getElementById('disableAftersync').classList.add('d-none');
                }, 7000)
            }else if(data.data.result === true && data.data.message === null){
                document.getElementById("entityMappingerrormessage").classList.remove("d-none")
                setTimeout(function () {
                    document.getElementById('entityMappingerrormessage').classList.add('d-none');
                }, 5000)
            }else if(data.data.result === false && data.data.message === "Please disable after all entities Synced...!"){
                $(event.target).prop("checked", true)
                document.getElementById("loaderContainer").classList.add("d-none")
                document.getElementById("disableAftersync").textContent = data.data.message
                document.getElementById("disableAftersync").classList.remove("d-none")
                setTimeout(function () {
                    document.getElementById('disableAftersync').classList.add('d-none');
                }, 7000)
            }
        })
    }

    entityerptoecommchangeUpdate = (event) => {
        let currentId = event.target.name;
        let isOutboundActiveStatus = event.target.checked
        let isInboundActiveStatus = JSON.parse(event.target.id)
        $(event.target).parents("tr").find("td:nth-child(2) input").attr("id",JSON.parse(event.target.checked))
        let connectionID = $(event.target).parents("tbody").attr("id")
        let instanceId = $(event.target).parents("tbody").attr("id")
        let entityId = event.target.name
        let id = window.sessionStorage.getItem("connectorIndex");
        var response = window.localStorage.getItem("accessToken");
        let self = this;
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        //const movies = self.state.datapoint.data;
        const body = {
            "isInboundActive" : isInboundActiveStatus,
            "isOutboundActive": isOutboundActiveStatus
        }
        API.post(`/Connection/${this.props.selectedConnectorInfo.id}/instance/${this.props.selectedConnectorInfo.instances[0].id}/entities/${entityId}`, body, { headers })
        .then(response => response)
        .then(data => {
            if(data.data.result === false && data.data.message === "No changes made"){
            }else if(data.data.result === true && data.data.message === null){
                document.getElementById("entityMappingerrormessage").classList.remove("d-none")
                setTimeout(function () {
                    document.getElementById('entityMappingerrormessage').classList.add('d-none');
                }, 5000)
            }
        })
    }

    render(){
        return(
            <>
                <div className="tab-pane tab-pane-details" id={"manageentities"+this.state.id} role="tabpanel">
                    <div className="row">
                        <div className="col-lg-12">
                            <div id="entityMappingerrormessage" className="alert alert-success d-none" role="alert">
                                Entity Updated successfully
                            </div>
                            <div id="productAttributeMappingerrormessage" className="alert alert-success d-none" role="alert">
                                Mapping(s) Updated successfully
                            </div>
                            <div id="disableAftersync" className="alert alert-warning d-none" role="alert">
                                
                            </div>
                            <div className="card mndesc">
                                <div className="card-header">
                                    Manage Entities
                                </div>
                                <div className="card-body mapping-tab-sec">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="mt-0 table-responsive overflowMapping mx-height270">
                                                <table className="table table-bordered table-striped mb-0 manageentitiestable">
                                                    <thead>
                                                        <tr>
                                                            <th>Entity Name</th>
                                                            <th className="text-center">eCommerce to ERP</th>
                                                            <th className="text-center">ERP to eCommerce</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody id={this.props.id}>
                                                        {
                                                            this.state.ManageEntities && this.state.ManageEntities.map((entityItem) =>
                                                            <tr>
                                                                <td> {entityItem.displayName} </td>
                                                                    <td className="text-center"> 
                                                                        <label class="switch">
                                                                            {
                                                                                (entityItem.isInboundEditable === true) && (entityItem.isInboundActive === true) ?
                                                                                    <input type="checkbox" id={entityItem.isOutboundActive.toString()} name={entityItem.id} defaultChecked="checked" onClick={this.entityecommtoerpchangeUpdate} /> 
                                                                                : null
                                                                            }
                                                                            {
                                                                                (entityItem.isInboundEditable === true) && (entityItem.isInboundActive === false) ?
                                                                                    <input type="checkbox" id={entityItem.isOutboundActive.toString()} name={entityItem.id} onClick={this.entityecommtoerpchangeUpdate} />
                                                                                : null
                                                                            }
                                                                            {
                                                                                (entityItem.isInboundEditable === false) && (entityItem.isInboundActive === true) ?
                                                                                    <input type="checkbox" id={entityItem.isOutboundActive.toString()} name={entityItem.id} defaultChecked="checked" disabled/>
                                                                                : null
                                                                            }
                                                                            {
                                                                                (entityItem.isInboundEditable === false) && (entityItem.isInboundActive === false) ?
                                                                                    <input type="checkbox" id={entityItem.isOutboundActive.toString()} name={entityItem.id} disabled/>
                                                                                : null
                                                                            }
                                                                            <span class="slider round"></span>
                                                                        </label>
                                                                    </td>
                                                                    <td className="text-center"> 
                                                                        {
                                                                            ((entityItem.isOutboundEditable === true) && (entityItem.isOutboundActive === true)) ?
                                                                            <label class="switch">
                                                                                <input type="checkbox" id={entityItem.isInboundActive.toString()} name={entityItem.id} onClick={this.entityerptoecommchangeUpdate} defaultChecked />
                                                                                <span class="slider round"></span>
                                                                            </label> : null
                                                                        }

                                                                        {
                                                                            ((entityItem.isOutboundEditable === true) && (entityItem.isOutboundActive === false)) ?
                                                                            <label class="switch">
                                                                                <input type="checkbox" id={entityItem.isInboundActive.toString()} name={entityItem.id} onClick={this.entityerptoecommchangeUpdate} />
                                                                                <span class="slider round"></span>
                                                                            </label> : null
                                                                        }

                                                                        {
                                                                            ((entityItem.isOutboundEditable === false) && (entityItem.isOutboundActive === true)) ?
                                                                            <label class="switch">
                                                                                <input type="checkbox" id={entityItem.isInboundActive.toString()} name={entityItem.id} defaultChecked="checked" disabled/>
                                                                                <span class="slider round"></span>
                                                                            </label> : null
                                                                        }

                                                                        {
                                                                            ((entityItem.isOutboundEditable === false) && (entityItem.isOutboundActive === false)) ?
                                                                            <label class="switch">
                                                                                <input type="checkbox" id={entityItem.isInboundActive.toString()} name={entityItem.id} disabled/>
                                                                                <span class="slider round"></span>
                                                                            </label> : null
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ManageEntities