import React, {Component} from 'react';
import { Auth } from 'aws-amplify'
import API from "../api"
export default class Sessiontimeout extends Component {
    constructor(props){
        super(props)
    }

    componentDidMount() {
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };    
        API.get("/Client/connections",{headers})
        .then(response => (response.status === 401))
        .catch(error=>
        {
            if(error.response.status === 401){
                Auth.signOut();
            }
        })
    }

    render(){
        const { match } = this.props
        return (
            <>
                
            </>
        )
    }
}