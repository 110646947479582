import React from 'react'
import { Route, BrowserRouter as Router, Switch, BrowserRouter, Redirect } from 'react-router-dom';

import Home from './Components/Home/Index'
import Users from './Components/Users/Users';
import Header from './Components/Shared/Header';
import SideBar from './Components/Shared/Sidebar';
import Contact from './Components/Contact/Contact'

import Login from './Components/Login/Login'

import Dashboard from './Components/Dashboard/Dashboard'
import Myconnectors from './Components/MyConnectors/Myconnectors'
import Configure from './Components/Configure/Configure'
import Servicerequests from './Components/ServiceRequests/ServiceRequests'

import Marketplace from './Components/MarketPlace/MarketPlace'
import Viewsummary from './Components/MyConnectors/ViewSummary/ViewSummary'
import SummaryDetails from './Components/MyConnectors/ViewSummary/SummaryDetails/SummaryDetails'
import HelpDesk from './Components/HelpDesk/HelpDesk'
import KnowledgeBase from './Components/KnowledgeBase/KnowledgeBase'
import EgesetupDownload from './Components/EGE/EgeSetup/EgeSetupDownload/EgeSetupDownload'
import Profile from './Components/Profile/Profile'
import Welcome from './Components/Welcome/Welcome'
import GetWalkThrough from './Components/Getawalkthrough/Getawalkthrough'
import Updates from './Components/Updates/Updates'
import BigcommerceRegistration from './Components/BigCommerce/BigcommerceRegistration'
import Shopifyconfiguration from './Components/Shopify/ShopifyConfiguration/Shopifyconfiguration'
import Authentication from './Components/NewConnector/Setup/Authentication/Authentication';
import AuthenticationFailed from './Components/NewConnector/Setup/Authentication/AuthenticationFailed'
import DashboardEGESetup from './Components/EGE/EgeSetup/EgeSetupDownload/DashboardEGESetup'
import Setup from './Components/EGE/EgeSetup/EgeSetupDownload/Setup';
import Market from './Components/MarketPlace/MarketPlace.js'
import ShopifyAuth from "./Components/Shopify/ShopifyConfiguration/Shopify.js";
import QuickbooksAuth from "./Components/Shopify/ShopifyConfiguration/Quickbooks";
import DynamicTables from './Components/Eedittable/DynamicTable'

import Ege from './Components/EGE/Ege'

class App extends React.Component {
  render() {
    
    return (
    <div>
     
       <Router >
        <Switch>
          <div>
          <Route exact path="/" 
            render={() => { 
              return ( 
                <Redirect to="/Welcome" />
              )
            }}
          />
         
          <Route path="/Helpdesk" component={HelpDesk} />
          <Route path="/Knowledgebase" component={KnowledgeBase} />
          <Route exact path="/" activeClassName="active" component={Dashboard} />
          <Route path="/users" component={Users} />
          <Route path="/contact" component={Contact} />
          <Route path="/Dashboard" component={Dashboard} />
          <Route path="/Myconnectors" component={Myconnectors} />
          <Route path="/home" component={Home} />
          <Route path="/configure" component={Configure} />  
          <Route path="/Servicerequests" component={Servicerequests} />
          <Route exact path="/Marketplace" component={Market} />
          <Route path="/Ege" component={Ege} />
          <Route path="/Viewsummary" component={Viewsummary} />
          <Route path="/SummaryDetails" component={SummaryDetails} />
          <Route path="/Egesetupdownload" component={EgesetupDownload} />
          <Route path="/Profile" component={Profile} />
          <Route path="/Welcome" component={Welcome} />
          <Route path="/Getawalkthrough" component={GetWalkThrough} />
          <Route path="/Updates" component={Updates} />
          <Route path="/DynamicTables" component={DynamicTables} />
          <Route path="/BigcommerceRegistration" component={BigcommerceRegistration} />
          <Route path="/Shopifyconfiguration" component={Shopifyconfiguration} />
          <Route path="/Authentication" component={Authentication} />
          <Route path="/AuthenticationFailed" component={AuthenticationFailed} />
          <Route path="/DashboardEGESetup" component={DashboardEGESetup} /> 
          <Route path="/Setup" component={Setup} />   
          <Route path="/ShopifyAuth/:Subscriptionid/:instanceType" component={ShopifyAuth}/>
           <Route path="/Auth/:Subscriptionid/:instanceType" component={QuickbooksAuth}/>

          </div>
        </Switch>
      </Router>
      
    </div>
     
    )
  }
}
export default App