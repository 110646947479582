import React, {Component} from 'react';

import  Connectorversion from '../ChooseVersion/ChooseVersion.js';
import API from '../../api';
import  Connectorversion1 from '../../EGE/EgeSetup/ChooseVersion/ChooseVersion';
import SlidingPane from 'react-sliding-pane';
import closeicon from '../../../assets/images/close-icon.svg';

class IntegrationType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datapointintegration:[],
            isLoaded:true,
            selectedCustomer: 1,
            testingid: this.props.id,
            imageType:[],
            sourceName:'',
            targetName:'',
            images:[],
            imgurl:'',
            
        }; 
    }

    togglePopup() {  
        this.setState({  
            isLoaded: !this.state.isLoaded
        }); 
    }

    async componentDidMount(){
       //this.getImageData();
       this.getConnectorData();
    }

    getConnectorData=(response)=>{
        let self=this;
        var response =window.localStorage.getItem("accessToken") ;
            const headers={
                "authorization": `Bearer ${response}`,
                "content-type" : "application/json"
            };
            API.get(`/MarketPlace/endpoints/${self.props.id}/connectors`,{headers})
            .then(response => response)
            .then(data => {
                self.setState({
                    isLoaded:true,
                    datapointintegration: data 
                })
                const endpointsData = self.state.datapointintegration;
                Promise.all(endpointsData.data.map(endpointData => API.get(
                    `/MarketPlace/connectors/${endpointData.id}/image`,{headers}
                )))
                .then(resp =>resp)
                .then(result => {
                    document.getElementById("loaderContainer2").className += ' d-none';
                    self.setState({
                        images:result
                    });
            })
        })
    }

    
    handleItems=(event)=>{
        this.setState({selectedCustomer:event.target.id})
        this.setState({sourceName:event.target.title});
        this.setState({targetName:event.target.value});
        this.setState({isLoaded:false})
        this.setState({imgurl:event.target.name})
        window.localStorage.setItem("targetname",event.target.value); 
        window.localStorage.setItem("sourcename",event.target.title); 
        window.localStorage.setItem("fullname",event.target.value );
    }

    render() {
        let {datapointintegration}=this.state;
        //let {imageType}=this.state;

        let {images} = this.state;
        let images1 = images.map((item)=>item.data);
        
        return (
            <div className="IntegrationTypenew">
                <div id="loaderContainer2" className="loaderContainer">
                    <div className="loader"></div>
                </div>
                <h4 className="newconnector-sub-title">
                    <span className="float-left color-blue prev-back" onClick={this.props.closePopup}> <i className="uil-angle-left"></i> Back</span>
                    <span className="float-left">Select an Integration Type</span>
                    <span className="clearfix"></span>
                </h4>
               
                <div className="row mt-3 padding-20-new">
                    {datapointintegration && datapointintegration.data && datapointintegration.data.map((item,i)=>
                        <div className="col-lg-6 mb-3"  onClick={this.handleItems} id={item.id} name={images1[i]}>
                            <div className="card-columns card-columns-2 integration-type-listing">
                                <div className="conhoverselect" >
                                    <button type="button" className="btn btn-primary btn-rounded waves-effect waves-light new-connector" enabled={item.name} onClick={this.handleItems} id={item.id} title={item.source.shortName +"-"+ item.target.shortName} value={item.name} name={images1[i]}>
                                        Select
                                    </button>
                                </div>
                                <div className="card no-border no-boxshadow pb-0 mb-0">
                                    <div className="card-body">
                                    
                                        <div className="connector-logo-container">
                                            <img src={images1[i]} className="leftimage" title={images1[i]} />
                                        </div>
                                    
                                        <h5 className="text-truncate add-connector-title"> {item.source.shortName} </h5>
                                    </div>
                                </div>
                                
                                <div className="card no-border no-boxshadow pb-0 mb-0 attatchicon">
                                    <div className="card-body">
                                        <div className="connector-logo-container">
                                            <img src={images1[i]} className="rightimage" title={images1[i]} />
                                        </div>
                                        <h5 className="text-truncate add-connector-title"> {item.target.shortName} </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {
                        datapointintegration && datapointintegration.data && datapointintegration.data.length < 1 ? 
                        <div className="col-lg-12 mb-3">
                            <div className="alert alert-danger" role="alert">
                                There is no connectors to Show 
                            </div>                            
                        </div>: null
                    }
                </div>
                {this.state.isLoaded?<div> </div>:
               (this.state.sourceName.split('-')[1] == "EGE")? <Connectorversion1 versionid={this.state.selectedCustomer} closePopup={this.togglePopup.bind(this)}   name={this.state.sourceName.split('-')[0]} targetName={this.state.sourceName.split('-')[1]} />:
                <Connectorversion versionid={this.state.selectedCustomer} closePopup={this.togglePopup.bind(this)} image={this.state.imgurl} name={this.state.sourceName} targetName={this.state.targetName} />}
            </div>
        )
    }
}

export default IntegrationType;