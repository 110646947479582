import React from 'react';
import Select from 'react-select';
import $ from 'jquery'

import DeleteMappingNew from './deleteMappingNew';

class NewMappings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render(){
        return(
            <>
                <div id='newlyaddedrowNew'>
                    {
                        this.props.addedMappings && this.props.addedMappings.map((item, index) =>
                            <div id={"newlyaddedrow"+this.props.id+index} className='row mt-2 deleteRow' data-sort={index}>
                                <>
                                    <div class="col">
                                        <Select 
                                            placeholder={"Select ERP Method..."}
                                            options={this.props.sourceMethodsGet}
                                            defaultValue={{ label: item.selectedSourceMethod, value: item.selectedSourceMethod }}
                                            isSearchable= {false}
                                        />
                                    </div>
                                    <div class="col">
                                        <Select 
                                            placeholder={"Select Ecommerce Method..."}
                                            options={this.props.targetMethodsGet}
                                            defaultValue={{ label: item.selectedTargetMethod, value: item.selectedTargetMethod }}
                                            isSearchable= {false}
                                        />
                                    </div>
                                    <div class="col">
                                        <div className='row'>
                                            <div className='col text-center'>
                                                <div class="form-check mb-2 custom-checkbox">
                                                    {
                                                        item.erptoecomDefault === true ? 
                                                        <input class="form-check-input custom-control-input" type="checkbox" defaultChecked={true} id={"erptoecom"+this.props.id} /> :
                                                        <input class="form-check-input custom-control-input" type="checkbox" id={"erptoecom"+this.props.id} />
                                                    }
                                                    <label class="custom-control-label" for={"erptoecom"+this.props.id}></label>
                                                </div>
                                            </div>
                                            <div className='col text-center custom-checkbox'>
                                                <div class="form-check mb-2 custom-checkbox">
                                                    {
                                                        item.ecomtoerpDefault === true ?
                                                        <input class="form-check-input custom-control-input" type="checkbox" defaultChecked={true} id={"ecomtoerp"+this.props.id} />:
                                                        <input class="form-check-input custom-control-input" type="checkbox" id={"ecomtoerp"+this.props.id} />
                                                    }
                                                    <label class="custom-control-label" for={"ecomtoerp"+this.props.id}></label>
                                                </div>
                                            </div>
                                            <div className='col text-center'>
                                                <DeleteMappingNew
                                                    erpMethodName={item.selectedSourceMethod}
                                                    ecommMethodName={item.selectedTargetMethod}
                                                    id={this.props.id}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            </div>
                        )
                    }
                </div>
            </>
        )
    }
}
export default NewMappings