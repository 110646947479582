import React from 'react';
import $ from 'jquery'
import API from '../../../../../../../../api'

class SubmitMappings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentWillReceiveProps(props) {
        this.setState({

        })
    }
    productattributesaveallSource = (event) =>{
        $("#mappingLoaderContainerTTS"+event.target.name).removeClass("d-none")
        let connectorId = event.target.name
        
        $("#productAttributeMainAppendTTS"+event.target.name+" tbody tr td.deletefinal").remove()

        $("#productAttributeMainAppendTTS"+event.target.name+" tr").each(function() {
            if (this.innerHTML === '') {
                this.remove()
            }
        });

        var response = window.localStorage.getItem("accessToken");

        const results = [];
        const headersnewway = [];
        const divnew = document.getElementsByTagName("pre")
        const bodynewway = document.getElementById("productAttributeMainAppendTTS"+event.target.name)
        if (bodynewway !== null) {
            const rows = bodynewway.querySelectorAll("tr");
            rows.forEach((rowData, rowIndex) => {
                const dataRowIndex = rowIndex - 1;
                if (rowIndex > 0) results[dataRowIndex] = {};
                for (let cellIndex = 0; cellIndex < rowData.cells.length; cellIndex++) {
                    const cellData = rowData.cells.item(cellIndex);
                    if (rowIndex === 0) {
                        if (cellData !== null)
                            headersnewway[cellIndex] = cellData.textContent || "";
                    } else if (rowIndex > 0) {
                        if (cellData !== null)
                            results[dataRowIndex][headersnewway[cellIndex]] = cellData.textContent === 'false' ? false : (cellData.textContent === 'true' ? true : cellData.textContent) || "";
                    }
                }
            });
        }

        divnew.innerHTML = results;

        let self = this;
        let id = window.sessionStorage.getItem("connectorIndex")
        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        const movies = this.props.selectedData;

        const body = {"targetMappedSchema" : JSON.stringify(results)}

        API.post(`/Connection/${movies.id}/instance/${movies.instances[0].id}/save-product-mappings/Target`, body, { headers })
        .then(response => response)
        .then(data => {
            self.setState({
                instanceProductAttribuetdataPost: data,
            })
            $("#mappingLoaderContainerTTS"+event.target.name).addClass("d-none")
            $("#productAttributeMappingerrormessageTTS").removeClass("d-none")
            setTimeout(function () {
                document.getElementById('productAttributeMappingerrormessageTTS').classList.add('d-none');
            }, 5000)
        })
        $("#disabledButtonTTS"+event.target.id).removeClass("d-none")
        $("#saveAllattributeMappingsTTS"+event.target.id).addClass("d-none")
    }
    render(){
        return(
            <>
                <button type="button" id={"disabledButtonTTS"+this.props.id} className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector new-connector-position newwaybtn submitmappingNewsubmit" disabled> Submit Mappings </button>
                <button type="button" id={"saveAllattributeMappingsTTS"+this.props.id} name={this.props.id} onClick={this.productattributesaveallSource} className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector new-connector-position newwaybtn submitmappingNewsubmit d-none"> Submit Mappings </button>
            </>
        )
    }
}

export default SubmitMappings


