import React, { Component } from 'react';
import axios from 'axios';
import './myconnections.css';
import $ from 'jquery';
import { NavLink, Link } from 'react-router-dom'
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Newconnector from '../NewConnector/Newconnector'
import pimg from '../../assets/images/nav-magento.png';
import pimgactive from '../../assets/images/nav-magento-active.png';
import closeicon from '../../assets/images/close-icon.svg';
import API from '../api';
import ConnectorDownloader from '../NewConnector/DownloadInstaller/ConnectorDownloader'
//import EgeSetup from '../EGE/EgeSetup/EgeSetupDownload/EgesetupDashboard';
import EgeSetup from '../EGE/EgeSetup/EgeSetupDownload/DashboardEGESetup';
import Tabs from './ConnectorData';
import * as myConstClass from '../Constants';

import nav_gp from '../../assets/images/nav_gp.svg'
import magento from '../../assets/images/magento.svg'
import bc from '../../assets/images/bcnew.png'
import EGE from '../../assets/images/EGE.svg'
import bigcommerce from '../../assets/images/bigcommerce.png'
import qb from '../../assets/images/qb_thumb.png'
import shopify from '../../assets/images/shopify.svg'

import switchtostage from '../../assets/images/switchtostage.png'
import switchtoproduction from '../../assets/images/switchtoproduction.png'

import cloneNew from '../../assets/images/cloneNew.png'


export default class Myconnections extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPaneOpen: false,
            isPaneOpenDownload: false,
            isPaneOpenSetup: false,
            isPaneOpenLeft: false,
            render: '',
            datapoint: [],
            instanceSourceData: [],
            instanceTargetData: [],
            keyType: [],
            isLoaded: false,
            name: "",
            tname: "",
            childClass: "",
            selectedIndex: 0,
            count: 0,
            targetcount: 0,
            noconnector: myConstClass.NO_CONNECTORS,
            constantValue: myConstClass.NO_BUSSINESS_ENTITY,
        };
        window.sessionStorage.setItem("connectorIndex", 0)
    }

    handleClick = index => {
        this.setState({
            selectedIndex: index
        });
    };

    componentDidMount() {
        this.handleDConnections();
    }

    handleDConnections = () => {
        let self = this;
        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        API.get("/Client/connections", { headers })
            .then(response => response)
            .then(data => {
                if (data.data.length < 1) {
                    document.getElementById("emptyNewconnector").classList.add("d-none");
                    document.getElementById("connectorErrorWarnign").classList.remove("d-none")
                    return
                }
                self.setState({
                    datapoint: data
                })
                
                $(".connectortab:first-child").addClass("active")
                $(".tab-pane:first-child").addClass("active")

                if(data.data[0].instances.length === 2){
                    self.setState({
                        instanceEntryID: 1,
                        subscriptionInstanceID: data.data[0].instances[1].id
                    })
                }else{
                    self.setState({
                        instanceEntryID: 0,
                        subscriptionInstanceID: data.data[0].instances[0].id
                    })
                }

                const connectionData = self.state.datapoint.data;
                API.post(
                    "/Client/connections/report/summary",
                    {
                        "subscriptionInstanceId": (data.data[0].instances.length === 2) ? data.data[0].instances[1].id : data.data[0].instances[0].id,
                        "sourceEndpointId": data.data[0].source.id,
                        "requestType": "Source"
                    },
                    { headers }
                )
                    .then(response => response)
                    .then(data => {
                        self.setState({
                            instanceSourceData: data
                        })
                    })
                API.post(
                    "Client/connections/report/summary",
                    {
                        "subscriptionInstanceId": (data.data[0].instances.length === 2) ? data.data[0].instances[1].id : data.data[0].instances[0].id,
                        "sourceEndpointId": data.data[0].target.id,
                        "requestType": "target"
                    },
                    { headers }
                )
                    .then(response => response)
                    .then(data => {
                        self.setState({
                            isLoaded: true,
                            instanceTargetData: data
                        })
                    })

            })
    }

    handlegetID = (event) => {
        let self = this;
        //alert(event.target.id)

        window.sessionStorage.setItem("connectorIndex", event.target.id)

        let id = window.sessionStorage.getItem("connectorIndex")


        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        const connectionData = self.state.datapoint.data;

        if(connectionData[event.target.id].instances.length === 2){
            self.setState({
                instanceEntryID: 1,
                subscriptionInstanceID: connectionData[event.target.id].instances[1].id
            })
        }else{
            self.setState({
                instanceEntryID: 0,
                subscriptionInstanceID: connectionData[event.target.id].instances[0].id
            })
        }


        API.post(
            "/Client/connections/report/summary",
            {
                "subscriptionInstanceId": connectionData[event.target.id].instances.length === 2 ? connectionData[event.target.id].instances[1].id : connectionData[event.target.id].instances[0].id,
                "sourceEndpointId": connectionData[event.target.id].source.id,
                "requestType": "Source"
            },
            { headers }
        )
            .then(response => response)
            .then(data => {
                self.setState({
                    isLoaded: true,
                    instanceSourceData: data
                })
            })
        API.post(
            "Client/connections/report/summary",
            {
                "subscriptionInstanceId": connectionData[event.target.id].instances.length === 2 ? connectionData[event.target.id].instances[1].id : connectionData[event.target.id].instances[0].id,
                "sourceEndpointId": connectionData[event.target.id].target.id,
                "requestType": "target"
            },
            { headers }
        )
            .then(response => response)
            .then(data => {
                self.setState({
                    isLoaded: true,
                    instanceTargetData: data
                })
            })
    }

    switchtoProduction = (event) =>{
        let self = this;
        
        let id = window.sessionStorage.getItem("connectorIndex")
        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        const connectionData = self.state.datapoint.data;
        
        self.setState({
            instanceEntryID: 1,
            subscriptionInstanceID: connectionData[id].instances[1].id
        })

        API.post(
            "/Client/connections/report/summary",
            {
                "subscriptionInstanceId": connectionData[id].instances[1].id,
                "sourceEndpointId": connectionData[id].source.id,
                "requestType": "Source"
            },
            { headers }
        )
            .then(response => response)
            .then(data => {
                self.setState({
                    isLoaded: true,
                    instanceSourceData: data
                })
            })
        API.post(
            "Client/connections/report/summary",
            {
                "subscriptionInstanceId": connectionData[id].instances[1].id,
                "sourceEndpointId": connectionData[id].target.id,
                "requestType": "target"
            },
            { headers }
        )
        .then(response => response)
        .then(data => {
            self.setState({
                isLoaded: true,
                instanceTargetData: data
            })
        })
        document.getElementById("switchtoStaging").classList.remove("d-none")
        document.getElementById("switchtoProduction").classList.add("d-none")

    }

    switchtoStaging = (event) => {
        let self = this;
        
        let id = window.sessionStorage.getItem("connectorIndex")
        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        const connectionData = self.state.datapoint.data;
        self.setState({
            instanceEntryID: 0,
            subscriptionInstanceID: connectionData[id].instances[0].id
        })
        API.post(
            "/Client/connections/report/summary",
            {
                "subscriptionInstanceId": connectionData[id].instances[0].id,
                "sourceEndpointId": connectionData[id].source.id,
                "requestType": "Source"
            },
            { headers }
        )
            .then(response => response)
            .then(data => {
                self.setState({
                    isLoaded: true,
                    instanceSourceData: data
                })
            })
        API.post(
            "Client/connections/report/summary",
            {
                "subscriptionInstanceId": connectionData[id].instances[0].id,
                "sourceEndpointId": connectionData[id].target.id,
                "requestType": "target"
            },
            { headers }
        )
        .then(response => response)
        .then(data => {
            self.setState({
                isLoaded: true,
                instanceTargetData: data
            })
        })
        document.getElementById("switchtoStaging").classList.add("d-none")
        document.getElementById("switchtoProduction").classList.remove("d-none")
    }

    cloneConnector = (event) => {
        //Subscription/{subscriptionConnectorId}/CloneConnector/{subscriptionInstanceId}
        let self = this;
        let id = window.sessionStorage.getItem("connectorIndex")
        var response = window.localStorage.getItem("accessToken");
        var subscriptionConnectorId = "";
        var subscriptionInstanceId = ""
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        const body = "";
        API.post(`/Subscription/${event.target.id}/CloneConnector/${event.target.name}`, body, { headers })
        .then(response => response)
        .then(data => {
            
        })
    }

    render() {
        window.localStorage.setItem("summaryID",this.state.instanceEntryID)

        // if (this.state.isLoaded == false) {
        //     $(".connectortab:first-child").addClass("active")
        //     $(".tab-pane:first-child").addClass("active")
        // }

        let datapoint = this.state;
        let instanceSourceData = this.state;
        let instanceTargetData = this.state;
        var date = new Date();
        let dateFinal = date.toISOString()
        let id = window.sessionStorage.getItem("connectorIndex")

        let connectionLength = datapoint.datapoint && datapoint.datapoint.data && datapoint.datapoint.data.length
        let instanceSourceDataLength = instanceSourceData.instanceSourceData && instanceSourceData.instanceSourceData.data && instanceSourceData.instanceSourceData.data.length
        let instanceTargetDataLength = instanceTargetData.instanceTargetData && instanceTargetData.instanceTargetData.data && instanceTargetData.instanceTargetData.data.length
        //let instanceTargetDataLen=instanceTargetData.instanceTargetData && instanceTargetData.instanceTargetData.data &&instanceTargetData.instanceTargetData.data.map(item=>item.totalCount)
        //let instanceTargetDataLen=instanceTargetData.instanceTargetData && instanceTargetData.instanceTargetData.data &&instanceTargetData.instanceTargetData.data.length > 0?instanceTargetData.instanceTargetData && instanceTargetData.instanceTargetData.data &&instanceTargetData.instanceTargetData.data.map(item=>item.totalCount):0
        //item.entityName
        //let instanceTargetDataname=instanceTargetData.instanceTargetData && instanceTargetData.instanceTargetData.data &&instanceTargetData.instanceTargetData.data.length > 0?instanceTargetData.instanceTargetData && instanceTargetData.instanceTargetData.data &&instanceTargetData.instanceTargetData.data.map(item=>item.entityId):0

        let Sname = datapoint.datapoint && datapoint.datapoint.data && datapoint.datapoint.data[id].source.shortName
        let Tname = datapoint.datapoint && datapoint.datapoint.data && datapoint.datapoint.data[id].target.shortName
        window.sessionStorage.setItem("SourceName", Sname)
        window.sessionStorage.setItem("targetname", Tname)

        let krpType = this.state

        return (
            <div>
                <h4 className="card-title mb-2 card-title-wth-border">
                    <span className="float-left">
                        My Products
                        (
                            {
                                (connectionLength == null) ? ("0") :
                                (connectionLength < 0) ? ("0" + connectionLength) : connectionLength
                            }
                        )
                    </span>
                    <button type="button" class="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector" onClick={() => this.setState({ isPaneOpen: true })}>
                        + New Connector
                    </button>
                    <div className="clearfix"></div>
                </h4>
                <div className="row">
                    <div class="col-xl-12">
                        <div class="card no-border no-boxshadow mb-0">
                            <div className="card-body no-padding">
                                <div id="loaderContainerDownload1" className="loaderContainer d-none">
                                    <div class="loader"></div>
                                </div>
                                <div id="connectorErrorWarnign" className="row d-none">
                                    <div className="col-lg-12">
                                        <div class="alert alert-warning" role="alert">
                                            {this.state.noconnector}
                                        </div>
                                    </div>
                                </div>
                                <div id="emptyNewconnector" className="row">
                                    <div class="col-md-5">
                                        <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                            {datapoint.datapoint && datapoint.datapoint.data && datapoint.datapoint.data.slice(0, 4).map((item, index) =>
                                                <a className="nav-link mb-2 connectortab" tabindex={index} id={"v-pills-home-tab" + item.id} data-toggle="pill" href={"#v-pills-home" + item.id} role="tab" aria-controls="v-pills-home" aria-selected="true" onClick={this.handlegetID}>
                                                    <div className="selectionProcessnew" title={item.name} id={index}>
                                                    </div>
                                                    <div class="media pb-3">
                                                        <div class="mr-2">
                                                            <div className="img-cont-full img-cont-1">
                                                                {
                                                                    item.source.shortName === "EGE" ? <img src={EGE} /> : 
                                                                    item.source.shortName === "Magento" ? <img src={magento} /> : 
                                                                    (item.source.shortName === "Nav") || (item.source.shortName === "GP") || (item.source.shortName === "AX") ? <img src={nav_gp} /> : 
                                                                    (item.source.shortName === "BC") || (item.source.shortName === "Business Central") ? <img src={bc} /> :
                                                                    item.source.shortName === "BigCommerce" ? <img src={bigcommerce} /> : 
                                                                    item.source.shortName === "QuickBooks" ? <img src={qb} /> : 
                                                                    item.source.shortName === "Shopify" ? <img src={shopify} /> : null
                                                                    
                                                                }
                                                            </div>
                                                            
                                                            <div className="img-cont-full img-cont-2">
                                                                {/* <img src={magento} /> */}
                                                                {
                                                                    item.target.shortName === "EGE" ? <img src={EGE} /> : 
                                                                    item.target.shortName === "Magento" ? <img src={magento} /> : 
                                                                    (item.target.shortName === "Nav")|| (item.target.shortName === "GP") || (item.target.shortName === "AX") ? <img src={nav_gp} /> : 
                                                                    (item.target.shortName === "BC") || (item.target.shortName === "Business Central") ? <img src={bc} /> :
                                                                    item.target.shortName === "BigCommerce" ? <img src={bigcommerce} /> : 
                                                                    item.target.shortName === "QuickBooks" ? <img src={qb} /> :
                                                                    item.target.shortName === "Shopify" ? <img src={shopify} /> : null
                                                                }
                                                            </div>
                                                            {/* <img src={pimg} alt="" class="avatar-lg connector-inactive" />
                                                            <img src={pimgactive} alt="" class="avatar-lg connector-active" /> */}
                                                        </div>
                                                        <div class="media-body align-self-center overflow-hidden">
                                                            <div>
                                                                <h5 class="font-size-14 custom-h5">
                                                                    <a href="" class="text-dark text-truncate" title={item.name}> {item.name}</a>
                                                                    {String(item.instances[0].isSetupCompleted) == "false" ?
                                                                        <a className="float-right font-size-10 infoNewway" id={index}>
                                                                            <i class="uil-circle" id={index}></i>
                                                                            <span id={index}>
                                                                                <span className="text-danger font-weight-bolder"> Setup Incomplete </span>
                                                                            </span>
                                                                        </a>
                                                                        : null
                                                                    }
                                                                </h5>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    {
                                                                        ((Math.round((new Date(item.expiryDate) - new Date(dateFinal)) / (1000 * 3600 * 24))) < "1") ? <div className=""> <p className="text-muted mb-0 font-size-10 d-none"> Expired </p> <h5 className="connectorExpired"> Expired </h5> </div> : <div className=""> <p className="text-muted mb-0 font-size-9"> Expires in </p> <h5 className="font-size-10"> {Math.round((new Date(item.expiryDate) - new Date(dateFinal)) / (1000 * 3600 * 24)) + " days"} </h5> </div>
                                                                    }
                                                                    <div className="d-none">
                                                                        <p className="text-muted mb-0 font-size-9">Expires in</p>
                                                                        <h5 className="font-size-9">
                                                                            {
                                                                                ((Math.round((new Date(item.expiryDate) - new Date(dateFinal)) / (1000 * 3600 * 24))) < "1") ? "Expired" : Math.round((new Date(item.expiryDate) - new Date(dateFinal)) / (1000 * 3600 * 24)) + " days"
                                                                            }
                                                                        </h5>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="">
                                                                        <p className="text-muted mb-0 font-size-9">Error Logs</p>
                                                                        <h5 className="font-size-10">{item.instances[0].errorCount}</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="">
                                                                        <p className="text-muted mb-0 font-size-9">Pipes</p>
                                                                        <h5 className="font-size-10">{item.instances[0].entityCount}</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            )}
                                            {
                                                (connectionLength > 4) ? <NavLink to="/Myconnectors" className="btn btn-primary btn-rounded waves-effect waves-light viewmorenewsec"> View More </NavLink> : null
                                            }
                                        </div>
                                    </div>
                                    <div class="col-md-7 connection-info no-padding">
                                        <div class="tab-content text-muted mt-4 mt-md-0" id="v-pills-tabContent">
                                            {datapoint.datapoint && datapoint.datapoint.data && datapoint.datapoint.data.slice(0, 4).map(item =>
                                                <div class="tab-pane fade show" id={"v-pills-home" + item.id} role="tabpanel" aria-labelledby={"v-pills-home-tab" + item.id}>
                                                    <div className="card no-border no-boxshadow mb-0">
                                                        <div className="card-header card-titlenew">
                                                            <span className="float-left">
                                                                {item.name} 
                                                            </span>
                                                            {/* <span className="connector-status float-right">
                                                                <span className="stagednew">
                                                                    {datapoint.datapoint && datapoint.datapoint.data && datapoint.datapoint.data[0].instances[0].instanceType}
                                                                </span>
                                                            </span> */}
                                                            <div className="connector-status float-right d-none">
                                                                <div className="float-left">
                                                                    {
                                                                        item.instances.map(instanceType =>

                                                                            instanceType.instanceType === "Staging" ? 
                                                                            <span className="stagednew">
                                                                                Staging
                                                                            </span> : null
                                                                        )
                                                                    }
                                                                </div>
                                                                
                                                                {
                                                                    item.instances.map(instanceType =>
                                                                        instanceType.instanceType === "Production" ?
                                                                        <>
                                                                            <div class="switchToggle form-check form-switch form-switch-md float-left">
                                                                                <label id="switchtoStaging" class="switch" onClick={this.switchtoStaging}>
                                                                                    <img src={switchtostage} />
                                                                                </label>

                                                                                <label id="switchtoProduction" class="switch d-none" onClick={this.switchtoProduction}>
                                                                                    <img src={switchtoproduction} />
                                                                                </label>
                                                                            </div>
                                                                            <div className="float-left">
                                                                                <span className="stagednew">
                                                                                    Production
                                                                                </span>
                                                                            </div>
                                                                        </> : null
                                                                    )
                                                                }


                                                                

                                                                
                                                            </div>
                                                            <span className="clearfix"></span>
                                                        </div>
                                                        <div className="card-body padding-btm-0">
                                                            <div class="table-responsive connectorshotdesc">
                                                                <table class="table table-nowrap table-centered mb-0">
                                                                    <tbody>
                                                                        <tr>
                                                                            <th scope="row" colspan="4" class="text-left">
                                                                                {item.source.name}
                                                                            </th>
                                                                            <td class="text-right">
                                                                                {item.source.version}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th scope="row" colspan="4" class="border-0 text-left">
                                                                                {item.target.name}
                                                                            </th>
                                                                            <td class="border-0 text-right">
                                                                                {item.target.version}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th scope="row" colspan="4" class="border-0 text-left">Product Type</th>
                                                                            <td class="border-0 text-right">{item.featureName}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th scope="row" colspan="4" class="border-0 text-left">
                                                                                {/* {this.state.subscriptionInstanceID} */}
                                                                            </th>
                                                                            <td class="border-0 text-right">
                                                                                {/* <a href="#" className="cloneConnector" name={this.state.subscriptionInstanceID} id={item.id} onClick={this.cloneConnector}> 
                                                                                    Clone Connector  
                                                                                </a> */}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            <h3 className="subtitleNew">
                                                                Business Entity (
                                                                    {
                                                                        instanceSourceDataLength > 0 && instanceTargetDataLength < 0 ? instanceSourceDataLength :
                                                                        instanceSourceDataLength < 0 && instanceTargetDataLength > 0 ? instanceTargetDataLength :
                                                                        instanceSourceDataLength < instanceTargetDataLength ? instanceTargetDataLength :
                                                                        instanceSourceDataLength > instanceTargetDataLength ? instanceSourceDataLength : 
                                                                        instanceSourceDataLength === instanceTargetDataLength ? instanceTargetDataLength : 0
                                                                    }
                                                                )

                                                                {instanceSourceDataLength || instanceTargetDataLength > 0 ?
                                                                    <NavLink to={{ pathname:"/ViewSummary", id: this.state.instanceEntryID}} className="float-right text-primary" >
                                                                        View Details
                                                                    </NavLink> : <button className="float-right text-primary no-border disabledNew" title="Installation setup is incomplete" disabled> View Details </button>
                                                                }
                                                            </h3>
                                                            <div className="scrollingContainer">
                                                                {
                                                                (instanceSourceDataLength > 0 && instanceTargetDataLength < 0) ?
                                                                <div className="row">
                                                                    {instanceSourceData.instanceSourceData && instanceSourceData.instanceSourceData.data && instanceSourceData.instanceSourceData.data.map((item,i)=>
                                                                        <div className="col-lg-6">
                                                                            <div class="card myconnector-businessentity-new text-white text-center p-3">
                                                                                <h5 class="mt-0 text-white businessentity-title mb-1">{item.entityName}</h5>
                                                                                <div class="syncContainer">
                                                                                    <div class="erpCircle float-left">
                                                                                        <span class="endtext">ERP</span>
                                                                                    </div>
                                                                                    <div class="erp-ec-dir float-left">
                                                                                        <span class="erp-ecomm-dir-container">
                                                                                            <span class="syncnmbr">{item.totalCount}</span>
                                                                                        </span>
                                                                                        <span class="ecomm-erp-dir-container">
                                                                                            {instanceTargetData.instanceTargetData && instanceTargetData.instanceTargetData.data && instanceTargetData.instanceTargetData.data.map(itemT =>
                                                                                                (item.entityId === itemT.entityId) ? <span className="syncnmbr"> {itemT.totalCount} </span> :
                                                                                                    null
                                                                                            )}
                                                                                            <span className="syncnmbr"> 0 </span>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div class="ecommCircle float-left">
                                                                                        <span class="endtext">EC</span>
                                                                                    </div>
                                                                                    <div class="clearfix"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div> :
                                                                (instanceSourceDataLength < 0 && instanceTargetDataLength > 0) ?
                                                                    <div className="row">
                                                                        {instanceTargetData.instanceTargetData && instanceTargetData.instanceTargetData.data &&instanceTargetData.instanceTargetData.data.map((item,i)=>
                                                                            <div className="col-lg-6">
                                                                                <div class="card myconnector-businessentity-new text-white text-center p-3" id={"myconnector-businessentity-new"+item.entityId}>
                                                                                    <h5 class="mt-0 text-white businessentity-title mb-1">{item.entityName}</h5>
                                                                                    <div class="syncContainer">
                                                                                        <div class="erpCircle float-left">
                                                                                            <span class="endtext">ERP</span>
                                                                                        </div>
                                                                                        <div class="erp-ec-dir float-left">
                                                                                            <span class="erp-ecomm-dir-container">
                                                                                                {instanceSourceData.instanceSourceData && instanceSourceData.instanceSourceData.data && instanceSourceData.instanceSourceData.data.map(itemT =>
                                                                                                    (item.entityId === itemT.entityId) ? <span className="syncnmbr"> {itemT.totalCount} </span> :
                                                                                                        null
                                                                                                )}
                                                                                                <span className="syncnmbr"> 0 </span>
                                                                                                
                                                                                            </span>
                                                                                            <span class="ecomm-erp-dir-container">
                                                                                                <span class="syncnmbr">{item.totalCount}</span>
                                                                                            </span>
                                                                                            {
                                                                                                /*<span class="ecomm-erp-dir-container">
                                                                                                <span class="syncnmbr">{ (instanceTargetDataLen[i] !== null) ?((item.entityId === instanceTargetDataname[i])? instanceTargetDataLen[i] :"0"):"0" }</span>
                                                                                                </span> */
                                                                                            }
                                                                                        </div>
                                                                                        <div class="ecommCircle float-left">
                                                                                            <span class="endtext">EC</span>
                                                                                        </div>
                                                                                        <div class="clearfix"></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div> : 
                                                                    (instanceSourceDataLength > instanceTargetDataLength) ?
                                                                    <div className="row">
                                                                        {instanceSourceData.instanceSourceData && instanceSourceData.instanceSourceData.data && instanceSourceData.instanceSourceData.data.map((item,i)=>
                                                                            <div className="col-lg-6">
                                                                                <div class="card myconnector-businessentity-new text-white text-center p-3" id={"myconnector-businessentity-new"+item.entityId}>
                                                                                    <h5 class="mt-0 text-white businessentity-title mb-1">{item.entityName}</h5>
                                                                                    <div class="syncContainer">
                                                                                        <div class="erpCircle float-left">
                                                                                            <span class="endtext">ERP</span>
                                                                                        </div>
                                                                                        <div class="erp-ec-dir float-left">
                                                                                            <span class="erp-ecomm-dir-container">
                                                                                                <span class="syncnmbr">{item.totalCount}</span>
                                                                                            </span>
                                                                                            <span class="ecomm-erp-dir-container">
                                                                                                {instanceTargetData.instanceTargetData && instanceTargetData.instanceTargetData.data && instanceTargetData.instanceTargetData.data.map(itemT =>
                                                                                                    (item.entityId === itemT.entityId) ? <span className="syncnmbr"> {itemT.totalCount} </span> :
                                                                                                        null
                                                                                                    )
                                                                                                }
                                                                                                <span className="syncnmbr"> 0 </span>
                                                                                            </span>
                                                                                            {
                                                                                                /*<span class="ecomm-erp-dir-container">
                                                                                                <span class="syncnmbr">{ (instanceTargetDataLen[i] !== null) ?((item.entityId === instanceTargetDataname[i])? instanceTargetDataLen[i] :"0"):"0" }</span>
                                                                                                </span> */
                                                                                            }
                                                                                        </div>
                                                                                        <div class="ecommCircle float-left">
                                                                                            <span class="endtext">EC</span>
                                                                                        </div>
                                                                                        <div class="clearfix"></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}

                                                                        
                                                                    </div> : 
                                                                    (instanceTargetDataLength  > instanceSourceDataLength) ? 
                                                                    <div className="row">
                                                                        {instanceTargetData.instanceTargetData && instanceTargetData.instanceTargetData.data && instanceTargetData.instanceTargetData.data.map((item,i)=>
                                                                            <div className="col-lg-6">
                                                                                <div class="card myconnector-businessentity-new text-white text-center p-3" id={"myconnector-businessentity-new"+item.entityId}>
                                                                                    <h5 class="mt-0 text-white businessentity-title mb-1">{item.entityName}</h5>
                                                                                    <div class="syncContainer">
                                                                                        <div class="erpCircle float-left">
                                                                                            <span class="endtext">ERP</span>
                                                                                        </div>
                                                                                        <div class="erp-ec-dir float-left">
                                                                                            <span class="erp-ecomm-dir-container">
                                                                                                {instanceSourceData.instanceSourceData && instanceSourceData.instanceSourceData.data && instanceSourceData.instanceSourceData.data.map(itemT =>
                                                                                                    (item.entityId === itemT.entityId) ? <span className="syncnmbr"> {itemT.totalCount} </span> :
                                                                                                        null
                                                                                                    )
                                                                                                }
                                                                                                <span className="syncnmbr"> 0 </span>
                                                                                            </span>
                                                                                            <span class="ecomm-erp-dir-container">
                                                                                                <span class="syncnmbr">{item.totalCount}</span>
                                                                                            </span>
                                                                                            {
                                                                                                /*<span class="ecomm-erp-dir-container">
                                                                                                <span class="syncnmbr">{ (instanceTargetDataLen[i] !== null) ?((item.entityId === instanceTargetDataname[i])? instanceTargetDataLen[i] :"0"):"0" }</span>
                                                                                                </span> */
                                                                                            }
                                                                                        </div>
                                                                                        <div class="ecommCircle float-left">
                                                                                            <span class="endtext">EC</span>
                                                                                        </div>
                                                                                        <div class="clearfix"></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}

                                                                        
                                                                    </div> : 
                                                                    (instanceSourceDataLength == instanceTargetDataLength) ? 
                                                                    <div className="row duplicationData" id="instanceSourceData">
                                                                        {instanceTargetData.instanceTargetData && instanceTargetData.instanceTargetData.data && instanceTargetData.instanceTargetData.data.map((item,i)=>
                                                                        <div className={"col-lg-6 lengthnew benew"+item.entityId} id={"lengthnew"+item.entityId}>
                                                                            <div className="card myconnector-businessentity-new text-white text-center p-3">
                                                                                <h5 className="mt-0 text-white businessentity-title mb-1">{item.entityName}</h5>
                                                                                <div className="syncContainer">
                                                                                    <div className="erpCircle float-left">
                                                                                        <span className="endtext">ERP</span>
                                                                                    </div>
                                                                                    <div className="erp-ec-dir float-left">
                                                                                        <span className="erp-ecomm-dir-container">
                                                                                            {instanceSourceData.instanceSourceData && instanceSourceData.instanceSourceData.data && instanceSourceData.instanceSourceData.data.map(itemT=>
                                                                                                (item.entityId === itemT.entityId) ? <span className="syncnmbr"> {itemT.totalCount} </span>  :
                                                                                                null
                                                                                            )}
                                                                                            <span className="syncnmbr">
                                                                                                0
                                                                                            </span>
                                                                                        </span>

                                                                                        <span class="ecomm-erp-dir-container">
                                                                                            
                                                                                            <span className="syncnmbr"> {item.totalCount} </span>
                                                                                        </span>
                                                                                    </div>

                                                                                    <div className="ecommCircle float-left">
                                                                                        <span className="endtext">EC</span>
                                                                                    </div>
                                                                                    <div className="clearfix"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        )}
                                                                    </div> :
                                                                    <div id="errorMessageSummarynew">
                                                                        <div className="alert alert-danger" role="alert">
                                                                            There is no entities to show releted to your selected connector
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div className="col-lg-12 downloadfromdashboard">
                                                                    {String(item.instances[0].isSourceInstalled) == "false" || String(item.instances[0].isTargetInstalled) == "false" ?
                                                                        <div className="row">
                                                                            <div className="col-lg-12">
                                                                                <div class="alert alert-danger" role="alert">
                                                                                    <p>Installation setup is incomplete. To complete the installation process, Please download installers from below, and
                                                                                    install in your pc.
                                                                                    </p>
                                                                                </div>
                                                                                <div className="newDownloadButtonofway">
                                                                                    {
                                                                                        (item.target.type === 1) || (item.source.type === 1) ? ((item.target.shortName == "EGE") ? 
                                                                                            <Link className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector downloadInstallersdashboard" to={{ pathname: "/DashboardEGESetup", downloadData: this.state.datapoint.data }} > Setup </Link> : 
                                                                                            <Link className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector downloadInstallersdashboard" to={{ pathname: "/Setup", downloadData: this.state.datapoint.data }} > Setup </Link>) :
                                                                                            <button className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector downloadInstallersdashboard" onClick={() => this.setState({ isPaneOpenDownload: true })}>
                                                                                                Download Installers
                                                                                        </button>
                                                                                    }
                                                                                </div>
                                                                                <div class="table-responsive connectorshotdesc border-0 d-none">
                                                                                    <table class="table table-nowrap table-centered mb-0">
                                                                                        <tbody>
                                                                                            {
                                                                                                krpType.krpType && krpType.krpType.data && krpType.krpType.data.source.installer ?
                                                                                                    <tr>
                                                                                                        <th scope="row" colspan="4" class="text-left"><strong>{item.source.shortName} Installer</strong></th>
                                                                                                        <td>{item.source.version}</td>
                                                                                                        <td>{krpType.krpType && krpType.krpType.data && krpType.krpType.data.source.installer.size}</td>
                                                                                                        <td className="text-right"><a className="cursor-pointer" onClick={this.sourceDownload}> Download </a></td>
                                                                                                    </tr> :
                                                                                                    <tr>
                                                                                                        <th scope="row" class="text-left"><strong>{item.source.shortName} Installer</strong></th>
                                                                                                        <td colSpan="4">
                                                                                                            <div class="alert alert-warning text-center pdngtb05" role="alert">
                                                                                                                Installer Not Found
                                                                                                        </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                            }
                                                                                            {
                                                                                                krpType.krpType && krpType.krpType.data && krpType.krpType.data.target.installer ?
                                                                                                    <tr>
                                                                                                        <th scope="row" colspan="4" class="border-0 text-left"><strong>{item.target.shortName} Installer</strong></th>
                                                                                                        <td>{item.target.version}</td>
                                                                                                        <td>{krpType.krpType && krpType.krpType.data && krpType.krpType.data.target.installer.size}</td>
                                                                                                        <td className="text-right"><a className="cursor-pointer" onClick={this.targetDownload}> Download </a></td>
                                                                                                    </tr>
                                                                                                    :
                                                                                                    <tr>
                                                                                                        <th scope="row" class="text-left"><strong>{item.target.shortName} Installer</strong></th>
                                                                                                        <td colSpan="4">
                                                                                                            <div class="alert alert-warning text-center pdngtb05" role="alert">
                                                                                                                Installer Not Found
                                                                                                        </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SlidingPane className='connector-popup' overlayClassName='some-custom-overlay-class' isOpen={this.state.isPaneOpen} onRequestClose={() => { this.setState({ isPaneOpen: true }); }}>
                    <button className="popup-close" onClick={() => this.setState({ isPaneOpen: false })}>
                        <img src={closeicon} />
                    </button>
                    <Newconnector />
                </SlidingPane>

                <SlidingPane className='connector-popup' overlayClassName='some-custom-overlay-class' isOpen={this.state.isPaneOpenDownload} onRequestClose={() => { this.setState({ isPaneOpenDownload: true }); }}>
                    <button className="popup-close" onClick={() => this.setState({ isPaneOpenDownload: false })}>
                        <img src={closeicon} />
                    </button>
                    <ConnectorDownloader downloadData={this.state.datapoint.data} />
                </SlidingPane>

                <SlidingPane className='connector-popup' overlayClassName='some-custom-overlay-class' isOpen={this.state.isPaneOpenSetup} onRequestClose={() => { this.setState({ isPaneOpenSetup: true }); }}>
                    <button className="popup-close" onClick={() => this.setState({ isPaneOpenSetup: false })}>
                        <img src={closeicon} />
                    </button>
                    <EgeSetup downloadData={this.state.datapoint.data} />
                </SlidingPane>
            </div>
        )
    }
}