import React from 'react';
import './shopifyconfiguration.css'
import axios from 'axios';
import Select from 'react-select';

import {Header} from '../../Shared/Header';
import Sidebar from '../../Shared/Sidebar';

import API from '../../api';


class Shopifyconfiguration extends React.Component {
    constructor(){
        super();
        this.state={
            locationValue : null
        }
    }
    componentDidMount() {
        let self = this;
        var response = window.localStorage.getItem("accessToken");  
        let id = window.sessionStorage.getItem("connectorIndex");
        const headers = {
          "authorization": `Bearer ${response}`,
          "content-type": "application/json"
        };
        
        let getData = window.localStorage.getItem("data")
            getData = JSON.parse(getData)
        let endpointId = getData && getData.target.id
        let instanceId = getData && getData.instances[0].id
        API.get(`/Shopify/endpoint/${endpointId}/instance/${instanceId}/locations`,{headers })
        .then(response => response)
        .then(data =>{
            self.setState({
                locations:data.data
            })
        })
    }
    selectLocation = (event) => {
        let self = this;
        self.setState({
            locationValue : event.value
        })
    }
    
    updateLocation = (event) => {
        let self = this;
        var response = window.localStorage.getItem("accessToken");  
        let id = window.sessionStorage.getItem("connectorIndex");
        const headers = {
          "authorization": `Bearer ${response}`,
          "content-type": "application/json"
        };
        const body ={
        }
        let getData = window.localStorage.getItem("data")
            getData = JSON.parse(getData)
        let endpointId = getData && getData.target.id
        let instanceId = getData && getData.instances[0].id
        let locationId = self.state.locationValue

        API.put(`/Shopify/endpoint/${endpointId}/instance/${instanceId}/locations/${locationId}`,{body},{headers })
        .then(response => response)
        .then(data =>{
            console.log("Praveen Data53218",data)
        })

    }
    render() {
        let locations = this.state.locations && this.state.locations.location

        const getLocations = locations && locations.map(item=>(
            {value: item.id, label: item.city}
        ))
        return (
            <div>
                <Header />
                <Sidebar />
                <div className="main-content myConnectors">
                    <div className="page-content pb-0">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <form>
                                                <div className="form-group row">
                                                    <label for="example-text-input" className="col-md-2 col-form-label">Select Store Location</label>
                                                    <div className="col-md-10">
                                                        <Select 
                                                            className=""
                                                            options={getLocations}
                                                            onChange={this.selectLocation}
                                                            placeholder= {"Select ERP Method"}
                                                            closeMenuOnSelect={true}                                                                                                                                            
                                                            value={this.state.value}
                                                            name={"getlocation"}
                                                        />
                                                        {/* <select className="form-control" >
                                                            <option value="">Please Select</option>
                                                            {
                                                                locations && locations.map(item =>
                                                                    <option value={item.city}> {item.city} </option>
                                                                )
                                                            }
                                                        </select> */}
                                                    </div>
                                                </div>
                                                <div className="form-group row justify-content-end">
                                                    <div className="col-sm-10">
                                                        <div>
                                                            <button type="button" className="btn btn-primary w-md" onClick={this.updateLocation}>Update</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Shopifyconfiguration